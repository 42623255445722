


const eyes = () => `<ellipse cx="78.2432" cy="122.811" rx="11.2432" ry="10.8108" fill="#CCE3FD"/>
<ellipse cx="75.3513" cy="123.486" rx="7.35135" ry="6.48649" fill="#08203C"/>
<ellipse cx="79.2102" cy="117.875" rx="3.56152" ry="3.28164" fill="white"/>
<ellipse cx="29.8269" cy="110.487" rx="8.8269" ry="8.48741" fill="#CCE3FD"/>
<ellipse cx="27.7714" cy="110.768" rx="5.77144" ry="5.09244" fill="#08203C"/>
<ellipse cx="30.586" cy="106.613" rx="2.7961" ry="2.57636" fill="white"/>`



const h1 = `<svg width="147" height="147" viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="147" height="147" fill="#D9D9D9" fill-opacity="0.00"/>
<path d="M135.084 80.8122C96.0036 102.693 48.2391 107.22 11.3302 80.8122C-25.3623 54.5595 70.9806 51.4229 72.1114 51.387C72.1161 51.3869 72.1192 51.3867 72.1213 51.3866C72.1323 51.3858 72.1099 51.3853 72.1213 51.3866C72.1233 51.3868 72.1264 51.3871 72.1309 51.3874C73.2598 51.4717 173.945 59.0542 135.084 80.8122Z" fill="#DCB011"/>
<path d="M11.3302 80.8122C48.2391 107.22 96.0036 102.693 135.084 80.8122C173.945 59.0542 73.2598 51.4717 72.1309 51.3874C72.0961 51.3848 72.1462 51.3859 72.1114 51.387C70.9806 51.4229 -25.3623 54.5595 11.3302 80.8122Z" stroke="#C09600" stroke-width="2"/>
<path d="M97.4495 66.0434C97.1159 31.4096 44.2312 31.4102 43.8985 66.044C43.8965 66.2526 43.9517 66.4577 44.0873 66.6164C49.9456 73.4713 91.4026 73.4709 97.2608 66.6158C97.3963 66.4572 97.4515 66.2521 97.4495 66.0434Z" fill="#D9A900" stroke="#C09600" stroke-width="2"/>
</svg>
`


const a1 = `<svg width="147" height="147" viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="147" height="147" fill="#D9D9D9" fill-opacity="0.00"/>
<path d="M21.3321 102.005C30.8936 101.89 35.8165 103.941 43.6877 108.253C44.1298 108.495 44.3207 109.03 44.1338 109.498L41.8027 115.338C48.7814 113.677 52.8889 113.97 59.4355 116.517C59.6533 116.602 59.8768 116.607 60.0792 116.551C59.9789 112.837 60.5095 110.01 60.5095 110.01C72.9139 107.615 79.951 107.774 92.1775 111.605C92.5283 111.715 92.7927 112.011 92.8433 112.376C93.3523 116.04 95.0829 135.494 74.1437 133.984C62.5669 133.149 60.2631 123.359 60.0792 116.551C59.8768 116.607 59.6533 116.602 59.4355 116.517C52.8889 113.97 48.7814 113.677 41.8027 115.338C41.8027 115.338 38.2509 126.519 28.251 123.019C18.4101 119.575 19.8652 106.175 20.4 102.796C20.4726 102.337 20.8678 102.01 21.3321 102.005Z" fill="url(#paint0_linear_6178_104218)"/>
<path d="M41.8027 115.338L44.1338 109.498C44.3207 109.03 44.1298 108.495 43.6877 108.253C35.8165 103.941 30.8936 101.89 21.3321 102.005C20.8678 102.01 20.4726 102.337 20.4 102.796C19.8652 106.175 18.4101 119.575 28.251 123.019C38.2509 126.519 41.8027 115.338 41.8027 115.338ZM41.8027 115.338C48.7814 113.677 52.8889 113.97 59.4355 116.517C60.1231 116.785 60.8675 116.258 60.8253 115.521L60.5095 110.01M60.5095 110.01C72.9139 107.615 79.951 107.774 92.1775 111.605C92.5283 111.715 92.7927 112.011 92.8433 112.376C93.3523 116.04 95.0829 135.494 74.1437 133.984C56.251 132.694 60.5095 110.01 60.5095 110.01Z" stroke="black" stroke-width="4"/>
<defs>
<linearGradient id="paint0_linear_6178_104218" x1="56.2509" y1="102.02" x2="56.2509" y2="134.02" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
</defs>
</svg>
`

const a2 = `<svg width="147" height="147" viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="147" height="147" fill="#D9D9D9" fill-opacity="0.00"/>
<path d="M58.7358 112.958C68.5138 112.84 72.4922 109.737 75.0507 102.248C75.3951 101.24 77.0097 101.27 77.2989 102.295C79.6074 110.479 83.0802 113.926 91.6444 113.466C92.7301 113.408 93.3473 115.017 92.4832 115.677C85.678 120.875 84.0172 125.32 86.7889 132.253C87.1918 133.261 85.8445 134.428 84.9054 133.884C77.7927 129.762 72.759 131.106 65.6867 136.001C64.8719 136.565 63.7488 135.736 64.0243 134.784C66.981 124.567 64.4517 120.032 58.0562 114.904C57.2837 114.285 57.7456 112.97 58.7358 112.958Z" fill="#1B146F"/>
<path d="M17.85 104.88C23.8453 104.636 26.6326 102.652 28.4295 98.2172C28.8295 97.2299 30.5008 97.2658 30.8331 98.2778C32.388 103.013 34.7113 105.226 39.622 105.301C40.7092 105.318 41.3526 107.011 40.5214 107.711C36.8011 110.849 35.7188 113.719 36.9635 117.738C37.2845 118.774 35.8533 120.016 34.8767 119.542C30.524 117.434 27.1585 118.296 22.7669 121.195C21.9399 121.741 20.7983 120.905 21.046 119.946C22.7186 113.467 21.1244 110.262 17.1508 106.893C16.3955 106.252 16.8605 104.92 17.85 104.88Z" fill="#1B146F"/>
<path d="M98.3832 113.372C98.1412 112.672 97.6832 112.054 97.0671 111.598C96.451 111.142 95.7044 110.868 94.922 110.81L83.8811 109.973L79.6201 100.309C79.3206 99.6259 78.8115 99.0417 78.1574 98.6306C77.5033 98.2195 76.7336 98 75.9463 98C75.1589 98 74.3893 98.2195 73.7352 98.6306C73.0811 99.0417 72.5719 99.6259 72.2725 100.309L68.0153 109.975L56.9744 110.81C56.1905 110.87 55.4433 111.147 54.8265 111.605C54.2097 112.063 53.7509 112.681 53.5078 113.383C53.2647 114.085 53.2482 114.838 53.4602 115.548C53.6722 116.259 54.1034 116.895 54.6995 117.376L63.1241 124.191L60.5487 134.385C60.3609 135.103 60.4053 135.858 60.6764 136.553C60.9474 137.248 61.4328 137.851 62.0708 138.287C62.7087 138.722 63.4703 138.969 64.2586 138.997C65.0469 139.025 65.8263 138.833 66.4974 138.444L75.9463 132.988L85.3951 138.444C86.0661 138.83 86.8441 139.021 87.6308 138.992C88.4175 138.963 89.1774 138.716 89.8143 138.282C90.4512 137.848 90.9366 137.247 91.2088 136.554C91.4811 135.861 91.5281 135.108 91.3438 134.39L88.7685 124.197L97.193 117.382C97.7927 116.899 98.226 116.26 98.4379 115.546C98.6498 114.832 98.6308 114.076 98.3832 113.372ZM84.6342 121.735C84.3094 121.998 84.0677 122.339 83.9356 122.722C83.8035 123.105 83.786 123.514 83.885 123.906L86.3999 133.868L77.1735 128.547C76.8044 128.334 76.3796 128.221 75.9463 128.221C75.5129 128.221 75.0881 128.334 74.7191 128.547L65.4926 133.868L68.0075 123.913C68.1066 123.522 68.0891 123.112 67.957 122.729C67.8249 122.347 67.5832 122.005 67.2583 121.743L59.0113 115.063L69.82 114.245C70.2502 114.212 70.6624 114.069 71.0111 113.831C71.3599 113.592 71.6317 113.268 71.7964 112.894L75.9463 103.469L80.0961 112.894C80.2609 113.268 80.5326 113.592 80.8814 113.831C81.2302 114.069 81.6424 114.212 82.0725 114.245L92.8812 115.063L84.6342 121.735Z" fill="#FF80CC"/>
<path d="M45.8536 105.205C45.6773 104.695 45.3434 104.245 44.8943 103.912C44.4452 103.58 43.901 103.38 43.3307 103.338L35.2826 102.728L32.1765 95.683C31.9582 95.1851 31.5871 94.7593 31.1103 94.4597C30.6335 94.16 30.0725 94 29.4986 94C28.9246 94 28.3636 94.16 27.8868 94.4597C27.41 94.7593 27.0389 95.1851 26.8206 95.683L23.7174 102.729L15.6693 103.338C15.0979 103.382 14.5532 103.583 14.1036 103.917C13.654 104.251 13.3196 104.702 13.1424 105.213C12.9652 105.725 12.9531 106.274 13.1077 106.792C13.2622 107.31 13.5765 107.773 14.0111 108.124L20.152 113.092L18.2747 120.522C18.1378 121.046 18.1702 121.596 18.3678 122.103C18.5654 122.609 18.9192 123.049 19.3842 123.366C19.8492 123.684 20.4044 123.864 20.979 123.884C21.5537 123.905 22.1217 123.764 22.611 123.481L29.4986 119.504L36.3862 123.481C36.8753 123.762 37.4424 123.902 38.0159 123.881C38.5893 123.86 39.1432 123.68 39.6075 123.363C40.0718 123.047 40.4255 122.608 40.624 122.103C40.8225 121.598 40.8567 121.049 40.7224 120.526L38.8451 113.096L44.9861 108.128C45.4232 107.776 45.7391 107.311 45.8935 106.79C46.048 106.27 46.0341 105.718 45.8536 105.205ZM35.8315 111.301C35.5947 111.493 35.4186 111.742 35.3223 112.021C35.226 112.3 35.2132 112.598 35.2854 112.884L37.1186 120.146L30.3931 116.267C30.1241 116.111 29.8144 116.029 29.4986 116.029C29.1827 116.029 28.873 116.111 28.604 116.267L21.8785 120.146L23.7117 112.889C23.7839 112.604 23.7712 112.305 23.6749 112.026C23.5786 111.747 23.4024 111.498 23.1656 111.307L17.1541 106.438L25.0329 105.841C25.3465 105.818 25.6469 105.713 25.9012 105.54C26.1554 105.366 26.3535 105.13 26.4736 104.857L29.4986 97.9869L32.5235 104.857C32.6436 105.13 32.8417 105.366 33.096 105.54C33.3502 105.713 33.6507 105.818 33.9642 105.841L41.8431 106.438L35.8315 111.301Z" fill="#FF80CC"/>
<path d="M41.5 108C49.8401 106.907 49.4172 109.52 57.5 113" stroke="#FF80CC" stroke-width="4"/>
</svg>

`

const a3 = `<svg width="147" height="147" viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="147" height="147" fill="#D9D9D9" fill-opacity="0.00"/>
<path d="M60.5095 110.01C72.9139 107.615 79.951 107.774 92.1775 111.604C92.5283 111.714 92.7927 112.011 92.8433 112.375C93.3523 116.039 95.0829 135.494 74.1437 133.984C56.7217 132.727 60.3007 111.188 60.5011 110.056C60.5065 110.025 60.5095 110.01 60.5095 110.01Z" fill="url(#paint0_linear_6179_183037)"/>
<path d="M35.5 75.5C35.5 75.5 51.6412 105.533 59.4852 110.534C59.9144 110.808 60.4334 110.513 60.5095 110.01V110.01M60.5095 110.01C72.9139 107.615 79.951 107.774 92.1775 111.604C92.5283 111.714 92.7927 112.011 92.8433 112.375C93.3523 116.039 95.0829 135.494 74.1437 133.984C56.251 132.693 60.5095 110.01 60.5095 110.01Z" stroke="black" stroke-width="4"/>
<defs>
<linearGradient id="paint0_linear_6179_183037" x1="56.2509" y1="102.02" x2="56.2509" y2="134.02" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
</defs>
</svg>
`


const accessories = {
    "a1": a1,
    "a2": a2,
    "a3": a3
  };

const hats = {
    "h1": h1
}





const bodyColor = (c1, c2) => `<stop stop-color="${c1}"/>
<stop offset="1" stop-color="${c2}"/>`

const svgBase = ({c1, c2, a, hair}) => `<svg width="900" height="900" viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="900" height="900" fill="#D9D9D9" fill-opacity="0.00"/>
<path d="M73.9691 58.0278C116.469 56.5286 145.778 116 120.732 133.528C95.6853 151.056 44.4151 151.857 20.7316 133.528C-2.95183 115.199 31.4691 59.5271 73.9691 58.0278Z" fill="url(#paint0_linear_6153_208971)" fill-opacity="0.8"/>
<path d="M74.0044 59.0272C94.7596 58.2951 112.415 72.4662 121.809 89.1337C126.499 97.4542 129.075 106.311 128.968 114.112C128.861 121.898 126.09 128.558 120.158 132.709C107.859 141.317 89.0034 145.875 70.2153 145.975C51.4193 146.074 32.9377 141.71 21.3437 132.737C15.7532 128.411 13.5362 121.86 14.0794 114.281C14.6242 106.681 17.9508 98.1213 23.363 90.0225C34.2 73.8059 53.1679 59.7623 74.0044 59.0272Z" stroke="#525FB5" stroke-opacity="0.61" stroke-width="2"/>
<path d="M72.2622 64.0197C102.419 62.9582 123.216 105.067 105.444 117.478C87.6715 129.889 51.2914 130.456 34.4862 117.478C17.681 104.5 42.1052 65.0813 72.2622 64.0197Z" fill="white" fill-opacity="0.1"/>
${eyes}
${a}
${hair}
<defs>
<linearGradient id="paint0_linear_6153_208971" x1="70.8689" y1="49.5283" x2="70.8689" y2="155.632" gradientUnits="userSpaceOnUse">
${bodyColor(c1, c2)}
</linearGradient>
</defs>
</svg>`



const svgTop_0 = ({c1, c2,a, hair}) => `<svg width="900" height="900" viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="900" height="900" fill="#D9D9D9" fill-opacity="0.00"/>
<path d="M73.9691 58.0278C116.469 56.5286 145.778 116 120.732 133.528C95.6853 151.056 44.4151 151.857 20.7316 133.528C-2.95183 115.199 31.4691 59.5271 73.9691 58.0278Z" fill="url(#paint0_linear_6154_209516)"/>
${hair}
<defs>
<linearGradient id="paint0_linear_6154_209516" x1="70.8689" y1="49.5283" x2="70.8689" y2="155.632" gradientUnits="userSpaceOnUse">
${bodyColor(c1, c2)}
</linearGradient>
</defs>
</svg>
`




const svgTop_1 = ({c1, c2,a, hair}) => `<svg width="900" height="900" viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="900" height="900" fill="#D9D9D9" fill-opacity="0.00"/>
<path d="M73.5595 58.0269C108.803 56.5778 133.108 114.06 112.338 131.002C91.5679 147.944 49.0514 148.718 29.4115 131.002C9.77173 113.286 38.3158 59.476 73.5595 58.0269Z" fill="url(#paint0_linear_6154_209552)"/>
${hair}
<defs>
<linearGradient id="paint0_linear_6154_209552" x1="70.9886" y1="49.8117" x2="70.9886" y2="152.366" gradientUnits="userSpaceOnUse">
${bodyColor(c1, c2)}
</linearGradient>
</defs>
</svg>
`


const svgTop_2 = ({c1, c2,a, hair}) => `<svg width="900" height="900" viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="900" height="900" fill="#D9D9D9" fill-opacity="0.00"/>
<path d="M69.9109 58.026C102.611 56.6274 125.162 112.104 105.891 128.455C86.6197 144.806 47.1714 145.553 28.9489 128.455C10.7263 111.357 37.2105 59.4245 69.9109 58.026Z" fill="url(#paint0_linear_6154_209588)"/>
${hair}
<defs>
<linearGradient id="paint0_linear_6154_209588" x1="67.5255" y1="50.0973" x2="67.5255" y2="149.075" gradientUnits="userSpaceOnUse">
${bodyColor(c1, c2)}
</linearGradient>
</defs>
</svg>
`



const svgTop_3 = ({c1, c2,a, hair}) => `<svg width="900" height="900" viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="900" height="900" fill="#D9D9D9" fill-opacity="0.00"/>
<path d="M73.0075 58.025C98.8044 56.677 116.594 110.149 101.392 125.909C86.1889 141.668 55.0685 142.388 40.693 125.909C26.3175 109.429 47.2105 59.373 73.0075 58.025Z" fill="url(#paint0_linear_6154_209624)"/>
${hair}
<defs>
<linearGradient id="paint0_linear_6154_209624" x1="71.1257" y1="50.383" x2="71.1257" y2="145.783" gradientUnits="userSpaceOnUse">
${bodyColor(c1, c2)}
</linearGradient>
</defs>
</svg>
`


const svgLB_0 = ({c1, c2, a, hair}) => `<svg width="900" height="900" viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="900" height="900" fill="#D9D9D9" fill-opacity="0.00"/>
<path d="M73.9691 58.0278C116.469 56.5286 145.778 116 120.732 133.528C95.6853 151.056 44.4151 151.857 20.7316 133.528C-2.95183 115.199 31.4691 59.5271 73.9691 58.0278Z" fill="url(#paint0_linear_6154_209372)"/>
${eyes}
${a}
${hair}
<defs>
<linearGradient id="paint0_linear_6154_209372" x1="70.8689" y1="49.5283" x2="70.8689" y2="155.632" gradientUnits="userSpaceOnUse">
${bodyColor(c1, c2)}
</linearGradient>
</defs>
</svg>
`

const svgLB_1 = ({c1, c2, a, hair}) => `<svg width="900" height="900" viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="900" height="900" fill="#D9D9D9" fill-opacity="0.00"/>
<path d="M73.5595 58.0269C108.803 56.5778 133.108 114.06 112.338 131.002C91.5679 147.944 49.0514 148.718 29.4115 131.002C9.77173 113.286 38.3158 59.476 73.5595 58.0269Z" fill="url(#paint0_linear_6154_209408)"/>
${eyes}
${a}
${hair}
<defs>
<linearGradient id="paint0_linear_6154_209408" x1="70.9886" y1="49.8117" x2="70.9886" y2="152.366" gradientUnits="userSpaceOnUse">
${bodyColor(c1, c2)}
</linearGradient>
</defs>
</svg>
`


const svgLB_2 = ({c1, c2, a, hair}) => `<svg width="900" height="900" viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="900" height="900" fill="#D9D9D9" fill-opacity="0.00"/>
<path d="M69.9109 58.026C102.611 56.6274 125.162 112.104 105.891 128.455C86.6197 144.806 47.1714 145.553 28.9489 128.455C10.7263 111.357 37.2105 59.4245 69.9109 58.026Z" fill="url(#paint0_linear_6154_209444)"/>
${eyes}
${a}
${hair}
<defs>
<linearGradient id="paint0_linear_6154_209444" x1="67.5255" y1="50.0973" x2="67.5255" y2="149.075" gradientUnits="userSpaceOnUse">
${bodyColor(c1, c2)}
</linearGradient>
</defs>
</svg>
`


const svgLB_3 = ({c1, c2, a, hair}) => `<svg width="900" height="900" viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="900" height="900" fill="#D9D9D9" fill-opacity="0.00"/>
<path d="M73.0075 58.025C98.8044 56.677 116.594 110.149 101.392 125.909C86.1889 141.668 55.0685 142.388 40.693 125.909C26.3175 109.429 47.2105 59.373 73.0075 58.025Z" fill="url(#paint0_linear_6154_209480)"/>
${eyes}
${a}
${hair}
<defs>
<linearGradient id="paint0_linear_6154_209480" x1="71.1257" y1="50.383" x2="71.1257" y2="145.783" gradientUnits="userSpaceOnUse">
${bodyColor(c1, c2)}
</linearGradient>
</defs>
</svg>
`


const bodyColors = [
    {c: 0, c1: "#FFFFFF4D", c2: "#FFFFFFB3", elixirs: 0},
    {c: 1, c1: "#6244B7", c2: "#272CA6", elixirs: 0},
    {c: 2, c1: "#FFC700", c2: "#FF8A00", elixirs: 0},
    {c: 3, c1: "#57A7E9", c2: "#5777E9", elixirs: 100},
    {c: 4, c1: "#43FF3FB3", c2: "#1B7986", elixirs: 100},
    {c: 5, c1: "#E957E3", c2: "#E95794", elixirs: 100},
  ];





const createAvatarSvg = ({code}) => {

    const c1 = bodyColors[code?.c]?.c1 || "white"
    const c2 = bodyColors[code?.c]?.c2 || "white"
    const a = accessories[code?.a]
    const hair = hats[code?.h]

    let svg = svgBase({ c1, c2, a, hair });
    const svgBase64 = `data:image/svg+xml;base64,${btoa(svg)}`;
    return svgBase64
}

const createAvatarUrl = async ({code, variant, svgRender}) => {
    console.log('code', code)
    // console.log('code color', bodyColors[code.c])
    const c1 = bodyColors[code?.c]?.c1 || "white"
    const c2 = bodyColors[code?.c]?.c2 || "white"
    const a = accessories[code?.a]
    const hair = hats[code?.h]

    let svg = svgBase({ c1, c2, a, hair });
    if (variant === "lb_0" || variant === "rb_0") svg = svgBase({ c1, c2, a, hair });
    if (variant === "lb_1" || variant === "rb_1") svg = svgLB_1({ c1, c2, a, hair });
    if (variant === "lb_2" || variant === "rb_2") svg = svgLB_2({ c1, c2, a, hair });
    if (variant === "lb_3" || variant === "rb_3") svg = svgLB_3({ c1, c2, a, hair });

    if (variant === "lt_0" || variant === "rt_0") svg = svgTop_0({ c1, c2, a, hair });
    if (variant === "lt_1" || variant === "rt_1") svg = svgTop_1({ c1, c2, a, hair });
    if (variant === "lt_2" || variant === "rt_2") svg = svgTop_2({ c1, c2, a, hair });
    if (variant === "lt_3" || variant === "rt_3") svg = svgTop_3({ c1, c2, a, hair });

    // Convertir le SVG en PNG
    const img = new Image();
    const svgBase64 = `data:image/svg+xml;base64,${btoa(svg)}`;

   
    
    return new Promise((resolve, reject) => {
        img.onload = () => {
            // Augmenter la résolution du canvas
            const scaleFactor = 2; // Ajustez ce facteur selon la qualité souhaitée
            const canvas = document.createElement('canvas');
            canvas.width = img.width * scaleFactor;
            canvas.height = img.height * scaleFactor;
            const ctx = canvas.getContext('2d');
    
            // Améliorer la qualité du rendu
            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = 'high';
    
            // Dessiner l'image avec le facteur de mise à l'échelle
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    
            // Créer un second canvas pour réduire la taille et améliorer la netteté
            const finalCanvas = document.createElement('canvas');
            finalCanvas.width = img.width;
            finalCanvas.height = img.height;
            const finalCtx = finalCanvas.getContext('2d');
    
            // Dessiner l'image mise à l'échelle sur le second canvas avec la taille originale
            finalCtx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, finalCanvas.width, finalCanvas.height);
    
            // Extraire les données PNG du second canvas
            const pngUrl = finalCanvas.toDataURL('image/png');
            resolve(pngUrl);
        };
        img.onerror = (e) => reject(e);
        img.src = svgBase64;
    }); 
};



export {createAvatarUrl,createAvatarSvg, a1, a2, a3, h1, bodyColors}