import { useState, useEffect } from "react";
import { db, app } from "../../config.js";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate} from 'react-router-dom';
import { ImageUpload } from "../components/imageUpload.js";
import {displayDate} from '../../utilities/helper/helper'
import { collection, onSnapshot, querySnapshot, where, getDocs, addDoc, getDoc, query, updateDoc, doc, setDoc } from "firebase/firestore";

import { GoogleAuthProvider , getAuth, sendPasswordResetEmail, signInWithPopup,signInWithRedirect, getRedirectResult, signOut, onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";

const userAgent = navigator.userAgent || navigator.vendor || window.opera;  
//const ios = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream
const ios = true


const mainBtn = "border-2 border-b-4 border-blue-500 fredoka text-blue-500 rounded-xl px-4 py-1 mx-auto bg-white flex gap-2 items-center"

const LandingScreen = (props) => {

  let pwa = "--"
  let ua = "--"
  let device = "--"
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;  
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
      device = 'Mobile';
  } else {
      device = 'Desktop';
  }
    
  
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      ua = "IOS"
  } else if (/android/i.test(userAgent)) {
      ua = "android"
  } else {
      ua = "autre"
  }
  if (window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches) {
    pwa = "yes"
    // console.log("L'application est exécutée en mode standalone/PWA");
  } else {
    pwa = "false"
    // console.log("L'application est exécutée dans le navigateur");
  }
  let askPWA = (device == "Mobile" && pwa == "false") ? true : false

  const ct = props.context
  const user =  props.user
  const [uc,  setuc] = useState()
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [showModal, setShowModal] = useState()
  const navigate = useNavigate()
  useEffect(() => {
    console.log("LandingScreen loaded")
}, [])

const auth = getAuth(app);

const handleSignInWithGoogle = () => {
  const provider = new GoogleAuthProvider();
  navigate("/home")
 
  // if (ios) {
  //   signInWithRedirect(auth, provider)

  //   getRedirectResult(auth)
  //     .then((result) => {
  //       console.log("result.user", result.user);
      
  //       setTimeout(async() => {
  //         const q =  query(collection(db, 'users'), where('uid', '==', result.user.uid)) 
  //         const querySnapshot = await getDocs(q);
  //         const user = querySnapshot.docs[0].data()
  //         console.log('user', user)
  //         if (!user.photo) updateDoc(querySnapshot.docs[0].ref, {photo: result.user.photoURL, blocked: true})
  //       }, 5000)
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  //     return
  // }

  signInWithPopup(auth, provider)
    .then((result) => {
      console.log("result.user", result.user);
    
      setTimeout(async() => {
        const q =  query(collection(db, 'users'), where('uid', '==', result.user.uid)) 
        const querySnapshot = await getDocs(q);
        const user = querySnapshot.docs[0].data()
        console.log('user', user)
        if (!user.photo) updateDoc(querySnapshot.docs[0].ref, {photo: result.user.photoURL, blocked: true})
        // console.log('querySnapshot du nouvel user', querySnapshot)
      }, 5000)
    })
    .catch((error) => {
      console.error(error);
    });
};

const handleSignInWithEmail = async () => {

  try {
    console.log('sign in 🟣', uc)
    setLoading(true)
    const userCredential = await signInWithEmailAndPassword(auth, uc.email, uc.password);
    console.log("userCredential.user", userCredential.user);
    setLoading(false)
    navigate("/home")
  

  } catch (error) {
    console.error(error);
    setError('Mauvais identifiant ou mot de passe')
    setLoading(false)

  } 
};

const handleForgotPassword = (email) => {
  sendPasswordResetEmail(auth, email)
    .then(() => {
      // Email sent.
      console.log('Password reset email sent!');
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      // Handle the errors accordingly.
      console.error("Error sending password reset email: ", errorMessage);
    });
}

const handleSignUpWithEmail = async () => {
  try {
    const q = query(collection(db, 'users'), where('email', '==', uc.email));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.docs.length > 0) {
      // L'utilisateur existe déjà, affichez une alerte
      alert("L'utilisateur existe déjà !");
      return;
    }

    // L'utilisateur n'existe pas, créez-le
    const userCredential = await createUserWithEmailAndPassword(auth, uc.email, uc.password);
    console.log("User created:", userCredential.user);
    console.log("Uc:", uc);

    // Mettez à jour les informations de l'utilisateur dans Firestore
    const userRef = doc(collection(db, 'users'), userCredential.user.uid);
    await setDoc(userRef, {
      uid: userCredential.user.uid,
      name: uc.name,
      email: uc.email,
      blocked: true,
      demo: true
    });

    // Récupérez les informations de l'utilisateur depuis Firestore
    const userSnapshot = await getDoc(userRef);
    const user = userSnapshot.data();

    // Mettez à jour le contexte utilisateur
    ct.setUser({
      ...ct.user,
      name: user.name,
      id: userRef.id,
      blocked: user.blocked,
      demo: user.demo
    });

    console.log('User data:', user);

  } catch (error) {
    console.error(error);
  }
};
  
  return (
    <div className=" fredoka bg-yellow-50 justify-center w-screen rounded-md relative p-16 overflow-scroll h-screen text-center">
      {user?.name}
      {!askPWA ? <div className="fixed bottom-3 left-0 right-0 flex justify-center z-10"><a target="_blank" href="/home.html" className="px-4 py-1 rounded-full border-2 flex gap-2 border-b-4 items-center border-black/50 bg-white text-slate-800 ">En savoir plus sur le projet <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
</svg>
</a> </div>: " "}
      <h1 className="fredoka self-start mt-4">Connexion</h1>
      <div className="h-full flex-col flex justify-center pb-[200px]">
      {!ios && <button className={mainBtn} onClick={handleSignInWithGoogle}>Se connecter avec google {ios && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
</svg>
}</button> }
      <button className={` ${ios ? mainBtn : "text-sm text-gray-400 w-full mt-4 "}`} onClick={() => setShowModal("signup")}>Se connecter {ios ? "" : "par mail"}</button>
      </div>
      {showModal && <div id="backdrop" onClick={() => (setShowModal())} className="!flex items-center justify-center !p-8"  style={{position: "fixed", top: 0, right: 0, bottom: 0, left: 0, backgroundColor: "rgba(0, 50, 0, 0.5)", zIndex: 999}}>
      
          
      <div onClick={(e) => e.stopPropagation()} className={`bg-white w-full flex pb-4 flex-col justify-center items-center gap-2 mx-auto rounded-xl`} style={{maxWidth: 500}}>
        <div className="flex p-4 w-full ">
          <div className={`${showModal == 'login' ? "border-b-blue-500 text-blue-500" : "text-slate-500"} w-1/2 border-b-4 pb-2`} onClick={() => {setShowModal("login")}}>Déjà un compte</div>
          <div className={`${showModal == 'signup' ? "border-b-blue-500  text-blue-500" : "text-slate-500"} w-1/2 border-b-4 pb-2`} onClick={() => {setShowModal("signup")}}>Créer un compte</div>
        </div>
        <div className="p-2 flex flex-col gap-2">
        {/* <h2 className="mb-4 text-slate-600">{showModal == "login" ? "Se connecter" : "Créer un compte"}</h2> */}
        <input type="email" placeholder="Email" className="px-2 py-1 rounded mx-auto border block w-[200px]" defaultValue={uc?.email} onBlur={(e) => setuc({...uc, email: e.currentTarget.value})} />
        {showModal != "login" && <input placeholder="Prénom Nom"className="px-2 py-1 rounded mx-auto border block w-[200px]" defaultValue={uc?.name} onBlur={(e) => setuc({...uc, name: e.currentTarget.value})} />}

        <input type="password" placeholder="Mot de passe" className="px-2 py-1 rounded mx-auto border block w-[200px]" defaultValue={uc?.password} onBlur={(e) => setuc({...uc, password: e.currentTarget.value})} />
        {showModal != "login" && <div className={`text-xs ${uc?.password?.length > 7 ? "text-green-500": "text-slate-500"} w-[200px] mx-auto text-justify`}>Ton mot de passe doit contenir au moins 8 caractères</div>}
        {showModal == "login" && <div onClick={() => {
          if (uc?.email){
            handleForgotPassword(uc?.email)
            ct.setAlerts([{title: `Un email t'as été envoyé à ${uc?.email} ✅`, time: 3000}])
            } else{
              ct.setAlerts([{title: "Ajoute ton email pour réinitialiser ton mot de passe", time: 3000}])
            }
            }} className="text-blue-500 underline">J'ai oublié mon mot de passe 😅</div>}
        <div className="mt-6 bg-purple-500 text-white flex gap-2 justify-center gap-4 hover:bg-purple-400 rounded-xl px-4 py-2 text-center cursor-pointer inline-block " onClick={() => {
         showModal == "login" ? handleSignInWithEmail() : handleSignUpWithEmail()
        }} style={{minWidth: 200}}>{showModal == "login" ? "Se connecter" : "Créer mon compte"} {loading ? <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg> : " "}</div>
      {error ? <div className="text-pink-500 text-center py-2">{error}</div> : ""}
      </div>
      </div>

    </div>}

    

    <div className="fixed bottom-0 left-0 right-0">
      <img src="images/UX/fun_signup.png" className="mx-auto" />
    </div>
    </div>
  );
};

export {LandingScreen};