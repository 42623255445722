import { useState, useEffect, useCallback, useRef } from "react";
import { BrowserRouter as Router, Routes, Route, Link, useParams} from 'react-router-dom';

import { db } from "../config.js";
import { collection, onSnapshot, querySnapshot, count, updateDoc,  getDoc, deleteDoc, writeBatch, batch, query, where, getDocs, doc, addDoc, orderBy } from "firebase/firestore";


import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { html } from '@codemirror/lang-html';
import { css } from '@codemirror/lang-css';
import { atomone } from '@uiw/codemirror-theme-atomone';
import {material} from '@uiw/codemirror-theme-material';
import {solarizedLight} from '@uiw/codemirror-theme-solarized' 
import { gpt } from '../utilities/helper/text.js';


import { linter, diagnosticCount, lintGutter } from "@codemirror/lint";
// import {keymap} from "@codemirror/view";
// import {HTMLHint} from "htmlhint";

import { useGlobalContext } from '../utilities/globalContext';




const CodeView = (props) => {

   // const { user, theme, cards,mapObj, setmapObj, decks, deck, setDeck, userWorkspace,setUserWorkspace,toggleTheme, setUser, setCards, workspace, strMapBiome, setStrMapBiome} = useGlobalContext();
    const user = props.user
    const userWorkspace = props.context?.userWorkspace 
    const [pages, setPages] = useState();
    const [iframe, setIframe] = useState(null);

    const [editor, setEditor] = useState()
    const [template, setTemplate] = useState()
    const [showSave, setShowSave] = useState(false)
    const [url, setUrl] = useState(window.location.href)
   
    const searchParams = new URLSearchParams(url);
    const [logs, setLogs] = useState([])
    const [rightViewSelected, setRightViewSelected] = useState({name: "Résulats", type: "iframe"})
    const [darkMode, setDarkMode] = useState(true)
    const [currentUser, setCurrentUser] = useState()
    const [askedHelp, setAskedHelp] = useState(false)
    const [loading, setLoading] = useState([])
    const [rightView, setRightView] = useState([{name: "Résulats", type: "iframe"}])
    const [showCollab, setShowCollab] = useState(false)
    const [userWorkspaces, setUserWorkspaces ] = useState();
    const [collab, setCollab] = useState([])

   
    let editor_id = searchParams.get("id")
    let shareMode = searchParams.get("s") == "t"
    let public_mode = searchParams.get("p") == "t"
    const [fullScreen, setFullScreen] = useState(public_mode)
  
    useEffect(() => {
        setFullScreen(searchParams.get("fs") || public_mode)
       
        
    }, [url])



    const getUser = async (id) => {
        const user = await getDoc(doc(db, "users", id))
        console.log('user', user.data())
        setCurrentUser({...user.data(), id: user.id})
    }

  const getEditor = async (id) => {
    const editor = await getDoc(doc(db, "editors", id))
    
    console.log('editor', editor.data())
    const template = await getDoc(doc(db, "editors", editor.data().template_id || "tailwind"))
  
    setEditor(editor.data())
    setCollab(editor.data().collab || [])
    setTemplate(template.data())
    getUser(editor.data().user_id)

    
  

    if (editor.data().docs) {
        const pages = editor.data().docs
        console.log('data trouvé', pages)
        setPages(pages)
    } else {
        const pages = template.data().docs
        console.log('pas de data', pages)
        setPages(pages)
    }

    document.title = editor.data().name
 
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.head.appendChild(link);
    }
    link.href = 'https://i.imgur.com/dcLqmkS.png';
  }

  const mode = pages?.find((p) => p.selected)?.name.split(".")[1]
 
 
  
  useEffect(()=> {
    editor_id && getEditor(editor_id)
    
  }, [editor_id])



  const handleHTMLChange = useCallback((value) => {
    const newPages = pages.map((page) =>
      page.selected === true ? { ...page, content: value } : page
    );
    setPages(newPages);
  }, [pages]);

  const selectPage = (selectedPage, right) => {
    if (!right) {
      const newPages = pages.map((page) =>
      page.name === selectedPage.name
        ? { ...page, selected: true }
        : { ...page, selected: false }
    );
    setPages(newPages);
    } else {
      const newPages = pages.map((page) =>
      page.name === selectedPage.name
        ? { ...page, selectedRight: true }
        : { ...page, selectedRight: false }
    );
    setPages(newPages);
    }
    
  };


  const save = async () => {
    const newEditor = {...editor, docs: pages, last_update: new Date(),  histo: [...editor.histo?.slice(0, 10) || [], {date: new Date(), docs: pages, mail: user.email}]}

    setEditor(newEditor)
    const editorRef = await updateDoc(doc(db, "editors", editor_id), newEditor)
  }

  const saveCollab = async (collab) => {
    console.log('setting collab', collab)
    setEditor({...editor, collab: collab})
    const editorRef = await updateDoc(doc(db, "editors", editor_id), {collab: collab})  
  }

  useEffect(() => {
    if (props.editor) {
      setEditor(props.editor);
      setPages(props.editor.docs)
    }
     props.setCurrentPage("focus");
  
  }, [props.editor]);


  useEffect(() => {
    props.setCurrentPage("focus");   
  }, []);



  const formatCode = async (code, type) => {
   setLoading([...loading, type])
  
   
   const data = await gpt(`Corrige la syntaxe du code ${type} suivant et ajoute des commentaires : ` + code)
 
   const content = data.choices[0].message.content
   
   setLoading(loading.filter(e => e !== type))
   setPages([...pages, {name: `Suggestion ${type}`, content: content, right: true}])
   console.log('nex page', [...pages, {name: `Suggestion ${type}`, content: content, right: true, mode: type }])
  
   console.log('content', content)
   //handleHTMLChange(content)

  }

  const showIframe = () => {
    if (iframe) {
      
        setLogs([])
        const iframeDocument = iframe.contentDocument;
        iframeDocument.open();
        iframeDocument.write(
          `<style>${pages?.find((p) => p.name === "style.css")?.content}</style>
          ${pages?.find((p) => p.name === "index.html")?.content}
          <script defer=true>
          //console.clear()
          ${pages?.find((p) => p.name === "script.js")?.content.replaceAll('const', 'var').replaceAll('let', 'var')}</script>
          `
        );
        iframeDocument.close();
      }

 
  }

  const getUserWorkspaces = async (wp_id) => {
    console.log('getUserWorkspaces 🟢', wp_id)
    const q = query(collection(db, 'user_workspace'),  where('workspace_id', "==", wp_id))
    const querySnapshot = await getDocs(q);
    const userWorkspaces = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
    console.log('userWorkspace', userWorkspaces)
    setUserWorkspaces(userWorkspaces)
}

  useEffect(() => {
    console.log('changement')
    // Utiliser setTimeout pour rafraîchir le contenu de l'iframe après un certain délai
    const timeoutId = setTimeout(() => {
        showIframe()
    }, 50);
     setShowSave(!(JSON.stringify(pages) == JSON.stringify(editor?.docs)))


    return () => clearTimeout(timeoutId);
   
  }, [pages, iframe, editor]);


  if (fullScreen == 1) {
    return <><iframe
    className="w-full bg-white"
    style={{ height: "100vh", border: "none" }}
    ref={(iframe) => setIframe(iframe)}
    title="Rendu visuel"
  />
     {((userWorkspace?.role == "admin" || userWorkspace?.user_id == editor?.user_id) && !public_mode) && <div onClick={() => {setFullScreen(!fullScreen)}} className="fixed  bottom-4 right-4 bg-indigo-600 px-4 py-2 b rounded-full text-white flex gap-2 items-center ">
            <div>Modifier</div> ✍️
           
        </div> }


  </>
  }

  


  logs.length > 1 && rightView.push("Console")


  return (
    <div key={editor?.id}>
     
      <div className="bg-[#171714] h-[8vh] w-full flex items-center px-12 py-2 justify-between">
        <div key={user?.id} className="text-white">
            {user ?
            <>
            <div className="opacity-50 text-[12px] -mb-1">{currentUser?.email} {currentUser?.email != user?.email && "[" + user?.email + "]"} </div>
            <div className="">{editor?.name}</div>  
            </> : <div>Vous n'êtes pas connecté</div>} 
            {userWorkspaces?.filter(uwp => editor.collab?.includes(uwp.id)).map(uwp => (
              <div className="bg-purple-500 text-white p-2 rounded border" key={uwp.id}>
                {uwp.user_name}
              </div>
            ))}    

        </div>
        <div className="flex gap-2">
        {userWorkspace?.user_id == editor?.user_id && <div onClick={() => {setShowCollab(true); getUserWorkspaces(userWorkspace?.workspace_id)}} className="bg-slate-600 rounded text-white px-3 py-1 b">Collaborer</div>}
       {((userWorkspace?.role == "admin" || (shareMode && user)) || (userWorkspace?.user_id == editor?.user_id || editor?.collab?.includes(userWorkspace?.id)) ) && <div className={`${showSave ? "bg-purple-500" : "bg-slate-700"}   px-3 rounded py-1 b text-slate-100`} onClick={()=> save()}>Enregistrer</div>}
        <div className={`${askedHelp ? "bg-green-500" : ""} px-2 border flex items-center b justify-center rounded-xl `} onClick={() => { 
                    updateDoc(doc(db, "editors", editor_id), {ask_help: "👋"})
                    setAskedHelp(true)
                   
                    setTimeout(() => {
                        updateDoc(doc(db, "editors", editor_id), {ask_help: null})
                        setAskedHelp(false)
                    }, [1000*30])
              }}>👋</div>
        </div>
      </div> 
      <div className="flex justify-center items-start bg-[#2E3129]  ">
        {/* Éditeur de code HTML et CSS */}
        <div id="editor" className="relative  box-border w-1/2 resize-x resize-drag h-[92vh] grow  flex flex-col items-start overflow-scroll bg-[#272c35]">
          {/* Onglets des pages */}
          <div className="flex bg-[#1d2128] w-full items-center relative">
           
            {pages?.map((page) => (
              <div
                key={page.name}
                onClick={() => selectPage(page)}
                className={`${ page.selected ? "bg-[#272c35] border-t-4 border-slate-500" : "bg-[#1d2128]" } b px-4 text-slate-400 py-1 inline-block uppercase`}>
                {page.name.split('.')[1]}
              </div>
            ))}
            
             
              <div onClick={() => setDarkMode(!darkMode)} className="text-slate-600 b self-end justify-self-end absolute right-1 bottom-[8px]">
               
               <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
               {darkMode ? <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" /> :
                   <path strokeLinecap="round" strokeLinejoin="round" d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z" />
                   }
               </svg>
           </div>
          </div>
          {/* <div onClick={() => formatCode(pages?.find((p) => p.selected)?.content, mode)}>Formater le code {mode}</div> */}
          {/* {loading.map(e => <div>{e}</div>)} */}

          {/* Éditeur de code HTML */}
          <CodeMirror
            value={pages?.find((p) => p.selected)?.content}
            theme={darkMode ? atomone : solarizedLight}
            className="h-full w-full text-[16px]"
            style={{ minHeight: "100%" }}
            extensions={[
                // basicSetup,
                mode == "css" ? css() : mode == "js" ? javascript() : html(),
                // linter(mode == "css" ? htmlLinter : htmlLinter),
                lintGutter(),
              ]}
         
            onChange={handleHTMLChange}
            />
          
          
              <div className="h-4 bg-red-200">test</div>
        
             
            </div>

         
    
            <div id="render" className={`border-gray-300  h-[92vh] overflow-hidden  grow  bg-[#272c35]`} style={{ position: "relative" }} >
                
                  <div className="flex bg-[#1d2128]">
                {rightView?.concat(pages?.filter(p => p.right)).map((page) => (
                    <div
                        key={page?.name}
                       
                        onClick={() => {selectPage(page, true);  setRightViewSelected(page)}}
                        className={`${ page != setRightViewSelected ? "bg-[#272c35]" : "bg-[#1d2128]" } b px-4 text-slate-400 py-1 inline-block uppercase`}>
                        {page?.name}
                    </div>
                    ))}
                </div>


    
            { rightViewSelected.type != "iframe" &&  
              <CodeMirror
                value={pages?.find((p) => p.selectedRight)?.content}
                theme={darkMode ? atomone : solarizedLight}
                className="h-full w-full text-[16px]"
                style={{ minHeight: "100%" }}
                extensions={[
                  pages?.find((p) => p.selectedRight)?.mode == "css" ? css() : pages?.find((p) => p.selectedRight)?.mode == "js" ? javascript() : html(),
                    lintGutter(),
                  ]}  
              />}  
               
              
            { rightViewSelected.type == "iframe" && 
             <><iframe
                id="iframe"
                sandbox="allow-same-origin allow-scripts"
                className="w-full bg-white"
                style={{ height: "100%", border: "none" }}
                ref={(iframe) => setIframe(iframe)}
                title="Rendu visuel"
              />
                <div onClick={() => {setFullScreen(!fullScreen)}} className="fixed  bottom-4 right-4 bg-indigo-600 px-4 py-2 b rounded-full text-white flex gap-2 items-center ">
                <div>Plein écran</div> 
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 ">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
                </svg>
                </div></>  
                }
            </div>
          </div>
          {showCollab && <div onClick={() => {setShowCollab(false)}} className="fixed top-0 flex items-center justify-center bottom-0 h-screen left-0 right-0" style={{backgroundColor: 'rgba(0,0,0,0.4)'}}>
        <div className="relative bg-white border p-8 rounded min-w-[400px]  overflow-scroll" onClick={(e) =>  e.stopPropagation()}>
          {editor?.collab?.length > 0 &&<> <h2 className="font-bold text-xl mb-2">Partagé avec</h2>
          <div className="overflow-scroll mb-4">
            {userWorkspaces?.filter(uwp => editor.collab?.includes(uwp.id)).map(uwp => (
              <div className="bg-purple-500 text-white p-2 rounded border" key={uwp.id}>
                {uwp.user_name}
              </div>
            ))}     
          </div>  </>}  
          <h2 className="font-bold text-xl mb-2">Inviter à collaborer</h2>
          <p className="text-slate-500 italic text-sm ">Pour collaborer choissisez une personne dans la liste et partagez lui ce lien :</p> 

          <p className="text-indigo-500 italic text-[12px] mb-4 mt-2 border-2 border-purple-200 p-1 rounded">{"https://mindseed-425a8.web.app/"+`editor?&id=${editor_id}&s=t`}</p> 
          <h2 className="font-bold text-xl mb-2">Partager le projet</h2>

          <p className="text-green-500 italic text-[12px] mb-4 mt-2 border-2 border-green-200 p-1 rounded">{"https://mindseed-425a8.web.app/"+`editor?&id=${editor_id}&p=t`}</p> 

          <div className="overflow-scroll h-[500px] pb-16">
            {userWorkspaces?.map(uwp => <div onClick={(e) => {collab?.length < 3 && setCollab([...collab, uwp.id])}} className={`${collab?.find(c => c == uwp.id) ? "bg-purple-500 text-white" : "hover:bg-slate-100"} p-2  rounded border`}>{uwp.user_name}</div>)} 
          </div>
        <div className="absolute bottom-0 left-0 right-0 h-[90px] border px-4 p-2 bg-white" >
          {editor.collab?.length < 3 && <div onClick={() => saveCollab(collab)} className="bg-purple-500 b text-white rounded p-2 text-center">Inviter</div>}
          <div onClick={() => {setCollab(editor.collab || []); saveCollab([])}} className="text-slate-500 b rounded p-2 text-center">Déselectionner</div>

          </div>
        </div>
      </div>}
        </div>
        );
        };
        
        export { CodeView };