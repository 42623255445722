import { createContext, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc, updateDoc, setDoc, getDoc, where, writeBatch, query, orderBy, doc, limit, getDocs } from "firebase/firestore";
// import { AudioConfig, SpeechConfig, SpeechRecognizer, PronunciationAssessmentConfig } from '@azure/cognitiveservices-speech-sdk';
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';

import { db } from "../../config.js";
import {gpt, numberSentences, speak, stopSound} from '../../utilities/helper/text'
import { SuperEditor } from '../../components/editor.js';
import { markdownToHtml } from '../../utilities/helper/helper.js';

import { bouncy , waveform, momentum} from 'ldrs'
import SuperText from './perfectScreen.js';
import { AudioReader } from '../components/quizzReader.js';

momentum.register()
waveform.register()
bouncy.register()

const key_1 = "3fb405dd620d40eea3c0604929d8089f"


const scenario = [
  {
    name: "Conversations libres", 
    id: "free",
    emo: "💬"
  },
  {
    name: "Aller au cinéma",
    description: "Visitez le cinéma pour découvrir des films, séances et spectacles à votre convenance.",
    steps: ["Aller au boulevard des Champs-Élysées", "Trouver un bouquet de films et séances", "Choisir un film ou une séance", "Prendre votre carte de crédit ou votre carte bancaire pour payer", "Payer le film ou la séance", "Trouver votre siège", "Regarder le film ou la séance", "Sortir du cinéma", "Retourner à la maison"],
    emo: "🎬"
  },
  {
    name: "Commander au restaurant",
    emo: "🍽️"
  },
  {
    name: "Faire un tour de la ville",
    emo: "🏙️"
  },
  {
    name: "Découvrir un nouveau lieu",
    emo: "🗺️"
  },
  {
    name: "Réserver un hôtel",
    emo: "🏨"
  },
  {
    name: "Payer en caisse",
    emo: "💳"
  },
  {
    name: "Passer un entretien",
    emo: "💼"
  },
  {
    name: "Se présenter à quelqu'un",
    emo: "🙋"
  },
  {
    name: "Faire une réservation pour un hôtel",
    emo: "🏨"
  },
  {
    name: "Flirter par message",
    emo: "💌"
  }
];

function scrollToBottom() {
  const element = document.getElementById('scroll');
  if (element) {
    element.scrollTo({
      top: element.scrollHeight - 250,
      behavior: 'smooth'
    });
  }
}


const referenceText = 'Hello how are you ?'; // Replace with the appropriate reference text





const SpeechToTextSimple2 = ({ referenceText, send, lang, ct}) => {
  const [transcription, setTranscription] = useState('');
  const [pronunciationScore, setPronunciationScore] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recognizer, setRecognizer] = useState(null);
  const [loadingRecord, setLoadingRecord] = useState(false);
  const [pronunciationScoreData, setPronunciationScoreData] = useState(null)

  useEffect(() => {
   
    if (lang) {
      const speechConfig = sdk.SpeechConfig.fromSubscription(key_1, 'francecentral');
      const pronunciationAssessmentConfig = new sdk.PronunciationAssessmentConfig(
        '',
        sdk.PronunciationAssessmentGradingSystem.HundredMark,
        sdk.PronunciationAssessmentGranularity.Phoneme,
        true
      );

      console.log('lang', lang)

      const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
      speechConfig.speechRecognitionLanguage = lang == "en-GB" ? "en-US" : lang
      
      const newRecognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
  
      pronunciationAssessmentConfig.applyTo(newRecognizer);
      setRecognizer(newRecognizer)
      console.log('new reco en ', lang)
      console.log('nnewRecognizer ', newRecognizer)
    }
    
  }, [lang])

  useEffect(() => {
    return () => {
      // Cleanup function to stop the recognizer if the component is unmounted
      if (recognizer) {
        recognizer.stopContinuousRecognitionAsync(
          () => {
            console.log('Recognition stopped');
            //recognizer.close();
          },
          (error) => {
            console.error('Error stopping speech recognition:', error);
          }
        );
      }
    };
  }, [recognizer]);

  useEffect(() => {
    let timeoutId;

    if (isRecording) {
      timeoutId = setTimeout(() => {
        stopRecognition();
        recognizer?.stopContinuousRecognitionAsync()
      }, 6*1000*10); // Arrête l'enregistrement après 5 secondes d'inactivité
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isRecording]);

  const startRecognition = () => {
    try {
      setLoadingRecord(true);
      setTranscription('');
      setPronunciationScoreData('')
      console.log('lang', lang)
      // const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
     

      // const newRecognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
      // speechConfig.speechRecognitionLanguage = lang;
      // pronunciationAssessmentConfig.applyTo(newRecognizer);

      // setRecognizer(newRecognizer);

      recognizer.startContinuousRecognitionAsync(
        () => {
          console.log('Recognition started', lang);
          setIsRecording(true);
          setLoadingRecord(false);
        },
        (error) => {
          console.error('Error during speech recognition:', error);
          setIsRecording(false);
        }
      );

      recognizer.recognized = (s, e) => {
        if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
          console.log('result', e.result);

       
          setTranscription((prevTranscription) => (prevTranscription || '') + ' ' + e.result.text);

          const pronunciationResult = sdk.PronunciationAssessmentResult.fromResult(e.result);
          console.log('pronunciationResult', pronunciationResult);
          console.log('word', pronunciationResult.privPronJson.Words);
       

          // setPronunciationScore(pronunciationResult.pronunciationScore);
          setPronunciationScoreData(data => ({
            ...data,
            Words: [...(data.Words || []), ...pronunciationResult.privPronJson.Words]
        }));
        }
      };
    } catch (error) {
      console.error('Error during speech recognition:', error);
      //recognizer.close();
    }
  };

  const stopRecognition = () => {
    if (recognizer) {
      recognizer.stopContinuousRecognitionAsync(
        () => {
          console.log('Recognition stopped');
          setIsRecording(false);

       //   recognizer.close();
          // setRecognizer(null);
        },
        (error) => {
          console.error('Error stopping speech recognition:', error);
        }
      );
    }
  };

  const active = (transcription || isRecording)
  console.log('pronunciationScoreData', pronunciationScoreData)


  return (
    <div className={`${active && "bg-blue-500 "} rounded-xl`}>
      <div className='flex justify-center '>
      <div className={`${active ? "absolute h-[40px] w-[40px] right-4 bottom-3 bg-white" : "h-[60px] w-[60px] -ml-6 bg-blue-500"}    flex items-center justify-center  border-2 border-b-4 border-black/30 rounded-full`} onClick={() => {
        if (!isRecording) {
          if(!transcription)  {startRecognition()}
          else {
            send(transcription);
            setTranscription()
            setPronunciationScoreData()
          }
        } else {
          stopRecognition()
        }
      }}>
      {!isRecording && !loadingRecord ? transcription ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="text-blue-500 rounded-full  h-8">
  <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
</svg>
 : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={` p-2 brounded-full h-10`}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z" />
      </svg> : 
       <div className={`${loadingRecord ? " h-10 w-10" : "bg-white text-purple-500 h-10 w-10"} p-2 rounded-full flex items-center justify-center  `}> 
       {loadingRecord ? 
<l-momentum
  size="40"
  speed="4.3" 
  color="white" 
></l-momentum> : 
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
</svg>
 }</div>
        }
      </div>
      </div>
      
      {!ct.user.admin ? <p className={`text-xs ${active ? "min-h-[50px] py-2" : ""} italic  flex items-center w-full text-white/90 pr-[60px]  px-10 `}>{
      isRecording ? "Enregistrement en cours ..."  : transcription}
      </p> : 
      <p className={`${active ? "min-h-[50px] py-2" : ""} flex flex-wrap gap-1 pr-[60px] items-center px-10 py-2 text-sm`}>{ isRecording && !transcription ? "Enregistrement en cours ..."  : pronunciationScoreData?.Words?.map(w => {
      const hasSyllables = w.Syllables?.[0]?.Syllable
      const score = w.PronunciationAssessment?.AccuracyScore
      return <div className={` ${hasSyllables ? "" : "opacity-[70%]"}`} onClick={() => speak({text: w.Word, lang: lang})}>{
        !hasSyllables ? 
        <div  className={`px-1 rounded ${score > 99 ? "" : score < 60 ? "bg-red-500" : score < 80 ? "bg-orange-500" : score < 90 ? "bg-yellow-500" : ""}`}>{w.Word} </div>
        : 
        w.Syllables?.map(s => <span className={`${!s.Grapheme ?  "purple-100" :  s.PronunciationAssessment?.AccuracyScore > 98 ? "text-green-100" : s.PronunciationAssessment?.AccuracyScore < 80 ? "text-yellow-200" : "text-red-300/90"}`}>{s.Grapheme }</span>)} </div>})
      
      }</p>}
      {/* {isRecording ? <div className='flex justify-center h-2'><l-waveform
  size="12"
  stroke="3.5"
  speed="1" 
  color="white" 
></l-waveform></div> :<div className={`h-2 `}></div>} */}
      {isRecording ? <div className='absolute left-5 bottom-6 h-5'><l-waveform
  size="18"
  speed="1.6" 
  color="white" 
></l-waveform> </div>: <div className='text-center' onClick={() => {
        stopRecognition()
        setTranscription()
        setPronunciationScoreData()
        }}>


<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${!transcription && "opacity-0"} absolute left-4 bottom-6 h-5`}>
<path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
</svg>

</div>}
   
    </div>
  );
};


const Message = ({mess, darkMode, ct, currentUserChat, messages, setMessages, vocalMode}) => {

  const [showTrad, setShowTrad] = useState(false)
  const [revealMsg, setRevealMsg] = useState(true)
  const [dataSpeak, setDataSpeak] = useState()
  const [isSpeaking, setIsSpeaking] = useState(false)

  useEffect(() => {

      setRevealMsg(!vocalMode)
  
  }, [vocalMode])

  console.log('dataSpeak', dataSpeak)


  const maxCharacter = mess.content.length

  return <div className={`${mess.role == "user" ? "justify-end text-white" : ""} transition-all relative flex gap-2`}>
       <div className=''>
      <div className={`flex transition-all relative ${mess.role == "user" ? "justify-end text-white" : ""}`}><div className={`rounded-xl border-[2px] ${mess.role == "user" ? mess.ia_updated ? `text-xs pb-2 ${darkMode ? "text-white/80 bg-white/20 border-transparent": "text-blue-500  bg-white/70"}  text-right justify-end self-end` : " self-end rounded-tr-[2px] bg-blue-500 border-blue-700 text-right justify-end" : `rounded-tl-[2px] w-full ${vocalMode ? "pr-8" : ""}  ${darkMode ? "bg-[#5e00c9] text-white border-black/20":" bg-white text-slate-700"}  `} min-w-[40px] max-w-[90vw] px-[8px] py-[6px] overflow-hidden border-b-[4px] mb-3 `}>
      {/* <SuperEditor className="text-xl pb-0" onBlur={() => {console.log('ee')}} content={markdownToHtml(mess.content)?.replaceAll('\n', '<br/> ---')} /> */}
      
        {mess.role == "user" && <div className={`flex text-right`}>{mess.content}</div>}
        {mess.role != "user" && vocalMode && <div>
          <div className='flex items-center gap-2'>
        {/* <div>{dataSpeak?.["_duration"]}s</div> */}
          <div className='' onClick={async() => {
            if (isSpeaking) {
              stopSound()
              setIsSpeaking(false)
            return
          }
            setIsSpeaking(true)
            const dataSpeak = await speak({text: removeEmojis(mess.content), lang: ct.workspace?.lang, setDataSpeak })
            setIsSpeaking(false)
            console.log(dataSpeak)
          }
            }>
              {isSpeaking ?  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
</svg>
: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
              </svg>}
</div>
<div className={`h-3 ${isSpeaking ? "animate-pulse" : ""}  w-full bg-white/50 rounded-full`}></div>


          </div>
          <div className='text-xs text-transparent h-[5px] max-h-[5px] pr-8 '>
            {!revealMsg && mess.content.slice(0, maxCharacter < 70 ? maxCharacter : 70) + '...'}
            <div className='absolute right-2 top-2 text-white/70' onClick={() => {setRevealMsg(t => !t)}} ><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`h-6 transition-all ${revealMsg ? "rotate-[180deg]" : ""}`}>
  <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
</svg>
</div>
            </div>
          </div>}
        {mess.role != "user" && revealMsg &&  <SuperText 
                        vocalMode={false} 
                        colorText={false}
                        text={numberSentences(mess.content)} 
                        
                        trad={numberSentences(mess.trad)} 
                        decks={[]} 
                        cards={ct.cards}  
                        ct={ct}/>}
              {mess.trad && showTrad && <div className={`transition-all ${darkMode ? "bg-black/30 text-white/50" : "bg-slate-100/60 text-slate-500"} text-xs italic   p-2 pt-1 mt-2 -mx-2 -mb-2   ${vocalMode ? "!-mr-8  " : ""}`}>{mess.trad}</div>}

        </div>

        </div>
        {mess.ia_updated && <div className='bg-emerald-500 text-right pl-6 relative rounded-xl border-[2px] border-black/20 border-b-[4px] mb-3 -mt-[20px] p-2 pt-1  -mx-2 -mb-2'>
        <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="h-4 absolute top-1 left-1">
  <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
</svg>
          {mess.ia_updated}
          </div>}

      {/* <div className='mb-5 text-lg'>{mess.content}</div> */}
      <div className={`flex gap-2 ${darkMode ?  "text-white/60" : "text-blue-500"}  mb-4  -mt-2`}>
            {!vocalMode && <svg onClick={() => {speak({text: removeEmojis(mess.content), lang: ct.workspace?.lang})}}  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor"  className={`w-4 h-4 cursor-pointer hover:scale-150 transition ${mess.role == "user" ? " ml-auto" : "rounded-full "}`}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z" />
            </svg>}

        {mess.role == "user" && !mess.ia_updated && <svg onClick={async() => {
          console.log('inject msg')
          console.log('mess', mess)
          console.log('messages', messages)
   
          const query = [
            {"role": "user", "content": `Donne uniquement la réponse. Reformule ce message dans un ${ct.workspace.name} naturel "${mess.content}"`}
        ]
          const newText = await gpt(query, true, ct)
          console.log('newText', newText)
          const updatedMessages = messages.map(m => m == mess ? {...m, ia_updated:  newText.choices[0]?.message.content} : m)

          setMessages(updatedMessages);
          updateDoc(doc(db, 'user_chat', currentUserChat.id), {messages: updatedMessages})




       
        }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
</svg>


        }
        {mess.role != "user" &&<svg onClick={() => setShowTrad(t =>!t)}  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4 ">
  <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
</svg>
}

       
        </div>
    
          
           </div>
  </div>}






function removeEmojis(text) {
  return text.replace(
    /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u2600-\u26FF\u2700-\u27BF\ufe0f]/gu, ''
  );
}


const ChatScreen = (props) => {
  const [prompt, setPrompt] = useState()
  const [preprompt, setPreprompt] = useState("Réponds en allemand et corrige les fautes")
  const [answers, setAnswers] = useState([])
  const [messages, setMessages] = useState([])
  const [selectedChat, setSelectedChat] = useState()
  const [showInfo, setShowInfo] = useState()
  const [loadingMessage, setLoadingMessage] = useState()
  const [darkMode, setDarkMode] = useState(true)

  const [userChats, setUserChats] = useState()

  const ct = props.context || props.ct
  const currentUserChat = userChats?.find(uc => uc.chat_id === selectedChat?.id)

  function countNewLines(input) {
    let matches = input?.match(/\n/g);
    return matches ? matches.length : 0;
  }

  const createUserChat = () => {
    console.log('createUserChat 🟢🟢🟢🟢🟢')
    const data = {
      workspace_id: ct.workspace.id,
      user_id: ct.user.id,
      chat_id: selectedChat.id,
      messages: [],
      answers: [],
      created_at: new Date(),
      updated_at: new Date(),
    }
    console.log('data', data)
    const docRef = addDoc(collection(db, "user_chat"), data);
    setUserChats([...userChats, {...data, id: docRef.id}])
    console.log('docRef', docRef)
  }

  const getUserChats = async (wp_id, user_id) => {
    console.log('getUserCards 🟢🟢🟢🟢🟢', wp_id,user_id)
    const q = query(collection(db, 'user_chat'), where('workspace_id', "==", wp_id), where('user_id', "==", user_id))
    const querySnapshot = await getDocs(q);
    const cards = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
 
    setUserChats(cards)
}


useEffect(() => {
  getUserChats(ct.workspace?.id, ct.user?.id)
}, [ct.workspace?.id, ct.user?.id])

useEffect(() => {
  setMessages(userChats?.find(uc => uc.chat_id === selectedChat?.id)?.messages || [])
},[selectedChat])

useEffect(() => {
  ct.setActiveScreen("chat")
  ct.setShowBottomBar(true)
}, [])
  

  const send = async (prompt) => {
    if (!prompt) return
    if (prompt == "") return 
    if (prompt == " ") return 
    try {
    console.log("send", prompt)
    const _messages= [...messages || [], {'role': 'user', 'content': prompt}]
    setMessages(_messages)


    const msgRequest =_messages.slice(-10);
    msgRequest.unshift({role: "system", content: selectedChat.prompt})
    console.log('msgRequest', msgRequest)
    if(ct.userWorkspace.total_tokens > 10000 || ct.userDaily.total_tokens > 2000) { 
    }
    setLoadingMessage(true)
    console.log('msgRequest', msgRequest)
    const data = await gpt(msgRequest, true, ct)
    setLoadingMessage(false)
    console.log("data", data)
    const _new_messages = [..._messages?.slice(-20) || [], {
      role: "assistant", 
      content: data.choices[0]?.message?.content?.split('>>')?.[0],
      trad: data.choices[0]?.message?.content?.split('>>')?.[1]
    }]
    setMessages(_new_messages)
    setPrompt()
    updateDoc(doc(db, 'user_chat', currentUserChat.id), {messages: _new_messages})
  } catch(e) {
    setLoadingMessage(false)
  }

   setTimeout(() => {scrollToBottom()}, 400)

  }


  const resetChat = () => {
    setMessages()
    updateDoc(doc(db, 'user_chat', currentUserChat.id), {messages: []})
  } 


  const chats = [
    {
    id: "didier",
    title: "Discussions du quotidient",
    name: {all: "John",  "en-GB": "John"}, 
    prompt: `
     Ignore toutes les instructions précédentes. Réponds uniquement avec du textes et pas de markdown.
     Ton nom est John, tu es un humain de 30 ans,  un  ${ct.workspace.name} natif, réponds en ${ct.workspace.name}.
     Tu discute avec une personne par sms, préviligé les réponses courtes.
     Sois joyeux, n'hésite pas à faire de l'humour et intéresses toi la vie de ${ct.user?.name}. 
     Pose régulièrement des questions qui incite l'utilisateur à répondre, mène la conversation il ne faut pas que l'utilisateur relance la conversation.
     Tu peux utiliser des émojis de occasionnelement sans en faire trop.
     Si l'utilisateurs fais des fautes en ${ct.workspace.name} ou s'exprime mal ou parle français il faut que tu le corrige, car il apprend ${ct.workspace.name} en discutant avec toi.
     Pour chacun de tes réponse en ${ct.workspace.name} et donne la traduction en français après ">>" dans le format suivant :
     Hello, how are you ? >> Salut, comment vas-tu ?.
     `,
    variant: "A",
    description: "Bon vivant et très blagueur, discutez avec lui du quotidien.",
    photo: "/images/tar/chat_bot/chat_3.png",
  },
  {
    id: "elprofessor",
    title: "Pose des questions",
    name: {all: "El professor",  "en-GB": "El professor"}, 
    prompt: `

    Ignore toutes les instructions précédentes. Tu es un professeur ${ct.workspace.name} expérimenté. 
    Tu parles nativement ${ct.workspace.name} et tu enseignes depuis 12 ans. 
    Tu discute avec une personne par sms.
     Tu connais plein de chose sur la culture ${ct.workspace.name} et tu aimes partager tes connaissances et raconter des anecdotes insolites.
     Tu peux utiliser des émojis de occasionnelement sans en faire trop.

     Si l'utilisateurs fais des fautes en ${ct.workspace.name} ou s'exprime mal ou parle français il faut que tu le corrige tout en répondant à son message, 
     car il apprend ${ct.workspace.name} en discutant avec toi. Si tu le corrige réponds à son message également !!
     
     Pour chacune de tes réponse en ${ct.workspace.name} et donne la traduction en français après ">>" dans le format suivant :
     Hello, how are you ? >> Salut, comment vas-tu ?.
     `,
    variant: "B",
    description: `Prof ${ct.workspace.name} depuis 12 ans. Si tu as des questions sur la grammaire, la culture ou autre c'est l'homme de la situation`,
    photo: "/images/tar/chat_bot/chat_2.png",
  },
  {
    id: "elpsy",
    title: "Raconte ta vie",
    name: {all: "Carl",  "en-GB": "Carl"}, 
    prompt: `

    Ignore toutes les instructions précédentes. Tu es un psychologue expérimenté. Tu parles nativement ${ct.workspace.name}. 
    Tu es reconnu pour avoir accompagné et aidé de nombreux patient à se sentir mieux. 
    Tu connais de nombreuses techniques et méthodes pour amener un patient à se poser les bonnes questions et à résoudre ses problèmes. 
    Ta tâche est de m'aider à réfléchir à ma situation en me posant les bonnes questions et à aller mieux.
    Tu peux utiliser toutes les méthodes que tu connais, en me posant des questions et en me faisant des retours lorsque tu juges cela nécessaire. Le but est de m'aider à trouver des réponses par moi même au maximum, juste en me posant les bonnes questions.
    Je veux tu attendes une réponse de ma part après chaque intervention de ta part.
    Je suis ton patient et tu dois m'aider à aller mieux

    Tu discute avec une personne par message textuel.

    Si l'utilisateurs fais des fautes en ${ct.workspace.name} ou s'exprime mal ou parle français il faut que tu le corrige en le reprenant, car il apprend ${ct.workspace.name} en discutant avec toi mais réponds aussi à son message ensuite.
    Pour chacun de tes réponse en ${ct.workspace.name} et donne la traduction en français après ">>" dans le format suivant :
    Hello, how are you ? >> Salut, comment vas-tu ?.
     `,
    variant: "B",
    description: "Psychologue de renommée, tu peux lui confier tes problèmes les plus profonds",
    photo: "/images/tar/chat_bot/chat_1.png",
  },


  // {
    
  //   id: "elprofessor",
  //   title: "Pose des questions",
  //   name: {all: "El professor",  "en-GB": "El professor"}, 
  //   prompt: `Ignore toutes les instructions précédentes. Tu es un professeur ${ct.workspace.name} expérimenté. Tu parles nativement ${ct.workspace.name} et tu enseignes depuis 12 ans quand tu recois une message ${ct.workspace.name} qui n'ai pas correct ou avec des fautes. Corriges-le avant de répondre. 
  //   Si l'user fais des fautes en ${ct.workspace.name} reprend le et corrige le en français, c'est important qu'il apprenne. 
  //   Mais tu dois quand même répondre au message en ${ct.workspace.name}. N'hésite pas envoyer des smiley et à donner beaucoup d'exemple pour illustrer tes propos. Reponds au format html avec des balises et n'hésite pas faire des tableaux pour les conjuguaisons `,
  //   variant: "B",
  //   description: `Prof ${ct.workspace.name} depuis 12 ans. Si tu as des questions sur la grammaire, la culture ou autre c'est l'homme de la situation`,
  //   photo: "/images/UX/professor_round.png",
  // },
  // {
  //   id: "elpsy",
  //   title: "Raconte ta vie",
  //   name: {all: "Marius", "en-GB": "Marius"}, 
  //   prompt: `Ignore toutes les instructions précédentes. Tu es un psychologue expérimenté. Tu parles nativement ${ct.workspace.name}. 
  //   Tu es reconnu pour avoir accompagné et aidé de nombreux patient à se sentir mieux. 
  //   Tu connais de nombreuses techniques et méthodes pour amener un patient à se poser les bonnes questions et à résoudre ses problèmes. 
  
  //   Ta tâche est de m'aider à réfléchir à ma situation en me posant les bonnes questions et à aller mieux.
  //   Tu peux utiliser toutes les méthodes que tu connais, en me posant des questions et en me faisant des retours lorsque tu juges cela nécessaire. Le but est de m'aider à trouver des réponses par moi même au maximum, juste en me posant les bonnes questions.
    
  //   Je veux tu attendes une réponse de ma part après chaque intervention de ta part.
    
  //   Je suis ton patient et tu dois m'aider à aller mieux`,
  //   variant: "B",
  //   description: "Psychologue de renommée, tu peux lui confier tes problèmes les plus profonds",
  //   photo: "/images/tar/tard7.svg",
  // }
  // {
  //   id: "elbg",
  //   name: {all: "Ton crush"}, 
  //   prompt: `Ignore toutes les instructions précédentes. Tu es une fille de 23 ans, tu es ${ct.workspace.name} native. 
  //   Tu parles ${ct.workspace.name} comme les jeunes  de ton ages. 
  //   Tu es très ouverte et tu aimes discuter avec les gens. 
  //   Utilise des smileys et des émojis. 
  //   Tu aimes draguer et tu veux me séduire.
  //   Tu dois mener la conversation et me poser des questions plutôt originale, il ne faut pas que l'utilisateur n'ai rien à dire
  //   `,
  //   variant: "B",
  //   description: `Ton crush qui vient te parler. `,
  //   photo: "/images/tar/tar3.svg",
  // },
]

  const progressCent = ct.userDaily?.total_tokens / 2000 *100
  const [isWriting, setIsWriting] = useState(false)
  const [audioMode, setAudioMode] = useState(false)
  const [vocalMode, setVocalMode] = useState(false)
  const [indexShow, setIndexShow] = useState(false)


  const index =  <div className='p-4 '>
 
  {/* <div className='fixed left-0 border-t right-0 bottom-[60px] p-4 fredoka '>
    <div className='text-sm  mb-2 text-center'>Mes credits du jour</div>
    <div className='h-[16px] bg-black/10 grow relative rounded overflow-hidden'>
            <div className='h-[16px] rounded relative w-full bg-black/50 grow' >
                <div className='bg-amber-400 h-full border-[1.5px] border-black/70  rounded transition-all ' style={{width: `${(progressCent)}%`}}></div>
                <div className='h-[4px] -mt-[14px] mx-2 rounded transition-all bg-white/60' style={{width: `calc(${(progressCent)}% - 12px)`}}></div>
                <div className='h-[5px] mt-[3px] mx-1 rounded transition-all bg-amber-900/20' style={{width: `calc(${progressCent}% - 14px)`}}></div>
                <div className='text-center text-shadow text-white fredoka font-bold -mb-4 mt-[-20px]'><span className='text-amber-200 mr-[1px]'>{ct.userDaily?.total_tokens || 0}</span>/2000</div>
            </div> 
                <div className={`bg-amber-500  h-full absolute rounded-xl`} style={{width: Math.round(progressCent) + "%" }}></div>
         </div>
  </div> */}
   {indexShow == "free" && <div>
    <div onClick={() => {setIndexShow('index')}} className='mb-2'>← Retour</div> 
    {/* <div className='text-white text-center mb-4'>Choisis avec qui tu souhaites discuter</div> */}
    </div>}
  {indexShow != "free" && scenario.slice(0, 1).map((e,i) => {
    const img_name = `/images/tar/chat_bot/chat_${i%3 + 1}.png`
    return   <div onClick={() => {e.id == "free" && setIndexShow("free")}} className={`p-3 ${e.id == "free" ? "bg-blue-900/20" : "bg-black/20"}  border-black/90  mb-2 flex gap-2 rounded-xl border-2 border-b-4`}>
        <div className='relative pl-4'>
        <div className='absolute -top-2 -left-1 bg-indigo-500/50 rounded-full h-[40px] w-[40px] flex text-xl items-center justify-center'>{e.emo}</div>
        <img src={img_name} className='h-[32px] ml-2 mt-1 relative rounded-full' /> 
        </div>
        {e.name}
      </div>
  })}
  
  {indexShow == "free" && chats.map(chat => {
    const userChat = userChats?.find(e => e.chat_id == chat.id)
    return <div 
    onClick={() => {setSelectedChat(chat); !userChat && setShowInfo(true)}} 
    className={`p-3 ${userChat ? "bg-blue-900/20" : "bg-black/20"}  border-black/90  mb-2 flex gap-2 rounded-xl border-2 border-b-4`}>
      <img src={chat.photo} className='h-[54px] rounded-full' />
      <div>
        <div className='fond-bolder'>{chat.name[`${ct.workspace?.lang}`] || chat.name.all}</div>
        {!userChat && <div className='text-white/80 text-xs'>{chat.description}</div>}
        { userChat&& <div className='text-white/80'>Continuer la discussion</div>}
      </div>
      </div>
  })}
   </div>
   

  const info = selectedChat && <div className={`h-screen fixed top-0 left-0 right-0 bottom-0 ${darkMode ? "bg-gradient-to-b from-[#370e57] to-[#2a0449]" : "bg-yellow-50"} p-4 flex flex-col text-center gap-2 items-center justify-center`}>
     <div onClick={() => setSelectedChat()} className='w-full fixed top-4 left-4 text-left text-slate-500'>← Retour </div>
     <img src={selectedChat.photo} className='h-[120px] mt-10'/>
     <h2 className={`${darkMode ? "text-white" : ""}`}>{selectedChat?.name[`${ct.workspace?.lang}`] || selectedChat.name.all}</h2>
     <div className={`${darkMode ? "text-white/60" : "text-slate-500"}  text-center`}>{selectedChat.description}</div>
     {currentUserChat && <div className={`${darkMode ? "text-red-300/90" : "text-red-900 "} text-center my-4 `} onClick={( ) => resetChat()}>Effacer la discussion</div>}
     {!currentUserChat ? <div onClick={() => {setShowInfo(); createUserChat()}} className='px-4 bg-purple-500 border-2 border-b-4 border-black/40 text-xl text-white py-1 rounded-xl mt-16'>Commencer à discuter</div> : 
     <div onClick={() => setShowInfo()} className='px-4 bg-emerald-500 border-2 border-b-4 border-black/40 text-xl text-white py-1 rounded-xl mt-16'>Reprendre la discussion</div>}
  </div>

  const chat = selectedChat && <div id="scroll" className={`h-screen fixed top-0 left-0 right-0 bottom-0 ${darkMode ? "bg-gradient-to-b from-[#370e57] to-[#2a0449]" : "bg-yellow-50"} z-[10] overflow-scroll pb-[200px]`}>
  <header className={`flex ${darkMode ? "bg-[#270742] border-black/30" :"bg-[#fefdf3]" }  z-10 fixed top-0 right-0 left-0 items-center border-b px-4 py-4 justify-between gap-2`}>
     <div className='flex gap-3 items-center'>
     <svg  onClick={() => setSelectedChat()} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-10  rounded-xl border-2 borderb-4 p-1 ${darkMode ? "bg-black/10 border-black/10 text-white" : "bg-slate-50 text-slate-500 "}  h-10`}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
    </svg>
        <img src={selectedChat.photo} className='h-[36px]'/>
        <h2 className={`${darkMode ? "text-white" : ""}`}>{selectedChat.name[`${ct.workspace?.lang}`] || selectedChat.name.all}</h2>
        <span onClick={() =>setShowInfo(true)}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`h-6 w-6 ${darkMode ? "text-white/50" : "text-slate-500"} mt-1 `}>
  <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
</svg>
</span>
        {/* <div className='text-slate-300'>[{ct.userWorkspace.total_tokens}] | {ct.userDaily?.total_tokens}</div> */}
       
     </div>
     <div className='grow flex justify-end'>
          <div className='flex bg-black/20 border-2 border-black/40 rounded-xl gap-2'>
            <svg onClick={() => {setVocalMode(false)}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`h-10 p-2 rounded-xl ${!vocalMode ? "text-indigo-100 bg-black/50" : "text-white/40"}`}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
            </svg> 
            <svg onClick={() => {setVocalMode(true)}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`h-10 p-2 rounded-xl ${vocalMode ? "text-indigo-100 bg-black/50" : "text-white/40"}`}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
            </svg>
          </div>
        </div>
     

     </header>
     <div className={`${darkMode ? "" : "bg-yellow-50"} p-4 mt-[100px]`}>
       {/* <div className='text-sm text-slate-300'>{selectedChat.prompt}</div>
       <div className='text-sm text-green-500'>{messages.slice(-10).map(m => <div>{m.content}</div>)}</div> */}


       {messages?.map((mess) => <Message messages={messages} vocalMode={vocalMode} currentUserChat={currentUserChat} setMessages={setMessages} ct={ct} darkMode={true} mess={mess}/>)}
      {loadingMessage && <div className='flex'><div className='bg-slate-50 rounded-xl border-2 pt-2 px-4 '><l-bouncy
  size="30"
  speed="1.75" 
  color="#607694" 
></l-bouncy></div></div>  }
     </div>


     <div className={`fixed left-0 ${darkMode ? "bg-[#370e57]" : "bg-yellow-50"} pt-2 right-0 bottom-[50px] pb-1 transition-all px-4 mb-4 ${!isWriting && !audioMode ? "pr-[80px]":  "pr-[2px]"} `}>
     {audioMode && <svg onClick={() => setAudioMode()} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`h-10 ${darkMode ? "text-blue-100" : "text-blue-500"}  rounded-full absolute -left-0 bottom-6`}>
  
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />


      </svg>}
      {audioMode && <div className='p-2 ml-6 mb-2 rounded-xl relative'>
      
        <SpeechToTextSimple2 ct={ct} lang={ct.workspace.lang} send={(text) => {
            send(text, {audio: true})
        }} />
      </div>}

      {!audioMode && <textarea onBlur={() => {setTimeout(() => {setIsWriting(false)}, 500)}} onFocus={() =>setIsWriting(true)} placeholder={'Ecris ton message '} style={{outline: "none", height: countNewLines(prompt) > 0 ? (countNewLines(prompt))* 25 + 45 : 46}} 
      className={`max-w-[800px] mx-auto  ${darkMode ? "bg-black/70 border-black/50 hover:border-purple-500/60 text-white/90" : "bg-white border-slate-400/50 hover:border-slate-200"} w-full border-2 border-b-4  text-slate-600  transition-all  rounded-xl p-2 pl-3 pr-16 `} id="prompt">{prompt}</textarea>}
      {isWriting && !audioMode && <div 
      onClick={(e) => {
        e.stopPropagation()
        const textArea = document.querySelector('#prompt')
        console.log('textArea', textArea)
        console.log('textArea target', textArea?.value)
        send(textArea?.value)
        textArea.value = ""

        
        
        }} className='text-blue-500 fredoka absolute  right-3 bottom-[22px]'>Envoyer</div>}
     
      {!isWriting && !audioMode && <svg onClick={() => setAudioMode(true)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`bg-blue-500 p-2 border-2 border-b-4 border-black/70 rounded-full h-[46px] absolute bottom-[10px] right-[12px]`}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z" />
      </svg>}
    </div>
  </div>

  const beta = <div></div>

  return (
    <div className='fredoka pb-[100px]'>
    
      {true ? <div>
       {!selectedChat && index}
       { selectedChat && showInfo && info}
       { selectedChat && !showInfo && chat}
       </div> : <div className={`p-4 rounded-xl mt-4 bg-black/30 mb-2 flex gap-2`}>
               <img src="/images/UX/time_logo.png" className="h-[80px]"/>
            
                <div className="flex-grow">
                  <div className="font-semibold fredoka">Coming soon</div>
                  <div className="text-sm">Cette fonctionnalité est en cours de développement et sera bientôt disponible !</div>
                </div>
              </div>}
      
    </div>
  );
};

export {ChatScreen};

