import  React, { createContext, useMemo, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc, doc, getDocs, query, updateDoc, getDoc, orderBy, limit, deleteDoc } from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";
import { getFunctions, httpsCallable } from "firebase/functions";

import { db, app } from "../../config.js";
import {fire} from '../🔥db.js'
import { useNavigate } from "react-router-dom";
import { findWord, clean, gpt, numberSentences, splitNumeredSentence, sentenceEndRegex, goodDate, shuffleArray } from "../../utilities/helper/text.js";
import SuperText from './perfectScreen.js';

import { quantum, waveform } from 'ldrs'
import { displayDuration } from '../../utilities/helper/helper.js';


function filterUniqueLetters(cards) {
    const seenLetters = new Set();
    const uniqueCards = [];
  
    for (const card of cards) {
      if (!seenLetters.has(card.letter)) {
        seenLetters.add(card.letter);
        uniqueCards.push(card);
      }
    }
  
    return uniqueCards;
  }

const LearnView = ({cardToLearn, setCardToLearn, setUserCards, userCards}) => {

    const [cards, setCards] = useState()
    const [indexCard, setIndexCard] = useState(0)

    const cardOnly = cardToLearn.map(c => c.card)

    
    const currentElement = cards?.[indexCard]
    const currentCard = currentElement?.card
    const currentUserCard = userCards?.find(uc => uc.card_id == currentCard?.id) 


    useEffect(() => {
        setCards(cardToLearn.map(c => ({...c, done: 0})))
    }, [])
    

    // séparer lecture de écriture ? ecriture izi
    const getIndex = (valid) => {

        // trouver les cartes avec done == null
        // trouve la carte avec done le plus bas


        const currentTrigger = ((currentElement?.userCard?.triggers?.[0]?.lvl || 0) + 1) % 2 || 0


        const new_userCard = {
            ...currentElement.userCard,
            card_id: currentElement.card.id,
            valid,
            next_trigger: currentTrigger,
            triggers: [
              { lvl: (currentElement?.userCard?.triggers?.[0]?.lvl || 0) + (valid && currentTrigger == 1 ? 1 : 0) },
              { lvl: (currentElement?.userCard?.triggers?.[1]?.lvl || 0) + (valid && currentTrigger == 0 ? 1 : 0) }
            ]
          }


        const new_card = {
            ...currentElement,
            userCard: new_userCard
          };


        //   console.log('currentElement', currentElement)
          console.log('currentUserCard', currentUserCard)
        if (!currentUserCard) {
            console.log('création de user_card !!')
            // création user cards
            setUserCards(uc => [...uc, {...new_userCard, valid: null}])
        } else {
            setUserCards(uc => uc.map(u => u.card_id == new_userCard.card_id ? {...new_userCard, valid: null} : u))
        }
        
       



        const _new_cards = cards.map((c,i) => i == indexCard ? {...new_card, done: c.done + 1, valid: valid} : c)

        setCards(_new_cards)
        const new_index = cards
        .map((card, index) => ({ ...card, originalIndex: index })) // Ajoute l'index original à chaque carte
        .filter(card => !card.userCard?.valid && indexCard != card.originalIndex) // Filtre les cartes non valides
        .sort((a, b) => a.done - b.done) // Trie par valeur croissante de 'done'
        .map(card => card.originalIndex) // Récupère l'index original de la carte triée
        .shift(); // Prend l'index de la première carte (celle avec le 'done' le plus faible)



        if (new_index == -1 || new_index == undefined) {
            setCardToLearn()
        
            
            return }
    
        setIndexCard(new_index)
    
        
            
        
    }



    


    // mettre le trigger automatique dans LetterCard
    // compter point double pour carte new 


    return <div className='bg-purple-900 p-4 h-[700px]'>
        <div className='flex gap-1'>{cards?.map((e, i) => <div className={`${i == indexCard ? "text-yellow-500 " : e.userCard?.valid && "text-green-500"}`}>{e.done}</div>)}</div>
        index: {indexCard} 
        total: {cardToLearn?.length} 


        
        {currentCard && <LetterCard trigger={"new_card"} cards={filterUniqueLetters(cardOnly)} card={currentCard} userCard={currentUserCard} />}
        <div className='flex gap-2 mt-4 ' >
        <div className='px-4 py-1 grow rounded-xl hover:scale-[1.1] cursor-pointer border-2 text-center ' onClick={() => {getIndex(false)}}>Faux</div>
        <div className='px-4 py-1 grow  rounded-xl hover:scale-[1.1] cursor-pointer border-2 text-center ' onClick={() => {getIndex(true)}}>Juste</div>
        </div>
     
    </div>

}

const PaintableLetter = ({ letter = 'A', opacity = 0.3 }) => {
    const canvasRef = useRef(null);
    const hiddenCanvasRef = useRef(null);
    const [isPainting, setIsPainting] = useState(false);
    const [ctx, setCtx] = useState(null);
    const [coloredPixels, setColoredPixels] = useState(0);
    const [totalPixels, setTotalPixels] = useState(1); // Initialisé à 1 pour éviter la division par zéro
    const [pixelMap, setPixelMap] = useState(null); // Nouvelle variable pour stocker les pixels de la lettre
  
    useEffect(() => {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      const hiddenCanvas = hiddenCanvasRef.current;
      const hiddenContext = hiddenCanvas.getContext('2d');
  
      // Dimensionnement du canevas caché
      hiddenCanvas.width = canvas.width;
      hiddenCanvas.height = canvas.height;
  
      const fontSize = 200;
      const fontFamily = 'Arial';
  
      // Définir les propriétés de la lettre
      context.font = `${fontSize}px ${fontFamily}`;
      context.textAlign = 'center';
      context.textBaseline = 'middle';
  
      hiddenContext.font = `${fontSize}px ${fontFamily}`;
      hiddenContext.textAlign = 'center';
      hiddenContext.textBaseline = 'middle';
  
      // Calculer la position pour centrer la lettre
      const x = canvas.width / 2;
      const y = canvas.height / 2;
  
      // Dessiner la lettre en arrière-plan du canevas caché
      hiddenContext.fillStyle = '#20193EEE';
      hiddenContext.fillText(letter, x, y);
  
      // Créer un tableau binaire pour les pixels de la lettre
      const imageData = hiddenContext.getImageData(0, 0, hiddenCanvas.width, hiddenCanvas.height);
      const pixels = new Array(imageData.data.length / 4).fill(false);
      let pixelCount = 0;
  
      for (let i = 0; i < imageData.data.length; i += 4) {
        if (imageData.data[i + 3] > 0) { // Vérifie si le pixel est opaque (fait partie de la lettre)
          pixels[i / 4] = true;
          pixelCount++;
        }
      }
  
      setTotalPixels(pixelCount);
      setPixelMap(pixels);
  
      // Préparer le canevas visible
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.fillStyle = '#20193EEE';
      context.fillText(letter, x, y);
  
      // Configuration du contexte pour le dessin
      context.globalCompositeOperation = 'source-atop';
      setCtx(context);
      setColoredPixels(0); // Réinitialiser les pixels coloriés
    }, [letter]);
  
    const getTouchPos = (canvas, touchEvent) => {
      const rect = canvas.getBoundingClientRect();
      return {
        x: touchEvent.touches[0].clientX - rect.left,
        y: touchEvent.touches[0].clientY - rect.top,
      };
    };
  
    const handleDraw = (x, y) => {
      const canvas = canvasRef.current;
      const pixelIndex = Math.floor(y) * canvas.width + Math.floor(x);
  
      // Vérifier si le pixel est dans la lettre et non encore colorié
      if (pixelMap[pixelIndex]) {
        ctx.strokeStyle = '#E77975';
        ctx.lineWidth = 36;
        ctx.lineCap = 'round';
  
        ctx.lineTo(x, y);
        ctx.stroke();
        ctx.beginPath();
        ctx.moveTo(x, y);
  
        // Marquer ce pixel comme colorié et incrémenter si c'est la première fois
        if (pixelMap[pixelIndex] !== 'colored') {
          pixelMap[pixelIndex] = 'colored';
          setColoredPixels((prev) => prev + 1);
        }
      }
    };
  
    const startPainting = (event) => {
      setIsPainting(true);
      if (event.type.includes('touch')) {
        const touchPos = getTouchPos(canvasRef.current, event);
        handleDraw(touchPos.x, touchPos.y);
      } else {
        handleDraw(event.nativeEvent.offsetX, event.nativeEvent.offsetY);
      }
    };
  
    const stopPainting = () => {
      setIsPainting(false);
      if (ctx) {
        ctx.beginPath(); // Réinitialiser le chemin pour un nouveau coup de pinceau
      }
    };
  
    const draw = (event) => {
      if (!isPainting) return;
  
      if (event.type.includes('touch')) {
        const touchPos = getTouchPos(canvasRef.current, event);
        handleDraw(touchPos.x, touchPos.y);
      } else {
        handleDraw(event.nativeEvent.offsetX, event.nativeEvent.offsetY);
      }
    };
  
    const completionPercentage = ((coloredPixels / totalPixels) * 100).toFixed(2);
  
    return (
      <div className='grow w-full relative'>
        <canvas
          ref={canvasRef}
          width={250}
          height={250}
          onMouseDown={startPainting}
          onMouseMove={draw}
          onMouseUp={stopPainting}
          onMouseLeave={stopPainting}
          onTouchStart={startPainting}
          onTouchMove={draw}
          onTouchEnd={stopPainting}
          className='bg-black/20 rounded-xl'
          style={{ border: '1px solid black', touchAction: 'none' }}
        />
        <canvas
          ref={hiddenCanvasRef}
          style={{ display: 'none' }} // Canevas caché
        />
        {/* <p>Completion: {completionPercentage}%</p> */}
      </div>
    );
  };
  










// charactère chinois dans la pièrre de rosette ? 
// apprendre uniquement le pinyin ici
// puis 10 premiers caractères (ici ou direct dans le cours)


const InputQCM = ({fied_id, array, correct, validAction}) => {

    const answer = correct[`${fied_id}`]
    const [selectedProps, setSelectedProps] = useState()
    const selectedAnswer = array[selectedProps]?.[`${fied_id}`]

    useEffect(() => {
        if (selectedAnswer == answer) {
            validAction()
        }

        console.log('array', array)
    }, [selectedAnswer])


    return <div>
        <div className='grid grid-cols-2 gap-2'>
        {array.map((e, i) => <div onClick={() => {setSelectedProps(i)}} className={`border-2 ${ selectedProps == i ? answer == e[`${fied_id}`] ? "bg-green-500" : "bg-red-500" : ""} px-2 py-2 grow rounded-xl border-black/40 border-b-4 text-center`}>{e[`${fied_id}`]}</div>)}
        </div>
    </div>
    }


const LetterMini = ({card, userCard, setShowCard}) => {

    return <div onClick={() => {setShowCard({card: card, userCard: userCard})}} className='border-2 text-center px-3 rounded-xl   py-1 border-b-4 border-black/30'>
        <div className='text-4xl'>{card.letter}</div>
        <div className='text-white/40 fredoka'>{card.latin}</div>
        </div>
}


const highlightText = (letter, text, text_latin) => {
    const regex = new RegExp(`(${letter})`, 'g');
    const parts = text.split(regex);
    return (
        <div className='text-4xl'>
            {parts.map((part, i) =>
                part === letter ? (
                    <span key={i} style={{ color: 'white' }}>{part}</span>
                ) : (
                    <span key={i} className='text-white/50'>{part}</span>
                )
            )} <div className='text-xs text-[#FF62A4] text-left'>{text_latin}</div>
        </div>
    );
}

const LetterCard = ({card, userCard, cards, trigger}) => {


   


    const [triggerMode, setTriggerMode] = useState()
    const [inputState, setInputState] = useState(0)
    const [randomCards, setRandomCards] = useState([])

    useEffect(() => {
        const lvl_trigger = userCard?.triggers?.[userCard?.next_trigger]?.lvl
        setInputState(0)
        if (!userCard?.next_trigger) 
            {setTriggerMode("new_card")} 
        if (userCard?.next_trigger == 1) {
           
            setTriggerMode(lvl_trigger%2 == 0 ? "audio_letter" : "audio_letter_context")
        } 
        if (userCard?.next_trigger == 0)    {
            setTriggerMode(lvl_trigger%2 == 0 ? "letter_latin" : "letter_latin_context")
        }
           
    }, [userCard, card.id])


    useEffect(() => {
        const otherCards = shuffleArray(cards.filter(c => c.letter != card.letter)) // prendre en priorité 2 cartes déjà apprisent
        const _randomCards = shuffleArray([card, ...otherCards.slice(0,3)]) 
        setRandomCards(_randomCards)
    }, 
    [cards.length])



    const inputOpen = <div lassName='flex justify-between gap-2'>
        <div onClick={() => setInputState(e => e+1)} className='border-2 mb-2 px-2 py-2 grow rounded-xl border-black/40 border-b-4 text-center'>Je sais pas</div>
        <div onClick={() => setInputState(e => e+1)} className='border-2 mb-2 px-2 py-2 grow rounded-xl border-black/40 border-b-4 text-center'>Je sais</div>
    </div>

    


    const new_card = <div className='p-4 relative h-full text-red-400'>
     <div className='text-center text-purple-300 text-white/70 mt-2 text-xs'>Nouvelle lettre ({cards.length}) - {randomCards.length}</div>
        {inputState == 0 && <div>
            <div className='text-center text-sm text-purple-300 text-white/70 mt-8 mb-2'>Dessine la lettre</div>

            <PaintableLetter letter={card.letter}  />
            <div onClick={() => setInputState(e => e+1)} className='border-2 mb-2 px-2 py-2 grow  mt-2 rounded-xl border-black/40 border-b-4 text-center'>Suivant</div>
            </div>}
        {inputState == 1 && <div>
            <div className='text-center text-white/70 mt-8 mb-2'>Comment se prononce cette lettre</div>
                <div className='mx-auto text-[60px] mt-16 flex justify-center'>
                {card.letter}
                </div>

            <InputQCM fied_id='latin' array={randomCards} correct={card} validAction={() => setTriggerMode()} />
            </div>}

    </div>

    const audio_letter = <div className='p-4 relative h-full text-red-400'>
        <div className='mx-auto mt-16 flex justify-center'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-[#FF62A4] w-12 h-12"><path stroke-linecap="round" stroke-linejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"></path></svg>        
        </div>
        <div className='text-center'>{card.latin}</div>
        <div className='text-center text-white/70 mt-8'>Quelle lettre fais ce son ?</div>

        <div  id="input" className='text-white absolute bottom-0 p-4 left-0 right-0'>
            {inputState == 0 && inputOpen }
            {inputState == 1 && <InputQCM fied_id='letter' array={randomCards} correct={card} validAction={() => setTriggerMode()} />}
        </div>
        </div>

        const audio_letter_context = <div className='p-4 relative h-full text-red-400'>
        <div className='mx-auto mt-16 flex justify-center'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-[#FF62A4] w-12 h-12"><path stroke-linecap="round" stroke-linejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"></path></svg>        
        </div>
        <div className='text-center'>{card.word_latin}</div>
        <div className='text-center text-white/70 mt-8'>Comment écrire ce mot ?</div>

        <div  id="input" className='text-white absolute bottom-0 p-4 left-0 right-0'>
            {inputState == 0 && inputOpen }
            {inputState == 1 && <InputQCM fied_id='word' array={randomCards} correct={card} validAction={() => setTriggerMode()} />}
        </div>
        </div>

    const letter_latin = <div className='p-4 relative h-full text-red-400'>
    <div className='mx-auto text-[60px] mt-16 flex justify-center'>
    {card.letter}
    </div>
    <div className='text-center text-white/70 mt-8'>Comment se prononce cette lettre ?</div>

    <div  id="input" className='text-white absolute bottom-0 p-4 left-0 right-0'>
        {inputState == 0 && inputOpen }
        {inputState == 1 && <InputQCM fied_id='latin' array={randomCards} correct={card} validAction={() => setTriggerMode()} />}
    </div>
    </div>

    const letter_latin_context = <div className='p-4 relative h-full text-red-400'>
    <div className='mx-auto text-[60px] mt-16 flex justify-center'>
    {card.word}
    </div>
    <div className='text-center text-white/70 mt-8'>Comment se prononce ce mot ?</div>

    <div  id="input" className='text-white absolute bottom-0 p-4 left-0 right-0'>
        {inputState == 0 && inputOpen }
        {inputState == 1 && <InputQCM fied_id='word_latin' array={randomCards} correct={card} validAction={() => setTriggerMode()} />}
    </div>
    </div>


    const back = <div className='p-4  overflow-hidden text-center'>
      
    <div className='mb-6 flex flex-col items-center'>
        <div className='relative -mt-4 text-[70px] min-w-[50px]   flex justify-center'>{card.letter}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-[#FF62A4] absolute top-2 -right-[30px] mt-1 rounded-full w-7 h-7"><path stroke-linecap="round" stroke-linejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"></path></svg>        

            <div className='w-[120px] z-[0] top-[10px] left-[-36px] absolute h-[120px] bg-white/10 rounded-full '></div>
        </div>
        <div className='text-[#FF62A4] -mt-2 fredoka'>{card.latin}</div>
    </div>

    <div className='mb-6 flex gap-4 items-center'>
        <img className='h-[160px] w-[160px] rounded-full -ml-8 bg-pink-100/10 border-8 border-white/20'  src='https://firebasestorage.googleapis.com/v0/b/mindseed-425a8.appspot.com/o/dalle3_img%2F1xbJQRX7g1WjjFFN9x5S?alt=media&token=ade9a2af-6771-4e51-9f67-8a6d9c09c9eb'/>
        <div className='relative'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-[#FF62A4] absolute top-0 -right-[30px] mt-1 rounded-full w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"></path></svg>        

            {highlightText(card.letter, card.word, card.word_latin)}
            <div className='text-white/40 fredoka text-left'>{card.word_fr}</div>
        </div>
    </div>

    {/* <div className='text-white/70 fredoka'>{card.sentence}</div>
    <div className='text-white/40 fredoka'>{card.sentence_fr}</div> */}


    {card.variants.length > 0 && <div>
    <div className='text-xs text-white/40 mt-4 '>Variations</div>
    <div className='gap-2 flex justify-center'>{card.variants.map((v) => <div className='text-3xl'>{v.letter}</div>)}</div>
    {/* {card.variants.map((v, index) => {
            const regex = new RegExp(`(${card.letter})`, 'g');
            const parts = v.word.split(regex);
            return (
                <div key={index} className='text-2xl'>
                    {parts.map((part, i) =>
                        part === card.letter ? (
                            <span key={i} style={{ color: '#FF62A4' }}>{part}</span>
                        ) : (
                            <span key={i}>{part}</span>
                        )
                    )} <div className='text-xs text-white/40'>{v.word_latin}</div>
                </div>
            );
        })} */}
    </div>}
</div>

    

    const expression = ''
    const comp = ''

    console.log('userCard', userCard)

   

    return <div onClick={(e) => e.preventDefault()} className='bg-[#433182]   h-[450px] w-[300px] border-4 border-b-8 border-black/50 rounded-2xl'>
        <div>info: {userCard ? userCard?.triggers?.[0]?.lvl : "new !!"} - next: {userCard?.next_trigger}</div>
        {!triggerMode && back}
        {triggerMode == "new_card" && new_card}
        {triggerMode == "letter_latin" && letter_latin}
        {triggerMode == "audio_letter" && audio_letter}
        {triggerMode == "audio_letter_context" && audio_letter_context}
        {triggerMode == "letter_latin_context" && letter_latin_context}

        
    </div>
}




const RosetaScreen = (props) => {
    const navigate = useNavigate()
    const [lastCall, setLastCall] = useState()
    const menu = props.menu
    const ct = props.ct



    useEffect(() => {
        console.log("StoryScreen loaded")

    }, [])


    const cards = [
        {
            letter: "أ",
            latin: "ʾ",
            
            word: "أنا",
            word_latin: "ana",
            word_fr: "je",
            sentence: "أنا طالب.",
            sentence_fr: "Je suis étudiant.",
            hint: "utilisé pour se désigner soi-même",
            note: "Utilisé pour se désigner soi-même",
            rank: 1,
            id: "c1",
            variants: [],
            img: "",
            workspace: "arabe",
            type: "character",
        },
        {
            letter: "ب",
            latin: "b",
            word: "بيت",
            word_latin: "bayt",
            word_fr: "maison",
            sentence: "هذا بيت.",
            sentence_fr: "Ceci est une maison.",
            hint: "utilisé pour désigner une maison",
            note: "Commence le mot 'maison'",
            rank: 2,
            id: "c2",
            variants: [
                { letter: "بـ", word: "بداية", word_latin: "bidaya", type: "initial" }, // Initial
                { letter: "ـبـ", word: "كتاب", word_latin: "kitab", type: "medial" }, // Medial
                { letter: "ـب", word: "حبيب", word_latin: "habib", type: "final" }  // Final
            ],
            img: "",
            workspace: "arabe",
            type: "character",
        },
        {
            letter: "ت",
            latin: "t",
            word: "تفاح",
            word_latin: "tufah",
            word_fr: "pomme",
            sentence: "أحب التفاح.",
            sentence_fr: "J'aime les pommes.",
            hint: "utilisé pour désigner une pomme",
            note: "Commence le mot 'pomme'",
            rank: 3,
            id: "c3",
            variants: [
                { letter: "تـ", word: "تاج", word_latin: "taj", type: "initial" }, // Initial
                { letter: "ـتـ", word: "كتابة", word_latin: "kitaba", type: "medial" }, // Medial
                { letter: "ـت", word: "بيت", word_latin: "bayt", type: "final" }  // Final
            ],
            img: "",
            workspace: "arabe",
            type: "character",
        },
        {
            letter: "ث",
            latin: "th",
            word: "ثلاثة",
            word_latin: "thalatha",
            word_fr: "trois",
            sentence: "عندي ثلاثة كتب.",
            sentence_fr: "J'ai trois livres.",
            hint: "utilisé pour désigner le nombre trois",
            note: "Commence le mot 'trois'",
            rank: 4,
            id: "c4",
            variants: [
                { letter: "ثـ", word: "ثمر", word_latin: "thamar", type: "initial" }, // Initial
                { letter: "ـثـ", word: "مثال", word_latin: "mithal", type: "medial" }, // Medial
                { letter: "ـث", word: "بحث", word_latin: "bahth", type: "final" }  // Final
            ],
            img: "",
            workspace: "arabe",
            type: "character",
        },
        {
            letter: "ج",
            latin: "j",
            word: "جمل",
            word_latin: "jamal",
            word_fr: "chameau",
            sentence: "هذا جمل.",
            sentence_fr: "Ceci est un chameau.",
            hint: "utilisé pour désigner un chameau",
            note: "Commence le mot 'chameau'",
            rank: 5,
            id: "c5",
            variants: [
                { letter: "جـ", word: "جدار", word_latin: "jidar", type: "initial" }, // Initial
                { letter: "ـجـ", word: "مجد", word_latin: "majd", type: "medial" }, // Medial
                { letter: "ـج", word: "حاج", word_latin: "hajj", type: "final" }  // Final
            ],
            img: "",
            workspace: "arabe",
            type: "character",
        },
        {
            letter: "ح",
            latin: "ḥ",
            word: "حب",
            word_latin: "hub",
            word_fr: "amour",
            sentence: "الحب جميل.",
            sentence_fr: "L'amour est beau.",
            hint: "utilisé pour désigner l'amour",
            note: "Commence le mot 'amour'",
            rank: 6,
            id: "c6",
            variants: [
                { letter: "حـ", word: "حديقة", word_latin: "hadiqa", type: "initial" }, // Initial
                { letter: "ـحـ", word: "محتاج", word_latin: "muhtaj", type: "medial" }, // Medial
                { letter: "ـح", word: "مفتاح", word_latin: "miftah", type: "final" }  // Final
            ],
            img: "",
            workspace: "arabe",
            type: "character",
        },
        {
            letter: "خ",
            latin: "kh",
            word: "خبز",
            word_latin: "khubz",
            word_fr: "pain",
            sentence: "أريد خبز.",
            sentence_fr: "Je veux du pain.",
            hint: "utilisé pour désigner le pain",
            note: "Commence le mot 'pain'",
            rank: 7,
            id: "c7",
            variants: [
                { letter: "خـ", word: "خيمة", word_latin: "khaima", type: "initial" }, // Initial
                { letter: "ـخـ", word: "مختار", word_latin: "mukhtar", type: "medial" }, // Medial
                { letter: "ـخ", word: "فخ", word_latin: "fakh", type: "final" }  // Final
            ],
            img: "",
            workspace: "arabe",
            type: "character",
        },
        {
            letter: "د",
            latin: "d",
            word: "دب",
            word_latin: "dub",
            word_fr: "ours",
            sentence: "هذا دب.",
            sentence_fr: "Ceci est un ours.",
            hint: "utilisé pour désigner un ours",
            note: "Commence le mot 'ours'",
            rank: 8,
            id: "c8",
            variants: [],
            img: "",
            workspace: "arabe",
            type: "character",
        },
        {
            letter: "ذ",
            latin: "dh",
            word: "ذهب",
            word_latin: "dhahab",
            word_fr: "or",
            sentence: "الذهب ثمين.",
            sentence_fr: "L'or est précieux.",
            hint: "utilisé pour désigner l'or",
            note: "Commence le mot 'or'",
            rank: 9,
            id: "c9",
            variants: [],
            img: "",
            workspace: "arabe",
            type: "character",
        },
        {
            letter: "ر",
            latin: "r",
            word: "ريح",
            word_latin: "rih",
            word_fr: "vent",
            sentence: "الريح قوية.",
            sentence_fr: "Le vent est fort.",
            hint: "utilisé pour désigner le vent",
            note: "Commence le mot 'vent'",
            rank: 10,
            id: "c0",
            variants: [],
            img: "",
            workspace: "arabe",
            type: "character",
        },
    ];
    


    // const cards = [
    //     {
    //         letter: "你",
    //         word: "你",
    //         word_fr: "toi",
    //         sentence: "你好吗？",
    //         sentence_fr: "Comment vas-tu ?",
    //         latin: "nǐ",
    //         pinyin: "nǐ",
    //         hint: "utilisé pour parler à quelqu'un",
    //         note: "Utilisé pour parler à quelqu'un",
    //         rank: 3,
    //         img: "",
    //         workspace: "chinois",
    //         type: "character",
    //     },
    //     {
    //         letter: "我",
    //         word: "我",
    //         word_fr: "je",
    //         sentence: "我是学生。",
    //         sentence_fr: "Je suis un étudiant.",
    //         latin: "wǒ",
    //         pinyin: "wǒ",
    //         hint: "utilisé pour se désigner soi-même",
    //         note: "Utilisé pour se désigner soi-même",
    //         rank: 1,
    //         img: "",
    //         workspace: "chinois",
    //         type: "character",
    //     },
    //     {
    //         letter: "他",
    //         word: "他",
    //         word_fr: "il",
    //         sentence: "他是我的朋友。",
    //         sentence_fr: "Il est mon ami.",
    //         latin: "tā",
    //         pinyin: "tā",
    //         hint: "utilisé pour parler d'un homme",
    //         note: "Utilisé pour parler d'un homme",
    //         rank: 4,
    //         img: "",
    //         workspace: "chinois",
    //         type: "character",
    //     },
    //     {
    //         letter: "她",
    //         word: "她",
    //         word_fr: "elle",
    //         sentence: "她是医生。",
    //         sentence_fr: "Elle est médecin.",
    //         latin: "tā",
    //         pinyin: "tā",
    //         hint: "utilisé pour parler d'une femme",
    //         note: "Utilisé pour parler d'une femme",
    //         rank: 5,
    //         img: "",
    //         workspace: "chinois",
    //         type: "character",
    //     },
    //     {
    //         letter: "好",
    //         word: "好",
    //         word_fr: "bien",
    //         sentence: "今天很好。",
    //         sentence_fr: "Aujourd'hui, ça va bien.",
    //         latin: "hǎo",
    //         pinyin: "hǎo",
    //         hint: "utilisé pour dire que quelque chose est bien",
    //         note: "Utilisé pour dire que quelque chose est bien",
    //         rank: 2,
    //         img: "",
    //         workspace: "chinois",
    //         type: "character",
    //     },
    //     {
    //         letter: "家",
    //         word: "家",
    //         word_fr: "maison",
    //         sentence: "我回家了。",
    //         sentence_fr: "Je suis rentré à la maison.",
    //         latin: "jiā",
    //         pinyin: "jiā",
    //         hint: "utilisé pour parler d'une maison ou d'une famille",
    //         note: "Utilisé pour parler d'une maison ou d'une famille",
    //         rank: 6,
    //         img: "",
    //         workspace: "chinois",
    //         type: "character",
    //     },
    //     {
    //         letter: "水",
    //         word: "水",
    //         word_fr: "eau",
    //         sentence: "我要喝水。",
    //         sentence_fr: "Je veux boire de l'eau.",
    //         latin: "shuǐ",
    //         pinyin: "shuǐ",
    //         hint: "utilisé pour parler de l'eau",
    //         note: "Utilisé pour parler de l'eau",
    //         rank: 7,
    //         img: "",
    //         workspace: "chinois",
    //         type: "character",
    //     },
    //     {
    //         letter: "火",
    //         word: "火",
    //         word_fr: "feu",
    //         sentence: "火很热。",
    //         sentence_fr: "Le feu est très chaud.",
    //         latin: "huǒ",
    //         pinyin: "huǒ",
    //         hint: "utilisé pour parler du feu",
    //         note: "Utilisé pour parler du feu",
    //         rank: 8,
    //         img: "",
    //         workspace: "chinois",
    //         type: "character",
    //     },
    //     {
    //         letter: "山",
    //         word: "山",
    //         word_fr: "montagne",
    //         sentence: "我们去爬山。",
    //         sentence_fr: "Nous allons escalader la montagne.",
    //         latin: "shān",
    //         pinyin: "shān",
    //         hint: "utilisé pour parler d'une montagne",
    //         note: "Utilisé pour parler d'une montagne",
    //         rank: 9,
    //         img: "",
    //         workspace: "chinois",
    //         type: "character",
    //     },
    //     {
    //         letter: "天",
    //         word: "天",
    //         word_fr: "ciel",
    //         sentence: "今天的天气很好。",
    //         sentence_fr: "Le temps est très beau aujourd'hui.",
    //         latin: "tiān",
    //         pinyin: "tiān",
    //         hint: "utilisé pour parler du ciel ou du temps",
    //         note: "Utilisé pour parler du ciel ou du temps",
    //         rank: 10,
    //         img: "",
    //         workspace: "chinois",
    //         type: "character",
    //     },
    // ];


    
    const userCards = props.userCards

    console.log('userCards', userCards)
    
    const cardWithData = cards.map(e => ({card: e, userCard: userCards.find(uc => uc.card_id == e.id)})) // on format en user_card

     


    const [showCard, setShowCard] = useState()
    const [cardToLearn, setCardToLearn] = useState()

    console.log('cardWithData', cardWithData)

 



    // interface rosetta avec plusieurs phrases qui non aucun sens. et contienne toutes les lettres de l'alphabet.
    // mettre pierre de rosette déchiffré à 80%. Tu es maitrise x% de l'alphabet russe..
    // !! impossible de le faire avec le chinois.
    // voir tuto dans chaque langue, qui explique les spécificités.

    // comment faire pour les signes ? 
    // pinying 
    // pierre de rosette hsk1 ? ou juste les 10 premiers signes 


    const newCards = [...cardWithData]


    // on prends les cards "news", 

    const nextLetter = [
        // ajouter les révisions ici 
        // si pas de revision ajouter revision de demain ici 
        newCards?.[0], 
        newCards?.[1], 
        newCards?.[0],
        newCards?.[1],
        newCards?.[2],
        newCards?.[0],
        newCards?.[1],
        newCards?.[2],
        newCards?.[3],
        newCards?.[2],
        newCards?.[3],
        newCards?.[3],

    ] 
        ?.slice(0, 20)

        // faire aussi exercice dérevision




    return <div className="px-2 text-white rounded-xl mb-2 mt-8 ">
    

        {/* <PaintableLetter letter={"ج"}  /> */}
        {/* <DrawingCanvas letter={"ج"} /> */}

        Liste des stacks
        {nextLetter.map( e => <div className='flex gap-2'><div className='w-12'>{e.card.letter} </div> 
        <div>
            <span className='text-green-500'>{e.userCard?.triggers?.[0]?.lvl}</span> - 
            <span className='text-purple-300'>{e.userCard?.triggers?.[1]?.lvl}</span>
        </div>
        </div>) }

        <div className='px-4 rounded-xl bg-purple-500 border-2 border-b-4 py-2 text-center border-black'  onClick={() => setCardToLearn(nextLetter)}>Apprendre</div>

        {cardToLearn && <LearnView userCards={userCards} setUserCards={props.setUserCards} cardToLearn={cardToLearn} setCardToLearn={setCardToLearn} />}

        {showCard && <div onClick={() => {setShowCard()}} className='fixed bg-black/20 flex items-center justify-center top-0 left-0 right-0 bottom-0'>
            <div onClick={(e) => e.stopPropagation()}><LetterCard cards={cards} card={showCard.card} userCard={showCard.userCard} /></div>
            </div>}

       <div className='text-center fredoka mb-4 text-2xl'>Pierre de rosette</div>

       <div className='flex gap-2'>Lettres - Pierre de rosette</div>

       <div className='grid grid-cols-4 gap-2 flex-wrap'>{cardWithData.map(e => <LetterMini setShowCard={setShowCard} userCard={e.user_card} card={e.card} />)}</div>

       
      </div>

};

export {RosetaScreen};





