import React, { useRef, useEffect, useState } from 'react';
import {displayDuration, images} from './../../../utilities/helper/helper'
import { BrowserRouter as Router, Routes, Route, Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { collection, onSnapshot, querySnapshot, count, updateDoc,  getDoc, deleteDoc, writeBatch, batch, query, where, getDocs, doc, addDoc, orderBy } from "firebase/firestore";
import { db } from "../../../config.js";
import {ProgressBar} from './../../../components/progressBar'
import { SuperEditor } from './../../../components/editor' 
import { getTitle } from './../../../utilities/helper/text';

//import { draw, carToIso, isoToCar} from "./draw";


const getCardsFromDeck = async (wp_id, deckIDS, set) => {
    console.log('getCArs deck 🟢🟢🟢🟢')
    const q = query(collection(db, 'cards'), where('workspace_id', "==", wp_id), where('deck_id', 'in', deckIDS))
    const querySnapshot = await getDocs(q);
    const cards = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
    console.log('cards deck: ', cards)
    set(cards)
   
}

const removeDuplicates = (array) => {
    return [...new Set(array)];
  };

function Popover(props) {
    // console.log('pop')
    const ct = props.context
    let deck = ct.decks?.find(d => d.id === props.deck_id)
    const navigate = useNavigate()
    const [showDetail, setShowDetail] = useState(false)
    const [cardDeck,setCardDeck] = useState(false)

    useEffect(() => {

        if (!deck) return
        let cards = ct.cards?.filter((card, index) => card.deck_id === deck.id || deck?.childs?.find(d => d.id == card.deck_id))
        let decksIDS = [
            deck.id,
            ...deck?.childs?.map(child => child.id) || []
          ];
        console.log('DECKIDS', decksIDS) 
        // faire get multi deck
        // if (!(cards?.length > 0)) {
            getCardsFromDeck(ct.workspace.id, decksIDS, setCardDeck)
        // }

    }, [deck])




    let posTop = props.pos?.top ? props.pos?.top : 0
    let posCursor = props.cursor ? props.cursor : 120
    let posLeft = props.pos?.left
    let posRight = 20
    var screenWidth = window.innerWidth;
    var screenHeight = window.innerHeight;
    let inverse = false

    if (props.pos?.left > screenWidth - 300) {
        posLeft = screenWidth - 300
        posCursor = 200
    }
    if (props.pos?.left < screenWidth - 300) {
        posLeft = 30
    }

    if (posTop > screenHeight - 150) {
        posTop -= 220
        inverse = true

    }

    posLeft = 10
    posRight = 10

    if (screenWidth > 700) {
        posLeft = screenWidth/2 - 200
        posRight = screenWidth/2 - 200
    }






    
    let minDateUpdate = new Date()
    minDateUpdate.setSeconds(minDateUpdate.getSeconds() + 30);
    let lastUpdate = new Date()
    lastUpdate.setMinutes(lastUpdate.getMinutes() - 66);
    if (props.card?.last_update) {
        const lu = props.card?.last_update
        if (lu.seconds) {
            lastUpdate = new Date(lu.seconds*1000)
       
        } else {
            lastUpdate = lu
        }
    }
    const last_update_duration = displayDuration(lastUpdate, new Date())
    const locked_time_left = displayDuration(lastUpdate, minDateUpdate)
    const diff_seconds = (new Date() - lastUpdate) / 1000
    
    const user_card = {...props.card, 
        next_date: props.card?.next_date?.seconds ? new Date(props.card.next_date.seconds * 1000) : props.card?.next_date,
        triggers: props.card?.triggers.map(t => t.next_date.seconds ? (
            {...t, next_date: new Date(t.next_date.seconds * 1000)}
            ) : t)}
    const duration = displayDuration(new Date(), user_card?.next_date)
    let state = user_card?.archived ? "archived" : user_card?.next_date > new Date() ? "master" : "review" 
    if (diff_seconds < 30 && state == "review") state = "locked"
    //if(state == "review") ct.setShowCards([{...user_card.card, user_card: user_card}])
    
    


    const allTriggers = user_card?.triggers?.map(trigger => <div className='mt-2 text-sm flex w-full gap-2 justify-between'>
        <div>{trigger.name}</div>  <div className='bg-white/20 px-2 rounded-xl '>{ displayDuration(new Date(), trigger.next_date)}</div>
        </div>)
    // console.log('allTriggers',props.card?.triggers)
    const textLvl = ["En cours d'apprentissage", "Mémoire courte", "Bon souvenir", "Très bon souvenir", "Excellent souvenir", "Souvenir impérissable"]
    // console.log('props.card', props.card)


  

    if (props.packCards) {
        console.log('coucou')
    }
 
    if (ct.selectedPack) {
     
        const cardsInDecks = ct.selectedPack.map(card => {
            const _user_card = ct.userCards?.find(uc => uc.card_id == card.id)
            const user_card = {..._user_card, 
                next_date: _user_card?.next_date?.seconds ? new Date(_user_card.next_date.seconds * 1000) : _user_card?.next_date,
                triggers: _user_card?.triggers.map(t => t.next_date.seconds ? (
                    {...t, next_date: new Date(t.next_date.seconds * 1000)}
                    ) : t)}
            const state = !user_card?.next_date ? "to_learn" : user_card?.archived ? "archived" : user_card?.next_date > new Date() ? "mastered" : "to_review"
            const indexTrigger = user_card.next_date ? user_card?.next_trigger : 42
            return {...card, user_card, state, indexTrigger}
        })

        const cardNotArchived = cardsInDecks.filter(c => !c.user_card.archived)
        
        // console.log('cardsInDecks', cardsInDecks)
       
        const cardsToDiscover = cardNotArchived?.filter(c => !c.user_card || !c.user_card?.next_date)
        const cardToReview = cardNotArchived?.filter(c => c.user_card?.next_date < new Date())
       
        const cardToReviewComp = cardToReview?.filter(c =>  c.user_card?.triggers[0]?.next_date < new Date())
        const cardToReviewExp = ct.userWorkspace?.goal_id == "TOEIC" ? []: cardToReview?.filter(c =>   c.user_card?.triggers[1]?.next_date < new Date())
     
        const todo = cardsToDiscover.length*3 + cardToReviewComp.length + cardToReviewExp.length
        const cardToPlace = cardNotArchived?.filter(c => (!c.user_card.x && c.user_card.x != 0) && c.user_card.next_date)

        // console.log('ct.selectedPack',ct.selectedPack)

        const sortedCards = ct.selectedPack.sort((cardA, cardB) => {
            const dateA = new Date(cardA.user_card?.next_date);
            const dateB = new Date(cardB.user_card?.next_date);
            
            // Si dateA est plus récente que dateB, retourner 1
            // Si dateB est plus récente que dateA, retourner -1
            // Si elles sont égales, retourner 0
            return dateA > dateB ? 1 : dateA < dateB ? -1 : 0;
          }) || []
          const timeUntilNextDate = sortedCards.length ? displayDuration(new Date(), sortedCards.filter(e => !e.user_card.archived)[0]?.user_card?.next_date).replace('jours', "j") : 'N/A';
          const sortedCards2 = sortedCards.map(e => {
            const elixirDate = e.user_card?.next_date - ((1000*60*60*24) - (1000*60*3))
            const lvl = ct.userCards?.find(uc => uc.card_id == e.id)?.lvl
            const bonusElixir = lvl - 1
            const isReadyToCollect = props.showElixir &&  !ct.visitedUser && (elixirDate - new Date() < 0) && !e.user_card?.archived && !e.user_card?.collected
            return {...e, elixirDate, isReadyToCollect, bonusElixir}
          })
          const packHasElixir = sortedCards2.find(e => e.isReadyToCollect)

        //   console.log('cardsToDiscover', cardsToDiscover)
        //   console.log('cardToReviewComp', cardToReviewComp)
        //   console.log('cardToReviewExp', cardToReviewExp)

        const miniMode = sortedCards2?.length > 4



        return  <div id="info-card" data-deck={true} className='border-2 border-b-4 z-20 border-indigo-600 text-white bg-gradient-to-b from-purple-500 to-indigo-500 ' style={{top: posTop, left: posLeft, right: posRight, opacity: '100', maxHeight: 400}}>
                <div className={` h-4 w-4 rounded rotate-45 absolute bg-indigo-600 mx-auto hidden -top-2`} style={{zIndex: -1, left: props.pos?.cursor}}></div>
                <div className='p-2'> 
                    <h3 onClick={() => {console.log(ct.selectedPack)}} className=' -mt-4  mb-1 text-white text-center flex justify-center text-sm'><div className='bg-purple-700  rounded-xl px-2'>Graines de vocabulaire</div> </h3>
                    {<div className={`grid ${todo == 0 ? miniMode ? "grid-cols-5" : "grid-cols-4" : ""} max-h-[130px] overflow-scroll mb-2  gap-1 items-center justify-center`}>
                        {todo == 0 ? 
                            ct.workspace.classic ? null: 
                            sortedCards2.map(e =>  <div className={`${e.isReadyToCollect ? "border-black/50 bg-[#530c9d] " :" border-purple-900 "} relative gap-2 items-center text-xs grow  text-center rounded-xl border-2 border-b-[4px]  ${cardsInDecks.find(cd => cd.user_card?.card_id == e.id)?.user_card.archived ? "opacity-50" : ""}`}>
                                
                                    {e.isReadyToCollect ? <div onClick={async() => {
                                        console.log('e', e.user_card.id)
                                        console.log('find', ct.userCards.find(uc => uc.card_id == e.id))
                                     
                                        ct.setSelectedPack(p => p.map(c => c.id == e.id ? {...c, user_card: {...c.user_card, collected: true}} : c))
                                        ct.setUserCards(p => p.map(c => c.card_id == e.id ? {...c, collected: true} : c))
                                        const user_card_ref = doc(db, 'user_cards', e.user_card.id);
                                        const userWorkace_ref = doc(db, 'user_workspace', ct.userWorkspace.id);
                                        ct.setUserWorkspace({...ct.userWorkspace, tt_elx: (ct.userWorkspace.tt_elx || 0) + e.bonusElixir, elixir: (ct.userWorkspace.elixir || 0) + e.bonusElixir})
                                        await updateDoc(user_card_ref, {collected: true});
                                        await updateDoc(userWorkace_ref, {elixir: (ct.userWorkspace.elixir || 0) + e.bonusElixir});
                                    }} className=''>
                                        <div className={`p-4 ${miniMode ? "h-[50px] " : " h-[70px]"}`}>
                                            <img src={'/images/deco/elixir.png'} />
                                        </div>
                                        <div className='text-amber-100 p-1'>
                                            + {e.bonusElixir} élixir{e.bonusElixir > 1 ? 's' : ''} 
                                        </div>
                                    </div> : 
                                    <div onClick={() => {
                                        console.log('e', e)
                                        ct.setMagnifier({...e, word: e.term, seeCard: true});
                                    }}>
                                        <img className={` ${miniMode ? "h-[50px] " : " h-[70px]"} w-full bg-gradient-to-b from-cyan-100 via-[#96d363] to-[#96d363] rounded-xl relative z-0 ${(e.img_400 || e.img) ? "" : "pb-2"}`} src={(e.img_400 || e.img) || images.find(i => i.name == "seed#1_green_2_mastered").src} />
                                        <div className='absolute top-1 right-1 text-[10px]'><div className='bg-black/60 px-1 rounded-full'>niv. {e.user_card?.lvl}</div></div>
                                        <div className='rounded rounded-t-xl py-[2px] bg-[#530c9d] relative -mt-3 z-10'>
                                        <div className={`overflow-hidden h-[18px] text-ellipsis ${miniMode ? "text-[11px] " : " text-xs "} whitespace-nowrap `}>{e.term}</div>
                                            {e.user_card.archived ? "--": <div className={` ${(( e.user_card?.next_date - new Date()) / 1000 / 60 / 60) < 25 ? "font-semibold text-amber-400" : "opacity-50" } `}>  {displayDuration(new Date(), e.user_card?.next_date).replace('jours', "j")}</div>}
                                            {/* <div className='opacity-50'>{displayDuration(new Date(), e.elixirDate ).replace('jours', "j")}</div> */}
                                        </div>
                                    </div>
                                    }
                                
                                </div>) 
                        : cardsToDiscover.length > 0 ? <div>Découvre {cardsToDiscover.length} mots de vocabulaire</div> 
                        :  <div className='text-center'></div> }
                    </div>}
                    {/* {<div onClick={() => {ct.setCardsToPlace(cardsInDecks); navigate('/forest')}} className='px-2 mb-1 h-full py-1 mt-1 fredoka text-amber-600 font-semibold cursor-pointer hover:scale-110  border-2 text-sm text-center bg-white grow border-b-4 border-amber-400  rounded-xl'>Déplacer</div>} */}
                    {/* {cardToPlace?.length > 0 && <div onClick={() => {ct.setCardsToPlace(cardToPlace);  navigate('/forest')}} className='px-2 mb-1 h-full py-1 mt-1 fredoka text-amber-600 font-semibold cursor-pointer hover:scale-110  border-2 text-sm text-center bg-white grow border-b-4 border-amber-400  rounded-xl'>Planter {cardToPlace?.length} graines</div>} */}
                   {!ct.visitedUser && <div className=''> 
                   {todo != 0 ? <div onClick={(e) => {console.log(props.packCards); props.setPopoverPos(); ct.setShowCards(props.packCards.filter(c => !c.user_card.archived))}} className={`${cardToPlace?.length > 0 ? "text-white": "text-purple-500 bg-white"}  px-4 py-1 border border-b-2  rounded-xl fredoka  text-center`}>{cardsToDiscover.length > 0 ? "Apprendre": "Rafraichir"} <span className={`${cardToPlace?.length > 0 ? "hidden" : ""}`}>+{cardsToDiscover.length + cardToReview.length} xp</span></div> : 
                   <div className="text-[12px] italic text-center">{packHasElixir ? "Appuie sur les cartes pour récolter leur elixirs" : <span>Prochaine évolution dans {timeUntilNextDate}</span>}</div>}</div>}
                </div>
            </div>
    }

   

    if (deck) { 
     
        let img = deck.mindcard?.img ? deck.mindcard?.img : deck.dialog?.img 
        let type =  deck.mindcard ? "la mindmap" : deck.dialog ? "le dialogue" : "le cours"
        
    
        // console.log('deck', deck)
        const dialog = deck?.dialog
        const mindMap = deck?.mindcard 
        let userDeck = ct.userDecks?.find(ud => ud.deck_id == deck.id)
        console.log('USERDECK', userDeck)
        const typeDeck = deck.presentation_mode || "Vocabulaire"
        // let cards = ct.cards?.filter((card, index) => card.deck_id === deck.id || deck?.childs?.find(d => d.id == card.deck_id))
        let cards = cardDeck || []
        console.log('cards', cards)

       

        const cardsIds = cards?.map(card => card.id)
        const userCards = ct.userCards?.filter((card) => cardsIds.includes(card.card_id)).map(uc =>  ({...uc, next_date: uc.next_date?.seconds ? new Date(uc.next_date.seconds * 1000) : uc?.next_date}))
    
        const masteredCards = userCards?.filter((c) => c.next_date > new Date())
        const cardsInDecks = cards?.map(card => {
          const _user_card = ct.userCards?.find(uc => uc.card_id == card.id)
          const user_card = {..._user_card, 
              next_date: _user_card?.next_date?.seconds ? new Date(_user_card.next_date.seconds * 1000) : _user_card?.next_date,
              triggers: _user_card?.triggers.map(t => t.next_date.seconds ? (
                  {...t, next_date: new Date(t.next_date.seconds * 1000)}
                  ) : t)}
          const state = !user_card?.next_date ? "to_learn" : user_card.archived ? "archived" : user_card?.next_date > new Date() ? "mastered" : "to_review"
         
          return {...card, user_card, state}
      })

      const cardNotArchived = cardsInDecks.filter(c => c.state != "archived")
        // const cardsInDecks = ct.cards?.filter(c => c.deck_id == deck?.id)?.map(card => {
        //     const _user_card = ct.userCards?.find(uc => uc.card_id == card.id)
        //     const user_card = {..._user_card, 
        //         next_date: _user_card?.next_date?.seconds ? new Date(_user_card.next_date.seconds * 1000) : _user_card?.next_date,
        //         triggers: _user_card?.triggers.map(t => t.next_date.seconds ? (
        //             {...t, next_date: new Date(t.next_date.seconds * 1000)}
        //             ) : t)}
        //     const state = !user_card?.next_date ? "to_learn" : user_card?.next_date > new Date() ? "mastered" : "to_review"
           
        //     return {...card, user_card, state}
        // })
        // console.log('cardsInDecks', cardsInDecks)
        // console.log('ct', ct)
        
        // console.log('cardsInDecks', cardsInDecks)
        const cardsToDiscover = cardNotArchived?.filter(c => !c.user_card || !c.user_card?.next_date)
        const cardToReview = cardNotArchived?.filter(c => c.user_card?.next_date < new Date())
        const nextCards = deck?.presentation_mode == "Grammar"  ?  [...cardsToDiscover || [], ...cardToReview || []] : [...cardsToDiscover?.slice(0,4) || [], ...cardsToDiscover?.slice(0,4) || [], ...cardsToDiscover?.slice(0,4) || [], ...cardToReview || []].slice(0,20)
        const start = async (place) => {
            // console.log('start', "sede" )
            props.setPopoverPos()
        
            if (!userDeck) {
                userDeck = await ct.fire.addUserDeck({user_id: ct.user.id, deck_id: deck.id, wp_id: ct.workspace.id}, ct)
            }
            if (place) {
                console.log('placement de userDeck', userDeck)
                ct.setSelectedDeck(userDeck)
       
                navigate('/forest')
            } else {
                if((dialog || mindMap)) ct.setActiveDeck(props.deck_id) 
                else ct.setShowCards(nextCards)
            }
          
        }
        console.log('nextCards', nextCards)
        const placed = userDeck && (!userDeck?.x  && userDeck?.x !=0)
        let classStart = "bg-white text-indigo-500 border-indigo-100 "
        if(placed) classStart = " bg-transparent text-white border-indigo-200/50"

        return (
           
        <div id="info-card" data-deck={true} className='border-2 border-b-4 z-20 border-indigo-600 text-white bg-gradient-to-b from-purple-500 to-indigo-500 ' style={{top: posTop, left: posLeft, right: posRight,opacity: deck ? '100' : '0', height: deck ? 'auto' : 0, maxHeight: 400}}>
            <div className={` h-4 w-4 rounded rotate-45 absolute bg-indigo-600 mx-auto -top-2`} style={{zIndex: -1, left: props.iconLeft ? 40 : 110}}></div>

           
           <div className='p-3'> <div className='flex justify-between items-center gap-2 -mb-1'>

                <h3 className='text-white'>{deck.name || "--"}</h3> 
                {(img && !props.iconLeft) && <img className={`object-cover object-top rounded-full ${props.iconLeft ? "m-2 h-[30px]" : "h-[42px]"} `} src={img}/> }

        
            </div>
            <div className='flex gap-2 flex-wrap mb-2'>
                <div className='text-green-300 text-xs items-end gap-2 flex'> <span className='text-lg -mr-1 -mb-1 font-bold'>{cardsInDecks?.filter(uc => uc.state == "mastered").length}</span> maitrisé</div>
                <div className='text-blue-100 text-xs items-end gap-2 flex'> <span className='text-lg -mr-1 -mb-1 font-bold'>{cardsInDecks?.filter(uc => uc.state == "to_review").length}</span>à revoir </div>
                <div className='text-yellow-400 text-xs items-end gap-2 flex'><span className='text-lg -mr-1 -mb-1 font-bold'>{cardsToDiscover?.length}</span> à découvrir  </div>
            </div>
            <ProgressBar height={10} value={deck?.user_deck?.mastered || 0} value2={deck?.user_deck?.review || 0} total={deck?.user_deck?.total || 0} />
            <div className='flex gap-2 items-center flex-wrap justify-between align-right mt-2'>

 
            {placed && <div onClick={() => {start(true)}} className='px-2 h-full py-1 mt-1 fredoka text-amber-600 font-semibold cursor-pointer hover:scale-110  border-2 text-sm text-center bg-white grow border-b-4 border-amber-400  rounded-xl '>Ajouter </div>}
            
            {!ct.visitedUser && <div>{userDeck ? <div className='flex gap-2 grow items-center justify-between align-right '> <div onClick={() => {console.log('next_card 🟠🟠🟠', nextCards); props.setPopoverPos() ;(dialog || mindMap) ? ct.setActiveDeck(props.deck_id) : ct.setShowCards(nextCards)}} className={`${classStart} px-2 py-1 mt-1 fredoka font-semibold cursor-pointer hover:scale-110 text-indigo-500 border text-sm text-center  grow border-b-4  rounded-xl `}>
                        Apprendre {!placed && `+ ${nextCards.length}xp`}
              </div>
           
             <div onClick={() => {ct.setShowDeckId(props.deck_id); props.setPopoverPos()}} style={{fontSize: 13}} className='px-2 py-1 mt-1 fredoka font-semibold text-white border text-sm text-center bg-transparent border-b-4 border-indigo-200/50 cursor-pointer hover:scale-110 rounded-xl  min-w-[100px]'>
                        Voir le cours
              </div></div>
              :  <div onClick={() => start()} className={`${classStart} px-2 py-1 mt-1 fredoka font-semibold border text-sm text-center grow border-b-4  rounded-xl inline-block`}>
              Commencer {!placed && `+ ${nextCards.length}xp`}
    </div> }</div>}

              
             


              
            </div>
            {/* {props.showAction &&
              <div onClick={() => props.setEditorMode(props.deck)} className='px-3 py-1 flex gap-3 items-center justify-between cursor-pointer grow text-center border mt-1 bg-gray-50 rounded-xl text-gray-600 inline-block'>
                        Déplacer <svg width="16" height="16" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.7057 9.70568C23.3159 9.31594 22.6841 9.31594 22.2943 9.70568V9.70568C21.9051 10.0949 21.9045 10.7257 22.293 11.1157L24.17 13H16C15.4477 13 15 12.5523 15 12V3.83L16.8843 5.70704C17.2743 6.0955 17.9051 6.09489 18.2943 5.70568V5.70568C18.6841 5.31594 18.6841 4.68406 18.2943 4.29432L14.7071 0.707106C14.3166 0.316582 13.6834 0.316583 13.2929 0.707107L9.70568 4.29432C9.31594 4.68406 9.31594 5.31594 9.70568 5.70568V5.70568C10.0949 6.09489 10.7257 6.0955 11.1157 5.70705L13 3.83V12C13 12.5523 12.5523 13 12 13H3.83L5.70704 11.1157C6.0955 10.7257 6.09489 10.0949 5.70568 9.70568V9.70568C5.31594 9.31594 4.68406 9.31594 4.29432 9.70568L0.707106 13.2929C0.316582 13.6834 0.316583 14.3166 0.707107 14.7071L4.29432 18.2943C4.68406 18.6841 5.31594 18.6841 5.70568 18.2943V18.2943C6.09489 17.9051 6.0955 17.2743 5.70705 16.8843L3.83 15H12C12.5523 15 13 15.4477 13 16V24.17L11.1157 22.293C10.7257 21.9045 10.0949 21.9051 9.70568 22.2943V22.2943C9.31594 22.6841 9.31594 23.3159 9.70568 23.7057L13.2929 27.2929C13.6834 27.6834 14.3166 27.6834 14.7071 27.2929L18.2943 23.7057C18.6841 23.3159 18.6841 22.6841 18.2943 22.2943V22.2943C17.9051 21.9051 17.2743 21.9045 16.8843 22.293L15 24.17V16C15 15.4477 15.4477 15 16 15H24.17L22.293 16.8843C21.9045 17.2743 21.9051 17.9051 22.2943 18.2943V18.2943C22.6841 18.6841 23.3159 18.6841 23.7057 18.2943L27.2929 14.7071C27.6834 14.3166 27.6834 13.6834 27.2929 13.2929L23.7057 9.70568Z" fill="#8E8E8E"/>
                </svg>

              </div>
            } */}
            </div>

      
          
            
                    



      
        </div>
    ) } else if (props.content) {
        return <div className='b content' key={props.card?.card?.term} id="info-card"  style={{top: posTop + 0, left: posLeft, right: posRight, opacity: props.content ? '100' : '0', height: props.content ? 'auto' : 0}}>
           {props.content}
        </div>
    } else if (props.pos?.content) {

        return <div className={'b content flex justify-center z-[100] absolute'} key={props.card?.card?.term}  style={{top: props.pos.top + 0, left: 40, right: 40, opacity: props.pos.content ? '100' : '0', height: props.pos.content ? 'auto' : 0}}>
           {props.pos?.content}
        </div>
    } else return (
        <div className='b' key={props.card?.card?.term} id="info-card"  style={{top: posTop + 0, left: posLeft, right: posRight, opacity: props.card ? '100' : '0', height: props.card ? 'auto' : 0}}>
            <div className={`${inverse ? " -bottom-[8px]" : ""} h-10 w-10 rounded rotate-45 absolute mx-auto`} style={{zIndex: -1, left: posCursor, backgroundColor: inverse ? "#fff" : state == "master" ? "#2c9de5" : "#855ff4" }}></div>
            <div onClick={() => setShowDetail(!showDetail)} className={`${state == "master" ? " from-cyan-500 to-blue-500" : state == "locked" ? "from-slate-500 to-slate-600" : "from-purple-500 to-indigo-500"} z-10 mb-4 pb-6 bg-gradient-to-r flex flex-col items-start  rounded-2xl px-3 py-1 rounded-xl text-white `}>
                
                <div className='flex  items-center justify-between  gap-4 justify-between w-full'>
        
                <h2 className={`text-white mb-1 text-base ${props?.card?.lvl == 0 ? "text-base" : ""}`}>{state != "locked" ? textLvl[props?.card?.lvl] : "Mémoire de travail"}</h2>
                {state != "locked" && <div>
                    {props.card?.lvl ? <div className='relative'>
                        {props.card?.lvl > 1 && <div className='absolute font-bold text-yellow-600 ' style={{left: 12, top: 6}}>{props.card?.user_card?.lvl > 1 ? props.card?.user_card?.lvl : ""}</div>}
                    <svg width="80" height="80" viewBox="0 0 59 61" fill="none" xmlns="http://www.w3.org/2000/svg" className=' h-9 w-9 '>
                        <path d="M13.3894 34.9642L14.0795 33.3851L13.0631 31.9934L3.93779 19.4993C3.71235 19.1296 3.57261 18.433 3.89849 17.3754C4.26728 16.9727 4.86192 16.6663 5.373 16.5949L5.47056 16.6145L5.62903 16.6291L20.6215 18.0119L22.3494 18.1712L23.3491 16.7529L31.4966 5.19387C31.7986 4.98071 32.0288 4.74113 32.1972 4.5249C32.2443 4.46433 32.2878 4.4042 32.3278 4.34507C32.9164 3.81654 33.5302 3.72192 33.8719 3.79045C34.0774 3.83169 34.1635 3.91238 34.1865 3.94011C34.1977 3.95372 34.2186 3.98221 34.2385 4.04666C34.2591 4.11332 34.2891 4.25352 34.2756 4.49366L34.1297 7.09151L34.939 7.25384L37.8314 20.3078L38.2068 22.002L39.8624 22.5213L54.2283 27.0277L54.3802 27.0753L54.5362 27.1066L54.5524 27.1099C55.1105 27.3831 55.4677 27.8785 55.6097 28.6478C55.7158 29.2227 55.4652 29.8075 55.0202 30.1038L55.0201 30.1039L54.7145 30.3074L47.7869 34.385L47.7152 34.4272L47.6458 34.4734C45.3016 36.0345 43.2732 37.3758 42.0787 37.9464L40.3411 38.7763L40.372 40.7016L40.6116 55.612C40.56 56.6206 40.0773 57.0305 39.7912 57.1325L39.6455 57.1845L39.5058 57.2512C39.3737 57.3143 38.9052 57.3748 38.2823 57.128C38.1197 56.9366 37.9504 56.7817 37.7933 56.6593C37.6863 56.576 37.5807 56.5043 37.4796 56.4426L26.3913 46.9263L25.1141 45.8301L23.5129 46.3489L9.2665 50.9646L8.77702 51.1232C8.69239 51.1079 8.59726 51.0889 8.46961 51.0633C8.32056 51.0334 8.19963 50.9822 8.11998 50.9338C8.08351 50.9116 8.06348 50.8949 8.05509 50.8873L7.86151 50.5965L7.58839 50.3479C7.36376 50.1434 7.07651 49.579 7.41786 48.6286L13.3894 34.9642Z" fill="#FFCF4C" stroke="#FFEEBE" strokeWidth="6"/>
                    </svg>
                    </div> : ""}
                    </div>}
                   
                    
                    </div>
    

                    {state == "locked" ? <p className='text-[12px]'>Ce souvenir est en cours d'apprentissage. Prochaine amélioration dans {locked_time_left}</p> : 
                    <div className='bg-blue-200 text-sm inline-block flex px-2 gap-2 items-center rounded-2xl   mb-1' style={{backgroundColor: 'rgba(255,255,255,0.2'}}><div>
                    {state == "master" ? "A rafraichir dans" : <div onClick={() => ct.setShowCards([props.card.card])} className='px-3 py-1 bg-white text-indigo-500 rounded-xl cursor-pointer hover:scale-110 inline-block'>
                        Rafraichir
                    </div>}
              
                    </div>
                    {state == "master" && <div className='rounded-xl text-purple-50 font-semibold text-md'>
                        {duration}
                    </div>}
                    </div>
                    
                 }
                 {/* {props.card.triggers.map(t => <div>{t.name} - {t.lvl} - {t.next_date.seconds}</div>)} */}
                {showDetail && props.card?.triggers?.length > 1 && allTriggers}
                </div>
            <div className='p-3 bg-yellow-50 -mt-8 rounded-2xl'>
            <div id="term-mini" className="term -mb-3 font-medium flex gap-3 items-center">
                
            {props.card?.card.img && <img src={ props.card?.card.img_400 || props.card?.card.img} className="h-14  right-4 object-fit rounded-xl"/>}

            <SuperEditor content={getTitle(props.card?.card?.term)}/>
    
            
    
                </div>
            <div id="def-mini"  className="def overflow-auto" style={{maxHeight: 100}}>
            <SuperEditor className="text-xs" content={state == "master" ? props.card?.card?.def : "Ce souvenir à besoin d'être amélioré"}/>
                </div>
             
        
            <div className=' mt-4 hidden flex items-center gap-2'>
            {state != "master" && props.showAction && <div onClick={() => ct.setShowCards([props.card?.card])} className='px-4 py-1 bg-purple-500 rounded-xl cursor-pointer hover:scale-110 text-white inline-block'>
                        Rafraichir
                    </div>}

                    {/* {state == "master" && props.showAction && <div onClick={() => props.setShowCards([props.card])} className='px-4 py-1 bg-blue-500 rounded-xl cursor-pointer hover:scale-110 text-white inline-block'>
                        Voir
                    </div>} */}
            {props.showAction && <div onClick={() => props.setEditorMode(props.card)} className='px-3 py-1 cursor-pointer bg-transparent flex justify-between items-center grow text-center border  bg-gray-50 rounded-xl text-slate-500 block '>
                        Déplacer <svg className='text-slate-200 opacity-50' width="16" height="16" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M23.7057 9.70568C23.3159 9.31594 22.6841 9.31594 22.2943 9.70568V9.70568C21.9051 10.0949 21.9045 10.7257 22.293 11.1157L24.17 13H16C15.4477 13 15 12.5523 15 12V3.83L16.8843 5.70704C17.2743 6.0955 17.9051 6.09489 18.2943 5.70568V5.70568C18.6841 5.31594 18.6841 4.68406 18.2943 4.29432L14.7071 0.707106C14.3166 0.316582 13.6834 0.316583 13.2929 0.707107L9.70568 4.29432C9.31594 4.68406 9.31594 5.31594 9.70568 5.70568V5.70568C10.0949 6.09489 10.7257 6.0955 11.1157 5.70705L13 3.83V12C13 12.5523 12.5523 13 12 13H3.83L5.70704 11.1157C6.0955 10.7257 6.09489 10.0949 5.70568 9.70568V9.70568C5.31594 9.31594 4.68406 9.31594 4.29432 9.70568L0.707106 13.2929C0.316582 13.6834 0.316583 14.3166 0.707107 14.7071L4.29432 18.2943C4.68406 18.6841 5.31594 18.6841 5.70568 18.2943V18.2943C6.09489 17.9051 6.0955 17.2743 5.70705 16.8843L3.83 15H12C12.5523 15 13 15.4477 13 16V24.17L11.1157 22.293C10.7257 21.9045 10.0949 21.9051 9.70568 22.2943V22.2943C9.31594 22.6841 9.31594 23.3159 9.70568 23.7057L13.2929 27.2929C13.6834 27.6834 14.3166 27.6834 14.7071 27.2929L18.2943 23.7057C18.6841 23.3159 18.6841 22.6841 18.2943 22.2943V22.2943C17.9051 21.9051 17.2743 21.9045 16.8843 22.293L15 24.17V16C15 15.4477 15.4477 15 16 15H24.17L22.293 16.8843C21.9045 17.2743 21.9051 17.9051 22.2943 18.2943V18.2943C22.6841 18.6841 23.3159 18.6841 23.7057 18.2943L27.2929 14.7071C27.6834 14.3166 27.6834 13.6834 27.2929 13.2929L23.7057 9.70568Z" fill="#8E8E8E"/>
                        </svg>
              </div>}
              </div>
              </div>
        </div>
    )
}


export {Popover}


