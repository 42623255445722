import { createContext, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc, updateDoc, setDoc, getDoc, where, writeBatch, query, orderBy, doc, limit, getDocs, deleteDoc } from "firebase/firestore";
import { createClient } from 'pexels';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, app } from "../../config.js";
import { gpt, getTextInParentheses, imageGenerator, removeQuotes, removeTextInParentheses, makeBold, extractTextWithTags, addBracketsToString, speak, tts, getLemma, transformText, clean, findWord} from '../../utilities/helper/text.js';
import { SuperEditor } from '../../components/editor.js';
import { MindMap } from '../components/mindMap.js';
import { categories, displayDuration, getFQlist, requestInfoMore, shuffleArray } from '../../utilities/helper/helper.js';
import { interleaveDecks } from './vocabScreen.js';
import { DisplayMore, filterDefinitions } from '../components/magnifier.js';
import { translate } from './cultureG.js';


const CardRender = ({ 
    ct, 
    contexts,
    completeCard, 
    index, 
    findHint, 
    setContexts,
    setRank, 
    updateCard, 
    selectedWp, 
    i2, 
    bug, 
    addMore,
    deleteCard,
    setCategory,addToUniversal,
    addImg,
    getInflexion,
    homonyme,
    setCards,
    googcontext,
    card}) => {


       // ajouter (+) pour ajouter un contexte
       // si image text et trad existe que le contexte n'existe pas 

       // ajouter ct_id et de-id pour tout les contextes déjà existant pour eviter de dupliquer un contexte

       // afficher les définitions de more


        const setIsGrammar = (card) => {
            updateCard({...card, isGram: true})

        }

        const findContext = (card) => {

       
            const compatibleContext = contexts.filter(e => transformText(e.de).split(' ').includes(transformText(card.term))).filter(e => !e['de-id'])
            console.log('compatibleContext', compatibleContext)
            console.log('compatibleContext 1', compatibleContext[0]?.de.split(' '))
            setContextCompatible(compatibleContext)
    
        }

        const [contextCompatible, setContextCompatible] = useState([])
        const [showMore, setShowMore] = useState()

        

    return (
        <>
        
     <div><div onClick={() => console.log('card', card)} key={card?.id+"-"+index} className={`${!googcontext ? "bg-red-100" : homonyme.length ? "bg-yellow-50" : card.deck_id == "draft" ? "bg-purple-500" : "bg-white"}  border-b p-2 group flex gap-4 hover:bg-blue-50 w-fit relative `}>
        <div className='absolute -bottom-2 gap-2  hidden justify-start group-hover:flex p-1 '>
            <div onClick={() => setIsGrammar(card)}  className='ml-[15px] b px-2 border hover:bg-white rounded py-1 text-[8px] text-slate-500'>setIsGrammar</div> 
            <div onClick={() => getInflexion(card)}  className='ml-[15px] b px-2 border hover:bg-white rounded py-1 text-[8px] text-slate-500'>getinflexion</div> 
            <div onClick={() => completeCard(card, ct)}  className='ml-[15px] b px-2 border hover:bg-white rounded py-1 text-[8px] text-slate-500'>completeCard</div> 
            <div onClick={() => findContext(card)}  className='ml-[15px] b px-2 border hover:bg-white rounded py-1 text-[8px] text-slate-500'>findContext</div> 
            <div onClick={() => findHint(card)}  className='ml-[15px] b px-2 border hover:bg-white rounded py-1 text-[8px] text-slate-500'>Hint</div> 
            <div onClick={() => setShowMore(true)}  className='ml-[15px] b px-2 border hover:bg-white rounded py-1 text-[8px] text-slate-500'>ShowMore</div> 
            <div onClick={() => setRank(card)}  className='ml-[15px] b px-2 border hover:bg-white rounded py-1 text-[8px] text-slate-500'>setRank</div> 
            <div onClick={() => deleteCard(card)}  className='ml-[15px] b px-2 border hover:bg-white rounded py-1 text-[8px] text-slate-500'>Supprimer</div> 
        </div> 
        <div className='text-[6px] -ml-2'>{i2}</div>
    <div className='text-slate-400  -ml-2 w-[50px] text-xs flex gap-2 hover:scale-110 cursor-pointer' onClick={() =>  tts(card.contexts[0].text)}><div className='text-gray-300'>{index} </div></div>
    {/* {!card.original_card && <div onClick={() => {addToUniversal(card)}} className='text-xs top-3 bg-amber-100 left-8 border border-amber-500 absolute text-amber-500 px-2 rounded-full'>Add</div>} */}
    <input className={`text-blue-500 bg-green-100 text-[10px] w-[50px]`} defaultValue={card.occ_tt}/>

    

    <input onBlur={(e) => updateCard({...card, rank: e.currentTarget.value})}  className={`text-blue-500 bg-blue-100 text-[18px]  w-[50px]`} defaultValue={card.rank}/>

    <input onBlur={(e) => updateCard({...card, img: e.currentTarget.value, img_400: e.currentTarget.value})}  className={`text-gray-300 text-[4px]  w-[50px]`} defaultValue={card.img}/>
    <img 
   onClick={() => 
        addImg(card, `
            Donne moi une image (sans aucun texte) qui représente le terme '${card.term}'.
            Dans le style illustration jolie, morderne et minimalist (sans trop de complexité) au format 1024x1024.
            Aucun mots ni écriture mots écris doit ne doit apparaitre. Aucun texte sur l'image` )}

    src={card.img_400 || card.img} className={`b hover:scale-150 -my-2 h-[50px] w-[50px] rounded-md hover:scale-[2.5] hover:z-10 ${!(card.img_400 || card.img) ? "bg-pink-500" : "bg-slate-200"} `} />
    <img  
    onClick={() => 
        addImg(card, `
            Donne moi une image (sans aucun texte) qui représente le terme '${card.term}'.
            Dans le style illustration jolie, morderne et minimalist (sans trop de complexité) au format 1024x1024.
            Aucun mots ni écriture mots écris doit ne doit apparaitre. Aucun texte sur l'image` )}
    src={card.img_400} className={`b hover:scale-150 ${card.img_400 ? "bg-slate-200 rounded-full" : "bg-red-500 rounded-md"}  h-[10px] w-[10px]  hover:scale-[1.1]`} />

    {/* <svg onClick={() => addImg(card, `Donne une image dans le style des illustration morderne qui rappelle les illustrations dans les livres qui réprésente le mot '${card.term}'. Aucun mots ni écriture mots écris doit ne doit apparaitre, l'image en format carrée. Sans Texte `)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-purple-500 hover:scale-110 cursor-pointer">
        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
    </svg> */}



    <svg onClick={() => 
        addImg(card, `
            Donne moi une image (sans aucun texte) qui représente le terme '${card.term}' (dans la phrase "${card.contexts[0].text}").
            Dans le style illustration jolie, morderne et minimalist (sans trop de complexité) au format 1024x1024.
            Aucun mots ni écriture mots écris doit ne doit apparaitre. Aucun texte sur l'image` )}
         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-amber-500 hover:scale-110 cursor-pointer">
        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
    </svg>
   
  
    <div className={'flex-col flex max-w-[140px]'}>
        <input onBlur={(e) => updateCard({...card, term: e.currentTarget.value})}  className={`${selectedWp?.id == "international" ? "order-2 italic text-gray-500 text-xs" : ""} w-[500px]`} defaultValue={card.term}/>
        <input onBlur={(e) => updateCard({...card, trad: e.currentTarget.value})} className={`${selectedWp?.id == "international" ? "order-1 font-bold text-purple-500" : "italic text-gray-500 text-xs"} w-[200px] `} defaultValue={card.trad} />
    </div>
    {card.isGram  && <div>GRAMMAR</div>}
    {card.deck_id == "draft" &&  <div onClick={() => {updateCard({...card, deck_id: null})}} className='px-2 rounded-full border bg-purple-100 text-purple-500' >Prod</div>}
    <div className={`border p-1 ${card.more ? "bg-blue-100" : "bg-red-100"} rounded`} onClick={() => {
        !card.more && addMore(card)
        card.more && console.log('more', JSON.stringify(card.more, null, 2))
    }} >{card.more ? "more" : "add"}</div>
    {/* <div className=' w-[40px]'><div onClick={() => updateCard({...card, isVisual: !card.isVisual})} className={`${card.isVisual ? 'bg-green-500' : 'bg-purple-500'} h-4 w-4 b rounded-full`}></div></div>
    <div className=' w-[40px]'><div  className={`${Universalcards.find(c => c.id == card.original_card) ? 'bg-purple-500' : 'bg-slate-100'} h-4 w-4 b rounded-xl`}></div></div> */}

    {<div onClick={() => {contextCompatible?.length ? setContextCompatible([]) : findContext(card)}} className={`${contextCompatible.length ?  "bg-red-500 text-white":  card.contexts.length ? "bg-blue-100" : "bg-blue-500 text-white" } h-10 w-10 flex items-center justify-center rounded-full`}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5">
  <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
</svg>
</div>}


    <div className='flex-col flex gap-4  '>
        {card.contexts?.map((ct,i) => <div key={card.id+"i"+i+ct?.text+""+index} className=' flex items-start gap-4 w-[800px]'>
            {/* <div className=' w-[50px]'>
            <div className='text-slate-500 text-sm px-2 py-1 rounded-xl bg-slate-100 inline-block w-[50px]'>{ct.type}</div>
            </div> */}
            {/* <div className='text-blue-500 text-sm  w-[100px]'>{ct.meaning}</div> */}
            <input key={`meaning${card.id}-${i}-${ct?.text}-${index}`}
                className={`italic text-blue-500 text-xs" : ""}`} 
                defaultValue={ct.meaning} 
                onBlur={(e) => {updateCard({...card, contexts: card.contexts.map(c => ct.text == ct.text ? {...c, meaning: e.currentTarget.value} : c)})}}
                />
            <div className='w-[700px] flex flex-col'>
                <input  key={`text${card.id}-${i}-${ct?.text}-${index}`}
                className={`${selectedWp?.id == "international" ? "order-2 italic text-gray-500 text-xs" : !googcontext ? "text-red-500": ""}`} 
                defaultValue={ct.text} 
                onBlur={(e) => {updateCard({...card, contexts: card.contexts.map(c => ct.text == ct.text ? {...c, text: e.currentTarget.value} : c)})}}
                />
                <input  key={`trad${card.id}-${i}-${ct?.text}-${index}`}
                    className={`${selectedWp?.id == "international" ? "order-1" : "italic text-gray-500 text-xs"} `}
                    defaultValue={ct.trad} 
                    onBlur={(e) => {updateCard({...card, contexts: card.contexts.map(c => ct.trad == ct.trad ? {...c, trad: e.currentTarget.value} : c)})}}

                   />
            </div>

        </div>)}
    </div>
    <div onClick={() => setCategory(card)} style={{backgroundColor: categories[card.bc_id]?.color}} className={`${card.bc_id ? "" : "bg-red-300"}  px-2 rounded-xl`}>{categories[card.bc_id]?.name_fr || "Ajouter catégorie"}</div>
    <input onBlur={(e) => updateCard({...card, deck_id: e.currentTarget.value})} className='w-[100px] text-slate-500' defaultValue={card.deck_id}/>
    <div className='w-[300px] text-purple-500'>{card?.inflexions?.join(',')}</div>
    <input placeholder='hint' onBlur={(e) => updateCard({...card, hint: e.currentTarget.value})} className='w-[400px] text-pink-500 text-sm' defaultValue={card.hint}/>
    <input onBlur={(e) => updateCard({...card, hint_img: e.currentTarget.value})} className='w-[100px] text-slate-500' defaultValue={card.hint_img}/>
    <input onBlur={(e) => updateCard({...card, c_id: e.currentTarget.value})} placeholder='c_id' className='w-[100px] text-slate-500' defaultValue={card.c_id}/>
    <input onBlur={(e) => updateCard({...card, s_id: e.currentTarget.value})} placeholder='s_id' className='w-[100px] text-slate-500' defaultValue={card.s_id}/>




    <img src={card.hint_img} className='b hover:scale-150 bg-slate-200 h-[40px] w-[40px] rounded-md' />
    <div className='w-[300px] '>Astuce</div>

    <div className='w-[300px] text-[6px] italic text-purple-500'>{card.prod_id}</div>
    <div className='text-blue-500 text-sm  w-[100px]'>{card.lastUpdate?.seconds}</div>
    <input onBlur={(e) => updateCard({...card, model: e.currentTarget.value})} className={`italic text-gray-500 text-xs"} w-[200px] `} defaultValue={card.model} />


</div>
 {bug && <div className='bg-pink-500 px-2 text-white rounded'> <div onClick={() => deleteDoc(doc(db, 'bug', bug.id))}>x</div> {bug?.user_name} - ({displayDuration(bug?.date?.toDate()) }): {bug?.message} </div>}
 {showMore && <div>
    <div onClick={() => {setShowMore(false)}}>fermer</div>
    <DisplayMore more={card.more} />
    </div>}
 <div className='gap-2 flex-wrap'>{contextCompatible.slice(0,80).map(cc => <div 
 onClick={() => {
    setContextCompatible([])
    const newCard = {...card, img: cc.img, ct_id: cc.id, contexts: [{text: cc.de, trad: cc.fr}]}
    const updatedContext =  {...cc, ["de-id"]: card.id}
    updateDoc(doc(db, 'contexts', cc.id), {"de-id": card.id})
    setContexts(old => old.map(c => cc.id == c.id ? updatedContext : c))
    setCards(old => old.map(c => card.id == c.id ? newCard : c))
 }}
 className='px-2 rounded-xl flex gap-2 items-center border rounded mt-2'>
    <img src={cc.img} className='h-12 ' />
    <div className=''>
        <div>{cc.de}</div>
        <div className='text-xs text-slate-500'>{cc.fr}</div>
    </div>
    </div>

)}</div>
 </div>

        </>
    )
}



const translateCt = async (context, lang, fieldName) => {
    console.log('translateCt', context.fr, lang)
    const data = await translate(context.fr, lang)

    console.log('doc updated 🟣', context.id)
    updateDoc( doc(db, 'contexts', context.id), {[`${fieldName}`]:  data} )

    
}


const ContextCard = ({context, lang = null}) => {

    const deleteCt = async() => {
        const cardWithContext = (await getDocs(query(collection(db, 'cards'), where("ct_id", "==", context.id)))).docs.map(doc => ({...doc.data(), id: doc.id}));
        console.log('cardWithContext', cardWithContext)
    }

    

    return <div className='border-2 items-center mb-1 flex gap-2'>
        <img className='h-[100px] w-[100px] rounded-xl ' src={context.img} />
        <div className='p-2'>
        <div>{context.fr}</div>
        {/* <div>{context.es}</div> */}
        <div className='text-purple-500'>{context.de || <div onClick={() => {translateCt(context, "allemand", "de")}}>traduire en Allemand</div>}</div>
        </div>
        {/* <div onClick={() => {deleteCt()}}>Supprimer {context.id}</div> */}
    </div>

}

const deckPerfects = [
    "cpy1SenB5eb01HhsyRR8",
    "IhO0pHCPflkIIktRo32q",
    "fakhioUuF0GgXy4hYS7R",
    "fIVB5tO5mvuIUghPILjL",
    "iyHY0EOuEaMJCfXrQqMe",
    "pxBEmcl9c9iArGjNbZfM",
    "6VrFXpa0LQJp2AiwHNGe"
]
const client = createClient('WRLwpbV0ATiZcEqOGyG2u2A3MdNkWq6lcY83aphNNZbY8mzWnU6nDwbi');

let i = 0 
const AdminScreen = (props) => {

    const storage = getStorage(app);

    const ct = props.context

    useEffect(() => {
        console.log("UserScreen loaded")
        ct.setActiveScreen("admin")
      }, [])




    
   

      const addDeckU = async (data, deck) => {  // Added async if you're planning to use await later
        console.log('ajout du deck 🟢🟢🟢 data: ', data);
        const newDeck = {
            "workspace_id": data.id,
            "presentation_mode": "Dialogue",
            "order": 999,
            "name": data.title,
            "original_id": deck.id,  // Make sure 'deck' is accessible in this scope
            "visible": true,
            "dialog": {
                img: deck.dialog.img || null,
                fr: data.fr,
                en: data.en,
            }
        };
    
        console.log('newDeck', newDeck);
    
        // Uncomment and adapt the following line if needed
        const docRef = await addDoc(collection(db, "decks"), newDeck);
    };
    
    const addExercice = () => {
        const lines = document.querySelector('#addExercice').value
        lines.split('\n').forEach(async(line,i) => {
          console.log('line', line)
          console.log('selectedDeck')
          const newCard = {
            "workspace_id": selectedWp.id ,
            "deck_id": selectedDeck.id,
            "rank": i,
            "term": line, 
            "props": line.split('|')[1],
            "trad": line.split('|')[2],
            "note": line.split('|')[3],
            "model": "grammar"
        }   
        console.log('newCard',newCard)
        const docRef = await addDoc(collection(db, "cards"), newCard)
        const docId = docRef.id;

        // Update the new document with its own ID
        await updateDoc(docRef, { id: docId });
        })

    }

    const changeImageforAll = async (card) => {
        console.log('card', card)
        const cardsCollection = collection(db, 'cards');
        const q = query(cardsCollection, where('original_card', '==', card.original_card));
        const querySnapshot = await getDocs(q);
        const data = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
        console.log('data', data)
        data.forEach(c => {
            const newCard = {...c, img: card.img}
            ct.fire.updateCard(newCard)
        })

    }


    const setCategory = async (card) => {
    console.log('setCategory', card)

    const text_request = `
    Pour le terme "${card.term}" dans la phrase "${card.more?.definitions?.[0]?.ex?.[0].text}".
    Donne la bonne catégorie parmis "${categories.map((c,i) => ({display: i + " - " +c.name_fr})).map(c => c.display).join(", ")}
    Donne uniquemnent le chiffre de la catégorie.
    `



    const request = [{ "role": "user", "content": text_request}]
   console.log('text_request', text_request)
    const requestData = await gpt(request, true, ct)
    const data_content = requestData.choices[0]?.message?.content;
    console.log('data_content', data_content)
    const newCard = {...card, bc_id: parseInt(data_content)}
    console.log('newCard', newCard)
    updateCard(newCard)
    

    }

    const createUniversalDeck = async (deck) => {
        console.log('createUniversalDeck');
        console.log(deck);
        const destination = [
            // {name: "English", wp_id: "anglais-gb"},
            // {name: "Italien", wp_id: "italien"},
            // {name: "German", wp_id: "allemand"},
            // {name: "Portugese", wp_id: "portugais"},
            // {name: "Dutch", wp_id: "neerlandais"},
            // {name: "Spanish", wp_id: "esp"},
            // {name: "Korean", wp_id: "Coréen"},
            // {name: "Romanian", wp_id: "roumain"},            

            {name: "Arabic", wp_id: "Arabe"},        
            {name: "Chinese", wp_id: "chinois"},
            {name: "Japanese", wp_id: "japonais"},        
            {name: "Lingala", wp_id: "lingala"},        
            {name: "Ourdou", wp_id: "ourdou"},      
            {name: "Russian", wp_id: "Russe"},  
            {name: "Turkish", wp_id: "Turc"},           
            {name: "Wolof", wp_id: "wolof"},         
            {name: "Hindi", wp_id: "hindi"},        
            {name: "Ukranian", wp_id: "ukrainien"},              
        ]
        destination.forEach(async (d) => {
            const prompt = `Just give the answer. Translate in the natural way as if this were written by a native ${d.name} speaker this text "${deck.dialog.fr}" from "Français" to "${d.name}"`;
            console.log('prompt', prompt);
            const request = [{ "role": "user", "content": prompt }];
            const requestData = await gpt(request, true, ct); // Make sure gpt and ct are defined
            const data_content = requestData.choices[0]?.message?.content;
            console.log('data_content', data_content);
            
            const data_en =  {id: d.wp_id, en: data_content, fr: deck.dialog.fr, title_fr: "test", title: deck.name, img: deck.dialog.img};  // Removed trailing comma
            
            await addDeckU(data_en, deck);
        })
       
    };


    const createWord = (w, deck_id) => {
        console.log('creation du mot pour le deck', deck_id)
        const exist = Universalcards.find(c => c.trad.trim().toLowerCase() == w.trim().toLowerCase())
        if (exist){
            console.log('Le mot existe déjà :', w, exist)
            const cardRef = doc(db, 'cards', exist.id);
            const newCard = {...exist, rank: Universalcards.filter(c => c.rank).length + i}
    
            //await updateDoc(cardRef, newCard);
        } else {
            createCard(w)
        }
    }
    const highlightedWords = (texte, knowWords, allWords) => {
        const regex = new RegExp(`\\b(${allWords.map(w => transformText(w))?.join("|")})\\b`, "gi");
        texte = texte?.replace(regex, (match) => {
          // console.log('match', match);
          if (knowWords.includes(match.toLowerCase())) {
            return `<i class="text-blue-500">${match}</i>`;
          } else {
            return `<span class="text-red-500">${match}</span>`;
          }
        });
        return <div className="text-content" style={{display: "content"}} dangerouslySetInnerHTML={{ __html: texte.replace('\n', '<br/>') }} />;
      }
  
    const [page, setPage] = useState("Words")
    const [contexts, setContexts] = useState()
    const [cards, setCards] = useState([])
    const [Universalcards, setUniversalCards] = useState([])
    const [decks, setDecks] = useState([])
    const [workspaces,setWorkspaces] = useState([])
    const [selectedDeck, setSelectedDeck] = useState()
    const [selectedWp, setSelectedWp] = useState()
    const [updatedDeck, setUpdatedDeck] = useState()
    const [filters, setFilters] = useState({
        wt: [{name:"Function Word", value: "fw", s: false}, {name:"Verb", value: "v", s: true}, {name:"Adjective", value: "j",  s: true}, {name:"Name", value: "n",  s: true}],
        visual: [{name:"Visual", value: "oui", s: true}, {name:"Non Visuel", value: "non", s: true}],
        context: [{name:"has context", value: "oui", s: true}, {name:"No context", value: "non", s: true}],
    })
    const [cardFiltered, setCardFiltered] = useState()
    const [vocabDecks, setVocabDecks] = useState()
    const [bugs, setbugs] = useState()


    const loadBug = async () => {
        const q = query(collection(db, 'bug'))
        const querySnapshot = await getDocs(q);
        const _data = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
        console.log('_data', _data)
        setbugs(_data)
    }

    const loadUniversalDecks = async () => {
        const q = query(collection(db, 'decks'), where("isGlobal", "==", true))
        const querySnapshot = await getDocs(q);
        const _data = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
        console.log('_data', _data)
        setVocabDecks(_data)
    }

    useEffect(() => {
        loadUniversalDecks()
        loadBug()
    }, [])

    const realCards = cards.filter(c => c.model == "lang-en").map(e => ({...e, rank: parseInt(e.rank) || 9999})).sort((a, b) => a.c_id?.localeCompare(b.c_id || 0)).sort((a,b) => a.rank || 0- b.rank || 0).sort((a, b) => (b.occ_tt || 0) - (a.occ_tt || 0))?.slice(0,900)

    // const realCards = cards.filter(c => c.model == "lang-en" &&  c.s_id == filters.section_id && c.rank).map(e => ({...e, rank: parseInt(e.rank) || 9999})).sort((a, b) => a.c_id?.localeCompare(b.c_id)).sort((a,b) => a.rank - b.rank).sort((a, b) => (b.occ_tt || 0) - (a.occ_tt || 0))?.slice(0,100)
    // const realCards = interleaveDecks(cards.filter(c => c.model == "lang-en" &&  vocabDecks.find(e => e.id == c.deck_id) ).slice(0,300))

    
    const fillContextAll = async (cards, func) => {
       
        const fillContext = cards.filter(card => {
        const notContext = transformText(card.contexts[0]?.text)
        const googcontext = notContext?.includes(card.term?.toLowerCase())
        return !googcontext
        }).slice(0,100).forEach(card => {
         
          // completeCardUniversal(card)
          func(card)

            // console.log('card', card.term)
        })
        console.log('FillContext', fillContext)

    }
   
    const resetRank = (cards) => {
        cards.forEach(card => {
            console.log('update', card.term)
            updateCard({...card, inflexions: null, rank: null})
        })
            
    } 

    const delDoublon = (cards) => {
   
        const toDel = []
        cards.forEach(card => {
            const homonyme = cards.find(c => (c.term == card.term && card.id != c.id))
            homonyme && toDel.push(homonyme)
            // const notContext = transformText(card.contexts && card.contexts[0]?.text)
            // const googcontext = notContext?.includes(card.term?.toLowerCase())
            // !googcontext && toDel.push(card)
           
        })
        toDel?.forEach(e => {
            e && deleteCard(e)
        })
    }

    const test = async () => {
   
        // tts("Ciao, come stai? Vuoi bere una birra con me?")
        const text = `Jacinda, there's been a lot of replies to the newspaper ad. Can you help me sort through the résumés and prepare a shortlist of candidates to interview?
        
        I'm behind the desk all day today, but I have some time tomorrow. We'll be able to discuss our impressions in the meeting.`
        
        const text2= `Je me permets de vous envoyer un mail quant à la présentation de Mindseed qui vous devait être envoyée cette semaine. Ainsi, je vous ai envoyé le lien de téléchargement WeTransfer de la vidéo afin que vos enseignants de langues puissent la visionner. 


        Nous vous remercions une nouvelle fois pour l'aide que vous nous apportez
        
        En vous souhaitant une très bonne fin de semaine, `
        tts(text2)

        // const q = query(collection(db, 'cards'), where('test', "array-contains", {foo: "too", test: "test"}))
        // const querySnapshot = await getDocs(q);
        // const decks = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id})).sort((a, b) => a.order - b.order)
        // console.log('decks', decks)
        // console.log('lancement de la fonction test',  cards.slice(10,15))
        // async function processCards(cards) {
        //     for (let i = 0; i < cards.length; i++) {
        //         console.log('inflexion cartes', i);
        //         const ended = await getInflexion(cards[i]);
        //         console.log('ended', ended);
        //     }
        // }
        
       
       
        // Universalcards?.sort((a, b) => {
        //     if (a.rank === undefined || a.rank === null) {
        //         return 1;
        //     }  if (b.rank === undefined || b.rank === null) {
        //         return -1;
        //     }
        //     return a.rank - b.rank;
        // }).slice(0, 500)?.forEach(async (card) => {
        //     //console.log('card', card.term)
        //    // addToInternational(card)
        //    const exist = cards.find(c => c.original_card == card.id)
        //     if (exist) {
        //        console.log('le mot existe déjà !')
        //     } else {
        //         console.log('new', card.term)
        //         internationalizeDe(card)
        //     }            
        // })



    }

    

    const getDecks = async (wp_id) => {
        console.log('getDecks 🟢', wp_id)
        const q = query(collection(db, 'decks'), where('workspace_id', "==", wp_id), orderBy('order'))
        const querySnapshot = await getDocs(q);
        const decks = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id})).sort((a, b) => a.order - b.order)
        console.log('decks', decks)
        setDecks(decks)
    }

    const getWorkspaces = async (wp_id) => {
        console.log('getDecks 🟢', wp_id)
        const q = query(collection(db, 'workspaces'))
        const querySnapshot = await getDocs(q);
        const wps = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id})).sort((a, b) => a.order - b.order)
        console.log('wps', wps)
        setWorkspaces(wps)
    }

    //const cards = [card, train, like, become]

    // Ouvrir la connexion à la base de données
    const createDB = async (dbName) => {

        const request = indexedDB.open(dbName, 1);
        // Événement de mise à niveau de la base de données
        request.onupgradeneeded = function(event) {
            const db = event.target.result;
            
            // Créer un object store pour les cartes
            const cards = db.createObjectStore('cards', { keyPath: 'id' });
            if (dbName == "DBU") return
            const deckStore = db.createObjectStore('deck', { keyPath: 'id' });
            const userCards = db.createObjectStore('user_card', { keyPath: 'id' });
            const decorationStore = db.createObjectStore('decoration', { keyPath: 'id' });
        };
    }

   // createDB('LOCALDB')
    


    const findImage = async (card) => {
        client.photos.search({ query: card.term, per_page: 1 }).then(photos => {
            photos.photos.forEach(p => {
                console.log("photos", p.src)
            })

            const src = photos?.photos[0]?.src?.tiny || card?.def_img
            console.log('src', src)
            updateCard({...card, img: src})
          
        });
    }


    const updateDeck = async (deck) => {
        console.log('updateDeck 🟢', deck)
        const docRef = doc(db, "decks", deck.id);
        await updateDoc(docRef, {
            ...deck
        });
    }


    const getUniverselCards = async () => {
        console.log('GET CARD UNIVERSAL')
        const cardsRef = query(collection(db, "cards"), where("workspace_id", "==", "international"), limit(5000))
        const snapshot = await getDocs(cardsRef);
        const _cards = snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
        console.log('_cards', _cards)
        const sortedCards = _cards.sort((a, b) => {
            if (a.rank === undefined || a.rank === null) {
                return 1;
            }
            if (b.rank === undefined || b.rank === null) {
                return -1;
            }
            return a.rank - b.rank;
        })
       
        setUniversalCards(sortedCards.map(c => formatCard(c)));
        writeDB("DBU", sortedCards.map(c => formatCard(c)))
    }

    const getCards = async () => {
        console.log('GET CARD 🟢🟢 🟢🟢 🟢🟢')
        const cardsRef = query(collection(db, "cards"), where("workspace_id", "==", selectedWp.id), limit(5000))
        const snapshot = await getDocs(cardsRef);
        const _cards = snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
        // const sortedCards = _cards.sort((a, b) => a.rank - b.rank) // Tri par ordre croissant de rank
        console.log("cards", _cards)
       

        setCards(_cards.map(c => formatCard(c)));
        writeDB("LocalDb", _cards.map(c => formatCard(c)))
       
      }

      function removeDuplicateWords(words) {
        return [...new Set(words)];
      }
      

      const writeDB = async (dbName, objs) => {
        indexedDB.deleteDatabase(dbName);
        await createDB(dbName)
        const request = indexedDB.open(dbName, 1);
        request.onsuccess = function(event) {
            console.log('ecriture en DB')
            const db = event.target.result;
            const transaction = db.transaction('cards', 'readwrite');
            const objectStore = transaction.objectStore('cards');
            
            // Ajouter chaque carte à l'object store
            objs.forEach(function(card) {
                objectStore.add(card);
            });
            };
    
            request.onerror = function(event) {
                console.log('error')
            // Gérer les erreurs lors de l'ouverture de la base de données IndexedDB
            };
      }


      const createCard = async(w, tag) => {
        console.log('Le mot n existe pas', w)
        const newCard = {
            "workspace_id": "international",
            "rank": Universalcards.filter(c => c.rank).length + i,
            "trad": w?.trim(),
            "tag": tag
        }
        const docRef = await addDoc(collection(db, "cards"), newCard)
        const docId = docRef.id;

        // Update the new document with its own ID
       
         return  await updateDoc(docRef, { id: docId });
      }

      

      const addNewCards = async () => {
        console.log('addCardsUniversal')
        const words = document.querySelector('#new-data').value
        const categorie_id = document.querySelector('#categorie_id').value
        const section_id = document.querySelector('#section_id').value

        console.log('cards', cards)
        console.log('categorie_id', categorie_id)
        console.log('section_id', section_id)
        words.split('\n').forEach(async(w,i) => {
            const exist = cards.find(c => c.trad.trim().toLowerCase() == w.trim().toLowerCase() || c.term.trim().toLowerCase()  ==  w.trim().toLowerCase())
            if (exist){
                console.log('Le mot existe déjà :', w, exist)
                const cardRef = doc(db, 'cards', exist.id);
                const newCard = {...exist, rank: cards.filter(c => c.rank).length + i}
        
                //await updateDoc(cardRef, newCard);
            } else {
                console.log('le mot existe pas !', w)
                //createCard(w)
                const newCard = {
                    "workspace_id": selectedWp.id,
                    "rank": cards.filter(c => c.rank).length + i,
                    "trad": w?.split(':')[1]?.trim(),
                    "term": w?.split(':')[0]?.trim(),
                    "model": "lang-en",
                    "s_id": section_id,
                    "c_id": categorie_id
                }
                console.log('newCard', newCard)
             //   await addDoc(collection(db, "cards"), newCard);
            }
                
        })
      }


      const addCardsUniversal = async () => {
        console.log('addCardsUniversal')
        const words = document.querySelector('#newData').value
        const categorie_id = document.querySelector('#newDataCategorie').value
        let i_new = 0
        words.split('\n').forEach((w,i) => {
            const exist = Universalcards.find(c => c.trad.trim().toLowerCase() == w.trim().toLowerCase())
            if (exist){
                // console.log('Le mot existe déjà :', w, exist)
                const cardRef = doc(db, 'cards', exist.id);
                const newCard = {...exist, rank: Universalcards.filter(c => c.rank).length + i}
        
                //await updateDoc(cardRef, newCard);
            } else {
                i_new++
                console.log('new ' + i_new + " : ", w)
                // console.log('categorie_id', categorie_id)
               createCard(w, categorie_id)
            }
                
        })
      }
      const addCard = async (universal) => {
        const newCard = {
            "workspace_id": selectedWp?.id || "international",
        }
        const docRef = await addDoc(collection(db, "cards"), newCard);
        await updateDoc(docRef, { id: docRef.id });
        console.log('document crééer avec succès', docRef)
        setCards([...cards, {...newCard, id: docRef.id}])
      }

      const addDeck = async () => {

        const newDeck = {
            "workspace_id": page == "deck_u" ? "international" : selectedWp.id,
            "presentation_mode": null,
            "order": decks.length + 1,
            "name": " Nouveau deck",
            "visible": true
        }

        const docRef = await addDoc(collection(db, "decks"), newDeck);
        setDecks([...decks, {...newDeck, id: docRef.id}])
      }

      const deleteDeck = async (selectedDeck) => {
        const docRef = doc(db, "decks", selectedDeck.id);
        await deleteDoc(docRef);
        setDecks(decks.filter(d => d.id !== selectedDeck.id))
      }

      const readDB = (dbName) => {
        const request = indexedDB.open(dbName, 1);
        request.onsuccess = function(event) {
                const db = event.target.result;

                const transaction = db.transaction('cards', 'readonly');
                const objectStore = transaction.objectStore('cards');
                const getAllRequest = objectStore.getAll();
                
                getAllRequest.onsuccess = function(event) {
                    const storedCards = event.target.result
                    console.log('lecture de la DB', storedCards);
     
                    // Utilisez les données stockées pour initialiser votre état local ou effectuer d'autres traitements
                    if (dbName == "DBU") {
                        setUniversalCards(storedCards.map(c => formatCard(c)));
                    } else {
                        setCards(storedCards.map(c => formatCard(c)));
                    }
                   //
                };
                };
      }
   

      function arrayToText(array) {
        // Vérifiez si le tableau est non-null et contient au moins un élément
        if (array && array.length > 0) {
            // Utilisez join() pour convertir le tableau en texte
            return array.slice(0, 3).join(', ');
        } else {
            // Retournez une chaîne vide si le tableau est null ou vide
            return '';
        }
    }
    const formatCard = (card) => {
        const contexts = []
        contexts.push({
            meaning: card.trad || null,
            text: card.context || null,
            trad: card.context_trad || null,
            type: card.wt || null,
            img: card.def_img || null

        })
        const newCard = {...card, trad: typeof card.trad === "string" ? card.trad : arrayToText(card.trad), contexts: card.contexts ? card.contexts : contexts}
        return newCard
    }

    const updateCard = async (card) => {
        console.log('update de la carte', card)
        const cardRef = doc(db, 'cards', card.id);
        const newCard = {...card, lastUpdate: new Date()}

        await updateDoc(cardRef, newCard);
        setCards(cards => cards.map(c => c.id === card.id ? newCard : c))
        setUniversalCards(Universalcards.map(c => c.id === card.id ? newCard : c))

    }

    useEffect(() => {
        setCardFiltered(cards.filter(c => {
            const testWt = filters.wt.filter(f => f.s).map(f => f.value).includes(c.wt) 
            
            const hasContext = c.contexts && c.contexts[0]?.text
            
            const allContext =  filters.context.filter(f => f.s).map(f => f.value).join("-") == "oui-non" ? hasContext : true
            const allVisual =  filters.visual.filter(f => f.s).map(f => f.value).join("-") == "oui-non" ? c.isVisual : true
            return true
            //return testWt && allContext && allVisual
        } ).sort((a, b) => a.rank - b.rank))
       
     }, [filters, cards])
     const cardsToFilter = page == "deck_u" ? Universalcards : cards 
     const filteredWords = cardsToFilter.filter(card => !card.deck_id).map(card => transformText(card.term))
     const filteredWordsInflexions = cardsToFilter.filter(card => !card.deck_id).map(card => card.inflexions).flat().map(card => transformText(card))


    useEffect(() => {
       // getCards()
       console.log('LOCAL DBBBB', readDB('LOCALDB'))
       
       readDB('DBU')

       getWorkspaces()
    }, [])


    
    useEffect(() => {
        // getCards()
        if (i > 0 && selectedWp) {
            console.log('selectedWp 🔥🔥🔥🔥', selectedWp)
            getCards()
            getDecks(selectedWp.id)
            }
        i ++
     }, [selectedWp])

    useEffect(() => {
        // getCards()

        setSelectedDeck(updatedDeck)
        setDecks(decks.map(d => d.id == updatedDeck?.id ? updatedDeck : d))
     }, [updatedDeck])
    



     function removeDuplicatesInput(input) {
        const uniqueElements = new Set(input?.split(';'));
          return  Array.from(uniqueElements).join(';');
      }
    
    function removeDuplicates(arr) {
        return [...new Set(arr)];
    }


    const deleteCard = (card) => {
        console.log('delete card', card?.term)
        const cardRef = doc(db, 'cards', card.id);
        deleteDoc(cardRef)
        setCards(cards.filter(c => c.id !== card.id))
        setUniversalCards(Universalcards.filter(c => c.id !== card.id))
    }

    const addToInternational = async (card) => {
       // console.log('card', card)

        const new_card = {...card, workspace_id: "international", isProd: true}
       
        // creation new card
        if (!card.prod_id) {
            console.log('new_card', new_card)
            const newCardRef = await addDoc(collection(db, 'cards'), new_card)
          
            await updateDoc(newCardRef, { id: newCardRef.id });
            console.log('newCardRef', newCardRef.id)
            updateCard({...card, prod_id: newCardRef.id})
        } else {
            console.log('déjà en prod')
        }
    }

    
    

    const randomiseForm = (randomNumber) => {
        let perso = ""
        let form = ""
    
        if (randomNumber >= 6) {
            perso = `en utilisant l'un des pronom suivant au hasard (${shuffleArray(["nous", "vous", "ils/elles", "tu", "je", "je", "tu", "on", "il/elle", "il/elle"])[0]}))`
            console.log("perso", perso)
        }
        if (randomNumber >= 3 && randomNumber <= 5) {
           
            form = shuffleArray(["dans la forme interrogative", "dans la forme négative", "dans la forme formelle"])[0]
        }

        return {perso, form}
    }

    const completeCardUniversal = async (card) => {

        const randomNumber = Math.floor(Math.random() * 10) + 1;
        const {perso, form} = randomiseForm(randomNumber)
      
        // if (randomNumber%4==0) {
        //         temp = `dans un temps qui n'est pas le présent (${shuffleArray(["impératif", "passé", "futur"])[0]})`
        // }
      

        const text_request = `Donne uniquemnent la réponse.  Au format JSON. sans markdown
        Pour le terme suivant "${card.trad}". 
        1. trad:  Trouve la traduction en Anglais.
        2. context: Donne une phrase simple et natuelle qui a du sens, en français, qui contient le terme 1, sans inflexion (pas de pluriel, pas de conjugaison, verbe à l'infinitif etc..) en utilisant un contexte de la vie quotienne ${perso} ${form} 
        3. context_trad: Donne la traduction de la phrase 4 en Anglais.`

        const request = [{ "role": "user", "content": text_request}]
       // console.log('text_request', text_request)
        const requestData = await gpt(request, true, ct)
       
        const requestDataContent = requestData.choices[0]?.message?.content
        const jsonData = JSON.parse(requestDataContent)
   //     console.log('DATA REQUEST', requestDataContent)
        // console.log('DATA REQUEST', jsonData)
    
        const newCard = {...card, 
            //term, 
            trad: card.trad.replace('.', ""),
            term: jsonData.trad.replace('.', ""), // anglais
            term_en: jsonData.trad,
            contexts: [{...card.contexts[0], text: jsonData.context_trad, trad: jsonData.context}]
        }
        delete newCard.infexions;
        delete newCard.infexion;
        console.log('newCard', newCard)
        updateCard(newCard)
   
    }

    function compareWordsWithoutAccents(word1, word2) {
        const normalizeWord = (word) => word.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        console.log(`${word1} + ${word2} : ${normalizeWord(word1) === normalizeWord(word2)}`)
        return normalizeWord(word1) === normalizeWord(word2);
      }

    

    const addFromFQ = async (term) => {
        const text_request = `Donne uniquemnent la réponse. Au format JSON. sans markdown
        Pour le terme suivant "${term}" en ${selectedWp?.name}. 
        1. trad:  Trouve la/les traductions en français.
        2. inflexions: Donne la liste des inflexions du terme 1. en ${selectedWp?.name} sous la forme d'un array de string, uniquement les formes du mot, sans déterminant ni article.  Toutes les formes possibles du mots sans doublons. En changeant de genre et en accordant si besoin. N'invente pas de nouveau terme ! Toutes les inflexions doivent exister. Les mots doivent exister et correspondre au inflexion du mot "${term}".`
        

        const request = [{ "role": "user", "content": text_request}]
        console.log('text_request', text_request)
        const requestData = await gpt(request, true, ct)
        console.log('DATA REQUEST', requestData)
        const requestDataContent = requestData?.choices[0]?.message?.content
        const jsonData = JSON.parse(requestDataContent)

        const cardDE = {
       
            term: term,
            trad: typeof jsonData.trad === "string" ? jsonData.trad : arrayToText(jsonData.trad),
            rank: 999,
            deck_id: "draft",
            inflexions: jsonData.inflexions,
            workspace_id: selectedWp.id,
            isProd: true, 
            model: "lang-en",
            original_card: null,
           
          
        }

        console.log('nouvelle carte ! ✅', cardDE)

      

        const newCardRef = await addDoc(collection(db, 'cards'), cardDE)
        await updateDoc(newCardRef, { id: newCardRef.id });
        console.log('id', newCardRef.id)
        cards.push({...cardDE, id: newCardRef.id});
        setCards(cards)
    }

   


    const internationalizeDe = async (card) => {

       
  
        const text_request = `Donne uniquemnent la réponse. Au format JSON. sans markdown
        Pour le terme suivant "${card.trad}" (issue de la phrase "${card.contexts[0].trad}"). 
        1. term:  Trouve la traduction en ${selectedWp?.name}.
        2. inflexions: Donne la liste des inflexions du terme 1. en ${selectedWp?.name} sous la forme d'un array de string, uniquement les formes du mot, sans déterminant ni article.  Toutes les formes possibles du mots sans doublons. En changeant de genre et en accordant si besoin. N'invente pas de nouveau terme ! Toutes les inflexions doivent exister. Les mots doivent exister et correspondre au inflexion du mot "${card.trad}".
        3. context_trad: Donne une traduction naturelle de "${card.contexts[0].trad}" en ${selectedWp?.name}.
        `

        const request = [{ "role": "user", "content": text_request}]
        const requestData = await gpt(request, true, ct)
        console.log('DATA text_request', text_request)
        const requestDataContent = requestData?.choices[0]?.message?.content
        const jsonData = JSON.parse(requestDataContent)
      
        
    
        const cardDE = {...card, 
            //term, 
            term: jsonData.term,
            inflexions: jsonData.inflexions,
            workspace_id: selectedWp.id, 
            isProd: true, 
            deck_id: card.deck_id,
            model: "lang-en",
            original_card: card.id,
           // inflexions: inflexionArray,
            contexts: [{...card.contexts[0], text: jsonData.context_trad}]
        }
        delete cardDE.infexions;
        delete cardDE.infexion;
        console.log('nouvelle carte ! ✅', cardDE)
         
        const newCardRef = await addDoc(collection(db, 'cards'), cardDE)
        console.log('id', newCardRef.id)
        await updateDoc(newCardRef, { id: newCardRef.id });
        cards.push({...cardDE, id: newCardRef.id});
        setCards(cards)
    }


    const findHint = async (card) => {
        const messages =[{"role": "user", "content": `Trouve un lien mnémotechnique en français pour retenir le mots allemand suivant : ${card.term}. Donne uniquement le liens mnémotechnique en une phrase`}]
        const hint = await gpt(messages, true, ct)
        const newCard = {...card, hint: removeQuotes(hint?.choices[0]?.message.content).replace('\n', '')}
        
        console.log('newCard', newCard)
        updateCard(newCard)
    }

   

const setRank = async (card) => {
    // console.log('text_50k', text_50k) // affiche la liste text_50k
    // console.log('int_50k', int_50k)
    // console.log('inflexion', card.inflexions) // affiche les inflexions de la carte

    // Initialiser un tableau pour stocker les indices trouvés
    const indices = [];
    const occ = []
    const inflexionArray = [...new Set([...card.inflexions?.map(e => transformText(e)) || [], transformText(card.term)])];
    // console.log('inflexionArray', inflexionArray)

  
    inflexionArray.forEach(inflexion => {

        const index = text_50k.indexOf(transformText(inflexion));

        if (index !== -1) {
            indices.push(index);
            occ.push(int_50k[index])
        }
    });


    if (indices.length > 0) {
        const minIndex = Math.min(...indices);
        // console.log('Index le plus petit:', minIndex); 
        // console.log('1er occ', int_50k[minIndex])
        const occ_tt = occ.reduce((acc, val) => parseInt(acc) + parseInt(val), 0);
        console.log(card.term, occ_tt)
        
        const newCard = {...card, 
            occ: parseInt(int_50k[minIndex]),
            occ_tt: occ_tt,
            rank_occ: minIndex
        }
        updateCard(newCard)
    } else {
        console.log('Aucune inflexion trouvée dans text_50k pour', card.term);
    }
}

    const addMore = async (card) => {
       
        const requestText = requestInfoMore(selectedWp?.name, card.term)
        console.log('requestText', requestText)
        const request =  [{ "role": "user", "content": requestText }]


        const requestData = await gpt(request, true, ct, {temp: 0.3})

        const data_content = requestData.choices[0]?.message?.content;
        console.log('data_content', data_content);
        const jsonData = JSON.parse(data_content)
        console.log('requestData', requestData)
        console.log('jsonData', jsonData)

        const newCard = {...card, more: jsonData}
        updateCard(newCard)
    }

    const addWordToDeck = (term, deck_id) => {
        console.log('term', term)
        const card = cards.find(card => card.term.toLowerCase() == term.toLowerCase())
        console.log('card', card)
        updateCard({...card, deck_id: deck_id})
    }

    const reduceImg = async (card) => {
        let cards = [card]
        if (card.original_card) {
            const cardsCollection = collection(db, 'cards');
            const qBrother = query(cardsCollection, where('original_card', '==', card.original_card));
            const qDad = query(cardsCollection, where('id', '==', card.original_card));
            const qSon = query(cardsCollection, where('original_card', '==', card.id));
            const querySnapshotBrother = await getDocs(qBrother);
            const querySnapshotSon = await getDocs(qSon);
            const querySnapshotDad = await getDocs(qDad);
            const dataBrother = querySnapshotBrother.docs.map(doc => ({...doc.data(), id: doc.id}));
            const dataDad = querySnapshotDad.docs.map(doc => ({...doc.data(), id: doc.id}));
            const dataSon = querySnapshotSon.docs.map(doc => ({...doc.data(), id: doc.id}));
            console.log('trouver les cartes soeurs,', dataBrother)
            console.log('trouver la carte mère', dataDad)
            console.log('trouver les cartes filles', dataSon)
            cards = [...dataBrother, ...dataDad, ...dataSon]
        }

        fetch(card.img)
        .then(response => response.blob()) // Convertit la réponse en Blob
        .then(blob => {
            console.log('fetch en cours')
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = URL.createObjectURL(blob);
            img.onload = () => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              canvas.width = 500;
              canvas.height = 500;
              ctx.drawImage(img, 0, 0, 500, 500);
              canvas.toBlob(resolve, 'image/webp'); // Convertit le canvas redimensionné en Blob
            };
            img.onerror = reject;
          });
        })
        .then(resizedBlob => {
          // Ici, 'resizedBlob' est votre image redimensionnée
          const storageRef = ref(storage, `dalle3_img/400/${card.id}`);
          return uploadBytes(storageRef, resizedBlob); // Utilisez le Blob redimensionné pour l'upload
        })
        .then(snapshot => {
          console.log("Uploaded a blob or file!");
          console.log('snapshot', snapshot);
          return getDownloadURL(snapshot.ref); // Utilisez getDownloadURL pour obtenir l'URL de téléchargement
        })
        .then(downloadURL => {
          console.log("Image URL:", downloadURL);
          // Mise à jour de vos cartes avec la nouvelle URL de l'image
          cards.forEach(c => {
            console.log('update de la carte', c)
            updateCard({...c, img_400: downloadURL });
          });
        })
        .catch(error => {
          console.error("Error uploading image:", error);
        });

    }

    const addImg = async(card, prompt) => {
        console.log('addImg', prompt)
        let cards = [card]
        if (card.original_card) {
            const cardsCollection = collection(db, 'cards');
            const qBrother = query(cardsCollection, where('original_card', '==', card.original_card));
            const qDad = query(cardsCollection, where('id', '==', card.original_card));
            const qSon = query(cardsCollection, where('original_card', '==', card.id));
            const querySnapshotBrother = await getDocs(qBrother);
            const querySnapshotSon = await getDocs(qSon);
            const querySnapshotDad = await getDocs(qDad);
            const dataBrother = querySnapshotBrother.docs.map(doc => ({...doc.data(), id: doc.id}));
            const dataDad = querySnapshotDad.docs.map(doc => ({...doc.data(), id: doc.id}));
            const dataSon = querySnapshotSon.docs.map(doc => ({...doc.data(), id: doc.id}));
            console.log('trouver les cartes soeurs,', dataBrother)
            console.log('trouver la carte mère', dataDad)
            console.log('trouver les cartes filles', dataSon)
            cards = [...dataBrother, ...dataDad, ...dataSon]
        }

        console.log('cartes à modifier', cards)

        const dataImg = await imageGenerator({
            "model": "dall-e-3",
            "prompt": prompt,
            "n": 1,
            "size": "1024x1024"
          })

       // Assurez-vous d'avoir 'fetch' disponible ou utilisez un polyfill
       fetch(dataImg.data[0].url)
       .then(response => response.blob()) // Convertit la réponse en Blob
       .then(blob => {
         return new Promise((resolve, reject) => {
           const img = new Image();
           img.src = URL.createObjectURL(blob);
           img.onload = () => {
             const canvas = document.createElement('canvas');
             const ctx = canvas.getContext('2d');
             canvas.width = 800;
             canvas.height = 800;
             ctx.drawImage(img, 0, 0, 800, 800);
             canvas.toBlob(resolve, 'image/webp'); // Convertit le canvas redimensionné en Blob
           };
           img.onerror = reject;
         });
       })
       .then(resizedBlob => {
         // Ici, 'resizedBlob' est votre image redimensionnée
         const storageRef = ref(storage, `dalle3_img/${card.id || new Date().getTime()}`);
         return uploadBytes(storageRef, resizedBlob); // Utilisez le Blob redimensionné pour l'upload
       })
       .then(snapshot => {
         console.log("Uploaded a blob or file!");
         console.log('snapshot', snapshot);
         return getDownloadURL(snapshot.ref); // Utilisez getDownloadURL pour obtenir l'URL de téléchargement
       })
       .then(downloadURL => {
         console.log("Image URL:", downloadURL);
         // Mise à jour de vos cartes avec la nouvelle URL de l'image
         cards.forEach(c => {
           console.log('update de la carte', c)
           updateCard({...c, img: downloadURL, img_400: null, img_pexel: card.img || null});
         });
       })
       .catch(error => {
         console.error("Error uploading image:", error);
       });

    } 

  

    const getInflexion = async (card) => {

        return new Promise(async (resolve, reject) => { 
        const messages = [
         
            {"role": "user", "content": `
            Donne uniquemnent la réponse. 
            Donne toutes les inflexions qui existe pour le mot "${card.term}" en ${selectedWp?.name} sous la forme du liste séparé par des virgules, uniquement les formes du mot, sans déterminant ni article. 
            toutes les formes possibles du mots sans doublons. y compris les changements de genre et les accords uniquement s'il existe ! 
            N'invente pas de nouveau terme toute les inflexions doivent exister. Les mots doivent existé et correspondre au inflexion du mot "${card.term}". 
            `}
        
        ]

        const inflexionsPromesse = await gpt(messages, true, ct)
        const inflexions = removeQuotes(inflexionsPromesse.choices[0]?.message.content)
        const array = removeDuplicates(inflexions?.split(',').map(e => e.trim()))
        console.log('inflexions', inflexions)
        console.log('array', array)
        updateCard({...card, inflexions: array})
        resolve(true)
        })
    }


    const loadContext = async (set) => {
        console.log('load context 🟢🟢🟢')
        const q = query(collection(db, "contexts"))
        const querySnapshot = await getDocs(q)
        const contexts = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
        set(contexts)
    }


    
   


    const completeCard = async (card, ct) => {
        console.log('ct completeCard', ct)
        const randomNumber = Math.floor(Math.random() * 10) + 1;
        const {perso, form} = randomiseForm(randomNumber)
        const messages = [
            {"role": "system", "content": "Donne uniquemnent la réponse"},
            {"role": "user", "content": `
            Trouve une phrase simple et naturelle en ${selectedWp?.name} qui contient  le terme "${card.term}" ${perso} ${form}.
            Le terme doit être dans sa forme de base (lemma). 
            Si le terme est un verbe l'afficher à l'infinif, si c'est un nom au singulier etc.. 
            La phrase doit être correcte et naturelle et doit faire environt 7 mots.
        
            Donne la traduction française entre () :
            `}
        
        ]
        console.log(`i=${randomNumber} ${selectedWp?.name} qui contient exacetement le terme "${card.term}" ${perso} ${form}`)
       // const trad = await gpt(`Traduit en français le term suivant : "${card.term}"`, false, ct)
        const contextData = await gpt(messages, true, ct, {temp: 0.7})
        // console.log("contextData", contextData)
        const contextResult = removeQuotes(contextData?.choices[0]?.message.content)
        // console.log("contextResult", contextResult)
        const contextTrad = getTextInParentheses(contextResult)
        const contextText = removeTextInParentheses(contextResult)

       

        // console.log('contextTrad', contextTrad)
        // console.log('contextText', contextText)
        const newCard = {...card, 
            //trad: trad?.choices[0]?.text,
            contexts: [...card.contexts.map((c, i) => i == 0 ? {...c, text: contextText, trad: contextTrad} : c)]
        }
        console.log('newCard', newCard)
        updateCard(newCard)
    }

    if (selectedDeck?.dialog?.en) {
        const dialogWords = transformText(selectedDeck?.dialog?.en).split(" ")?.filter(e => !e == " ")
      //  console.log('dialogWords', dialogWords)
       // console.log('dialogWords no repeat', removeDuplicateWords(dialogWords))
    }

    useEffect(() => {
        workspaces && setSelectedWp(workspaces.filter(wp => wp.img)[1])
    }, [workspaces.length])

 
    const univCards = Universalcards?.sort((a, b) => b.tag?.localeCompare(a?.tag || "")).filter(c =>  c.tag != "temps").filter(e => e.img).sort((a, b) => {
        // if (a.rank === undefined || a.rank === null) {
        //     return 1;
        // }
        // if (b.rank === undefined || b.rank === null) {
        //     return -1;
        // }
        // return a.rank - b.rank;
    }).sort((a, b) => b.trad?.localeCompare(a?.trad || "")).slice(0, 3000)
    .filter(c => deckPerfects.includes(c.deck_id))
    // pour afficher uniquement les univ cartes des decks globaux

   

    const flashcardsWords = cards.filter(c => c.model == "lang-en")
    const allTermInDecks = decks.map(d => d.words).join(';').split(';').map(e => transformText(e))
    // console.log('allTermInDecks', allTermInDecks)
    const allTermsWithInlexions = cards && flashcardsWords.flatMap(c => c.inflexions?.map(inflexion => transformText(inflexion))).concat(flashcardsWords?.map(card => transformText(card.term)));
    const fqArray = ct.fqlist?.all?.split(';') 
    const allWordsFQ = fqArray?.map(w => {
        const split = w.split(':')
        const lem = split[1]
        const i = split[0]
        const isInCards = allTermsWithInlexions?.includes(transformText(lem)) 
        const isInDeck = allTermInDecks?.includes(transformText(lem)) 
       
        return {lem,i, isInCards, isInDeck}
    })

   


    const addToUniversal = async (card) => {
        const newCard = {
            trad: card.trad,
            rank: card.rank_occ || null,
            img: card.img,
            img_400: card.img_400,
            contexts: card.contexts,
            workspace_id: "international", 
            deck_id: card.deck_id,
            lastUpdate: new Date()
        }
        
         console.log('newCard', newCard)
        const data = await addDoc(collection(db, "cards"), newCard);
         updateDoc(data, { id: data.id });
        console.log('data', data)
        
        updateCard({...card, original_card: data?.id})
    }



    const allLemma = removeDuplicateWords(allWordsFQ?.flatMap(w => w.lem.split(' '))).map(l => {
        const isInCards = allTermsWithInlexions?.includes(transformText(l))
        const isInDeck = allTermInDecks?.includes(transformText(l)) 

        return {word: l, isInCards, isInDeck}
    })

    const [text_50k, setText_50k] = useState()
    const [int_50k, setInt_50k] = useState()

    const inflexionsList = []
    const uniqueLemma = []


    const text_50k2 = removeDuplicateWords(text_50k?.slice(0, 5000).map(l => {
        const isInCards = allTermsWithInlexions?.includes(transformText(l)) 
        const isInDeck = allTermInDecks?.includes(transformText(l)) 
        const isDel = ct.fqlist?.del?.includes(l)
        const isGram = ct.fqlist?.gram?.includes(transformText(l))
     
        return {word: l, isInCards, isInDeck, isDel, isGram}
    }))

    // const uniqueLemma = removeDuplicateWords(inflexionsList).map(l => {
    //     return flashcardsWords.find(c => transformText(c.term) == transformText(l) || c.inflexions?.find(word => transformText(word) == transformText(l)))
    // })


    


    const termNotInList = flashcardsWords?.filter(card => {
        let isInList = fqArray?.includes(transformText(card.term)) || card.inflexions?.find(word => fqArray?.includes(transformText(word)))
      
        return!isInList
    })


    const addToDeck = async (card) => {
        const deck_id = "fIVB5tO5mvuIUghPILjL"
        console.log('real cards for sales', realCards)
        realCards.forEach(card => {
             updateCard({...card, deck_id: deck_id})
        })
      
    }

   

    const removeDigits = (inputString) => inputString.replace(/\d+|\n|\r/g, '')
    const removeNoDigits = (inputString) => inputString.replace(/[^\d\n\r]/g, '');


    async function fetchData() {
        try {
            const response = await fetch('https://raw.githubusercontent.com/hermitdave/FrequencyWords/master/content/2018/de/de_50k.txt');
            if (!response.ok) {
                throw new Error('Network response was not ok: ' + response.statusText);
            }
            const data = await response.text();
            setText_50k(removeDigits(data).split(' '))
            setInt_50k(removeNoDigits(data).split('\n'))
            // console.log(removeDigits(data));
        } catch (error) {
            console.error('There was a problem with the fetch operation: ', error);
        }
    }
    
    const [story, setStory] = useState()
    const [mode, setMode] = useState("add")

    
    const allWordsCards = allTermInDecks.concat(allTermsWithInlexions)
    // console.log('allWordsCards', allWordsCards)

    const storyWithData = transformText(story)?.split(" ").map(word => {
        const inList =  allWordsFQ?.map(w => w.i).indexOf(transformText(word.trim())) + 1
        const inBigList = text_50k?.find(e => e == transformText(word))
        const inCards =  allWordsCards?.includes(transformText(word.trim()))
        const out1200 = inList+1 > 3000 || inList == 0
        const outlist = !inCards && !inList
        const index_50k = text_50k?.indexOf(transformText(word));
        // console.log('index_50k', text_50k)
        return {w: word, index_50k, inList, inCards, outlist, out1200, inBigList}
    })


    let i2 = 0

    const next100 = text_50k2.filter(w => !w.isInCards).slice(0, 400)


    



    return (
        <div className={`fredoka h-screen overflow-scroll`}>

            
          
            <div className='px-16 pt-4 border-b-4 bg-green-100 border-green-200'>
       
                {/* <h1 className='text-green-500 text-xl mb-2'>{selectedWp?.name}</h1> */}
                <div className='flex gap-2 mb-2 flex-wrap'>{workspaces.filter(wp => wp.img).map(wp => <div className={`flex gap-2 items-center rounded-full p-1 ${selectedWp == wp ? "font-bold bg-green-500 text-white" :""}`} onClick={() => setSelectedWp(wp)}><img src={wp.img} className='h-[24px] rounded-full' />{wp.name}</div>)}</div>
                <div className='flex gap-4 text-green-500 -mb-1'>
                    <div onClick={() => setPage("Words")} className={`b ${page == "Words" && "border-b-4"} border-green-500`}>Mots</div>
                    <div onClick={() => setPage("Decks")} className={`b ${page == "Decks" && "border-b-4"} border-green-500`}>Decks</div>
                    <div className='b'>Expressions</div>
                    <div className='b'>Grammaire</div>
                    <div onClick={() => setPage("Contexte")} className='b'>Contexte</div>
                    <div onClick={async() => {
                        setPage("Frequency");  
                        const data = await getFQlist(selectedWp.name)
                        console.log('data',data)
                        ct.setfqlist(data)
                        setStory(data?.stories)
                    }} className='b'>Frequency </div>
                    <div onClick={() => setPage("Universel")} className={`b ${page == "Universel" && "border-b-4"} border-green-500`}>Universel</div>
                    <div className='b'>IA</div>
                   
                </div>
                
            </div>

          
            {page == "Frequency" && selectedWp && <div className='p-16 py-8 pb-24'>
               <div>
                    <div className='' onClick={() => fetchData()}>GET DATA - Lemma ({allLemma?.length})</div>
                    <div>Nombre de mots esp ({cards?.length})</div>
                    <div>Nombre de mots dans la liste ({allWordsFQ?.filter(a => a.isInCards)?.length}) </div>
                    <div onClick={() => {console.log('termNotInList',termNotInList.map(e => e.term))}}>Pas dans la liste: {(termNotInList?.length)}</div>
                </div>

                <div className={` p-2 rounded-xl border-2 ${mode == "add" ? "border-green-500" : mode == "del" ? "border-red-500" : "border-cyan-500"} `} onClick={() => setMode(m => m == "add" ? "del" : m == "del" ? "gram" : "add") }>mode: {mode}</div>

                <div onClick={() => {
                    console.log(storyWithData?.filter(e => e.inCards)?.map(e => e.w)?.join('\n'))
                }}>Nb mots en liste ({storyWithData?.filter(e => e.inCards).length}/{storyWithData?.length} - { Math.round(storyWithData?.filter(e => e.inCards).length*100/storyWithData?.length)}%)</div>
               <div className='flex gap-1 flex-wrap my-2' > 
                {/* {storyWithData?.map(e => <div className={`p-1 ${e.inCards ? "bg-green-500" : e.inBigList ? "bg-orange-500" : "bg-red-500" } text-white`}>{e.w}</div>)} */}

                {/* {storyWithData?.map(e => <div className={`p-1 ${e.w =="" ? "bg-gray-400" : e.inCards ? "bg-green-500" : e.inList < 1200 && e.inList != 0? "bg-green-500 " : e.inList == 0 ? "bg-orange-500": "bg-orange-500"} text-white`}>{e.w}</div>)} */}
                </div>
                
                {/* <div className='flex gap-2'>
                    <div className='p-2 rounded-xl text-white bg-blue-500' onClick={async() => {
                    const data = await getFQlist(selectedWp.name)
                    ct.setfqlist(data.all)
                }}>recharger all</div>
                <div className='p-2 rounded-xl text-white bg-green-500' onClick={async () => {
                    console.log('selectedWp', selectedWp.name)
                   // const add = await addDoc(collection(db, 'fq'), {list: "test"})
                    const docRef = doc(db, 'fq', selectedWp.name);
                    await setDoc(docRef, {list: "test;hola;hole;Hola"});
                    
                    // console.log('add', add)
                }}>Créer</div>
                  <div className='p-2 rounded-xl text-white bg-purple-500' onClick={async () => {
                  
                
                }}>Changer les fq ranks</div>
                </div> */}
                {/* <textarea id="fq-storie" className='w-full h-[200px] p-2 rounded-xl my-2 bg-purple-100' onChange={(e) => {setStory(e.currentTarget.value)}} placeholder='ajouter la liste de fréquence'></textarea> */}

                {/* <textarea id="fq-text" className='w-full h-[200px] p-2 rounded-xl my-2 bg-purple-100' placeholder='ajouter la liste de fréquence'></textarea> */}

               {/* <div className='flex gap-2'>

                <div onClick={() => {
                    const text = document.querySelector('#fq-text').value
                    const newText = text.replace(/\n/g, ';')
                
                    const docRef = doc(db, 'fq', selectedWp.name);
                    updateDoc(docRef, {list: newText});
                    ct.setfqlist(newText)
                }} className='p-2 rounded-xl text-white bg-red-500' >Ecraser</div>
                <div  className='p-2 rounded-xl text-white bg-green-500' onClick={() => {
                    const text = document.querySelector('#fq-text').value
                    const newText = text.replace(/\n/g, ';')
                    const docRef = doc(db, 'fq', selectedWp.name);
                    const newList = removeDuplicatesInput(ct.fqlist + ";" + newText)
                    updateDoc(docRef, {list: newList});
                    ct.setfqlist(newList)
                }} >Ajouter dans lemma</div>

                <div  className='p-2 rounded-xl text-white bg-green-500' onClick={() => {
                    const text = document.querySelector('#fq-text').value
                    const newText = text.replace(/\n/g, ';')
                    const docRef = doc(db, 'fq', selectedWp.name);
                    const newList = removeDuplicatesInput(ct.fqlist + ";" + newText)
                    updateDoc(docRef, {all: newList});
                    ct.setfqlist(newList)
                }} >Ajouter dans all</div>

<div  className='p-2 rounded-xl text-white bg-green-500' onClick={() => {
                    const text = document.querySelector('#fq-text').value
                    const newText = text.replace(/\n/g, ';').replace(/\t/g, ':')
                    const docRef = doc(db, 'fq', selectedWp.name);
                    const newList = newText
                    console.log(newText)
                    updateDoc(docRef, {all: newList});
                    ct.setfqlist(newList)
                }} >Ajouter dans all:lemma</div>
                </div> */}


                <div onClick={async() => {
                    console.log('ct.fqlist', ct.fqlist)
                    const data = await getFQlist(selectedWp.name)
                    
                    ct.setfqlist(data)
                    
                    }}> mot supprimé 
                    <div className='flex gap-2 flex-wrap '>{ct.fqlist?.del?.map(e => <div className='border-1 text-red-600 rounded-xl text-sm'>{e}</div>)}</div>
                </div>
                <div> 
                    mot de grammaire
                    <div className='flex gap-2 flex-wrap '>{ct.fqlist?.gram?.map(e => <div className='border-1 text-cyan-600  rounded-xl text-sm'>{e}</div>)}</div>

                </div>
                <div className='' onClick={( )=> {
                  
                   
                    console.log('flashcardsWords', flashcardsWords)
                    console.log(allLemma.filter(e => !e.isInCards && !e.isInDeck ).map(e => e.word).slice(0,100).join(' '))
                }}>Liste des mots 50k ({flashcardsWords?.filter(e => e.rank_occ && e.rank_occ < 1500)?.length} done)</div>
                <div className='flex text-xs gap-1 flex-wrap p-2 bg-pink-100 rounded-xl'>{next100.map((w,i) => {return <div>
                    {w.word}
                </div>})}</div>
                <div onClick={async() => {
                    next100.forEach(async w => {
                     const lemma = await getLemma(w.word, selectedWp.name, ct)
                     const _card = findWord(realCards, clean(lemma))
                     if(_card) {
                         
                         const newCard = {..._card, inflexions: [..._card.inflexions || [], w.word]}
                         updateCard(newCard)
                       
                         console.log('_card 🟢🟢🟢🟢🟢🟢🟢🟢', _card)
                         return
                        
                     }
                  
                  
                     addFromFQ(lemma)
                    })
                }}>Ajouter 100 mots </div>
                <div className='flex gap-1 flex-wrap'>{text_50k2?.slice(0, 5000).map((w,i) => {
                  
                    return <div onClick={async() => {
                       

                        if (mode == "add"){
                            const lemma = await getLemma(w.word, selectedWp.name, ct)
                            const _card = findWord(realCards, clean(lemma))
                            if(_card) {
                                
                                const newCard = {..._card, inflexions: [..._card.inflexions || [], w.word]}
                                updateCard(newCard)
                              
                                console.log('_card 🟢🟢🟢🟢🟢🟢🟢🟢', _card)
                                return
                               
                            }
                         
                         
                            addFromFQ(lemma)
                        }
                        if (mode == "gram") {
                            ct.setfqlist( fq => ({...fq, gram: [...fq?.gram || [], w.word]}))
                           
                            updateDoc(doc(db, 'fq', selectedWp.name), {gram: [...ct.fqlist?.gram || [], w.word]});
                        }
                        if (mode == "del") {
                            console.log('selectedWp.name', selectedWp.name)
                            console.log('fq', ct.fqlist)
                            console.log('added', w.word)
                            ct.setfqlist( fq => ({...fq, del: [...fq?.del, w.word]}))
                            updateDoc(doc(db, 'fq', selectedWp.name), {del: [...ct.fqlist?.del || [], w.word]});
                            
                        }
                        
                    }} className={`${w.isInCards ? "bg-green-500":  (w.isInDeck || w.isDel || w.isGram) ? "bg-cyan-500" : "bg-red-500"}`} >{w.word} </div>
                })}</div>

                <div className='' onClick={() => console.log('Text_50k', text_50k)}>50k</div> <div>{text_50k2?.length}</div>
                {/* {text_50k?.slice(0, 1000)} */}


            </div>}
            {page == "Words" && selectedWp && 
            <div className='p-16 py-8 pb-24'>
                <input id="section_id" placeholder='section_id' className='border-2 px-4 py-1' />
                <input id="categorie_id" placeholder='categorie_id' className='border-2 px-4 py-1' />
                <textarea id="new-data"  className='border-2 w-full px-4 py-1 h-[200px]'></textarea>
                <div onClick={() => {addNewCards()}} className='border-2 px-4 py-1'>Envoyer</div>
               
                <div className='mb-4'>
                    <h2>Filter</h2>
                
                    <div className='flex gap-8 text-slate-500 italic mb-2'>
                        <div>
                        Type de mot
                        <div className='flex gap-2'>{filters?.wt?.map(filter => <div onClick={() => setFilters({...filters, wt: filters.wt?.map(f => f.value == filter.value ? {...f, s: !filter.s}: f)})} className={`${filter.s ? "bg-slate-100 text-slate-500 border-2 border-slate-400" : "text-slate-300 bg-slate-50"} mb-1 px-2 py-1 rounded border b`}>{filter.name}</div>)}</div>
                        </div>

                        <div>
                        Mot visuel 
                        <div className='flex gap-2'>{filters?.visual?.map(filter => <div onClick={() => setFilters({...filters, visual: filters.visual?.map(f => f.value == filter.value ? {...f, s: !filter.s}: f)})} className={`${filter.s ? "bg-slate-100 text-slate-500 border-2 border-slate-400" : "text-slate-300 bg-slate-50"} mb-1 px-2 py-1 rounded border b`}>{filter.name}</div>)}</div>
                        </div>

                        <div>
                        Contexte
                        <div className='flex gap-2'>{filters?.context?.map(filter => <div onClick={() => setFilters({...filters, context: filters.context?.map(f => f.value == filter.value ? {...f, s: !filter.s}: f)})} className={`${filter.s ? "bg-slate-100 text-slate-500 border-2 border-slate-400" : "text-slate-300 bg-slate-50"} mb-1 px-2 py-1 rounded border b`}>{filter.name}</div>)}</div>
                        </div>
                        <div className='block'>
                        <div onClick={() => {
                            compareWordsWithoutAccents("café", "cafe")
                            compareWordsWithoutAccents("café", "cafey")
                            compareWordsWithoutAccents("Montaña", "Montana")
                            compareWordsWithoutAccents("Corazón", "Corazon")
                            
                            }}>Section Id</div>
                        <input onBlur={(e) => {setFilters({...filters, section_id: e.target.value})}} className='border-2 px-4 py-1 w-full block h-[36px]' defaultValue={filters.section_id}/>
                        </div>

                    </div>
              
                </div>

                <div onClick={() => {loadContext(setContexts)}}>GET CONTEXT ({contexts?.length})</div>

                <div className='flex flex-wrap gap-2 items-center  mb-4'>

                    <div onClick={() => getUniverselCards()}>
                    <div className="bg-green-500 text-white border-2 b font-bold px-3 py-1 rounded-md border-b-4 border-green-600 inline-block ">Get universal DB ({Universalcards.length})</div>
                    </div>
                    <div>
                    {/* <div onClick={() => {cards.forEach(c => deleteCard(c))}} className="bg-red-500 text-white border-2 b font-bold px-3 py-1 rounded-md border-b-4 border-green-600 inline-block ">Suppr</div> */}
                    </div>
                    {/* <div onClick={() => {
                        const filteredCards = cards.filter(c => c.model == "lang-en" &&  c.s_id == filters.section_id).sort((a, b) => a.c_id?.localeCompare(b.c_id))?.slice(0,900)
                        const contextPhrase = filteredCards?.map(c => c.contexts[0]?.text)
                        const terms = filteredCards?.map(c => c.term)
                        console.log("contextPhrase",contextPhrase)
                        console.log("terms",terms)
                        console.log('lang', selectedWp.lang)
                        contextPhrase.forEach(t => speak({text:t, lang: ct.workspace?.lang, upload: true}))
                        
                    }} className="bg-amber-500 text-white border-2 b font-bold px-3 py-1 rounded-md border-b-4 border-amber-600 inline-block ">{Universalcards.length - cards.filter(c => c.mode =="lang-en").length} premier mots en {selectedWp?.name}</div> */}


                    <div onClick={() => delDoublon(realCards)} className="bg-amber-500 text-white border-2 b font-bold px-3 py-1 rounded-md border-b-4 border-amber-600 inline-block ">Supprimer doublon (clean)</div>


                    <div 
                    onClick={() => {
                        // realCards.filter(c => c.ct_id).forEach(c => {
                        //     updateCard({...c, ct_id: null})
                        // })
                        //clean
                        const noContextCards = realCards?.filter(c => !c.ct_id && (c.img || c.img_400)).slice(0,500)
                        const lang = selectedWp?.lang?.split('-')?.[0]
                        if (!lang) return
                        
                        noContextCards.forEach(async (card) => {  
                            const img = card.img_400 || card.img 
                            const cardContext = card.contexts[0]
                            const contextExist = contexts.find(context => context.img == img && context.fr == cardContext.trad)
                         
                            if (contextExist) {
                                // console.log('update contexte 🟣🟣', newContext)
                                // const newContext = {
                                //     img,s
                                //     fr: cardContext.trad,
                                //     [`${lang}`]: cardContext.text,
                                //     [`${lang}-id`]: card.id,
                                //     cd: new Date(),   
                                // }

                            } else {

                                const newContext = {
                                    img,
                                    fr: cardContext.trad,
                                    [`${lang}`]: cardContext.text,
                                    [`${lang}-id`]: card.id,
                                    cd: new Date(),

                                    
                                }
                                console.log('nouveau contexte 🟢🟢', newContext)
                                const docRef = await addDoc(collection(db, "contexts"), newContext);
                                updateCard({...card, ct_id: docRef.id})
                                
                            }
                            
                        })
                    }}
                    className="bg-pink-500 text-white border-2 b my-2 font-bold px-3 py-1 rounded-md border-b-4 border-pink-600 inline-block ">UPDATE CONTEXTE {selectedWp?.lang?.split('-')?.[0]} ({realCards?.filter(c => !c.ct_id && (c.img || c.img_400))?.length})

                    </div>
                    



            
                        {text_50k && <div className='px-2 rounded-xl border-2 ' onClick={() => {
                             realCards.forEach(async (card) => {  !card.occ_tt && setRank(card)})
                        }} >SET RANK ({realCards.filter(c => !c.occ_tt)?.length})</div>}


                    <div className='' onClick={async() => {
       
                            try {
                                // Step 1: Fetch all cards from the collection
                                console.log('card sans id')
                                const cardsCollection = collection(db, 'cards');
                                const q = query(cardsCollection, where('id', '==', null)); // Query for documents where 'id' is missing
                            
                                const querySnapshot = await getDocs(q);
                            
                                // Step 2: Iterate over each document and add 'id' field if missing
                                const updatePromises = querySnapshot.docs.map(async (docSnap, i) => {
                                    console.log('id : ' +  i, docSnap.id)
                                const cardDocRef = doc(db, 'cards', docSnap.id);
                                await updateDoc(cardDocRef, { id: docSnap.id });
                                });
                            
                                // Wait for all update operations to complete
                                await Promise.all(updatePromises);
                            
                                console.log('All cards updated with id field where necessary.');
                            } catch (error) {
                                console.error('Error updating cards with id field: ', error);
                            }
                          
                    }}>carte sans ids</div>
                    <div onClick={async() => {
                          console.log('all production for ', selectedWp.id)
                        const q = query(collection(db, 'cards'), where('workspace_id', "==", selectedWp.id), limit(3000))
                        const querySnapshot = await getDocs(q);
                        const cards = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
                        .filter(card => card.deck_id != "draft")
                        .filter(c => !c.s_id && !c.cm)
                        .filter(card => !card.deck_id)
                        .sort((a,b) => a.rank - b.rank)
                        .sort((a, b) => (b.occ_tt || 0) - (a.occ_tt || 0))

                        console.log('liste des cartes à apprendre trier ', cards.map(c => c.term))
      

                        const global_decks = (await getDocs(query(collection(db, 'decks'), where("isGlobal", "==", true)))).docs.map(doc => ({...doc.data(), id: doc.id}));

                        // console.log('global_decks', global_decks)
                        const cards_with_id =  querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id})).filter(card => card.deck_id).filter(card => global_decks.find(d => d.id == card.deck_id)).sort((a, b) => (b.occ_tt || 0) - (a.occ_tt || 0))
                        // fitrage global
                        const grouped_by_global_deck = cards_with_id.reduce((acc, card) => {
                            const deck = acc.find(d => d.deck_id === card.deck_id);
                            if (deck) {
                              deck.cards.push(card.id);
                            } else {
                              acc.push({ deck_id: card.deck_id, cards: [card.id] });
                            }
                            return acc;
                          }, [])

                         const cards_all = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
                    
                        .sort((a, b) => (b.occ_tt || 0) - (a.occ_tt || 0))

                        const filterDef = (more) => filterDefinitions(more)?.filter(def => {
                            const freq = parseInt(def.freq?.replace('%', ''), 10)
                             return !(freq && freq < 11) 
                        }).map(d => d.trad_fr).join(',')

                        const dico_term_inflexions = cards_all.map(c => `${c.id}|${c.term}|${filterDef(c.more)}|${c.inflexions?.join(',')}`).join(';')
                        // console.log('dico_term_inflexions', dico_term_inflexions)


                        // console.log('grouped_by_global_decks', grouped_by_global_deck)
                        
                        const cards_map = cards.map(card => card.id).join(';')
                       
     
                        const card_map_exist = (await getDocs(query(collection(db, 'card_map'), where("wp_id", "==", selectedWp.id)))).docs;

                        if (card_map_exist.length > 0) {

                          const existingDocRef = doc(db, 'card_map', card_map_exist[0].id);
                          await setDoc(existingDocRef, {
                            wp_id: selectedWp.id,
                            cards_map,
                            grouped_by_global_deck,
                            dico_term_inflexions,
                          }, { merge: true });
                          console.log('Document updated successfully!');
                        } else {
                   
                          const newDocRef = doc(collection(db, "card_map"));
                          await setDoc(newDocRef, {
                            wp_id: selectedWp.id,
                            cards_map,
                            grouped_by_global_deck,
                            dico_term_inflexions,
                          }, { merge: true });
                          console.log('New document created successfully!');
                        }
                          

                         
                    }}>CARD MAP</div>
                  
                    
                    <div onClick={() =>  {
                    //    cards.filter(card => !card.img_400).forEach(card => reduceImg(card))
                        //  const filteredCard =  cards.filter(c => c.model == "lang-en" ).slice(0,1500)
                        //  console.log('filteredCard', filteredCard)

                        //  const bugTrad = filteredCard.filter(card => typeof card.trad !== "string")
                        //  bugTrad.forEach(card => {
                        //     console.log('card',card.trad)
                        //  })
                      
                      
                         realCards.slice(0,3000).forEach(async (card) => {
                            // console.log(`${card.term} (${card.trad}) : ${card.contexts[0]?.text} (${card.contexts[0]?.trad})`)
                            !card.more && addMore(card)
                            // !card.more  && console.log('card !!', card)
                            card.more && !card.bc_id && setCategory(card)
                            card.more && !card.bc_id && console.log('card', card.term)
                           !card.occ_tt && setRank(card)

                           // getInflexion(card)
                            // const cardRef = doc(db, "cards", card.id);
                           /// await updateDoc(cardRef, { hint: null });
                           
                        })
                    }
                        } className="bg-green-500 text-white border-2 b font-bold px-3 py-1 rounded-md border-b-4 border-green-600 inline-block ">HINTE ADD MORE ({realCards.filter(c => !c.more)?.length})</div>
                

                    <div>
                    <div onClick={() => fillContextAll(realCards, completeCard)} className="bg-green-500 text-white border-2 b font-bold px-3 py-1 rounded-md border-b-4 border-green-600 inline-block ">FillContextAll REal ({
                    realCards.filter(c => c.model == "lang-en" ).filter(card => {
        const notContext = transformText(card.contexts && card.contexts[0]?.text)
        const googcontext = notContext?.includes(card.term?.toLowerCase())
        return !googcontext
        }).length
        })</div>
                    </div>

                    <div>
                    <div onClick={() => addCard()} className="bg-green-500 text-white border-2 b font-bold px-3 py-1 rounded-md border-b-4 border-green-600 inline-block ">Ajouter une carte</div>
                    </div>
                    </div>

          
                <div className='my-2 rounded-xl border overflow-scroll max-h-[50vh]'>
                    <div className='border-b-2 bg-slate-100 p-4 flex gap-4 text-slate-500 font-600 uppercase text-sm w-fit'>
                        <div className='text-slate-400 w-[20px]'>#</div>
                        <div className='w-[80px] rounded-md'>Img</div>
                        <div className='w-[250px]'>Term</div>
                        <div className='w-[40px]'>Visuel</div>
                        <div className='w-[40px] '>Univ.</div>
                        <div className='w-[800px] '>Contexte</div>
                        <div className='w-[100px] '>Deck</div>
                        <div className='w-[300px] '>Astuce</div>
                        <div className='w-[100px] '>hint img</div>
                        <div className='w-[300px] '>Inflexion</div>
                        <div className='w-[100px] '>Last update</div>
                    </div>
                   
                    {realCards.filter(c => !c.isGram).map((card, index) =>  {
                        // cards.filter(c => c.model == "lang-en" &&  c.s_id ).sort((a, b) => a.c_id?.localeCompare(b.c_id))?.slice(0,300).map((card, index) => {

                        const homonyme = cards.filter(c => (c.term == card.term && card.id != c.id))
                        const notContext = transformText(card.contexts && card.contexts[0]?.text)
                        const googcontext = notContext?.includes(card.term?.toLowerCase())
                        const isDraft = card.deck_id == "draft" 
                        const noUniversal = !card.original_card 

                        const bug = bugs.find(b => b.card_id == card.id)

                        const isFinished = !isDraft && !noUniversal && googcontext

                        // if (isFinished) return 



                        i2 ++ 
             
                        return <CardRender  
                        setCards={setCards}
                        setContexts={setContexts}
                        contexts={contexts}
                        ct={ct}  
                        completeCard={completeCard}  
                        index={index}  
                        findHint={findHint} 
                        setRank={setRank}  
                        internationalizeDe={internationalizeDe}  
                        updateCard={updateCard}  
                        selectedWp={selectedWp}  
                        i2={i2}  
                        bug={bug}  
                        addMore={addMore}  
                        deleteCard={deleteCard}  
                        setCategory={setCategory} 
                      
                        addToInternational={addToInternational} 
                        addToUniversal={addToUniversal} 
                        changeImageforAll={changeImageforAll} 
                        addImg={addImg} 
                        getInflexion={getInflexion} 
                        homonyme={homonyme}  
                        googcontext={googcontext} 
                        card={card} />
                })})}
                
                </div>
                </div>}

                            






















            {page == "Contexte" && <div className='p-16 py-8 pb-24'>

                <div onClick={() => {loadContext(setContexts)}}>Charger Context</div>
                <div onClick={() => {contexts?.filter(c => !c.de).slice(0, 300).forEach((context) => {
                    if (!context.de) {
                        translateCt(context, "allemand", "de")
                    }
                })}}>Traduire 300 cartes en Allemand ({contexts?.filter(c => c.de)?.length} déjà traduit)</div>

                Liste des contextes : 

                
                {contexts?.map(context => <ContextCard context={context} />)}
                </div>}

            {page == "Universel" && <div className='p-16 py-8 pb-24'>
                <div onClick={() => {setPage('deck_u'); getDecks("international")}}>Deck</div>
                <div className='flex gap-4 mb-4'>

                    <div onClick={() => getUniverselCards()}>
                    <div className="bg-purple-500 text-white border-2 b font-bold px-3 py-1 rounded-md border-b-4 border-green-600 inline-block ">Get universal DB ({Universalcards.length})</div>
                    </div>
                    <div>
                    <div onClick={() => resetRank(Universalcards)} className="bg-green-500 text-white border-2 b font-bold px-3 py-1 rounded-md border-b-4 border-green-600 inline-block ">Reset Ranks</div>
                    </div>



                    <div>
                    <div onClick={() => {
                        // univCards.forEach(card => {
                        //     if(!cards.find(c => c.original_card == card.id)) {internationalizeDe(card)}
                        // })
                        // univCards.filter(e => e.trad?.split(',')?.length > 1).forEach(card => {
                        //     console.log('card', card.trad)
                        //      const cardRef = doc(db, 'cards', card.id);
                        //     updateDoc(cardRef, { trad: card.trad.split(',')[0] });

                        // })

                          univCards.slice(0,1000).forEach(card => {
                            if(!cards.find(c => c.original_card == card.id)) {
                                 internationalizeDe(card)
                                // console.log('go pour', card.trad)
                            }

                        })

                      
                    }} className="bg-green-500 text-white border-2 b font-bold px-3 py-1 rounded-md border-b-4 border-green-600 inline-block ">TEST GLOBAL</div>
                    </div>

                    <div>
                    <div onClick={() => fillContextAll(Universalcards)} className="bg-green-500 text-white border-2 b font-bold px-3 py-1 rounded-md border-b-4 border-green-600 inline-block ">FillContextAll ({
                        Universalcards.filter(card => {
                            const notContext = transformText(card.contexts[0]?.text)
                            const googcontext = notContext?.includes(card.term?.toLowerCase())
                            return !googcontext
                            }).length
                    })</div>
                    </div>

                    <div>
                    </div>

                    <div>
                    <div onClick={() => addCardsUniversal()} className="bg-yellow-500 text-white border-2 b font-bold px-3 py-1 rounded-md border-b-4 border-green-600 inline-block ">Ajouter les cartes</div>
                    </div>
                    </div>
                    <input id="newDataCategorie" className='p-2 bg-yellow-100 ' placeholder='Section_id'  />
                    <textarea id="newData" className='p-2 bg-pink-100 focus:h-[60vh] overflow-scroll'></textarea>
             

                <div className='my-2 rounded-xl border overflow-scroll max-h-[65vh]'>
                    <div className='border-b-2 bg-purple-100 p-4 flex gap-4 text-slate-500 font-600 uppercase text-sm w-fit'>
                        <div className='text-slate-400 w-[20px]'>#</div>
                        <div className='w-[80px] rounded-md'>Img</div>
                        <div className='w-[120px]'>Term</div>
                        <div className='w-[40px]'>Visuel</div>
                        <div className='w-[120px]'>Dans {selectedWp?.name}</div>
                        <div className='w-[800px]'>Contexte</div>
                        <div className='w-[100px]'>Deck</div>
                        <div className='w-[100px] '>Last update</div>
                    </div>
                   
                    {univCards.map((card, index) => {
                        const homonyme = Universalcards.filter(c => (transformText(c.trad) == transformText(card.trad) && card.id != c.id))
                        const notContext = transformText(card.contexts[0]?.trad)
                        const googcontext = notContext?.includes(typeof card.trad === "string" && card.trad?.toLowerCase())
                        
                     
                        return <div key={card?.id+"-univ-"+(index+Universalcards.length)} className={`${homonyme.length ? "bg-yellow-50" : !googcontext ? "bg-red-100" : "bg-white"}  border-b p-2 group flex gap-4 hover:bg-blue-50 w-fit relative `}>
                            <div className='absolute -bottom-2 gap-2  hidden justify-start group-hover:flex p-1 '>
                            
                                <div onClick={() => getInflexion(card)}  className='ml-[15px] b px-2 border hover:bg-white rounded py-1 text-[8px] text-slate-500'>getinflexion</div> 
                                <div onClick={() => completeCardUniversal(card)}  className='ml-[15px] b px-2 border hover:bg-white rounded py-1 text-[8px] text-slate-500'>completeCard univ</div> 
                                <div onClick={() => findHint(card)}  className='ml-[15px] b px-2 border hover:bg-white rounded py-1 text-[8px] text-slate-500'>Hint</div> 
                                <div onClick={() => deleteCard(card)}  className='ml-[15px] b px-2 border hover:bg-white rounded py-1 text-[8px] text-slate-500'>Supprimer</div> 


                             
                                </div> 
                            <div className='text-slate-400 w-[80px] flex gap-2'><div className='text-blue-200'>{index}</div>
                            
                            <input onBlur={(e) => updateCard({...card, rank: e.currentTarget.value})}  className={`text-blue-500 text-[12px] w-[40px]`} defaultValue={card.rank}/>
                            </div>
                            <input onBlur={(e) => updateCard({...card, img: e.currentTarget.value})}  className={`text-gray-300 text-[4px] w-[20px]`} defaultValue={card.img}/>
                            
                            <img onClick={() => {findImage(card)}} src={card.img_400 || card.img} className={`b hover:scale-150 bg-slate-200 h-[40px] w-[40px] rounded-md border ${!card.img_pexel ? "border-red-500" : "border-slate-500"}`} />
                            <svg onClick={() => 
                                addImg(card, `
                                Donne moi une image qui peut représenter le terme '${card.term}' dans le style illustration morderne, simple, clean lines avec un fond léger (pas trop chargé ou neutre). 
                                Si tu ne trouve pas d'image pour le term tu peux t'aider de la phrase suivante '${card.contexts[0].text}'. 
                                Aucuns mots ni écriture, ni lettre ne doit ne doit apparaitre sur l'image !!`
                             )}
                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-amber-500 hover:scale-110 cursor-pointer">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                            </svg>
                            {/* <div onClick={() => reduceImg(card)} className={`${card.img_400 ? "" : "text-red-400"}`}>mini</div> */}


                            {/* {!card.img_pexel && <svg onClick={() => {
                                const selectedExportedCard = cards?.find(c => c.original_card == card.id)
                                selectedExportedCard && updateCard({...card, img: selectedExportedCard?.img, img_pexel: card.img})
                                console.log("selectedExportedCard", selectedExportedCard)
                            }}
                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-purple-500 hover:scale-110 cursor-pointer">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                            </svg>} */}
                            <div className={'flex-col flex  '}>
                                <input onBlur={(e) => updateCard({...card, trad: e.currentTarget.value})} className={`text-purple-500 w-[120px]`} defaultValue={card.trad} />
                                {/* <input onBlur={(e) => updateCard({...card, term: e.currentTarget.value})}  className={` italic text-gray-500 text-xs w-[120px]`} defaultValue={card.term}/> */}
                            </div>
                            <div className=' w-[40px]'><div onClick={() => updateCard({...card, isVisual: !card.isVisual})} className={`${card.isVisual ? 'bg-green-500' : 'bg-purple-500'} h-4 w-4 b rounded-full`}></div></div>
                            <div className=' w-[40px]'><div onClick={() => {
                                if(!cards.find(c => c.original_card == card.id)) {internationalizeDe(card)}
                                }} className={`${cards.find(c => c.original_card == card.id) ? 'bg-purple-500' : 'bg-slate-100'} h-4 w-4 b rounded-xl`}></div></div>

                            <div className='flex-col flex gap-2  '>
                                {card.contexts?.map((ct,i) => <div  key={`ct${card.id}-${i}-${ct?.text}-${index}`} className=' flex items-start gap-4 w-[800px]'>
                                  
                                    {/* <div className='text-blue-500 text-sm  w-[100px]'>{ct.meaning}</div> */}
                                    <input  key={`meaning_u${card.id}-${i}-${ct?.text}-${index}`}
                                        className={`italic text-blue-500 text-[10px]`} 
                                        defaultValue={ct.meaning} 
                                        onBlur={(e) => {updateCard({...card, contexts: card.contexts.map(c => ct.text == ct.text ? {...c, meaning: e.currentTarget.value} : c)})}}
                                        />
                                    <div className='w-[700px] flex flex-col'>
                                    <input  key={`trad_u${card.id}-${i}-${ct?.text}-${index}`}
                                            className={`italic text-purple-500 text-xs"} `}
                                            defaultValue={ct.trad} 
                                            onBlur={(e) => {updateCard({...card, contexts: card.contexts.map(c => ct.trad == ct.trad ? {...c, trad: e.currentTarget.value} : c)})}}

                                           />
                                        <input  key={`text_u${card.id}-${i}-${ct?.text}-${index}`}
                                        className={`order-2 italic text-gray-500 text-xs`} 
                                        defaultValue={ct.text} 
                                        onBlur={(e) => {updateCard({...card, contexts: card.contexts.map(c => ct.text == ct.text ? {...c, text: e.currentTarget.value} : c)})}}
                                        />
                                       
                                    </div>
                
                                </div>)}
                            </div>
                            <input onBlur={(e) => updateCard({...card, deck_id: e.currentTarget.value})} className='w-[100px] text-slate-500' defaultValue={card.deck_id}/>
                            <div className='w-[300px] text-purple-500'>{card.inflexions?.join(',')}</div>
                            <input placeholder='hint' onBlur={(e) => updateCard({...card, hint: e.currentTarget.value})} className='w-[400px] text-pink-500 text-sm' defaultValue={card.hint}/>
                            <input onBlur={(e) => updateCard({...card, hint_img: e.currentTarget.value})} className='w-[100px] text-slate-500' defaultValue={card.hint_img}/>
                        


                            <img src={card.hint_img} className='b hover:scale-150 bg-slate-200 h-[40px] w-[40px] rounded-md' />
                            <div className='w-[300px] '>Astuce</div>
                       
                            <div className='w-[300px] text-[6px] italic text-purple-500'>{card.prod_id}</div>
                            <div className='text-blue-500 text-sm  w-[100px]'>{card.lastUpdate?.seconds}</div>
                            <input onBlur={(e) => updateCard({...card, model: e.currentTarget.value})} className={`italic text-gray-500 text-xs"} w-[200px] `} defaultValue={card.model} />

                        </div>
                    })}
                </div>


              
            </div> }

            {(page == "Decks" || page == "deck_u") && <div className='  pb-24 flex'>
                <div className='mb-4 max-w-[300px] min-w-[300px] p-4 pb-[300px] h-screen overflow-scroll '>
                    <h2>Liste des decks {page == "deck_u" && "Universel"}</h2>
                    {decks.sort((a, b) => a.order - b.order).map(deck => {
                        return <div key={page+deck.id} onClick={(e) => {setSelectedDeck(deck); console.log('deck', deck)}}  className={`${deck.presentation_mode == "Grammar" ? 'text-pink-400' : 'text-slate-400'} flex justify-between h-[30px] overflow-hidden group p-1 px-4 hover:bg-slate-100 cursor-pointer rounded`}>
                            {deck.name} 
                            <div onClick={() => deleteDeck(deck)} className='h-6 w-6 flex items-center  hidden b justify-center group-hover:flex text-[10px] border rounded-full'>x</div>
                        </div>
                    })}
                    <div onClick={() => addDeck()} className='p-1 px-4 hover:bg-slate-100 cursor-pointer rounded'>Ajouter</div>
                </div>
                <div  className='min-w-[500px] grow bg-slate-50 p-16 '>
                    
                    <input key={`name_${selectedDeck?.id}`} placeholder='name' className='bg-transparent text-2xl font-bold w-full' onChange={(e) => {setUpdatedDeck({...selectedDeck, name: e.currentTarget.value})}} value={selectedDeck?.name} />
                    <input key={`color_${selectedDeck?.id}`} placeholder='color' className='bg-transparent text-slate-700 w-full' onChange={(e) => {setUpdatedDeck({...selectedDeck, color: e.currentTarget.value})}} value={selectedDeck?.color} />
                    <input key={`type_${selectedDeck?.id}`} placeholder='type' className='bg-transparent text-slate-700 w-full' onChange={(e) => {setUpdatedDeck({...selectedDeck, type: e.currentTarget.value})}} value={selectedDeck?.type} />
                    <input key={`order_${selectedDeck?.id}`} placeholder='order' className='bg-transparent text-slate-700 w-full' onChange={(e) => {setUpdatedDeck({...selectedDeck, order: e.currentTarget.value})}} value={selectedDeck?.order} />
                    <input key={`parent_id_${selectedDeck?.id}`} placeholder='parent_id' className='bg-transparent text-slate-700 w-full' onChange={(e) => {setUpdatedDeck({...selectedDeck, parent_id: e.currentTarget.value})}}  value={selectedDeck?.parent_id} />
                    <input key={`section_id_${selectedDeck?.id}`} placeholder='section_id' className='bg-transparent text-slate-700 w-full' onChange={(e) => {setUpdatedDeck({...selectedDeck, s_id: e.currentTarget.value})}} value={selectedDeck?.s_id} />
                    <input key={`words_${selectedDeck?.id}`} placeholder='words' className='bg-transparent text-slate-700 w-full' onChange={(e) => {setUpdatedDeck({...selectedDeck, words: e.currentTarget.value})}} value={selectedDeck?.words} />

                    <input key={`presentation_mode_${selectedDeck?.id}`} placeholder='presentation_mode' className='bg-transparent text-slate-700 w-full' onChange={(e) => {setUpdatedDeck({...selectedDeck, presentation_mode: e.currentTarget.value})}} defaultValue={selectedDeck?.presentation_mode} />
                    {selectedDeck?.presentation_mode == "Grammar" &&  <input key={`inflexion_${selectedDeck?.id}`} placeholder='inflexion_mode' className='bg-transparent text-slate-700 w-full' onChange={(e) => {setUpdatedDeck({...selectedDeck, inflexions: e.currentTarget.value.split(',')})}} defaultValue={selectedDeck?.inflexions?.join(',')} />}


                    <div className="text-[8px] text-slate-400">{selectedDeck?.id}</div>
                    {/* <div className='border-2 px-3 py-1 my-2 rounded-xl inline-block mr-2 b'>
                        {selectedDeck?.presentation_mode}
                    </div> */}

                    <div onClick={() => {setUpdatedDeck({...selectedDeck, visible: !selectedDeck.visible || true})}} className={`${selectedDeck?.visible ? "bg-blue-500 text-white" : ""} border-2 px-3 py-1 my-2 rounded-xl b inline-block`}>
                        Visible
                    </div>
                    {/* <div>
                        {selectedDeck?.color}
                    </div> */}
                    <div className='border-b my-4'></div>

                    {selectedDeck?.presentation_mode == "Grammar" && <div className='p-4 '>
                    <SuperEditor  
                        key={`content_${selectedDeck.id}`}
                        content={selectedDeck?.content}
                        onBlur={(e) => {
                          // update deck
                          setUpdatedDeck({...selectedDeck, content: e })

                            }}
                        onChange={(e) => {}} />     
                            <textarea id="addExercice" className='focus:h-[200px] w-full rounded bg-slage-50 mt-4 p-4 border' placeholder='exercice à ajouter'></textarea>
                            <div onClick={() => addExercice()} className=''>Ajouter les exercices</div>
                        </div>
                    
                        }

                    
                    {selectedDeck?.presentation_mode == "MindCard" && <div>
                    <input className='bg-transparent text-[6px] text-slate-300 italic w-full ' onChange={(e) => {setUpdatedDeck({...selectedDeck, mindcard: {...selectedDeck.mindcard, img: e.currentTarget.value}})}} value={selectedDeck?.mindcard?.img} />

                         <MindMap key={selectedDeck.id} cardsInDecks={Universalcards} context={ct} deck={selectedDeck} cards={Universalcards} />
                        </div>}
                    {selectedDeck?.presentation_mode == "Dialogue" && <div className='p-4 '>
                        <div className='flex justify-between mb-4 gap-4'>
                            <div className='grow'>
                                <h2>Dialogue (contient {cards.filter(c => c.deck_id == selectedDeck?.id).length}) [+{removeDuplicateWords(transformText(selectedDeck?.dialog?.en)?.split(" ")?.filter(e => !e == " ")?.filter(word => filteredWords?.includes(word))).length}]</h2>
                                <input className='bg-transparent text-[6px] text-slate-300 italic w-full ' onChange={(e) => {setUpdatedDeck({...selectedDeck, dialog: {...selectedDeck.dialog, img: e.currentTarget.value}})}} value={selectedDeck?.dialog?.img} />

                            </div>
                            <img className='w-[100px] rounded-xl' src={selectedDeck?.dialog?.img}/>
                        </div>
                        <div className=' '>
                            <textarea key={"en"+selectedDeck.id} className='bg-transparent text-sm w-full p-2 rounded-xl bg-slate-100 border min-h-[200px]' style={{ height: 'auto' }} onBlur={(e) => {setUpdatedDeck({...selectedDeck, dialog: {...selectedDeck.dialog, en: e.currentTarget.value}})}}>
                                {selectedDeck?.dialog?.en}
                            </textarea>

                            <textarea key={"fr"+selectedDeck.id} className='bg-transparent text-sm w-full p-2 rounded-xl bg-slate-100 border min-h-[200px]' style={{ height: 'auto' }} onBlur={(e) => {setUpdatedDeck({...selectedDeck, dialog: {...selectedDeck.dialog, fr: e.currentTarget.value}})}}>
                                {selectedDeck?.dialog?.fr}
                            </textarea>
                            {/* <div contentEditable 
                               // onBlur={(e) => console.log(e.currentTarget.innerText)} 
                                onBlur={(e) => setUpdatedDeck({...selectedDeck, dialog: {...selectedDeck.dialog, en: e.currentTarget.innerText}})} 
                                className="text-blue-600 mb-4 p-2 rounded-xl bg-slate-100">{highlightedWords(selectedDeck?.dialog?.en, cardFiltered.map(c => c.term), cardFiltered.map(c => c.term))}</div>
     */}
                            {/* <div className='text-slate-500 text-sm italic p-2 rounded-xl bg-yellow-100' contentEditable onBlur={(e) => setUpdatedDeck({...selectedDeck, dialog: {...selectedDeck?.dialog, fr: e.currentTarget.innerText}})} >{selectedDeck?.dialog?.fr}</div> */}
                            <div className='flex gap-1 flex-wrap'>
                            {removeDuplicateWords(transformText(selectedDeck?.dialog?.en)?.split(" ")?.filter(e => !e == " ")?.filter(word => filteredWords?.includes(word))).map(word => <span onClick={() => addWordToDeck(word, selectedDeck.id)} className='mr-2 text-amber-500 px-4 py-2 rounded-xl hover:scale-110 border border-b-2 '>{word}</span>)}
                            {removeDuplicateWords(transformText(selectedDeck?.dialog?.en)?.split(" ")?.filter(e => !e == " ")?.filter(word => filteredWordsInflexions?.includes(word))).map(inflex => <span onClick={() => addWordToDeck(findWord(inflex)?.term, selectedDeck.id)} className='mr-2 hover:scale-110 text-purple-500 px-4 py-2 rounded-xl border border-b-2 '>{inflex}</span>)}
                            <hr className='my-4 w-full'/>
                            {removeDuplicateWords(transformText(selectedDeck?.dialog?.en)?.split(" ")?.filter(e => !e == " ")?.filter(word => !filteredWords?.includes(word) && !filteredWordsInflexions?.includes(word))).map(word => <span onClick={() => createWord(word, page == "deck_u"? "international" :selectedDeck.id)} className='mr-2 hover:scale-110 text-sm text-gray-500 px-4 py-2 rounded-xl border border-b-2 bg-yellow-50'>{word}</span>)}
                          

                            </div>
                            {/* <div>all words</div>
                            {filteredWords?.map(word => <span className='mr-2'>{word}</span>)} */}
                        </div>
                        <div className='flex flex-wrap'></div>
                    </div> }

                    <div className='flex flex-wrap gap-2'>
                        {cards.filter(c => c.deck_id == selectedDeck?.id).map(card => <div
                        onClick={() => deleteCard(card)} 
                        className='hover:bg-red-100 w-full border px-3 py-1'>
                            <div>{card.term.split('|')[0]}</div>
                            <div className='text-slate-400 text-sm'>{card.term.split('|')[2]}</div>
                            <div>{card.term.split('|')[1]}</div>
                            <div className='text-green-500'>{card.term.split('|')[3]}</div>
                           
                        </div>)}
                    </div>


                    <div className='flex flex-wrap gap-2'>
                        {cards.filter(c => c.deck_id == selectedDeck?.id).map(card => <input
                        // onClick={() => deleteCard(card)} 
                        key={card.id}
                        onBlur={(e) => updateCard({...card, term: e.currentTarget.value})}
                        className='rounded-xl hover:bg-red-300 w-full border-2 px-3 py-1' defaultValue={card.term} />)}
                    </div>
                    {page == "deck_u" && <div onClick={() => createUniversalDeck(selectedDeck)} className='b border-b-4 bg-purple-500 text-white px-4 py-2 rounded-xl inline-block border-2'>Ajouter dans les autres decks</div>}
                    {updatedDeck && <div onClick={() => updateDeck(updatedDeck)} className='b border-b-4 bg-purple-500 text-white px-4 py-2 rounded-xl inline-block border-2'>Modifier</div>}
                    {/* {selectedDeck && <div onClick={() => ct.fire.addDeck({...selectedDeck, workspace_id: "allemand"}, ct)} className='b border-b-4 bg-purple-500 text-white px-4 py-2 rounded-xl inline-block border-2'>Dupliquer</div>} */}

                </div>

                </div>
            }
            
        </div>
      );
    };

export {AdminScreen};


