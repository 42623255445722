
import { useState, useEffect } from "react";
import { db, app } from "../../config.js";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";


import {displayDate} from '../../utilities/helper/helper'
import { collection, addDoc, updateDoc,deleteDoc, setDoc, getDoc, where, writeBatch, query, orderBy, doc, limit, getDocs } from "firebase/firestore";
import { BrowserRouter as Router, Routes, Route, Link, useParams, useLocation, useNavigate } from 'react-router-dom';

import { GoogleAuthProvider, getAuth, signInWithPopup, signOut, onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { WeekSreak } from "../components/weekStreak.js";
import { ParticipationNote } from "../components/participationNote.js";
import { a1, a2, a3, bodyColors, createAvatarSvg, createAvatarUrl, h1 } from "../avatar/parts.js";


let pwa = "--"
let ua = "--"
let device = "--"
const userAgent = navigator.userAgent || navigator.vendor || window.opera;  
if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
    device = 'Mobile';
} else {
    device = 'Desktop';
}

function secondsToTimeFormat(seconds) {
  if (seconds < 60) {
      return `${seconds}s`;
  } else if (seconds < 3600) { // Moins d'une heure
      return `${Math.floor(seconds / 60)}min`;
  } else if (seconds < 86400) { // Moins d'un jour
      const hours = Math.floor(seconds / 3600);
      const remainingMinutes = Math.floor((seconds % 3600) / 60);
      return `${hours}h${remainingMinutes ? `${remainingMinutes}min` : ""}`;
  } else {
      const days = Math.floor(seconds / 86400);
      const remainingHours = Math.floor((seconds % 86400) / 3600);
      return `${days}jour${days > 1 ? "s" : ""}${remainingHours ? `${remainingHours}h` : ""}`;
  }
}

if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    ua = "IOS"
} else if (/android/i.test(userAgent)) {
    ua = "android"
} else {
    ua = "autre"
}
if (window.navigator.standalone) {
  pwa = "true"
  console.log("L'application est exécutée en mode standalone/PWA");
} else {
  pwa = "false"
  console.log("L'application est exécutée dans le navigateur");
}

let askPWA = device == "Mobile" && pwa == "false" ? true : false





const AvatarCustomize = ({setUser, user, close}) => {


  


  const accessories = [
    { a: "--" },
    { a: "a1", svg : a1},
    { a: "a2", svg : a2},
    { a: "a3", svg : a3},
  ];


  const hairOptions = [
    { h: "--" },
    { h: "h1", svg: h1 },
  ];

  const [code, setCode] = useState(user.avtc)
  const [url, setUrl] = useState()

  


  const updateCode = (key, value) => {
    setCode(prev => ({ ...prev, [key]: value }));
   
  };

  useEffect(() => {
    const generateUrl = async () => {
      const avatarUrl = await createAvatarUrl({code});
      setUrl(avatarUrl);
      console.log('changmeent url', code)
    };
    setUser(user => ({...user, avtc: code}))
    if (code) updateDoc(doc(db, "users", user.id), {avtc: code}) 
    generateUrl();
  }, [code]);


  const categories = ["Corps", "Accessoires", "Cheveux"];
  const [selectedCategory, setSelectedCategory] = useState(0);

  

return <div className="p-4 relative my-8 rounded-xl border-2 border-black/20">
   <div className="absolute top-2 right-2" onClick={() => close()}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="h-10 text-white/90"><path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" /></svg>
   </div>


  <div className="relative mx-auto w-[80px] mb-8">
  <div className="absolute z-0 bottom-[-20px] left-[10px] h-[55px] w-[55px] bg-black/10 border-2 border-black/20 rounded-full" style={{ transform: 'scaleX(2.2)' }}></div>
  <img className=" h-20 relative" src={url} />
  </div>
  

  <div className="flex gap-2 my-2 py-1 bg-black/20 rounded-xl text-sm text-center px-2">{categories.map((category, index) => <div onClick={() => setSelectedCategory(index)} className={`${selectedCategory == index ? "text-white" :"text-white/50"} `}>{category}</div>)}</div>
  

  {/* Sélection de la couleur du corps */}
  {selectedCategory == 0 && <div>

    <div></div>

        <div className="flex gap-2 flex-wrap">
        {bodyColors.map((e, index) => (
          <button key={index} onClick={() => {
            updateCode('c', e.c)
            updateCode('c', e.c)
            }}>
          
          <div className="border-2 border-b-4  border-black/20 rounded-xl overflow-hidden"><div
  className="h-8 w-8 "
  style={{ 
    background: `linear-gradient(180deg, ${e.c1} 0%, ${e.c2} 100%)` 
  }}
></div></div>
           
          </button>
        ))}
       </div>
  </div>}


  {selectedCategory == 1 && <div>

        <div className="flex gap-2 mt-1 flex-wrap">
        {accessories.map((e, index) => (
          <div key={index} onClick={() => updateCode('a', e.a)} className="h-20 w-20 rounded-xl border-2 border-b-4 border-black/20 bg-black/10 items-center justify-center flex">
           
            {e.svg ?<img className="mb-8 ml-2" src={`data:image/svg+xml;base64,${btoa(e.svg)}`}/> :  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="h-10 text-black/70"><path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" /></svg>}
          </div>
        ))}
      </div>
      </div>}

      {selectedCategory == 2 && <div>

        <div className="flex gap-2 mt-1 flex-wrap">
        {hairOptions.map((e, index) => (
          <div key={index} onClick={() => updateCode('h', e.h)} className="h-20 w-20 rounded-xl border-2 border-b-4 border-black/20 bg-black/10 items-center justify-center flex">
           
            {e.svg ?<img className="mt-4 ml-2" src={`data:image/svg+xml;base64,${btoa(e.svg)}`}/> :  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="h-10 text-black/70"><path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" /></svg>}
          </div>
        ))}
      </div>
      </div>}

     
    </div>


  

  }





const ImageUpload = ({ ct, userId, image, showImage, setShowImage, setImage, updateUser}) => {
  const storage = getStorage(app);

  const handleChange = (e) => {
    console.log('handleChange')
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      console.log('handleChange', e.target.files[0])
      handleUpload(e.target.files[0])
    }
  };


  const resizeImage = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        // Créez un canvas
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
  
        // Calculez le nouveau rapport hauteur/largeur
        let width = img.width;
        let height = img.height;
        const maxDimension = 800; // La dimension maximale (largeur ou hauteur)
  
        // Ajustez la largeur et la hauteur pour conserver le rapport hauteur/largeur
        if (width > height) {
          // Si l'image est plus large que haute
          if (width > maxDimension) {
            height *= maxDimension / width;
            width = maxDimension;
          }
        } else {
          // Si l'image est plus haute que large
          if (height > maxDimension) {
            width *= maxDimension / height;
            height = maxDimension;
          }
        }
  
        // Appliquez les dimensions ajustées au canvas
        canvas.width = width;
        canvas.height = height;
  
        // Dessinez l'image redimensionnée sur le canvas
        ctx.drawImage(img, 0, 0, width, height);
  
        // Convertissez le canvas en Blob
        canvas.toBlob(resolve, 'image/webp'); // Vous pouvez ajuster la qualité ici, par exemple, 'image/jpeg', 0.9
      };
      img.onerror = reject;
    });
  };


  const handleUpload = (image) => {
    if (image) {
      resizeImage(image).then((resizedBlob) => {
        const imageRef = ref(storage, `profile_images/${userId}`);
        uploadBytes(imageRef, resizedBlob).then((snapshot) => {
          console.log('Image uploaded successfully!');

          const url = getDownloadURL(imageRef).then((url) => {
            console.log('File available at', url);
            updateUser(ct.user.id, {...ct.user, photo: url})
            setImage(null)
          })
          // Ici, vous pouvez également récupérer l'URL de l'image et la stocker
        }).catch((error) => {
          console.error('Error uploading image:', error);
        });
      });
    }
  };

  return (
    <div>
      {showImage ? <input id="addImg" accept="image/*"  type="file" className="max-w-[200px] overflow-hidden" onChange={handleChange} /> : 
      <button className="text-white" onClick={() => setShowImage(true)}>Changer de photo</button> }
      {image && <div className="text-white" onClick={handleUpload}>Modifier la photo</div>}
    </div>
  );
};

const UserScreen = (props) => {
  const ct = props.context
  
  const auth = getAuth(app);
  const user = props.user
  const [showWords, setShowWords] = useState(false)
  const [showTrad, setShowTrad] = useState(false)
  const nagivate = useNavigate()

  useEffect(() => {
    console.log("UserScreen loaded")
    ct.setActiveScreen("user")
  }, [])

  const updateUser = async (user_id, user) => {
    console.log('updateUser', user)
    const user_ref = doc(db, 'users', user_id)
    const q = query(collection(db, 'user_workspace'),  where('user_id', "==", user_id))
    const querySnapshot = await getDocs(q);
    const userWorkspaces = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
    for (const userWorkspace of userWorkspaces) {
      console.log('Modification des userWorkspaces ' + userWorkspace.id, {...userWorkspace, user_name: user.name, user_photo: user.photo || null});
      const userWorkspaceRef = doc(db, 'user_workspace', userWorkspace.id); // Assurez-vous que 'db' est bien votre référence à Firestore
      try {
        await updateDoc(userWorkspaceRef, {user_name: user.name, user_photo: user.photo || null});
        console.log('Mise à jour réussie pour', userWorkspace.id);
       
      } catch (error) {
        console.error('Erreur lors de la mise à jour de', userWorkspace.id, error);
      }
    }
      updateDoc(user_ref, {name: user.name, photo: user.photo || null})
      ct.setUser({...ct.user, name: user.name, photo: user.photo || null})
      ct.setUserWorkspace({...ct.userWorkspace, user_name: user.name, user_photo: user.photo || null})
    // setTimeout(() => {
    
    // }, 500)
   
  }


  function pareto(x) {
    const k = 0.001; // Coefficient d'ajustement, vous pouvez le modifier selon vos besoins
    const base = 1000; // Valeur de référence pour le calcul
  
    // Calcul du pourcentage en utilisant la formule de la distribution de Pareto
    const percentage = 100 * (1 - Math.pow(base / (base + x), k));
  
    return Math.min(percentage, 100).toFixed(2) // Assure que le pourcentage est entre 0% et 100%
  }

  

const handleSignOut = () => {
  signOut(auth)
    .then(() => {
      console.log('User signed out');
      console.log('User', ct.user)
      ct.setUser()
      nagivate('/')
    })
    .catch((error) => {
      console.error(error);
    });
};


const games = [{name: "test", author: "author", slug: "slug"}, {name: "test2", author: "author2", slug: "slug"}]

// function randomNotification(txt) {
//   const randomItem = Math.floor(Math.random() * games.length);
//   const notifTitle = txt || "notif";
//   const notifBody = `Created by ${games[randomItem].author}.`;
//   const notifImg = `data/img/${games[randomItem].slug}.jpg`;
//   const options = {
//     body: notifBody,
//     icon: notifImg,
//   };
//   new Notification(notifTitle, options);
//   //setTimeout(randomNotification, 30000);
// }


// const askNotification = () => {
//   Notification.requestPermission().then((result) => {
//     console.log(result)
//     if (result === "granted") {
    
//       randomNotification();
//       setTimeout(() => randomNotification('3s notif'), 3000);
//       setTimeout(() => randomNotification('10s notif'), 10000);
//       setTimeout(() => randomNotification('20s notif'), 20000);
//       alert('notifcation activé')
//     }
//   });
// }
const [image, setImage] = useState(null);
const [showImage, setShowImage] = useState()

console.log('user', user)





const modalWordLearned = showWords && <div id="backdrop" className='flex flex-col items-between justify-between' onClick={() => {setShowWords(false)}} style={{ position: "fixed", top: 0, right: 0, bottom: 0, left: 0, backgroundColor: "rgba(0, 50, 0, 0.5)", zIndex: 999}}>
    <div onClick={(e) => e.stopPropagation()} className="bg-white rounded-xl h-full overflow-scroll p-2">
      <div className="mb-4 my-2 ">
        <div onClick={() => setShowWords(false)}>Retour</div>
        <h2>Liste des mots appris</h2>
        <div className="" onClick={() =>{setShowTrad(!showTrad)}}>Voir les {showTrad? "Termes" : "Traductions"}</div>
      </div>
      <div className="flex flex-wrap gap-1">
        {ct.userCards?.map(c => ct.cards.find(card => card.id == c.card_id)).filter(c => c?.term)?.map(c => <div onClick={() => {

        }} className="px-2 rounded-xl text-sm border-[1.4px] border-b-[4px] ">{ showTrad ? c.trad : c.term}</div>)}
      </div>

    </div>
  </div>
  

  const [editAvatar, setEditAvatar] = useState()
  
  return (
    <div className="w-full fredoka relative bg-gradient-to-b from-[#a728d0] via-purple-700 to-purple-700 text-center p-8 pb-[100px] mb-[200px] min-h-screen h-screen overflow-y-scroll">
      <Link to={"/home"}  className="absolute top-4 right-4 text-white z-40 bg-red-500 p-1 rounded-xl border border-b-4 border-red-700">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </Link>
      {modalWordLearned}
      {/* <code className="text-left block" >
            secureKey : {process.env.REACT_APP_TEST_SECURITY}<br/>
            Pwa : {pwa}<br/>
            User Agent: {ua}<br/>
            Device : {device}<br/>
            Banner: {askPWA ? "ASK" : "NOT ASK"}<br/>
            Version: {"Orange 2.7"}<br/>
            GPT use: {ct.userWorkspace?.total_tokens}<br/>
            TS: {ct.userWorkspace?.timeSpend}<br/>
            TSForest: {ct.userWorkspace?.timeSpendForest}<br/>
            Streak: {ct.userWorkspace?.streak}<br/>
            Xp: {ct.userWorkspace?.total_xp}<br/>
        </code> */}


        <div className="bg-black/10 -mx-8 -mt-8 p-8 pb-2 mb-4">



        {!editAvatar && <div className="relative h-[100px] w-[100px] mx-auto rounded-full flex items-center justify-center  relative ">
        <div className="absolute z-0 bottom-[-0px] h-[55px] w-[55px] bg-black/10 border-2 border-yellow-200/20 rounded-full" style={{ transform: 'scaleX(2.2)' }}></div>

          <img src={createAvatarSvg({code: user?.avtc})} className="h-[70px] relative" /></div>}
        {!editAvatar && <div className="flex mx-auto justify-center"><div onClick={() => setEditAvatar(true)} className="px-4 py-1 rounded-xl my-4 text-white/90 border-2 flex gap-2 border-white/50 text-xs border-b-2">Personnaliser
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
</svg>

        </div></div>}

        {editAvatar && <AvatarCustomize user={user} setUser={ct.setUser} close={() => setEditAvatar(false)} />}
        </div>

        <div className="text-center  text-xl mb-4 text-white font-bold text-shadow capitalize">{ct.user?.name || "Sans nom"}</div>

     
    
      <div className="text-center">
      {/* <img className="h-24 object-cover	 mx-auto object-cover w-24 border-4 backdrop-blur-md	border-amber-400/50 rounded-full text-center" src={ct.user.photo || "images/tar/tar0.png"} /> */}
      {/* <ImageUpload  updateUser={updateUser} ct={ct} userId={ct.user?.id} setShowImage={setShowImage} image={image} setImage={setImage} showImage={showImage} /> */}
      <div className="flex my-4 item-center bg-gradient-to-r  from-purple-[#a728d0] border-purple-900 to-purple-500  justify-start text-white text-shadow gap-2 rounded-xl p-2 border-2 border-b-4">
          <img src="images/UX/crown.png" className="h-[48px]" />
          <div  className="flex items-center gap-2 fredoka"><span className="text-2xl">{ct.userCards?.length}</span> mots appris</div>
        </div>

      <div  className="p-4 relative rounded-xl text-white border-2 border-b-4  text-left bg-gradient-to-r  from-purple-700 border-purple-900 to-purple-800 ">
      <div className='text-white text-sm  absolute -top-2 -left-2 gap-2 rounded-full px-3  pl-2 flex bg-[#aa4aff] items-center'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
        </svg>
            Aujourd'hui
        </div>
        <div className="ml-2 flex gap-2 mt-1 justify-between">Temps passé : <span className="bg-white/10 text-shadow px-2 rounded">{secondsToTimeFormat(ct.userDaily?.ts || 0)}</span></div>
        <div className="ml-2 flex gap-2 mt-1 justify-between">Xp gagné : <span className="bg-white/10 text-shadow px-2 rounded">{(ct.userDaily?.xp || 0)}xp</span></div>
        <div className="ml-2 flex gap-2 mt-1 justify-between">Mots appris : <span className="bg-white/10 text-shadow px-2 rounded">{(ct.userDaily?.new || 0)}</span></div>
      </div>

      <WeekSreak className="mt-4 border-2 border-b-4 bg-gradient-to-r from-purple-700 border-purple-900 to-purple-800 " context={ct}/>

     <ParticipationNote  className="mt-4 border-2 border-b-4 bg-gradient-to-r from-purple-700 border-purple-900 to-purple-800 " context={ct}  />

      <div  className="p-4 pt-6  rounded-xl relative mt-6 text-white border-2 border-b-4  text-left bg-gradient-to-r  from-purple-700 border-purple-900 to-purple-800 ">
 
        <div className='text-white text-sm  absolute -top-2 -left-2 gap-2 rounded-full px-3  pl-2 flex bg-[#aa4aff] items-center'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M20.893 13.393l-1.135-1.135a2.252 2.252 0 01-.421-.585l-1.08-2.16a.414.414 0 00-.663-.107.827.827 0 01-.812.21l-1.273-.363a.89.89 0 00-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.212.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 01-1.81 1.025 1.055 1.055 0 01-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.655-.261a2.25 2.25 0 01-1.383-2.46l.007-.042a2.25 2.25 0 01.29-.787l.09-.15a2.25 2.25 0 012.37-1.048l1.178.236a1.125 1.125 0 001.302-.795l.208-.73a1.125 1.125 0 00-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 01-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 01-1.458-1.137l1.411-2.353a2.25 2.25 0 00.286-.76m11.928 9.869A9 9 0 008.965 3.525m11.928 9.868A9 9 0 118.965 3.525" />
          </svg>

              Mon impact écologique
        </div>
        
        <div className="text-sm">Quand tu apprends tu plantes de vrais arbres 🌱</div>
      </div>

      <div  className="text-white/50 mt-4 text-sm"  onClick={() => {
        ct.setUser(prev => ({...prev, learnMode: prev.learnMode == "A" ? "B" : "A"}))
        updateDoc(doc(db, 'users', user.id), {...user, learnMode: "A" ? "B" : "A"});
      }}>
        Mode apprentissage : {ct.user?.learnMode == "B" ? "avec contexte" : "classique"}
      </div>

      <div className="text-white/50 mt-4" onClick={() => ct.setIsBlocked(true)}>Rejoindre une classe</div>
      {/* <input className="text-center border-b-2 w-full border-slate-200 " onBlur={(e) => updateUser(ct.user.id, {...ct.user, name: e.currentTarget.value})} defaultValue={ct.user.name} /> */}
      {/* <input className="text-center border-b-2 w-full border-slate-200 italic text-gray-300 text-sm " onBlur={(e) => updateUser(ct.user.id, {...ct.user, photo: e.currentTarget.value})} defaultValue={ct.user.photo} /> */}
     
      <div className="mt-[160px] italic text-white/50 " onClick={() => {handleSignOut()}}>Se déconnecter</div>
      {/* <div id="notification" className="hover:text-red-500 italic text-white/50" onClick={() => {askNotification()}} >Activer les notifications ({Notification?.permission})</div> */}
     {(window.location.hostname.includes('localhost')) && <div className="mt-[16px] italic text-white/50 " onClick={() => {
        const user_ref = doc(db, 'users', ct.user.id)
        // delete doc
        deleteDoc(user_ref)
        handleSignOut()
      }}>Supprimer mon compte</div>}





     

    
      </div>
  
      
     
    </div>
  );
};

export {UserScreen};