import React, { useRef } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AppV2 from './V2/🧠App';
import reportWebVitals from './test/reportWebVitals';
import { useGlobalContext, GlobalProvider } from './utilities/globalContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import {ErrorBoundary} from './components/ErrorBoundary';
import { ContextProvider } from './V2/context';

const root = ReactDOM.createRoot(document.getElementById('root'));

const searchParams = new URLSearchParams(window.location.href);
const version = searchParams.get("v")
const clientId = "1033205216984-kq2o5bdl3onf4uj2tf0v4p40q2k7511l.apps.googleusercontent.com"

try {
if ('serviceWorker' in navigator) {
 

  window.addEventListener('load', () => {
    console.log('loading page');
  
    navigator.serviceWorker.getRegistrations().then(registrations => {
      if (registrations.length > 0) {
       
        Promise.all(registrations.map(registration => registration.unregister())).then(() => {
         
          caches.keys().then(cacheNames => {
            Promise.all(cacheNames.map(cacheName => caches.delete(cacheName))).then(() => {

              
              navigator.serviceWorker.register('/service-worker.js');
            });
          });
        });
      } else {
        // Aucun service worker enregistré, enregistrez-en un nouveau
        navigator.serviceWorker.register('/service-worker.js');
      }
    });
  
    navigator.serviceWorker.getRegistration().then((registration) => {
      if (registration) {
        registration.update();
      }
    });
  });

    

  

    // Enregistrement du service worker
    

    // Vérification et mise à jour du service worker enregistré
    

}
} catch (error) {
  console.log(error)
}

if (version != 3) {

  root.render(
    <ErrorBoundary>
      <ContextProvider>
        <AppV2 />
      </ContextProvider>
    </ErrorBoundary>,

  );
} else {
  root.render(
    <React.StrictMode>
        <GlobalProvider>
          <App url={window.location.href} />
        </GlobalProvider>
    </React.StrictMode>
  );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
