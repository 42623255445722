import { collection, addDoc, updateDoc, setDoc, getDoc, where, writeBatch, query, orderBy, doc, limit, getDocs } from "firebase/firestore";
import { db } from "../../config";
import { BrowserRouter as Router, Routes, Route, Link, useParams, useLocation, useNavigate } from 'react-router-dom';


const creatUserWorkspace = async (uwp) => {
    console.log('creatUserWorkspace 🟣')
    const userWorkspace = await addDoc(collection(db, 'user_workspace'), uwp)
  
    return userWorkspace.id
  }

const ChooseWP = ({
    user, workspaces, 
    userWorkspaces,  changeWorkspace, 
    setShowWorkspaces, setUser, 
    setWorkspace, setUserWorkspace, 
    updateUser}) => {

        const navigate = useNavigate()
    
    const joinWp = async ({wp, user, setUserWorkspace, setUser, setWorkspace}) => {
    
        
      console.log(`${user.name} veut rejoindre le wp ${wp.name}`)
    
      const userWorkspace = {
          user_id: user.id,
          workspace_id: wp.id,
          name: wp.name, 
          user_name: user.name,
          user_photo: user.photo || null
      }
  
      const qUserWorkspaces =  query(collection(db, 'user_workspace'), where('user_id', '==', user.id), where('workspace_id', '==', wp.id)) 
      const querySnapshotUW = await getDocs(qUserWorkspaces);
      const uwps = querySnapshotUW.docs.map(doc => ({...doc.data(), id: doc.id}))[0]
      
      
      if (uwps) {
        console.log('uwps existe déjà !', uwps)
        
        
        setUserWorkspace(uwp => ({...uwp, id: uwps.id}))
        const _user = {...user, user_workspace_id: uwps.id, last_workspace_id: wp.id}
        updateUser(_user)
        setUser(_user)
        updateDoc(doc(db, "users", _user.id), _user);  

    
        setWorkspace({...wp, id: wp.id})
       
        
      } else {
        console.log('création nouveau uwp', uwps)
        const _userWorkspace = {...userWorkspace,   creation_date: new Date()}
        const user_workspace_id = await creatUserWorkspace(_userWorkspace)
        setUserWorkspace(uwp => ({...uwp, id: user_workspace_id}))
        const _user = {...user, user_workspace_id: user_workspace_id, last_workspace_id:  wp.id}
        updateUser(_user)
        setUser(_user)
        updateDoc(doc(db, "users", _user.id), _user);  
        const houseObjData = {x: 4, y: 4, name: 'house_0', uwp: user_workspace_id}
        await addDoc(collection(db, "user_workspace_element"), houseObjData);
  
        if (wp.id == "anglais-gb") {
          // const templeObjData = {x: 1, y: 1, name: 'temple_0', uwp: user_workspace_id}
          // await addDoc(collection(db, "user_workspace_element"), templeObjData);
          // console.log('ajout du temple')
        }
        const yValues = [5, 6, 7, 8]; // Array of y values
  
        yValues.forEach(async y => {
            const wayObjData = { x: 4, y, name:"way", uwp: user_workspace_id };
            await addDoc(collection(db, "user_workspace_element"), wayObjData);        
        });
        setWorkspace({...wp, id: wp.id})
        // navigate("/classic")
        console.log('PAS DE TOKEN on dEMANDE LES NOTIFICATIONS 🟠🟠🟠', user);
        if (!user.fcmToken) {
          console.log('PAS DE TOKEN on dEMANDE LES NOTIFICATIONS');
   
        }
       
      }

     
        
    
    }
    
    
    const miniWorkspace = (wp) => <div className={`${userWorkspaces?.find(uwp => uwp.workspace_id == wp.id) ? "border-red-400" : "border-yellow-50"} mt-4  rounded-md w-[90px] text-white capitalize` } 
        onClick={() => {
         
          const uwp = userWorkspaces?.find(uwp => uwp.workspace_id == wp.id)
          if (uwp) {
            changeWorkspace(uwp.id, uwp.workspace_id)
          } else {
          joinWp({wp: {...wp, id: wp.id, name: wp.name}, user: user, setUserWorkspace, setUser, setWorkspace})
        } 
  
        navigate("/goal")
        }}>
            <img src={wp.img}  className="h-[60px] mb-2 border-b-4 border-2 border-slate-900 mx-auto rounded-full"/>
            {wp?.name} 
            </div>

    if (!user) return <div>...</div>
    
    return <div className={` fredoka text-center  fixed z-[100] py-[100px]  max-h-[100vh] overflow-scroll w-full bg-purple-500 top-0 left-0 bottom-0  gap-2 px-4 `}>
          <h2 className="text-white mb-8">Quelle langue souhaites-tu apprendre ?</h2>
          <div className="flex items-center justify-center flex-wrap gap-2">
            {workspaces?.filter(w => w.img && w.order < 5).map((wp, i) => miniWorkspace(wp))}
            {user?.admin &&<div className='w-full p-2 mt-4'><hr className='mx-auto  opactity-50 border-dashed w-[40%]'/></div>}
            {user?.admin && workspaces?.filter(w => w.img && w.order > 6 && w.order < 40).map((wp, i) => miniWorkspace(wp))}
            </div>
          </div>
  }

  export {ChooseWP}