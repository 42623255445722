import { useState, useEffect } from "react";
import { collection, addDoc, doc, updateDoc } from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";
import { getFunctions, httpsCallable } from "firebase/functions";

import { db, app } from "../../config.js";
import {fire} from '../🔥db.js'
import { Link, useNavigate } from "react-router-dom";
import { InteractivePage } from "../components/interactivePages.js";
import { tutoPareto, tutoPerf } from "../../utilities/helper/helper.js";

const GoalScreen = (props) => {
    const navigate = useNavigate()
    const [selectedGoal, setSelectedGoal] = useState()
  const ct = props.context
  useEffect(() => {
    console.log("GoalScreen loaded")
    ct.setShowBottomBar(false)
   
 
}, [])


const goals = [
    {
      name: "Apprendre @lang",
      goal_id: 'learn',
      courseName: "L'archipel du débutant",
      description: "Le parcours ideal pour commencer à apprendre une langue et maitriser les bases",
      content: "Apprends à comprendre et à t'exprimer en utilisant les 1 200 mots les plus utilisés en @lang, ce qui te donnera la capacité de comprendre 80 % de n'importe quel texte",
      goal: "Atteindre le niveau B1 et comprendre 80% de n'importe quel texte",
      bgColor: "#00FFE0",
      img: "images/UX/plant_seed.png",
      tuto: true,
      pages: tutoPareto(ct),
      lvl: ["Débutant"]
    }, {
      name: "Perfectionner son @lang",
      goal_id: 'perfect',
      courseName: "Les 5 chemins de la perfection",
      description: "Plonge dans l'univers captivant de cette langue et affine tes compétences linguistiques à travers ce que tu aimes.",
      goal: "Adopte une approche personnalisée et dynamique pour maîtriser le @lang. Selon tes passions, qu'il s'agisse de littérature, de conversations interactives, de sujets d'actualité brûlants ou de trésors de la culture générale, construis ton parcours d'apprentissage sur mesure et deviens un virtuose de la langue.",
      bgColor: "#0EC5FF",
      content: "Améliore ton @lang avec des conversations interactives, des histoires, de l'actualité ou des cours de culture générale",
      img: "images/UX/fusee.png",
      pages: tutoPerf,
      tuto: true,
      lvl: ["Intermédiaire", "Avancé"]
    },
    {
      name: "Préparer un concours",
      courseName: "Les jardins du TOEIC",
      goal_id: "TOEIC", 
      description: "La formation ultime pour développer ton vocabulaire, ta grammaire, et ta compréhension écrite et orale.",
      content: "Un parcours complet dédié au TOEIC, axé sur la compréhension. Mets-toi au défi sur chaque section du test et construis un socle lexical solide, te donnant les clés pour décrypter 90 % de n'importe quel texte du TOEIC.",
      goal: "Réussir le concours devient un jeu d'enfant",
      bgColor: "#6D41EB",
      content: "Prépare ton TOEIC avec du vocabulaire, de la grammaire et des exercices de compréhension orale et écrite",
      img: "images/UX/toeic.png", 
      open_only_to: ["anglais-gb"],
      // open_only_to: [""],
      lvl: ["Intermédiaire"]
    },
    // {
    //   name: "Apprendre l'alphabet et les signes",
    //   courseName: "La Pierre de Rosette",
    //   goal_id: "roseta",
    //   description: "Apprends les lettres de l'alphabet et les signes de la langue.",
    //   content: "Ce cours te permet d'apprendre les lettres de l'alphabet et les différents signes utilisés dans la langue.",
    //   goal: "Apprendre à lire et écrire en utilisant les lettres de l'alphabet et les signes.",
    //   bgColor: "#6D41EB",
    //   img: "images/UX/i_book.png",
    //   lvl: ["Intermédiaire"]
    // }
  ]


  const handleGoal = async (goal_id) => {
    console.log('userWorkspace', ct.userWorkspace)
    fire.updateUserWorkspace({...ct.userWorkspace, goal_id, path_id: "toiec_path"}, ct)
    ct.setShowWorkspaces()
    ct.setShowBottomBar(true)
    ct.setNextCards()
    
    navigate('/app')
  }

  const [showTuto, setShowTuto] = useState()

  const hasSeenTuto = ct.user["tuto_" + goals[selectedGoal]?.goal_id] || !goals[selectedGoal]?.tuto
  
  return (
    <div className="w-full text-left min-h-[100vh] max-h-[100vh] fredoka text-white bg-gradient-to-b to-[#5A3591] from-[#36206C] overflow-scroll relative">
      {/* <div>{ct.cardMap?.length} cartes {ct.cardMapGlobal?.length} perf {ct.userCards.length} cartes apprisent</div> */}
      {showTuto && <div className="fixed bg-red-500 z-[100] top-0 bottom-0 left-0 right-0">
        <InteractivePage close={() => setShowTuto(false)} 
          endAction={() => {
            ct.setUser(u => ({...u, ["tuto_" + goals[selectedGoal].goal_id]: true})) 
            updateDoc( doc(db, 'users', ct.user.id), {["tuto_" + goals[selectedGoal].goal_id]: true});
            showTuto == "choose" && handleGoal(goals[selectedGoal].goal_id)
          }} 
          pages={goals[selectedGoal].pages} 
          back={() => {setShowTuto(); console.log('cc2')}} 
          lockScreen={false} 
          blocked={false} 
          ct={ct}
          />
          </div>}
      <header className="h-[160px] flex items-center justify-center relative bg-[#34bdff]">
        <div className="absolute top-4 left-0 right-0  flex justify-center ml-6">
            <Link to={"/choose"} className="flex gap-2 rounded-full items-center mx-auto px-2 bg-black/20  border-black/50"> <img src={ct.workspace?.img}  className="h-[20px] border border-black border-b-2 rounded-full -ml-1" /> Changer de langue</Link>
        </div>
        <img src="/images/UX/arrow_man.png" className="h-[120px] absolute left-0 bottom-6 mx-auto"/>
        <div className=" pl-8 mb-3">

        <div className="text-xl text-center font-bold text-center  text-shadow">Choisis ta destination</div>
        </div>
        <div className=" h-[100px] absolute bottom-0 right-0 left-0" style={{
            backgroundImage: "url('/images/seeds/bottom-store.png')",
            backgroundRepeat: "repeat-x",
            backgroundPositionY: "bottom"
            }}></div>
        </header>
        <div className="h-[8px] bg-[#241256]"></div>
        <main className="p-8 px-6 pt-4 pt-2 pb-[60px] max-w-[600px] mx-auto">
      {goals.filter(g => !g.open_only_to || g.open_only_to.includes(ct.workspace?.id)).map((g,i) => <div> 
        <div className="mb-4">
            <div className="text-md mb-1">{g.name.replace("@lang", ct.workspace?.name)}</div>
            <div onClick={() => setSelectedGoal(i)} className=" flex  transition z-10  bg-white/20 rounded-xl border-2 border-black/80 border-b-[6px] bg-gradient-to-r from-purple-700 to-purple-800">
                <div className="min-w-[70px] max-w-[70px] flex items-center justify-center rounded-l-xl" style={{backgroundColor: g.bgColor}}>
                    <img src={g.img} className="w-[80%]"/>
                </div>
                <div className="p-2 px-4 grow">
                    <div className="text-[16px] font-semibold">{g.courseName}</div>
                    <div className="text-[12px]">{g.description.replace("@lang", ct.workspace?.name)}</div>
                </div>
                {/* <div className="text-amber-300">→ {g.goal}</div> */}
            </div>
            {selectedGoal == i && <div className="bg-black/50 p-4  -mt-[8px] rounded-b-xl z-0">
            
            <div className="flex gap-2 my-2">Niveau : {g.lvl.map(lvl => <div className="bg-white/20 border px-2 rounded-xl">{lvl}</div>)}</div>
              <div className="text-sm text-justify">{g.content.replace("@lang", ct.workspace?.name)}</div>
            
             <div className="flex justify-between mt-4 items-center my-2">
              {g.tuto ? hasSeenTuto ? <div onClick={() => {setShowTuto(true)}} className="px-2 py-1 text-white/90 rounded-xl border-2 text-sm items-center border-white/10 flex gap-2">Voir le tutoriel <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 text-white/40">
  <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
</svg>
</div> : <div></div> : <div></div>}
              <div onClick={() => {
                if(hasSeenTuto) {
                  handleGoal(g.goal_id);
                } else {
                  setShowTuto("choose");
                }
              
                }} className="bg-white px-6  py-1 border border-b-2 rounded-xl text-purple-500 font-semibold">{hasSeenTuto ? "Choisir →" : "Découvrir →" }</div>
              </div> 
            </div>}
        </div>

      </div>)}
      <div className="flex justify-center">
      <Link to={"/choose"} className="flex  gap-2 rounded-xl  items-center mx-auto px-4 py-1 bg-purple-700 border-2 border-b-4 inline-block mx-auto  border-black/80"> Changer de langue →</Link>
      </div>

      </main>
       
    

    
    </div>
  );
};

export {GoalScreen};