import { createContext, useContext, useState } from 'react';

const GlobalContext = createContext();


export function GlobalProvider({ children }) {
  const [user, setUser] = useState(null);
  const [workspace, setWorkspace] = useState(null);
  const [cards, setCards] = useState(null);
  const [userCards, setUserCards] = useState(null);
  const [decks, setDecks] = useState(null);
  const [deck, setDeck] = useState(null);
  const [userWorkspace, setUserWorkspace] = useState(null);
  const [theme, setTheme] = useState('light');
  const [userWorkspaces, setUserWorkspaces] = useState(null);
  const [timer, setTimer] = useState(null);
  const [mapObj, setmapObj] = useState([])
  const [hasFetched, setHasFetched] = useState([])

 
  const [strMapBiome, setStrMapBiome] = useState('0-0'); 
  const learn = () => {
      alert('')
  }
  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  return (
    <GlobalContext.Provider value={{ 
        user, setUser, 
        theme, toggleTheme,
        workspace, setWorkspace,
        userWorkspaces, setUserWorkspaces,
        hasFetched, setHasFetched,
        decks, setDecks,
        userCards, setUserCards,
        mapObj, setmapObj,
        timer, setTimer,
        cards, setCards,
        userWorkspace, setUserWorkspace,
        strMapBiome, setStrMapBiome,
        deck, setDeck
    }}>
      {children}
    </GlobalContext.Provider>
  );
}

export function useGlobalContext() {
  return useContext(GlobalContext);
}