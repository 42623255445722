import  React, { createContext, useMemo, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";
import { getFunctions, httpsCallable } from "firebase/functions";

import { db, app } from "../../config.js";
import {fire} from '../🔥db.js'
import { useNavigate } from "react-router-dom";
import { findWord, clean, gpt, numberSentences, splitNumeredSentence, sentenceEndRegex, speak } from "../../utilities/helper/text.js";
import SuperText from './perfectScreen.js';
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';
import { quantum, waveform } from 'ldrs'
import { AudioReader } from '../components/quizzReader.js';
import {Howl, Howler} from 'howler';  


const Whitelist = (props) => {
  
    const searchParams = new URLSearchParams(window.location.href);
    const params = searchParams.get("from")

   const [email, setEmail] = useState()
   const [focus, setFocus] = useState()
   const [valid, setValid] = useState()
   const [version, setVersion] = useState()
   const [done, setDone] = useState()

   console.log('params', params)

   const addWhite = async () => {
    const articleRef = collection(db, "whiteList");
    const white = {
        email: email,
        version: version,
        from: params,
        date: new Date()
    }
    console.log('white', white)
    const done = await addDoc(articleRef, white)
    setDone(true)
   }

   const width = window.innerWidth;
   
  

   if (done)  return <div  className="fredoka bg-emerald-500 text-white border-2 px-2 py-2 rounded-xl justify-center  ">
<div>Ton inscription a bien été envoyée. Tu recevras bientôt un code d'accès.</div>    
   </div>

    return <div onClick={() => {
  
        }} className={`fredoka justify-center ${props.full ? "bg-yellow-50 h-screen flex justify-center items-center" : "rounded-xl "} `}>
        {!valid && <div className={`flex flex-wrap pt-5 ${width < 500 ? "justify-center" : "" } px-2 relative gap-2 items-center`}>
            <div className='absolute text-slate-400 text-sm -top-1'>{focus && <div>Si tu es sur Android, utilise ton adresse gmail</div>}</div>
        <input  onFocus={() => {setFocus(true); console.log('params', params)}} onBlur={(e) => {setEmail(e.target.value); setFocus(false)}} className='p-2 grow max-w-[400px] min-w-[200px] rounded-xl border-2' placeholder={`Ton plus beau mail ✨`} /> 
         <div onClick={() => {setValid(true)}} className={"px-3 lg:hover:bg-purple-600 cursor-pointer rounded-xl bg-purple-500 text-white py-2 border-b-4 border-2 border-purple-900"}>Recevoir mes accès</div>
       </div>}

       {valid && < >
       <div className='text-slate-500 text-center text-sm mb-1'>Choisis ta version</div><div className='flex gap-2 flex-wrap justify-center items-center'>

        <div onClick={() => {setVersion("android")}} className={`px-2 ${version != 'android' ? "text-purple-900 bg-purple-100" : "text-white bg-purple-500" }  flex w-[140px] gap-2 items-center lg:hover:bg-purple-100 cursor-pointer rounded-xl    py-2 border-b-4 border-2 border-purple-900`}>
        <div className='border-2 rounded-full border-purple-900 h-4 min-w-[16px] flex items-center justify-center  bg-white'>
            {version == 'android' && <div className='bg-purple-500 h-2 w-2 rounded-full'></div>}
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="w-6 h-6" viewBox="0 0 512 512">
                <path d="M99.617 8.057a50.191 50.191 0 00-38.815-6.713l230.932 230.933 74.846-74.846L99.617 8.057zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877L32.139 20.116zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711L60.815 510.629c3.786.891 7.639 1.371 11.492 1.371a50.275 50.275 0 0027.31-8.07l266.965-149.372-74.849-74.847z"></path>
              </svg>
            <div>ANDROID</div></div>
        <div onClick={() => {setVersion("ios")}} className={`px-2  ${version != 'ios' ? "text-purple-900 bg-purple-100" : "text-white bg-purple-500" } flex w-[140px] gap-2 items-center lg:hover:bg-purple-100 cursor-pointer rounded-xl   py-2 border-b-4 border-2 border-purple-900`}>
        <div className='border-2 rounded-full border-purple-900 h-4 w-4 flex items-center justify-center  bg-white'>
            {version != 'android' && <div className='bg-purple-500 h-2 w-2 rounded-full'></div>}
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="w-6 h-6" viewBox="0 0 305 305">
                <path d="M40.74 112.12c-25.79 44.74-9.4 112.65 19.12 153.82C74.09 286.52 88.5 305 108.24 305c.37 0 .74 0 1.13-.02 9.27-.37 15.97-3.23 22.45-5.99 7.27-3.1 14.8-6.3 26.6-6.3 11.22 0 18.39 3.1 25.31 6.1 6.83 2.95 13.87 6 24.26 5.81 22.23-.41 35.88-20.35 47.92-37.94a168.18 168.18 0 0021-43l.09-.28a2.5 2.5 0 00-1.33-3.06l-.18-.08c-3.92-1.6-38.26-16.84-38.62-58.36-.34-33.74 25.76-51.6 31-54.84l.24-.15a2.5 2.5 0 00.7-3.51c-18-26.37-45.62-30.34-56.73-30.82a50.04 50.04 0 00-4.95-.24c-13.06 0-25.56 4.93-35.61 8.9-6.94 2.73-12.93 5.09-17.06 5.09-4.64 0-10.67-2.4-17.65-5.16-9.33-3.7-19.9-7.9-31.1-7.9l-.79.01c-26.03.38-50.62 15.27-64.18 38.86z"></path>
                <path d="M212.1 0c-15.76.64-34.67 10.35-45.97 23.58-9.6 11.13-19 29.68-16.52 48.38a2.5 2.5 0 002.29 2.17c1.06.08 2.15.12 3.23.12 15.41 0 32.04-8.52 43.4-22.25 11.94-14.5 17.99-33.1 16.16-49.77A2.52 2.52 0 00212.1 0z"></path>
              </svg>
            <div>APPLE</div></div>


       </div>
      <div className='justify-center flex'><div onClick={() => {addWhite()}} className={`${version ? "bg-purple-500 text-white border-purple-900" : " bg-slate-100 opacity-50 border-slate-400 text-slate-400"} px-[110px] mt-2 lg:hover:bg-purple-600 cursor-pointer rounded-xl py-2 border-b-4 border-2 `}>Recevoir mes accès</div></div>
</>} 

      </div>

};

export {Whitelist};





