import { useState, useEffect } from "react";
import { collection, addDoc, updateDoc, setDoc, getDoc, where, writeBatch, query, orderBy, doc, limit, getDocs } from "firebase/firestore";
import { db } from "../../config.js";
import {Popover} from '../components/forest/popover.js' 
import { BrowserRouter as Router, Routes, Route, Link, useParams, useLocation } from 'react-router-dom';
import { ct } from "../../utilities/data.js";
import { PerfectScreen, RedDot } from "./perfectScreen.js";
import { ConcoursScreen } from "./concoursScreen.js";
import { Liane, formatDecks } from "../components/liane.js";
import { sectionInfos } from "../components/quizzReader.js";
import { categories, centToStars, getCardsData, getDeckData, getStarsFromTraining, getUniqueTest, quizList2 } from "../../utilities/helper/helper.js";
import { RosetaScreen } from "./RosetaScreen.js";
import { createAvatarSvg } from "../avatar/parts.js";


let i = 0
let asc = true


const DemoPreview = ({goal_id, userCards}) =>  {

  const [expended, setExpended] = useState(false)
  const maxScore = 50
//userCards.lenght > maxScore 
  if (userCards?.length > maxScore ) {
    return <div className="fixed top-0 bottom-0 text-white text-center left-0 right-0 bg-[#5f32b3] z-[40]">
      <div className="max-w-[500px] mx-auto p-4">
        <img src="/images/deco/cool_seed.png" className="h-[160px] mt-12 mx-auto" />
        <div className="mt-12 text-xl">Version démo terminé </div>
        <div>Tu as atteint le quota maximum de mot appris</div>
        {/* <div className="mt-4 rounded-xl border-2 border-b-4 bg-purple-500 px-4 py-1">Demander un accès complet</div> */}
      </div>
    </div>
  }



  return  <div onClick={() => {setExpended(e =>!e)}} id="teae" className={`flex items-center justify-center   ${goal_id == "perfect" ? " mt-2 " : "-mt-4"}`}><div className={`${goal_id == "perfect" ?"-mb-0":"mb-8"}   text-white/50 text-sm text-center bg-black/20 rounded-xl px-4 py-1  `}>
  <div className="flex items-center gap-2 mx-auto justify-center">
    <div>Version demo</div>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
    </svg>
  </div>
  {expended && (
  <div className="pb-4">

    <div>Tu as accès à une version de démonstration. Pour le moment, tu peux apprendre jusqu'à {maxScore} mots.</div>
    <div className="mt-1 flex mx-auto w-[160px] bg-black/20 rounded-xl px-2">{userCards?.length}/{maxScore} souvenirs créés</div>
  </div>
)}


  </div></div>
}


const partsInit = [
{
  id: "sales", 
  img: "/images/mini-island-yellow-orange.png", 
  name: "Sales", 
  nb: 70,
}, 
{
  id: "travel", 
  img: "/images/mini-island-blue-purple.png", 
  name: "Travel",
  nb: 70
}, 
{
  id: "entertainement", 
  img: "/images/mini-island-blue-green.png", 
  name: "Entertainement",
  nb: 70
}, 
{
  id: "technology", 
  img: "/images/mini-island-blue-purple.png", 
  name: "Technology",
  nb: 70
}, 
{
  id: "health", 
  img: "/images/mini-island-green-blue.png", 
  name: "Health",
  nb: 70
}, 
{
  id: "finance", 
  img: "/images/mini-island-yellow-orange.png", 
  name: "Finance",
  nb: 70
}, 
{
  id: "workplace", 
  name: "Workplace",
  img: "/images/mini-island-blue-green.png",
  nb: 70
} ]


// 40 mots par cycle
let previousFlowers = 0

const gardens = [{
  name: "Île de l'Aurore", 
  nb: 50,
  img: "/images/mini-island-blue-green.png",
  bgColor: "#47ACA9",
}, 
{
  name: "Île des Saisons", 
  nb: 75,
  img: "/images/mini-island-yellow-orange.png",
  bgColor: "#7622B7",
}, 
{
  name: "Île des Explorateurs ", 
  nb: 100,
  img: "/images/mini-island-blue-purple.png",
  bgColor: "#3062AC",
}, 
{
  name: "Île des Mille Couleurs", 
  nb: 175,
  img: "/images/mini-island-blue-green.png",
  bgColor: "#47ACA9",
} , //450
{
  name: "Île des Constellations ", 
  nb: 200,
  img: "/images/mini-island-blue-purple.png",
  bgColor: "#3E9EB3",
}, 
{ name: "Île des Échos",
  nb: 250,
  img: "/images/mini-island-green-blue.png",
  bgColor: "#323588",
}, 
{ 
  name: "Île des Oasis",
  nb: 350,
  img: "/images/mini-island-blue-green.png",
  bgColor: "#1FA6A1",
}
].map((garden, i) => {
  const start = previousFlowers ; // Commence à 1 plus que le total précédent
  const end = previousFlowers + garden.nb; // Calcule la fin basée sur nb
  previousFlowers += garden.nb; // Met à jour previousFlowers pour le prochain jardin
  

  return {
    ...garden,
    id: i + 1, // Assurez-vous que l'id commence à 1 au lieu de 0
    start: start,
    end: end,
  };
});

previousFlowers = 0


const menu = [
  {
    
    pathName: "news", 
    id: "news",
    name: "Actualités",
    icon: "/images/UX/i_actualite.png",
    ready: false,
    beta: true,
    hue: 42,
    description: "Découvre l’actualité directement en @lang",
  },
  {
    
    pathName: "brain", 
    id: "cultureG",
    name: "Culture Générale", 
    icon: "/images/UX/i_culture.png",
    ready: false,
    beta: true,
    hue: 100,
    description: "Apprends des informations utiles pour ta culture générale tout en améliorant ton niveau de langue",
  },
  {
    
    pathName: "voc", 
    id: "voc",
    name: "Vocabulaire", 
    icon: "/images/UX/i_voc.png",
    beta: true,
    ready: true,
    available: true,
    hue: 0,
    description: "Des groupes de vocabulaire spécifiques à différents domaines : voyage, immobilier, langage soutenu, finance... ",
  },

  {
    
    pathName: "chat", 
    id: "chatbot",
    name: "Immersion intéractive", 
    icon: "/images/UX/i_chat.png",
    ready: false,
    hue: 140,
    description: "Exerce-toi directement à parler en immersion.",
  },
  {
    
    pathName: "book", 
    id: "book",
    name: "Livres et histoires", 
    icon: "/images/UX/i_book.png",
    ready: false,
    hue: 300,
    description: "Apprends la langue en lisant des histoires et des livres adaptés à ton niveau",
  }
 
  


]






const msg = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-8 h-8">
<path strokeLinecap="round" strokeLinejoin="round" d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z" />
</svg>

const voc = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-8 h-8">
<path strokeLinecap="round" strokeLinejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z" />
</svg>

const img = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.75} stroke="currentColor" className="w-8 h-8">
<path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
</svg>

const map = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-8 h-8">
<path strokeLinecap="round" strokeLinejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" />
</svg>

const lecon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-8 h-8">
<path strokeLinecap="round" strokeLinejoin="round" d="M4.098 19.902a3.75 3.75 0 005.304 0l6.401-6.402M6.75 21A3.75 3.75 0 013 17.25V4.125C3 3.504 3.504 3 4.125 3h5.25c.621 0 1.125.504 1.125 1.125v4.072M6.75 21a3.75 3.75 0 003.75-3.75V8.197M6.75 21h13.125c.621 0 1.125-.504 1.125-1.125v-5.25c0-.621-.504-1.125-1.125-1.125h-4.072M10.5 8.197l2.88-2.88c.438-.439 1.15-.439 1.59 0l3.712 3.713c.44.44.44 1.152 0 1.59l-2.879 2.88M6.75 17.25h.008v.008H6.75v-.008z" />
</svg>


const chat = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-8 h-8">
<path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
</svg>

const puzzle = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-8 h-8">
<path strokeLinecap="round" strokeLinejoin="round" d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 01-.657.643 48.39 48.39 0 01-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 01-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 00-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 01-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 00.657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 01-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 005.427-.63 48.05 48.05 0 00.582-4.717.532.532 0 00-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 00.658-.663 48.422 48.422 0 00-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 01-.61-.58v0z" />
</svg>


const logos = {Vocabulaire: voc, lecon, Dialogue: msg, puzzle, MindMap: img}

const gameMode = true



const user = ct.user



const deletedUserCards = async (userCards) => {
  console.log('suppression de ', userCards)
  const batch = writeBatch(db);
  userCards?.forEach(uc => {
 
      uc.id && batch.delete(doc(db, "user_cards", uc.id))
  })
   await batch.commit()
  

}

const deleteTests = async (tests) => {
  console.log('suppression de ', tests)
  const batch = writeBatch(db);
  tests?.forEach(uc => {
      
      uc.id && batch.delete(doc(db, "user_tests", uc.id))
  })
   await batch.commit()

}

const deleteDeck = async (decks) => {
  console.log('suppression de ', decks)
  const batch = writeBatch(db);
  decks?.forEach(uc => {
      
      uc.id && batch.delete(doc(db, "user_deck", uc.id))
  })
   await batch.commit()

}




const HomeScreen = (props) => {
  const ct = props.context

  


  const [popoverPos, setPopoverPos] = useState();
  const [hoverDeck, setHoverDeck] = useState()
  const [userCardDeck, setUserCardDeck] = useState()
  const [showGrammar, setShowGrammar] = useState(false)

  const selectedPath = menu.find(m => m.pathName == ct.userWorkspace?.pathName)

  const [packCards, setpackCards] = useState()
  const [sectionCards, setSectionCards] = useState()



  // const cards = ct.cards
  const cards = ct.userWorkspace?.goal_id == "perfect" ? 
  ct.userCards.map(lc => lc.content).filter(c => c != undefined && c.path =="perfect" ) :
  ct.userCards.map(lc => lc.content).filter(c => c != undefined && c.path !="perfect" )



  const _filteredCards = ct.workspace?.classic ? cards : 
  ct.userWorkspace?.goal_id == "perfect" ?  
    cards?.filter(c => !c.deck_id && !c.s_id).sort((a, b) => (b.occ_tt || 0) - (a.occ_tt || 0)) : 
    cards?.filter(c => !c.deck_id && !c.s_id && !c.cm).sort((a, b) => (b.occ_tt || 0) - (a.occ_tt || 0))

    const filteredCards = [..._filteredCards || [], ...ct.nextCards || []]

   




  const islandsInit = gardens.map((i) => {
    const cards = filteredCards?.slice(i.start, i.end) // carte pour la liane   

    // Assurez-vous que `selectedGarden` est défini correctement avant cette opération.
    const decks = ct.decks?.filter(d => d.presentation_mode != "Dialogue").slice(Math.round(i.start / 7), Math.round(i.end / 7));
    const data = getCardsData(ct, cards);
    const mastered = data?.filter((c) => c.state === "mastered").length;
    const toReview = data?.filter((c) => c.state === "to_review").length;
    const toLearn = data?.filter((c) => c.state === "to_learn").length;
    const archived = data?.filter(c => c.state === "archived").length;
    const total = mastered + toReview + toLearn + archived;
    const centMastered = Math.round(((mastered + archived)/ total) * 100) || 0;
  
    
  
    return {
      ...i,
      cards,
      mastered,
      toReview,
      toLearn,
      decks,
      archived,
      total,
      centMastered,
      // content,
    };
  });
  
  // Deuxième étape : Ajout de la propriété `prevMastered` à chaque île.
  const islands2 = islandsInit.map((island, i) => {
    const prevMastered = i > 0 ? islandsInit[i - 1].mastered + islandsInit[i - 1].archived : 1;
    const unlocked = prevMastered >= (islandsInit[i - 1]?.nb || 0)
    return { ...island, prevMastered, unlocked };
  });
  const islands = islands2.map((island, i) => {
    const prevUnlock = i > 0 ? islands2[i - 1]?.unlocked : true
    const unlocked = prevUnlock && island.unlocked
    return { ...island, unlocked };
  });

  const _parts = partsInit.map((part, i) => {

    const cards = ct.userWorkspace?.goal_id == "TOEIC" ? sectionCards : ct.cards?.filter(c => c.s_id == part.id).slice(0, 150)
    const totalCards = cards?.length
    const data = getCardsData(ct, cards);

    

    const mastered = data?.filter((c) => c.state === "mastered").length;
    const toReview = data?.filter((c) => c.state === "to_review").length;
    const toLearn = data?.filter((c) => c.state === "to_learn").length;
    const archived = data?.filter(c => c.state === "archived").length;
    const total = mastered + toReview + toLearn ;
    const centMastered = Math.round(((mastered + archived) / total) * 100) || 0;



    // bug volontaire pour savoir si les lianes hors render qui bug (ici) ou les progress
    const content = <Liane 
    // decks={ct.decks?.filter(d => d.s_id == part.id)}
    popoverPos={popoverPos} 
    index={i}
    setPopoverPos={setPopoverPos} 
    setHoverDeck={setHoverDeck}
    setUserCardDeck={setUserCardDeck}
    setpackCards={setpackCards}
    showCards={ct.showCards}
    filteredCards={cards}
    workspace={ct.workspace}
    context={ct}/>
    
    return {...part, cards, totalCards, mastered, toReview, toLearn,  total, centMastered, content}
  })

  const parts = _parts.map((part, i) => {
    const prevMastered = i > 0 ? _parts[i - 1].mastered : 1;
    const prevTotalCards = (_parts[i - 1]?.totalCards-1 || 0)
    const unlocked = (prevMastered >= prevTotalCards || true)
    // console.log('prevTotalCards', prevTotalCards)
    return { ...part, prevMastered, unlocked, prevTotalCards };
  });





  const handleScroll = () => {
    setPopoverPos();
    ct.setSelectedPack()
    if (window.pageYOffset > 0) {

    } else {

    }
  };
  

  console.log('home...')


  useEffect(() => {
    
    const body = document.querySelector('#currentPage')
    body?.addEventListener('scroll', handleScroll);
    body?.addEventListener('click', (e) => {
      let element = e.target;
      let found = false;
    
      // Parcourez les parents de l'élément jusqu'à ce que vous trouviez un élément avec deck=true ou atteignez le body
      while (element && element !== document.body) {
        if (element.dataset && element.dataset.deck === 'true') {
          found = true;
          break;
        }
        element = element.parentNode;
      }
    
      if (!found) {
        // Déclenchez votre fonction ici
         setPopoverPos();
         ct.setSelectedPack()
        
      }
    });
    
    window?.addEventListener('scroll', handleScroll);
    // Nettoyer l'écouteur d'événement lors du démontage du composant
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [window]);


  useEffect(() => {
    ct.setActiveScreen("home")
    ct.setShowBottomBar(true)
  }, [])

  useEffect(() => {
     setPopoverPos()
     ct.setSelectedPack()
  }, [ct.showDeckId, ct.showCards])








  const formattedDecksGrammar = formatDecks(ct.decks?.filter(d => d.presentation_mode == "Grammar" && d.s_id ))
  // console.log('formattedDecksGrammar', formattedDecksGrammar)

  const modalGrammar = showGrammar && <div onScroll={() => handleScroll()} id="backdrop" className='flex fredoka text-white flex-col items-between justify-between' onClick={() => {
    setShowGrammar(false)
    }} style={{ position: "fixed", top: 0, right: 0, bottom: 0, left: 0, backgroundColor: "rgba(0, 50, 0, 0.5)", zIndex: 500}}>
  <div  onScroll={() => handleScroll()} onClick={(e) => {
    e.stopPropagation()

    setPopoverPos()
    setHoverDeck()
    setUserCardDeck()
    }} className="rounded-xl h-full overflow-scroll p-2 bg-gradient-to-b from-[#6c1bab] to-[#391556] border-4 border-b-[9px] border-black/90 ">
    <div className="mb-4 my-2 ">

      <div onClick={() => setShowGrammar(false)} className="absolute top-2 right-2 z-40 bg-red-500 p-1 rounded-xl border border-b-4 border-red-700" >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div> 
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 mx-auto mb-3 mt-8 h-8">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25" />
</svg>
      <h2 className="text-white  text-center mb-2">Leçon & Grammaire </h2>

    </div>
    <div className="mt-8">
      {formattedDecksGrammar?.sort((a,b) => (a.order || 0) - (b.order || 0)).map(d => <div 
      onClick={(e) => {
        e.stopPropagation()
        const posY = e.currentTarget.getBoundingClientRect().top - 20
        const posX = 50

        setPopoverPos({left: posX , top: posY + 70})
        setHoverDeck(d)
        setUserCardDeck(d.userCards)
      }}
      key={d.id} className="flex justify-between flex-wrap gap-1 border-2 border-b-4 border-black/80 px-3 py-2 rounded-[6px] mt-1 bg-black/30 ">
        <div className={`flex gap-2 ${d.name.length > 30 ? "text-sm" : ""}`}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="-ml-2 mt-1 w-4 h-4 text-white/80">
  <path strokeLinecap="round" strokeLinejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z" />
</svg>
 {d.name}</div>
        <div>
          {!ct.userDecks?.find(ud => ud.deck_id == d.id) && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 -mr-1 text-white/70"><path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" /> </svg>}
</div>
      </div>)}
  </div>
  </div>
</div>

  


  let prevState = ""
  let i = 0



  useEffect(() => {
    ct.setVisitedUser()
  }, [])


  useEffect(() => {
     if (ct.user && !ct.workspace) {
      ct.setShowTuto("Tutoriel")
    } else {
      ct.setShowTuto(false) 
    }
  }, [ct.workspace, ct.user])





  const goal_id = ct.userWorkspace?.goal_id || "classic"

  const wordList = ct.userCards?.map(c => filteredCards?.find(card => card.id == c.card_id && card.model == "lang-en")).filter(c => c?.term)

  const wordCounterElement = <div className="flex my-4 max-w-[224px] mx-auto item-center bg-gradient-to-r  from-[#a855f74f] border-[#311f53] to-[#a855f74f]  justify-start text-white text-shadow gap-4 rounded-xl p-2 border-2 border-b-4">
  <img src="images/UX/crown.png" className="h-[36px]" />
  <div className="flex items-center gap-2 fredoka"><span className="text-2xl">{wordList?.length}</span> mots appris</div>
</div>


 const user_card_stacked = ct.userCards?.filter(uc => uc.stacked && !uc.archived)




  const paths = [
    {
      path_id: "classic_toeic",
      name: "Notions de base ", 
      title: "Débutant", 
      description: "",
      optional: true,
      goal: "Apprends à comprendre 80% de n'importe quel texte en Anglais avec les 1200 mots les plus utilisés"
    }, 
    {
      path_id: "toiec_path",
      name: "Préparation TOEIC", 
      title: "Intermédiaire", 
      description: "Tu sais déjà lire en Anglais ?",
      goal: "Apprends à comprendre 95% de n'importe quel contenu du TOIEC"
  }
  ]




  const nb_grammar = ct.userDecks?.filter(ud => ct.decks?.find(d => d.id == ud.deck_id)?.presentation_mode == "Grammar")?.length
  const nb_voc = ct.userCards?.filter(c => !filteredCards?.find(card => card.id == c.card_id)?.deck_id)?.length
  const nb_training = getUniqueTest(ct.userTests)?.length || 0

  const perfectUserCards = ct.userCards?.filter(u => u.path == "perf")


  
  const perfectCards = filteredCards?.filter(card => perfectUserCards?.find(uc => uc.card_id == card.id))

  


  const perfectLiane =  <Liane 
 decks={null}
 popoverPos={popoverPos} 
 index={i}
 setPopoverPos={setPopoverPos} 
 setHoverDeck={setHoverDeck}
 workspace={ct.workspace}
 setUserCardDeck={setUserCardDeck}
 setpackCards={setpackCards}
 showCards={ct.showCards}
 filteredCards={perfectCards}
 context={ct}
 />



 const getCardsSections = async (wp_id, section_id) => {
  console.log('getCArs OTIEC 🟢🟢🟢🟢')
  const q = query(collection(db, 'cards'), where('workspace_id', "==", wp_id), where('s_id', "==", section_id))
  const querySnapshot = await getDocs(q);
  const cards = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
  setSectionCards(cards)
 }  

 useEffect(() =>{
  if (ct.userWorkspace.goal_id != "TOEIC") return
  if (!ct.workspace?.id) return
  // console.log('perfectIDS', partsInit)
  const partSelected = partsInit?.[ct.userWorkspace?.selectedPartTOEIC]?.id
  
  getCardsSections(ct.workspace?.id, partSelected)
  // console.log('partSelected !! s_id', partSelected)

  
  // on load les cartes en fonction de parti INIT
   
 }, 
[ct.userWorkspace.goal_id, ct.userWorkspace?.selectedPartTOEIC, ct.workspace?.id])


let pathName =  ct.userWorkspace.goal_id
pathName = pathName == "learn" ? "Débutant" : pathName
pathName = pathName == "perfect" ? "Avancé" : pathName



  return (
    <>
      
    <div id="currentPage" className={`min-h-screen max-h-screen overflow-scroll ${gameMode ? "text-white" : ""}`} 
    style={{background: gameMode && 'linear-gradient(180deg, #5A3591 0%, #36206C 100%)'}}
    >
     

      <header className={`w-full relative  ${goal_id == "TOEIC" ? "h-[100px]": ""} transition  duration-[3000] text-center`}
      
       >


        {/*       ${goal_id == "perfect" ? "bg-blue-500"  : goal_id == "TOEIC" ? "bg-purple-500" : "bg-[#0088ff]"}   */}
   

       
       <div className={`absolute  z-0 ${goal_id == "TOEIC" ? "h-[112px]" : "h-[112px]"}  ${goal_id == "perfect" ? "bg-blue-500"  : goal_id == "TOEIC" ? "bg-purple-500" : "bg-[#0088ff]"} top-0 left-0 right-0 `} style={{
  filter: `hue-rotate(${goal_id == "perfect" ? selectedPath?.hue || 0 : 0}deg)`
       }}>
        <div className={`absolute   z-0 ${goal_id == "TOEIC" ? "h-[110px]":"h-[110px]"} -bottom-[12px] right-0 left-0 opacity-50`} style={{
            backgroundImage: "url(/images/seeds/backfleur.png)",
            backgroundRepeat: "repeat-x",
            backgroundPositionY: "bottom",
            // filter: `hue-rotate(${selectedPath.hue}deg)`
          }}


          
          ></div>
      
        <div className="absolute h-[100px] right-0 -bottom-[12px] left-0" style={{
          backgroundImage: "url(/images/seeds/bottom-store.png)",
          backgroundRepeat: "repeat-x",
          backgroundPositionY: "bottom",
          // filter: `hue-rotate(${selectedPath.hue}deg)`
          }}></div>

        </div>


      <div className="z-90 relative flex flex-col">
     
       
        <h1 className="fredoka mt-[50px] text-white text-2xl text-shadow mb-4">{goal_id == "perfect" ?  (selectedPath?.name || "Parcours avancés") : goal_id == "TOEIC" ? 'Jardins du TOEIC' : "L'archipel du débutant"  }</h1>
          </div>

   


      

{  ( goal_id == "perfect" && ct.userWorkspace?.pathName) &&  <div>

<div id="cpoucou" style={{ filter: `hue-rotate(0)deg)`}} className=" absolute z-10 mt-1 left-0 right-0   mb-6 flex gap-1 justify-center px-4">
<div className={`absolute left-0  z-0 h-[1px] ${ct.userWorkspace?.pathName == "voc" ? "-mt-[15px]" : "-mt-[10px]"} right-0 flex justify-center`}>
<svg  className={`-ml-[2px] transition-all ${ct.userWorkspace?.pathName == "voc" ? "h-[80px] rotate-[-90deg] w-[80px] -mt-2 " : "h-[68px] w-[68px] -mt-1"} `}  viewBox="0 0 61 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.87459 19.6297L27.8682 7.56866C29.8176 6.49966 32.1781 6.50112 34.1261 7.57254L55.1325 19.126C57.2095 20.2684 58.5 22.4509 58.5 24.8214V48.8307C58.5 51.1425 57.2721 53.2804 55.2752 54.4452L34.1218 66.7847C32.1575 67.9305 29.7371 67.9665 27.7396 66.8794L5.89292 54.9901C3.80168 53.8521 2.5 51.6617 2.5 49.2808V25.329C2.5 22.9556 3.79358 20.7709 5.87459 19.6297Z" fill="#B296FF" stroke="#1E0C32" stroke-width="5"/>
<path d="M5.87459 16.6297L27.8682 4.56866C29.8176 3.49966 32.1781 3.50112 34.1261 4.57254L55.1325 16.126C57.2095 17.2684 58.5 19.4509 58.5 21.8214V45.8307C58.5 48.1425 57.2721 50.2804 55.2752 51.4452L34.1218 63.7847C32.1575 64.9305 29.7371 64.9665 27.7396 63.8794L5.89292 51.9901C3.80168 50.8521 2.5 48.6617 2.5 46.2808V22.329C2.5 19.9556 3.79358 17.7709 5.87459 16.6297Z" fill="#6326A2" stroke="#311351" stroke-width="5"/>
</svg>

  <div onClick={() => ct.fire.updateUserWorkspace({...ct.userWorkspace, pathName: "voc"}, ct) } className={` ${ct.userWorkspace?.pathName == "voc" ? "h-[1px] w-[1px]" : "h-[1px] w-[1px]"} transition-all relative  rounded-2xl`}>
    {user_card_stacked?.length ? <div className="h-[20px] absolute rounded-full flex justify-center items-center  right-1 w-[20px] bg-red-500 fredoka font-semibold text-shadow">{user_card_stacked?.length}</div> : ""}
  </div>
  </div>

  <div className="mx-auto rounded-full border-b-4  border  border-black bg-[#411067] w-[400px] justify-around flex">
  {menu.map(m => <div onClick={() => ct.fire.updateUserWorkspace({...ct.userWorkspace, pathName: m.pathName}, ct)} className="relative">
    
    <img src={m.icon}  className={`  ${ct.userWorkspace?.pathName == m.pathName ? "scale-[2.1]" : "grayscale opacity-50 "}  transition h-[32px]  hover:rotate-[10deg] `}/>
    {props.lockerScreen?.[m.id]?.access && !props.lockerScreen?.[m.id]?.unlocked && ct.userWorkspace?.pathName != m.pathName && <RedDot className={"absolute top-1 right-1"} />}
    </div>)}</div></div></div>}

    
    
    <div className="flex items-center absolute top-0 right-0 left-0 border-black/10 bg-[#ffffff08] py-[6px] pt-[10px]  mb-4 px-2 justify-between fredoka text-shadow">
     
    <div onClick={() => ct.setShowMenu(true)} className={`text-shadow px-[6px] py-[4px] flex items-center gap-2 rounded-md font-bold text-xl`} style={{backgroundColor: "rgba(0,0,0,0.1)"}}>  <img  onClick={() => ct.setShowMenu(true)} className='h-5 w-5' src={'/images/UX/menu.svg'}/>  </div>


      <div className="gap-2   items-center flex px-2 justify-center bg-black/10 rounded-2xl " >
          <Link to={'/choose'}> <img src={ct.workspace.img}  className="h-[20px] border border-slate-900 mx-auto rounded-full"/></Link>
          <Link className="flex gap-2 items-center" to={"/goal"}>{pathName}
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
</svg>

          </Link>
      </div>
      <Link  to={'/me'} >
        <div className="bg-black/10 border-black/20 p-1 h-[26px] w-[26px] flex  items-center justify-center border  rounded-full">
          {/* <img src={createAvatarSvg({code: ct.user?.avtc})} className="h-[30px] scale-[1.2] mb-1 " /> */}
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
</svg>

          </div>

      </Link>
          
    </div>

     
    </header>

     

      <main className="max-w-[500px] fredoka mt-[30px] mb-[200px] mx-auto p-2"> 

      {ct.user?.demo && <DemoPreview goal_id={goal_id} userCards={ct.userCards}/>}

          
             
      
        {goal_id == "perfect" && <PerfectScreen   
                    popoverPos={popoverPos} 
                    setPopoverPos={setPopoverPos} 
                    setHoverDeck={setHoverDeck}
                    setUserCardDeck={setUserCardDeck}
                    setpackCards={setpackCards} 
                    lockerScreen={props.lockerScreen} 
                    liane={perfectLiane} 
                    menu={menu} 
                    context={ct} 
                    />}
       

     

    
       {goal_id == "roseta" && <RosetaScreen setUserCards={ct.setUserCards} userCards={ct.userCards} />}

        {goal_id == "TOEIC" && <div className="">
      
         
          {ct.userWorkspace?.path_id && <div className="flex justify-center mb-4  items-center text-center gap-2">
            <div onClick={() => ct.setShowModalWords(true)} className="min-w-[100px]  text-white/70 rounded-xl p-2 px-4 bg-[#5f2d9a] border-2 border-b-4 border-[#34283f]">
              <div className="flex justify-center items-center rounded-xl py-1 px-2 gap-2 text-xl font-bold text-white">{nb_voc}
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" /></svg>
              </div>
              <div className="text-white/50 text-xs ">Vocabulaire</div>
            </div>

            <div onClick={() => setShowGrammar(true)}  className="min-w-[100px]  text-white/70 rounded-xl bg-pink-500/20 border-2 border-b-4 border-[#34283f] p-2 px-4">
              <div className="flex justify-center items-center rounded-xl py-1 px-2 gap-2 text-xl font-bold text-white">{nb_grammar}
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25" /></svg>

              </div>
              <div className="text-white/50 text-xs">Grammaire</div>
            </div>

            <div onClick={() => ct.setShowModalTraining(true)}  className="min-w-[100px]  text-white/70 rounded-xl bg-[#7134ea] p-2 px-4 border-2 border-b-4 border-[#34283f]">
              <div className="flex justify-center items-center rounded-xl py-1 px-2 gap-2 text-xl font-bold text-white">{nb_training}
              <img src="images/UX/dumbel.png" className='h-10 w-10 -mb-2 -mt-2 -ml-2 rotate-[344deg]' />
              </div>
            
              <div className="text-white/50 text-xs">Entrainement</div>
            </div>
        </div>}

            {ct.userWorkspace?.path_id ? 
            <div>
          
                
                  
              
                  <div>
               
                   {parts.map((part, index) => <div 
                   
                   key={part.id} >
                   <div className={` ${ct.userWorkspace?.selectedPartTOEIC == index ? "mb-6 mt-4" : "scale-[0.8] -mb-1"} ${part.unlocked ? "" : "grayscale-[0.8] opacity-50"} bg-black/10 border-2 border-b-4 border-black/40 transition rounded-2xl `}>
                      <div onClick={() =>  ct.setUserWorkspace({...ct.userWorkspace, selectedPartTOEIC: ct.userWorkspace?.selectedPartTOEIC == index  ? null : index})}  className="bg-black/10 relative gap-2  justify-center text-center px-6 py-3 tracking-wider	">
                        {/* <img src={part.img} className="h-[50px] w-[55px] absolute -top-2 left-2"/> */}
                        <div  className="flex items-center justify-center">
                          {part.name} 
                        {!part.unlocked && <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 opacity-50 absolute top-3 right-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                          </svg>
                          }</div>
                          <div className="h-[8px] mt-4 relative w-full bg-black/30 rounded">
                            <div className="absolute bg-amber-500 h-[8px] rounded " style={{width: `${part.centMastered}%`}}></div>
                          </div>
                        </div>
                        
                          {ct.userWorkspace?.selectedPartTOEIC == index && part.unlocked && <Liane 
                          decks={ct.decks?.filter(d => d.s_id == part.id)}
                          popoverPos={popoverPos} 
                          index={index}
                          workspace={ct.workspace}
                          setPopoverPos={setPopoverPos} 
                          setHoverDeck={setHoverDeck}
                          setUserCardDeck={setUserCardDeck}
      
                          setpackCards={setpackCards}
                          userTests={ct.userTests}
                          showCards={ct.showCards}
                          filteredCards={part.cards}
                
                          context={ct}
                        /> }
                    </div>

                   </div>)} 

                   
                
                    </div>
                  
                   

           </div> 
            : 
            <div className="p-4">
              <div className="text-center text-xl font-semibold mb-6 mt-12 ">Choisis ton parcours</div>
              {paths.map((p, i) =>  <div key={`${p.name}-${i}`} className="px-4 bg-black/30 mb-2 py-2 rounded-xl border-2 border-b-4 border-black/80" 
              onClick={() => ct.fire.updateUserWorkspace({...ct.userWorkspace, path_id: p.path_id}, ct)}>
                
                <div className="flex gap-2 justify-between mb-2">
                  <div className="font-semibold text-lg">{p.name}</div>
               
                </div>
                <div>{p.description}</div>
                <div className="text-purple-300">{p.goal}</div>
                </div>)}
             
            </div>
          }
                
              
             
            </div>
            
            }

        {wordList?.length > 1 && goal_id == "classic" && wordCounterElement}


        

          {/* {selectedGarden?.name} */}
          {goal_id == "learn" && <div className="mt-12">
        
            {islands?.map((garden,i) => <div className={`w-full flex-wrap transition flex max-w-[500px] ${(ct.userWorkspace?.selectedGardenNb) == i ? "my-12 mb-[80px]" : "-mt-[30px] px-4"} ${i%2!=0 ? "justify-end " : ""} `}>
              <div onClick={() => ct.setUserWorkspace({...ct.userWorkspace, selectedGardenNb: ct.userWorkspace?.selectedGardenNb == i ? null : i})} className={` ${(ct.userWorkspace?.selectedGardenNb) == i ? " w-full" : !garden.unlocked ? "scale-[0.7] w-[170px]" : "w-[170px] " } ${!garden.unlocked ? "grayscale-[0.9]  opacity-50 ": ''} transition  bg-[#47ACA9] p-2 border-2 mb-4  border-[#241256] self-end relative rounded-xl  text-center h-[105px]`} style={{backgroundColor: garden.bgColor}}>
               
                <div className=" rounded-xl mx-auto max-w-[200px] relative h-[42px]">
                  <img className="absolute bottom-0 border-red-300 w-auto" src={garden.img}/>
                </div>
              
               
                
                {/* <div>({garden.start}/{garden.end}) | ({Math.round(garden.start/7)}/{Math.round(garden.end/7)}) {Math.round(garden.nb/7)}</div> */}
          
                <div className={` text-center  font-semibold text-sm z-10`}>{garden.name}</div>
                <div className="rounded-b-xl left-0 right-0 bg-[#241256] absolute -bottom-1 h-[36px]">
                <div className="p-1 rounded-b-xl left-0 right-0 bg-[#47ACA9] absolute bottom-[28px] " style={{backgroundColor: garden.bgColor}}></div>
               
            
                <div className="flex px-2 gap-2 mt-3 items-center justify-between">
                  <div className="bg-[#41266F] grow rounded-xl relative h-[8px]" >
                    <div className="bg-amber-500 rounded absolute h-[8px]" style={{width: `${garden.centMastered}%`}}></div>
                  </div>
                  {garden.unlocked && <div className="text-white/50 text-sm">{garden.mastered}/{garden.end - garden.start}</div>}
                </div>
             
                </div>
                </div>

                {  (ct.userWorkspace?.selectedGardenNb ) == i &&  <div className="w-full bg-[#351E69] px-2 rounded-xl -mt-8">
              
                 {garden.unlocked ? <Liane 
      popoverPos={popoverPos} 
      // decks={garden.decks}
      setPopoverPos={setPopoverPos} 
      nbNextLocked={((garden.end - garden.start) - garden.cards.length)/4}
      setHoverDeck={setHoverDeck}
      workspace={ct.workspace}
      setUserCardDeck={setUserCardDeck}
      setpackCards={setpackCards}
      filteredCards={garden.cards}
      showCards={ct.showCards}
      context={ct}
    /> : 
                 <div className="p-8 text-center text-white/80">
                
                  <div className="flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                  </svg>
                  </div>
                  <div className="mb-2 text-sm">Vérouillé</div>
                  <div>Tu dois maîtriser tes souvenirs de l'ile précédente avant de pouvoir apprendre ici</div>
                  <div className="flex justify-center my-2"><div className="bg-white/10 rounded-full px-2 ">{garden.prevMastered}/{(gardens[i-1]?.nb||0)} à maitriser</div></div>
                  </div>
                 }
              </div>
          }
                
                </div>
            )}

          </div>}
            

          




                {goal_id == "learn" && <div className="text-center bg-white/10 rounded-xl pb-4 mx-4 p-2 text-white/70 mt-24">
                  <img src="/images/seeds/goldcup.png" className="mx-auto -mt-12 w-[72px]"/>
                  <h2 className="text-white ">Maitrise de la langue à 80%</h2>
                  <p className="text-sm">Grâce aux 1200 mots que tu viens d'apprendre,
                  tu es désormais capable de lire en {ct.workspace?.name} et enrichir ton vocabulaire naturellement</p>
                  <div className="mt-8 bg-white/10 p-1 px-6 rounded-md inline-block mx-auto">
                  <div>Perfectionner mon {ct.workspace?.name}</div>
                  
</div>
 </div>}
               


      
              
                <div className="text-center mt-[160px] opacity-50">
                  {goal_id == "learn" && ct.user?.admin && <div className="b px-3 py-1 rounded-xl bg-white/10 mx-auto inline-block" onClick={() => {
                    deletedUserCards(ct.userCards);  
                    ct.setUserCards([])
                    deleteTests(ct.userTests);
                    ct.setUserTests([])
                    deleteDeck(ct.userDecks);
                    ct.setUserDecks([]);
                    ct.fire.updateUser({...ct.user, tt_dia: null, tt_for: null, tt_pla: null, tt_sta: null}, ct)
                    const newUserWorkspace = {...ct.userWorkspace, timeSpend: 0, timeSpendForest: 0, xp_table: [], total_xp: 0}
                    ct.setUserWorkspace(newUserWorkspace);
                    ct.fire.updateUserWorkspace(newUserWorkspace);
                  }}>Réinitialiser toutes mes cartes</div>}
                
                  <div className="hidden" onClick={() => {deletedUserCards(ct.userCards);  ct.setUserCards([])}}>RESET cartes ({ct.userCards?.length})</div>
                  <div className="hidden" onClick={() => {deleteDeck(ct.userDecks); ct.setUserDecks([])}}>RESET cours ({ct.userDecks?.length})</div>
                </div>

      </main>

     
    </div>
   
    {popoverPos && <Popover  
      deck_id={hoverDeck?.id} 
      packCards={packCards}
      userCards={userCardDeck} 
      iconLeft={true} 
      setPopoverPos={setPopoverPos} 
      setShowDeckId={(e) => ct.setShowDeckId(hoverDeck.id)} 
      pos={popoverPos} 
      showAction={true}
      context={ct}/>}


      {modalGrammar}


    </>
  );
};

export {HomeScreen};