import { addDoc, doc, query, collection, limit, orderBy, startAfter, getDocs, where, updateDoc,getDoc, deleteDoc, startAt } from "@firebase/firestore";
import { db, app} from "../config.js";
function replaceKeywords(template, replacements) {
    return template.replace(/@(\w+)/g, (match, key) => replacements[key] || match);
  }

function notnull(obj) {
    if (typeof obj === 'object' && obj !== null) {
        const newObj = {};
        for (const [key, value] of Object.entries(obj)) {
            if (value !== null) {
                newObj[key] = value;
            }
        }
        return newObj;
    } else {
        return obj;
    }
  }

const getWorkspaces = async () => {
    console.log('getWorkspaces 🟢')
    const q = query(collection(db, 'workspaces'))
    const querySnapshot = await getDocs(q);
    const workspaces = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id})).sort((a, b) => {
        return parseInt(a.order, 10) - parseInt(b.order, 10);
      });
   return workspaces
  }

const getNotifications = async (ct) => {
    console.log('getNotification 🟢')
    const currentDate = new Date();
    const q = query(
        collection(db, 'notifications'),
        where('user_id', '==', ct.user?.id),
        where("date", '>', currentDate)
      );

    //  const q = query(
    //     collection(db, 'notifications'),
    //     where("date", '<', currentDate),
    //     where('sended', "==", false)
    //   );
    const querySnapshot = await getDocs(q);
    const notifications = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
    ct.setNotifications(notifications)

   return notifications
  } 

const addNotifications = async (ct, coach, user) => {
        if (!user.fcmToken) {
            console.log('Pas de token pas de notification 🔴', user)
            return 
        }

  
        const remind1 =  coach.notifications?.remind1.map(notif => replaceKeywords(notif, {name: user?.name, lang: ct.workspace?.name}))[(user?.remind1_i || 0) % coach.notifications?.remind1.length]
        const remind2 =  coach.notifications?.remind2.map(notif => replaceKeywords(notif, {name: user?.name, lang: ct.workspace?.name}))[(user?.remind2_i || 0) % coach.notifications?.remind2.length]
        const lastRemind =  coach.notifications?.lastRemind.map(notif => replaceKeywords(notif, {name: user?.name, lang: ct.workspace?.name}))[(user?.lastRemind || 0) % coach.notifications?.lastRemind.length]
        const notifications = [remind1, remind2, lastRemind]
       
        // supprimer toutes les notifications comeback de l'utilisateur
       
        const _notifications = await getNotifications(ct)
        _notifications.forEach(async(n) => {
            const ref = await deleteDoc(doc(db, "notifications", n.id));
            console.log('notification supprimée 🔴')
        })
     
        

        let now = new Date();
        let day1 = new Date();
        day1.setDate(now.getDate() + 1); // Ajoute un jour
        day1.setHours(18, 0, 0, 0); // Règle l'heure à 18h

        let day2 = new Date();
        day2.setDate(now.getDate() + 2); // Ajoute deux jours
        day2.setHours(18, 0, 0, 0); // Règle l'heure à 18h

        let day5 = new Date();
        day5.setDate(now.getDate() + 5); // Ajoute cing jours
        day5.setHours(18, 0, 0, 0); // Règle l'heure à 18h

        const dates = [day1, day2, day5]
        const newNotifications = []
       
        notifications.forEach(async(n) => {
            const notif = {content: n, date: dates[notifications?.indexOf(n)], user_id: user.id, token: user.fcmToken, type: "comeback", sended: false, coach: coach.id, img: coach.img_round, title: coach.name}
            const ref = await addDoc(collection(db, 'notifications'), notif);
            newNotifications.push({...notif, id: ref?.id})
            
            
        })
        // Convertissez la dernière connexion en millisecondes pour JavaScript (JS utilise des millisecondes)
        const lastConnexionDate = user?.last_connexion?.seconds ? new Date(user?.last_connexion.seconds * 1000) : (user?.last_connexion || new Date())
        const currentDate = new Date();
        const diffMilliseconds = currentDate.getTime() - lastConnexionDate.getTime();
        const diffSeconds = diffMilliseconds / (1000 );
        const diffHours = diffMilliseconds / (1000 * 60 * 60);
        const newUser = {
            ...user, 
            remind1_i: diffHours > 16 ? (user?.remind1_i || 0) + 1 :( user?.remind1_i || null) ,
            remind2_i: diffHours > 48 ? (user?.remind2_i || 0) + 1 : (user?.remind2_i || null),
            lastRemind_i: diffHours > 96 ? (user?.lastRemind_i || 0) + 1 : (user?.lastRemind_i || null),
            last_connexion: new Date(),
         }
         updateUser( newUser, ct)

        ct.setUser(newUser)
       
        ct.setNotifications([...ct.notifications || [], newNotifications])
        getNotifications(ct)
        
     // return {...deck, id: ref?.id}

  }

const getChildCards = async (card) => {
    console.log('getChildCards for 🟢', card.id);
    const cardsCollection = collection(db, 'cards');
    const q = query(cardsCollection, where('original_card', '==', card.id));
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));

    return data;
}

  const getCards = async (wp) => {
    console.log('GET CARD 🟢🟢 🟢🟢 🟢🟢')
    const cardsRef = query(collection(db, "cards"), where("workspace_id", "==", wp.id), limit(5000))
    const snapshot = await getDocs(cardsRef);
    const _cards = snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
    const sortedCards = _cards.sort((a, b) => a.rank - b.rank) // Tri par ordre croissant de rank
    return sortedCards
  }

  const getDecks = async (wp) => {
    console.log('GET CARD 🟢🟢 🟢🟢 🟢🟢')
    const cardsRef = query(collection(db, "decks"), where("workspace_id", "==", wp.id), limit(5000))
    const snapshot = await getDocs(cardsRef);
    const _decks = snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
    const sortedDecks = _decks.sort((a, b) => a.rank - b.rank) // Tri par ordre croissant de rank

    return sortedDecks
  }

  const getDailies = async (uwp_id) => {
    console.log('GET DAILIES 🟢🟢 🟢🟢 🟢🟢')
    const cardsRef = query(collection(db, "daily"), where("uwp", "==", uwp_id), limit(5000))
    const snapshot = await getDocs(cardsRef);
    const _decks = snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
    const sortedDecks = _decks.sort((a, b) => a.date - b.date) // Tri par ordre croissant de rank

    return sortedDecks
  }

const addUserDeck = async (deck, ct) => {
    console.log('addDeck 🟣', deck)
    const ref = await addDoc(collection(db, 'user_deck'), deck);

    ct.setUserDecks([...ct.userDecks || [], {...deck, id: ref?.id}])
    return {...deck, id: ref?.id}
}

const updateCard = async (card) => {
    console.log('updateCard 🟣', card)
    const ref = await updateDoc(doc(db, 'cards', card.id), card);

 }

const addDeck = async (newDeck) => {
    console.log('adding new deck')
    const docRef = await addDoc(collection(db, "decks"), newDeck);
    await updateDoc(doc(db, 'decks', docRef.id), {...newDeck, id: docRef.id});

  }

const getTests = async (wp, ct) => {
    console.log('GET TEST 🟢🟢 🟢🟢 🟢🟢')
    const dataRef = query(collection(db, "tests"))
    const snapshot = await getDocs(dataRef);
    const _data = snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))

    return _data
  }

const getPages = async (test_id, ct) => (await getDocs(query(collection(db, "pages"), where('test_id', "==", test_id), limit(1000)))).docs.map(doc => ({...doc.data(), id: doc.id}));
const getPagesPart = async (test_id, index_section, ct) => {

    const firstQuery = query(
        collection(db, "pages"),
        where("test_id", "==", test_id), where("index_section", "==", index_section - 1)
      );

    const documentSnapshots = await getDocs(firstQuery)
    const pageData = documentSnapshots.docs.map(doc => ({...doc.data(), id: doc.id}));

    return pageData

}

const getUserTest = async (user_id, wp_id, ct) => (await getDocs(query(collection(db, "user_tests"), where('user_id', "==", user_id), limit(1000)))).docs.map(doc => ({...doc.data(), id: doc.id}));

const updateDb = async (fieldName, newUserTests) => {
    console.log('update de 🟣' + fieldName, newUserTests)
    await updateDoc(doc(db, fieldName, newUserTests.id), newUserTests);
 }

const createDb = async (fieldName, newData) => {
    console.log('adding new 🟣' + fieldName)
    const docRef = await addDoc(collection(db, fieldName), newData);
    await updateDoc(doc(db, fieldName, docRef.id), {...newData, id: docRef.id});
    return docRef.id
  }



const addDailySteak = (uwp, ct) => {
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    const yesterday = new Date(new Date().setHours(-24, 0, 0, 0));
    // console.log('uwp.lastStreakDate', uwp.lastStreakDate)
    const lastStreakDate = uwp.lastStreakDate instanceof Date ? uwp.lastStreakDate : uwp.lastStreakDate?.toDate() 

    if (lastStreakDate - today == 0) {
        // console.log('daily steak déjà ajouté')
        return
    }
    let newStreak
    if (lastStreakDate - yesterday == 0 ) {
        // console.log('dernier daily etait hier')
        newStreak = (uwp.streak||0) + 1
    } else {
        newStreak = 1
    }
    updateUserWorkspace({...uwp, streak: newStreak, lastStreakDate: today})
    ct.setUserWorkspace({...uwp, streak: newStreak, lastStreakDate: today})
}

const updateDaily = async(updatedDaily, ct) => {

    
    let currentDaily 
    const dateWithoutTime = new Date(new Date().setHours(0, 0, 0, 0));

    // on cherche si daily existe
    
    if (updatedDaily?.date - dateWithoutTime == 0) {
        console.log('update du currentdaily 🟣', updatedDaily)
        await updateDoc(doc(db, 'daily', updatedDaily.id), updatedDaily);
        ct.setUserDaily(updatedDaily)
        if (updatedDaily.xp > 7) {
            // console.log('palier atteind !')
            addDailySteak(ct.userWorkspace, ct)
        }

    } else if (ct.userWorkspace?.id) {

        const q = query(collection(db, 'daily'), where('uwp', '==', ct.userWorkspace?.id), where('date', "==", dateWithoutTime))
        const querySnapshot = await getDocs(q);
        const _userDailies = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))

        if (_userDailies.length) {
            const _userDaily = {..._userDailies[0], date: new Date(_userDailies[0].date.seconds*1000)}
            // console.log('update du currentdaily trouve en db 🟣', _userDaily)
            await updateDoc(doc(db, 'daily', _userDaily?.id), _userDaily);
            ct.setUserDaily(_userDaily)
        } else {
            console.log('création user daily 🟢')
            // attention avant de lancez la fonction il faut qu'il y ai un uwp
            const newDaily = {date: dateWithoutTime, uwp: ct.userWorkspace.id}
      
            currentDaily = newDaily
            const ref = await addDoc(collection(db, 'daily'), newDaily);
            ct.setUserDaily({...newDaily, id: ref?.id})
        }


        
      
        
    }

    // si il existe pas on le créer
        // on regarde si le daily d'hier existe
        // s'il existe cs ++ sinon cs =0 

    // on ajoute new daily dans le daily
    // on met à jout le contexte
}

const updateUser = async (user, ct) => {
    ct.setUser(user)
    console.log('updateUser 🟣', user)
    const ref = await updateDoc(doc(db, 'users', user.id), user);
   

}

const updateUserDeck = async (deck) => {
    console.log('updateUserDeck 🟣', deck)
    const ref = await updateDoc(doc(db, 'user_deck', deck.id), deck);
   

}

    const updateUserWorkspace = async (userWorkspace, ct) => {
        console.log('updateUseruserWorkspace 🟣', userWorkspace)
        if (ct) {
            ct.setUserWorkspace(userWorkspace)
        }
        const ref = await updateDoc(doc(db, 'user_workspace', userWorkspace.id), userWorkspace);
    
      
    }

    const updateUserCard = async (userCard, ct) => {
        console.log('updateUserCard 🟣', userCard)
        if (ct) {
            ct.setUserCards(ct.userCards.map(c => c.id == userCard.id ? userCard : c))
        }
        const ref = await updateDoc(doc(db, 'user_cards', userCard.id), userCard);  
    }

const remove = async (id, collection_name) => {
    console.log('remove 🟣', collection_name, id)
    const ref = await deleteDoc(doc(db, collection_name, id));

}

const fire = {
    addDeck, 
    updateUserDeck, 
    remove, 
    addUserDeck, 
    updateDaily,
    updateCard, 
    updateUser,
    getWorkspaces,
    getCards,
    getDecks,
    getDailies,
    getChildCards,
    addNotifications,
    getNotifications,
    updateUserCard,
    updateUserWorkspace,
    getTests,
    getPages,
    getPagesPart,
    getUserTest,
    updateDb, 
    createDb
}


export {fire}