import { createContext, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc } from "firebase/firestore";

import { db } from "../../config.js";
import {displayDuration, displayDate} from '../../utilities/helper/helper'

const Alert = ({alerts, setAlerts}) => {



  const date3s = new Date(new Date().getTime() - 10000)

  
  useEffect(() => {


    // Supprimer l'alerte après 3 secondes
    const timeout = setTimeout(() => {
      // Code pour supprimer l'alerte ici
      setAlerts(null)
      setAlerts(null)
    }, alerts && alerts[0]?.time || 1000);

    // Nettoyer le timeout lors de la mise à jour des alertes
    return () => clearTimeout(timeout);
  }, [alerts]);
 
  

  return (
    <div className={`fredoka fixed ${alerts?.length ? "bottom-0 rotate-5" : "-bottom-[140px]"} z-[999] rounded-top-xl transition-all right-0 left-0`}>
    
        <div className=' bg-gradient-to-b from-purple-600 to-indigo-600 bg-blue-500 items-center text-white gap-4 flex p-4 pb-8'>
          {alerts?.length && alerts[0]?.icon && <div>{alerts[0]?.icon}</div>}
          <div>
            <h4 className='text-xl font-semibold mb-2'>{alerts?.length && alerts[0]?.title}</h4>
            <div>{alerts?.length && alerts[0]?.content}</div>
          </div>
        </div>
    </div>
  );
};

export {Alert};