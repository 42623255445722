import  React, { createContext, useMemo, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc, query, getDocs, doc, updateDoc, where } from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";
import { getFunctions, httpsCallable } from "firebase/functions";




import { db, app } from "../../config.js";
import {fire} from '../🔥db.js'
import { useNavigate } from "react-router-dom";
import { findWord, clean, gpt, numberSentences, splitNumeredSentence, sentenceEndRegex, capitalizeFirstLetter, imageGenerator, speak } from "../../utilities/helper/text.js";
import { NewScreen } from './newScreen.js';
import { CultureG, getVoiceByIndex, synthesizeSpeech, voices } from './cultureG.js';

import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';

import HanziWriter from 'hanzi-writer';
// import Tesseract from 'tesseract.js';

const HandwritingRecognition = () => {
    const canvasRef = useRef(null);
    const [drawing, setDrawing] = useState(false);
    const [result, setResult] = useState('?');
    const [confidence, setConfidence] = useState(null);
    const [lang, setLang] = useState('eng');

    const clearCanvas = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        setResult('?');
        setConfidence(null);
    };

    const startDrawing = (e) => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        setDrawing(true);
        ctx.beginPath();
        ctx.moveTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
        ctx.lineWidth = 20;
    };

    const draw = (e) => {
        if (!drawing) return;

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.lineTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
        ctx.stroke();
    };

    const endDrawing = () => {
        setDrawing(false);
        recognizeCharacter();
    };

    const preprocessCanvas = (canvas) => {
        const ctx = canvas.getContext("2d");

        // Convert image to grayscale
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        for (let i = 0; i < imageData.data.length; i += 4) {
            const avg = (imageData.data[i] + imageData.data[i + 1] + imageData.data[i + 2]) / 3;
            imageData.data[i] = avg;      // Red
            imageData.data[i + 1] = avg;  // Green
            imageData.data[i + 2] = avg;  // Blue
        }
        ctx.putImageData(imageData, 0, 0);

        // Further binarize the image
        for (let i = 0; i < imageData.data.length; i += 4) {
            const brightness = imageData.data[i];
            const newValue = brightness > 128 ? 255 : 0;
            imageData.data[i] = newValue;
            imageData.data[i + 1] = newValue;
            imageData.data[i + 2] = newValue;
        }
        ctx.putImageData(imageData, 0, 0);

        // Redimensionne l'image à une taille plus petite pour accélérer la reconnaissance
        const tempCanvas = document.createElement('canvas');
        const tempCtx = tempCanvas.getContext('2d');
        tempCanvas.width = 100;
        tempCanvas.height = 100;
        tempCtx.drawImage(canvas, 0, 0, tempCanvas.width, tempCanvas.height);
        return tempCanvas;
    };

    const recognizeCharacter = () => {
        const canvas = canvasRef.current;

        // Preprocess the canvas image
        const processedCanvas = preprocessCanvas(canvas);

        // Tesseract.recognize(
        //     processedCanvas,
        //     lang,
        //     {
        //         tessedit_char_whitelist: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
        //         psm: Tesseract.PSM.SINGLE_CHAR,
        //         logger: (m) => console.log(m),
        //     }
        // ).then(({ data: { text, words } }) => {
        //     console.log(text);
        //     console.log(words);
        //     const firstLetter = words[0]?.text.charAt(0) || '?';
        //     const confidenceScore = words[0]?.confidence || 0;
        //     setResult(firstLetter);
        //     setConfidence(confidenceScore);
        // });
    };

    return (
        <div>
            <h1>Dessinez une lettre</h1>
            <canvas
                ref={canvasRef}
                width={300}
                height={300}
                style={{ border: '2px solid black' }}
                onMouseDown={startDrawing}
                onMouseMove={draw}
                onMouseUp={endDrawing}
                onMouseOut={endDrawing}
            />
            <p>Lettre détectée : <span>{result}</span></p>
            <p>Score de confiance : <span>{confidence ? (confidence).toFixed(2) + '%' : '?'}</span></p>
            <div>
                <label htmlFor="lang">Choisissez une langue : </label>
                <select id="lang" value={lang} onChange={(e) => setLang(e.target.value)}>
                    <option value="eng">English</option>
                    <option value="ara">Arabic</option>
                    <option value="rus">Russian</option>
                    {/* Ajouter d'autres options de langue ici */}
                </select>
            </div>
            <button onClick={clearCanvas}>Effacer</button>
        </div>
    );
};



const KanjiCharacter = ({ character }) => {
    const characterDivRef = useRef(null);
    const [isSupported, setIsSupported] = useState(true); // Par défaut à true pour essayer de charger le caractère
  
    useEffect(() => {
      const fetchCharacterData = async (char) => {
        try {
          const response = await fetch(`https://raw.githubusercontent.com/mnako/hanzi-writer-data-ja/master/data/${encodeURIComponent(char)}.json`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          if (characterDivRef.current) {
            const writer = HanziWriter.create(characterDivRef.current, char, {
              width: 100,
              height: 100,
              padding: 5,
              charDataLoader: () => Promise.resolve(data)
            });
            writer.quiz();
          }
        } catch (error) {
          console.error(`Error fetching character data for ${char}:`, error);
          setIsSupported(false);
        }
      };
  
      fetchCharacterData(character);
    }, [character]);
  
    return (
      <div>
        <div
          className='p-2 rounded-xl bg-red-100'
          id="character-target-div"
          ref={characterDivRef}
          style={{ width: 100, height: 100 }}
        >
          {isSupported ? null : <p>Caractère non supporté</p>}
        </div>
      </div>
    );
  };

const tts = `
Bonjour, je m'appelle François Pichot. Je suis une vrai petite salope. Mais je parle bien. Bisous le sang !! And Then Now I can also speak english hihih`




const ChineseCharacter = ({text}) => {
    const characterDivRef = useRef(null);
  
    useEffect(() => {
      if (characterDivRef.current) {
        const writer = HanziWriter.create(characterDivRef.current,text, {
          width: 100,
          height: 100,
          padding: 5
        });
  
        writer.quiz();
      }
    }, []);
  
    return (
      <div>
        <div
          className='p-2 rounded-xl bg-red-100'
          ref={characterDivRef}
          style={{ width: 100, height: 100 }}
        ></div>
      </div>
    );
  };




const NewDeck = ({setDecks, _deck, _setDeck, ct}) => {
    

    const [deck, setDeck] = useState(_deck)
    
    const addDeck = async() => {
        setDeck()
        const newDeck = {...deck,
            createdAt: new Date(),
            author: ct.user.id,   
            type: "fact"    
        }
    
       console.log('deccks', newDeck)
       const factRef = collection(db, "decks");
       const done = await addDoc(factRef, newDeck)

       setDecks(art => [...art || [], {...newDeck, id: done?.id}])
    
    }

    return <div onClick={() => { _deck && _setDeck(_deck)}}  className={`${!_deck ? "bg-indigo-500/10 border": "bg-blue-500 border-2 border-b-4 border-blue-700"} fredoka my-2 rounded-xl  p-4`}>
       <div className='flex justify-between' >
        <input onBlur={(e) => {
            e.stopPropagation()
            setDeck(d => ({...d, name: e.target.value}))}
            } defaultValue={deck?.name} placeholder='Nom de la liste ' className={` ${!_deck ? "border-b-2 text-purple-500" :"text-white " } bg-transparent  `}/>
        {!_deck && <div onClick={() => {addDeck()}} className='bg-blue-500 text-white px-3 py-1 inline-block rounded-xl'>Créer</div> }
        {_deck && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 text-white">
  <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
</svg> }

        </div>


    </div>
}

// ajouter les tags (20)
// ajouter génération du cours par IA prompt
// ajouter génération du cours par IA + Document


const addImg = async (prompt, storage, fact_id) => {
    console.log('addImg', prompt);

    try {
        const dataImg = await imageGenerator({
            "model": "dall-e-3",
            "prompt": prompt,
            "n": 1,
            "size": "1024x1024"
        });

        const response = await fetch(dataImg.data[0].url);
        const blob = await response.blob();

        const resizedBlob = await new Promise((resolve, reject) => {
            const img = new Image();
            img.src = URL.createObjectURL(blob);
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = 800;
                canvas.height = 800;
                ctx.drawImage(img, 0, 0, 800, 800);
                canvas.toBlob(resolve, 'image/webp'); // Convertit le canvas redimensionné en Blob
            };
            img.onerror = reject;
        });

        const storageRef = ref(storage, `dalle3_img/${fact_id}`);
        const snapshot = await uploadBytes(storageRef, resizedBlob);
        const downloadURL = await getDownloadURL(snapshot.ref);

        return downloadURL;

    } catch (error) {
        console.error("Error uploading image:", error);
        throw error; // Rejette la promesse avec l'erreur pour que l'appelant puisse la gérer
    }
};

// Utilisation de la fonction avec await

function addDelimiter(text) {
    const delimiter = ' || ';
    const minLength = 30;
    const maxLength = 40;

    let result = '';
    let currentLength = 0;
    const words = text.split(' ');

    for (const word of words) {
        if (currentLength + word.length + 1 > maxLength) {
            result += delimiter;
            currentLength = 0;
        }
        result += (currentLength === 0 ? '' : ' ') + word;
        currentLength += word.length + 1;

        // If the length after adding the word exceeds minLength and is less than maxLength, add delimiter
        if (currentLength >= minLength && currentLength < maxLength) {
            result += delimiter;
            currentLength = 0;
        }
    }

    return result;
}

// Example usage
const text = "Voici un exemple de texte qui doit être segmenté avec des délimiteurs tous les 30 à 40 caractères sans couper les mots.";
console.log(addDelimiter(text));


const addNewFact = async (fact, user, setFacts, deck) => {

    const newFact = {...fact,
        createdAt: new Date(),
        author: user.id,      
        deck_id: deck.id
    }

    console.log('newFact', newFact)
    const factRef = collection(db, "facts");
    const done = await addDoc(factRef, newFact)
    setFacts(art => [...art || [], newFact])

}

const updateNewFact = async (fact, user, setFacts, lang) => {

    const updatedFact = {...fact,
        updatedAt: new Date(),

    }

    console.log('newFact', updatedFact)
    const factRef = doc(db, "facts", fact.id);
    const done = await updateDoc(factRef, updatedFact)
    setFacts(art => art.map(a => a.id == updatedFact.id ? updatedFact : a))

}

const NewFact = ({ fact, user, setFacts, storage, deck, lang, index }) => {
    const [_fact, set_fact] = useState(fact);
    const [extended, setExtended] = useState(false)
    const origin = 'fr';
    const question = fact?.[`question_${lang}`] 
    const more = fact?.[`more_${lang}`]?.replace(/\[\d+\]\s*/g, '')?.replace(/\|/g, '')
    const sentence = fact?.[`text_${lang}`]?.replace(/\[\d+\]\s*/g, '')?.replace(/\|/g, '')
    const text = '<break time="1s"/>' + question + '<break time="0.7s"/>' + sentence + '<break time="0.7s"/>' + more 


    const read = () => synthesizeSpeech({voice: getVoiceByIndex(voices, index), 
        text: text , 
        setPlaying: () => {} , 
        setTsw: () => {}, 
        setWordIndex: () => {}, 
        setProgressAudio: () => {},
        storage, 
        fact: {...fact, [`audio_${lang}`]: null}, 
        lang}); 

    
    const titleRef = useRef(null);
    const questionRef = useRef(null);
    const textRef = useRef(null);
    const moreRef = useRef(null);
    const sourceRef = useRef(null);
    const tagRef = useRef(null);
    const originRef = useRef(null);

    const clearInputs = () => {
        if (titleRef.current) titleRef.current.value = '';
        if (questionRef.current) questionRef.current.value = '';
        if (textRef.current) textRef.current.value = '';
        if (moreRef.current) moreRef.current.value = '';
        if (sourceRef.current) sourceRef.current.value = '';
        if (tagRef.current) tagRef.current.value = '';
        if (originRef.current) originRef.current.value = 'fr';
    };

    const handleAddNewFact = () => {
        addNewFact(_fact, user, setFacts, deck);
        clearInputs();
        set_fact({});
    };

    return (
        <div onClick={() => setExtended(!extended)} className={`${fact ? "bg-blue-500/10" : "bg-emerald-500/10"}  mt-4  rounded-xl p-4`}>
            <div onClick={(e) => {e.stopPropagation(); console.log('_fact', _fact)}}>
            <div className='flex gap-2 items-center'>
            <img
                onClick={async () => {
                    const url = await addImg(
                        `Donne moi une image (sans aucun texte) qui représente l'information suivante  '${fact[`title_${origin}`]} - ${fact[`question_${origin}`]} '.
                        Dans le style illustration jolie, moderne et minimaliste (sans trop de complexité) au format 1024x1024.
                        Aucun mots ni écriture mots écris doit ne doit apparaitre. Aucun texte sur l'image`,
                        storage,
                        fact.id + "_0" || fact[`title_${origin}`].slice(0, 30)
                    );
                    console.log('url', url);
                    set_fact(f => ({ ...f, img: url }));
                    updateNewFact({..._fact, img: url}, user, setFacts)
                }}
                src={_fact?.img}
                className={`${extended ? "h-[88px] w-[88px]" : "h-[60px] w-[60px]"}  border-2 border-blue-300/50 bg-gray-200/20 rounded`}
            />
            <div className='flex gap-2'>
            <img
                onClick={async () => {
                    const url = await addImg(
                        `Donne moi une image (sans aucun texte) qui représente l'information suivante  '${fact[`title_${origin}`]} - ${fact[`question_${origin}`]} '.
                        Dans le style illustration jolie, moderne et minimaliste (sans trop de complexité) au format 1024x1024.
                        Aucun mots ni écriture mots écris doit ne doit apparaitre. Aucun texte sur l'image`,
                        storage,
                        fact.id + "_1" || fact[`title_${origin}`].slice(0, 30)
                    );
                    console.log('url', url);
                    set_fact(f => ({ ...f, img_1: url }));
                    updateNewFact({..._fact, img_1: url}, user, setFacts)
                }}
                src={_fact?.img_1}
                className={`${extended ? "h-[88px] w-[88px]" : "h-[60px] w-[60px]"}  border-2 border-blue-300/50 bg-gray-200/20 rounded`}
            />
            <img
                onClick={async () => {
                    const url = await addImg(
                        `Donne moi une image (sans aucun texte) qui représente l'information suivante  '${fact[`title_${origin}`]} - ${fact[`question_${origin}`]} '.
                        Dans le style illustration jolie, moderne et minimaliste (sans trop de complexité) au format 1024x1024.
                        Aucun mots ni écriture mots écris doit ne doit apparaitre. Aucun texte sur l'image`,
                        storage,
                        fact.id + "_2" || fact[`title_${origin}`].slice(0, 30)
                    );
                    console.log('url', url);
                    set_fact(f => ({ ...f, img_2: url }));
                    updateNewFact({..._fact, img_2: url}, user, setFacts)
                }}
                src={_fact?.img_2}
                className={`${extended ? "h-[88px] w-[88px]" : "h-[60px] w-[60px]"}  border-2 border-blue-300/50 bg-gray-200/20 rounded`}
            />
            <img
                onClick={async () => {
                    const url = await addImg(
                        `Donne moi une image (sans aucun texte) qui représente l'information suivante '${fact[`title_${origin}`]} - ${fact[`question_${origin}`]} '.
                        Dans le style illustration jolie, moderne et minimaliste (sans trop de complexité) au format 1024x1024.
                        Aucun mots ni écriture mots écris doit ne doit apparaitre. Aucun texte sur l'image`,
                        storage,
                        fact.id + "_3" || fact[`title_${origin}`].slice(0, 30)
                    );
                    console.log('url', url);
                    set_fact(f => ({ ...f, img_3: url }));
                    updateNewFact({..._fact, img_3: url}, user, setFacts)
                }}
                src={_fact?.img_3}
                className={`${extended ? "h-[88px] w-[88px]" : "h-[60px] w-[60px]"}  border-2 border-blue-300/50 bg-gray-200/20 rounded`}
            />
            </div>
            {(extended || !fact) ? <div>
                <textarea ref={titleRef} onBlur={(e) => { set_fact(f => ({ ...f, [`title_${origin}`]: e.target?.value })) }} className="w-full text-sm h-10 bg-transparent border-blue-500 border-b-2" placeholder="Titre" defaultValue={_fact?.[`title_${origin}`]}></textarea>
                <textarea ref={questionRef} onBlur={(e) => { set_fact(f => ({ ...f, [`question_${origin}`]: e.target?.value })) }} className="w-full text-sm h-10 text-blue-500 bg-transparent border-blue-500 border-b-2" placeholder="Question" defaultValue={_fact?.[`question_${origin}`]}></textarea>
            </div> : <div>
                <div className='text-sm text-blue-900/50'>{_fact?.[`title_${origin}`]}</div>
                <div className='text-md text-blue-500'>{_fact?.[`question_${origin}`]}</div>
                </div>}
            </div>
            {(extended || !fact) && <div>
            <textarea ref={textRef} onBlur={(e) => { set_fact(f => ({ ...f, [`text_${origin}`]: numberSentences(addDelimiter(e.target?.value)) })) }} className="w-full text-sm bg-transparent border-blue-500 border-b-2 h-20 text-slate-600" placeholder="Entrez votre fait culturel" defaultValue={_fact?.[`text_${origin}`]?.replace(/\[\d+\]/g, '').replace(/\|\|/g, "")}></textarea>
            <textarea ref={moreRef} onBlur={(e) => { set_fact(f => ({ ...f, [`more_${origin}`]: numberSentences(addDelimiter(e.target?.value)) })) }} className="w-full text-xs italic bg-transparent border-blue-500 border-b-2 h-20 text-slate-500" placeholder="Entrez votre fait culturel" defaultValue={_fact?.[`more_${origin}`]?.replace(/\[\d+\]/g, '').replace(/\|\|/g, "")}></textarea>
            {/* <input ref={sourceRef} placeholder='source' /> */}
            {/* <input ref={tagRef} placeholder='tag' /> */}
            {/* <input ref={originRef} placeholder='origin' defaultValue={"fr"} /> */}
            <div className='flex justify-end text-center'>{fact ? 
                <div onClick={() => updateNewFact(_fact, user, setFacts)} className='border-2 border-b-4 rounded-xl px-3 py-2 bg-purple-500 text-white'>Modifier</div> : 
                <div onClick={handleAddNewFact} className='border-2 border-b-4 rounded-xl px-3 py-2 bg-cyan-500 text-white'>Ajouter</div>}
                </div>
            </div>}
            <div>text: {lang} - {_fact?.[`text_${lang}`] && _fact?.[`question_${lang}`] && _fact?.[`title_${lang}`] && _fact?.[`more_${lang}`] ? "✅" : "❌"}</div>
            <div>wt: {lang} - {_fact?.[`wt_${lang}`] ? "✅" : "❌"}</div>
            <div>audio: {lang} - {_fact?.[`audio_${lang}`] ? "✅" : "❌"}</div>
            <div>src:  {_fact?.[`audio_${lang}`]}</div>
            <audio key={_fact?.[`audio_${lang}`]}  controls>
                    <source src={_fact?.[`audio_${lang}`]} type="audio/mpeg" />
                    Your browser does not support the audio element.
                </audio>
                <div className='' onClick={() => read()}>new sound</div>
            </div>
        </div>
    );
};


const AddFact = (props) => {
    const navigate = useNavigate()
    const ct = props.ct

    const storage = getStorage(app);
    useEffect(() => {
        console.clear()
        ct.setShowBottomBar(false)
    }, [])

    const [facts, setFacts] = useState()
    const [decks, setDecks] = useState()
    const [deck, setDeck] = useState()


    useEffect(() => {
        console.log("CultureG loaded")
        loadFacts()
        loadDecks()
    }, [])

    const loadDecks = async () => { 
        console.log('load decks 🟢🟢🟢')
        const q = query(collection(db, 'decks'), where("type", "==", "fact"))
        const querySnapshot = await getDocs(q);
        const _facts = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
        console.log('loaded decks', _facts)
        setDecks(_facts)
       }
    const loadFacts = async () => { 
        console.log('load articles 🟢🟢🟢')
        const q = query(collection(db, 'facts'))
        const querySnapshot = await getDocs(q);
        const _facts = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
        console.log('loaded articles', _facts)
        setFacts(_facts)
       }



       console.log('decks', decks)
       const addModes = ["Classique", "IA", "Document"]
       const [addMode, setAddMode] = useState("Classique")
       const [workspace, setWorkspace] = useState()

       const lang = workspace?.lang?.split('-')?.[0]


     




    return <div className="px-2 rounded-xl mb-2 overflow-scroll max-h-screen ">

        <div className='flex gap-2 flex-wrap p-4'>{ct.workspaces?.map(w => <div onClick={() => {setWorkspace(w)}} className={`px-2 ${w.id == workspace?.id ? "bg-purple-500" : ""} rounded-full border`}>{w.name}</div>)}</div>
        <div className='max-w-[500px] mx-auto p-4'>
            {/* <div className='p-16 rounded-xl bg-purple-100'>
        <ChineseCharacter text={"あ"} />
        <HandwritingRecognition />
        
        </div> */}
       
            {/* <h1 onClick={() => console.log('facts', facts)} className='mb-12'>Création de culture G</h1> */}
            {/* <div onClick={() => {synthesizeSpeech("fr-CH-FabriceNeural")}}>M homme</div>
            <div onClick={() => {synthesizeSpeech("fr-BE-GerardNeural")}}>M Gerar</div>
            <div onClick={() => {synthesizeSpeech("fr-FR-RemyMultilingualNeural")}}>Rémy (multi)</div>
            <div onClick={() => {synthesizeSpeech("fr-FR-VivienneMultilingualNeural")}}>Vivienne (multi)</div> */}
        
            
            {!deck ?
                <div className='mt-8'>
                    {!decks?.length ? <div>
                        <h2>Créer un cours avant de créer une carte</h2>
                        <p>Exemple les 7 merveilles du mondes ... </p>
               
                        <NewDeck ct={ct}  setDecks={setDecks}/>
                    </div> : 
                    <div>
                        <h2 className='mb-4'>Sélectionne une liste avant de créer des cartes</h2>
                        {decks.map(deck => <NewDeck ct={ct} _setDeck={setDeck} setDecks={setDecks} _deck={deck} />)}
                        <NewDeck ct={ct} setDecks={setDecks}/>
                        </div>}
                   
                </div> : <div>
                <div  onClick={() => {setDeck()}}  className='text-slate-500'> ← Choisir une autre liste</div>

                <div onClick={() => {setDeck()}} className='text-3xl fredoka text-blue-500 '>{deck.name}</div>

                {facts.filter(fact => fact.deck_id == deck.id)?.map((f, index) => <NewFact index={index} deck={deck} storage={storage} user={ct.user} setFacts={setFacts} fact={f} lang={lang}/>)}
                
                <div className='my-4 mt-12 flex gap-2'>{addModes.map(mode => <div onClick={() => {setAddMode(mode)}} className={`${addMode == mode ?  "bg-blue-500 text-white" : "bg-blue-500/10"} px-4 py-1 rounded-full border `}>{mode}</div>)}</div>
                
                
                {addMode == "Classique" && <NewFact deck={deck} storage={storage}  user={ct.user} setFacts={setFacts}  />}
                {addMode == "IA" &&<div className='p-4 border bg-blue-500/10 rounded-xl mt-2'>
                    Ajout automatique avec L'IA
                    <div className='flex justify-end'><div className='px-3 border-2 border-b-4 border-blue-700 rounded-xl text-white py-1 bg-blue-500'>Ajouter</div></div>
                </div>}
                {addMode == "Document" &&<div className='p-4 border bg-blue-500/10 rounded-xl mt-2'>
                    Ajout automatique à partir d'un document
                    <textarea className='p-2 rounded-xl mt-2 w-full' placeholder='Ajoutez votre cours ici'></textarea>
                    <div className='flex justify-end'><div className='px-3 border-2 border-b-4 border-blue-700 rounded-xl text-white py-1 bg-blue-500'>Ajouter</div></div>
                </div>}
            </div>}
        </div>
      </div>

};

export {AddFact};