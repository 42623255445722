// import Forest from './components/forest';
// import { createContext, useContext, useState, useEffect, useRef} from 'react';
// import { BrowserRouter as Router, Routes, Route, Link, useParams, useLocation } from 'react-router-dom';
// import { collection, addDoc, updateDoc, setDoc, getDoc, where, writeBatch, query, orderBy, doc, limit, getDocs } from "firebase/firestore";
// import { db, app} from "./config.js";
// import Classic from './components/classic';
// import AdminView from './components/adminView';
// import DeckView from './components/deckView';
// import LoginWithGoogle from './components/loginWithGoogle';
// import WorkspaceShow from './components/workspaceShow';
// import { useGlobalContext } from './utilities/globalContext';
// import LearnView from './components/learnView';
// import { debounce } from 'lodash';
// import { checkAuth } from './utilities/auth';
// import Leaderboard from './components/leaderboard'
// import { Store} from './components/store'
// import { DevoirView } from './components/devoirsView';
// import { UserView } from './components/userView';
// import {TestScreen} from './V2/screens/testScreen'
// import {DeckScreen} from './V2/screens/💰deckScreen'
// import {ImageUploadScreen} from './V2/screens/imageUpload'
// import { GoogleAuthProvider, getAuth, signInWithPopup, signOut, onAuthStateChanged } from "firebase/auth";


// import {learn, validCard, resetUserCards} from './utilities/learn';
// import { ParetoAdmin } from './components/paretoAdmin';
// import { CodeView } from './components/codeView';


// window.speechSynthesis.onvoiceschanged = () => {
//   // console.log("Voix disponibles mises à jour");
// };



// // document.ontouchmove = function(e) { 
// //   e.preventDefault()
// //   e.stopPropagation()
// //   console.log('hey')
// // };
// let oneTime = false

// let _intervalId = null
// let total =  0;
// let inactive = 0;
// let active = 0;
// let lastWp = null

// function App() {
//   const {theme, toggleTheme, setUser, mapObj, timer, setTimer, user, cards, decks, setDecks, userWorkspace, setUserWorkspace, strMapBiome, setStrMapBiome, setCards, workspace, setUserCards} = useGlobalContext();
//   const [showCards, setShowCards] = useState([]);
//   const appRef = useRef(null);
//   const auth = getAuth(app);
//   const [isActive, setActive] = useState(true)
//   const [currentPage, setCurrentPage] = useState();
//   const [resetTimer, setResetTimer] = useState()
//   const [hideNavBar, setHideNavBar] = useState()
//   const [intervalId, setIntervalId] = useState(_intervalId)
//   const [parcoursMode, setParcoursMode] = useState()
//   const [devoir, setDevoir] = useState([])
//   const [userDevoir, setUserDevoir] = useState([])
//   const [activeTimer, setActiveTimer] = useState(true)
//   const [showFooter, setShowFooter] = useState(true)
//   const [whiteHeader, setWhiteHeader] = useState(false)
//   const role = "admn"
//   const learnContext = (card, currentPage) => {
//     learn(
//       card, cards, setCards,  
//       user, workspace, userWorkspace, setUserWorkspace,
//       toggleTheme, 
//       strMapBiome, setStrMapBiome, mapObj,
//       currentPage, timer, resetTime)
//   }

 


//   // appRef.addEventListener('mousemove', () => {
//   //   // L'utilisateur est actif.
//   //   console.log('action')
//   // });

//   // appRef.addEventListener('touchstart', () => {
//   //   // L'utilisateur est actif.
//   //   console.log('action')
//   // });
  
//   const resetTime = () => {
//     console.log('RESET DU COMPEUT', intervalId)
//     total =  0;
//     inactive = 0;
//     active = 0;
//     setTimer({ ...timer, total, active, inactive });
//      clearInterval(intervalId)
//      setResetTimer(new Date())
//   }

  


  
//   let done = false

//   useEffect(() => {
//     if (!activeTimer) { 
//       clearInterval(intervalId)
//       return 
//     }
//     console.log('RESET DU TIMER')
//     if(!done) {
//       console.log('LANCEMENT DU TIMER')
//       done = true
//       const body = document.querySelector('body');
  
//       if (!oneTime) {
//         oneTime = true
//         body.addEventListener('touchstart', () => {
//           inactive = 0 
         
//           // clearInterval(intervalId)
//       });

//       body.addEventListener('mousemove', () => {
//         inactive = 0 
        
//         // clearInterval(intervalId)
//     });
//       }
    
//       setTimer({ ...timer, total, active, inactive });
//       _intervalId = setInterval(() => {
//         total++;
//         inactive++;
//         if (inactive < 20) active++;
//         setTimer({ ...timer, total, active, inactive });
//         // console.log('timer', timer)
//       }, 1000);

//       setIntervalId(_intervalId)
//   }

//   },[resetTimer, activeTimer])




//   const checkAuthDebounced = debounce(() => {
//     checkAuth(auth, user, setUser, toggleTheme);
//   }, 500);

//   useEffect(() => {
//     checkAuthDebounced();
//     console.log('useRRRRr', user)
  
//   }, []);

//   useEffect(() => {
//     console.log('user', user)
//   }, [user]);

//   const fetchCardsDebounced = debounce((id) => {
//     fetchCards(id);
//   }, 1000);

//   const fetchCards = async (deck_id) => {
//     console.log('fetching cards 🟢 ', deck_id);
//     const q =  query(collection(db, 'cards'), where('workspace_id', '==', workspace.id), orderBy('order')) 
//     const querySnapshot = await getDocs(q);
//     const _cards = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id }));
//     if (_cards.length) {
//         const userCardQuery = query(collection(db, 'user_cards'), where('workspace_id', '==', workspace.id), where('user_id', '==', userWorkspace.user_id));
//         const userCardQuerySnapshot = await getDocs(userCardQuery);
//         const _user_cards = userCardQuerySnapshot.docs.map(doc => (
//             {    ...doc.data(), 
//                 next_date: new Date(doc.data().next_date.seconds * 1000), 
//                 triggers: doc.data().triggers.map(trigger => ({...trigger, next_date: new Date(trigger.next_date?.seconds * 1000)})),
//                 id: doc.id
//              }));

//         const __cards = _cards.map(card => {
//             const uc = _user_cards.find(user_card => user_card.card_id == card.id)
//             return {original_card: card, user_card: uc}
//         })  
//         setCards(__cards);
//     }
//   }

//   useEffect(() => {
//    // workspace?.id && fetchCards()
// }, [workspace, user])


// const formatDeck = (deck, decks) => {
//  // console.log('deck', deck)
//   const user_deck = userWorkspace?.decks?.find(d => d.id == deck.id) || {};
//   const mastered = user_deck?.cards?.filter(c => c.date.seconds ? new Date(c.date.seconds * 1000) > new Date() : c.date > new Date()).length || 0

//   const totalCard = deck.counter || 0
//   const totalUserCard = user_deck?.cards?.length || 0
//   if (deck.name == "Les listes") {
//     console.log('deck', deck.name)
//     console.log('mastered', mastered)

//     console.log('totalUserCard', totalUserCard)
//     console.log('totalCard', totalCard)
//     console.log('toDiscover', totalCard - totalUserCard)
//   }
//   user_deck.mastered = mastered;
//   user_deck.review = totalUserCard - mastered;
//   user_deck.total = totalCard;
//   user_deck.toDiscover = totalCard - totalUserCard;
//   return {user_deck, deck}
// }

// // fonction qui met les decks à jours ({user_deck, deck})

// const formatAllDecks = (decks) => {
//   console.log('decks')
//   setDecks(decks.map(deck => {

    
//     const formatedDeck = formatDeck(deck, decks)
   
//     // console.log('data.id', deck.id)
//     formatedDeck.deck.id = deck.id
//     const parent_deck = decks.find(d => d.id == deck.deck_id) &&  formatDeck(decks?.find(d => d.id == deck.deck_id))
//     const child_decks = decks.filter(d => d.deck_id == deck.id).length && decks?.filter(d => d.deck_id == deck.id).map(d => formatDeck(d))
//     return {...formatedDeck, parent_deck, child_decks}
//   }))
// }



// const getDecks = async (workspace, lastWp) => {
 
//   console.log('lastWp 🟢🟢🟢', lastWp)
//   console.log('workspace 🟢🟢🟢', workspace?.id)
//   if (decks && lastWp == workspace?.id) {
//     console.log('NO NEW DECK')
//     console.log('decks !!', decks)
//     console.log('workspace.decks', workspace.decks)
//     decks && formatAllDecks(decks.map(d => d.deck))
//     //workspace.decks && formatAllDecks(workspace.decks)
//   } else{
//   console.log('GET DECK 🟢', workspace.id)
//   const q = query( collection(db, "decks"), where("workspace_id", "==", workspace.id))
//   const querySnapshot = await getDocs(q);
//   const _decks = querySnapshot.docs.map(d => {return{...d.data(), id: d.id}})
//   console.log('_decks', _decks)
//   formatAllDecks(_decks)
//   }


//   // console.log('decks', decks)
  
// }
//   useEffect(() => { 
//     workspace?.id && getDecks(workspace, lastWp)
//     if (workspace?.id) lastWp = workspace.id
//   }, [userWorkspace, workspace])

//   useEffect(() => {
//     if (currentPage == "devoirs" || currentPage) {
//       setHideNavBar(true)
//     }
//     if (currentPage == "forest" ||  currentPage == "leaderboard") {
//       setHideNavBar(false)
//     }
//   }, [currentPage])


//   const context = {
//     user: user,
//     setUser: setUser,
//     decks: decks,
//   }


//   const searchImg = '/images/mind_book.png';

//   const funLogin = '/images/fun_login.png';

//   const _cards = userWorkspace?.decks?.map(d => d?.cards).flat()

//   const xp =  cards?.map(c => c.user_card?.lvl ? c.user_card.lvl : 0).reduce((acc, curr) => acc + curr, 0);
//   let money = (xp * 1 - (userWorkspace?.money_spent || 0)) > 0 ? xp * 1 - (userWorkspace?.money_spent || 0) : 0


//   const classicView = <Classic parcoursMode={parcoursMode} setParcoursMode={setParcoursMode} mode="game" setCurrentPage={setCurrentPage} auth={auth} setShowCards={setShowCards} learn={(card) => learnContext(card, "classic")}  resetUserCards={() =>resetUserCards(cards, workspace, setCards, userWorkspace, setUserWorkspace)} role={role} />
//   const forestView = <Forest parcoursMode={parcoursMode} setParcoursMode={setParcoursMode} setHideNavBar={setHideNavBar} classicView={classicView} setCurrentPage={setCurrentPage} setShowCards={setShowCards} learn={(card) => learnContext(card, "forest")}  fetchCards={(id) => fetchCardsDebounced(id)} role={role}/>
//   return (
//     <Router >
//       {<div id="navbar" className={`${ whiteHeader ? "border-b" : "bg-transparent"} ${hideNavBar || currentPage == "focus" ? "hidden" : "flex"} fixed top-0 left-0 right-0 z-10 text-gray-400  p-4  justify-between items-center`}>
//         {user && <WorkspaceShow currentPage={currentPage} user={user} setUser={setUser} /> }

//         {/*
//         <div onClick={resetTime} className='bg-white text-black p-2 rounded-xl'>
//         [timer : ({userWorkspace?.timer_total || 0}) +  total{timer?.total}s  : ({userWorkspace?.timer_active || 0}) active: {timer?.active}s +  inactive: {timer?.inactive}s]
//          -- id: {intervalId}
//         </div> */}
//         <LoginWithGoogle auth={auth} user={user} setUser={user =>setUser(user)} />
      

//         <div className='flex gap-3'>
//         <div ref={appRef} className={` ${ whiteHeader  ? "text-yellow-600" : "text-yellow-200"}  px-3 py-1 flex items-center  gap-2 rounded-md relative font-bold text-xl`} style={{backgroundColor: currentPage != "classic" && currentPage != "user" ? "rgba(0,0,0,0.3)" : "#FFEFB6"}}>
//             <div>{xp || 0}</div>
//             <svg width="59" height="61" viewBox="0 0 59 61" fill="none" xmlns="http://www.w3.org/2000/svg" className=' h-6 w-6'>
//               <path d="M13.3894 34.9642L14.0795 33.3851L13.0631 31.9934L3.93779 19.4993C3.71235 19.1296 3.57261 18.433 3.89849 17.3754C4.26728 16.9727 4.86192 16.6663 5.373 16.5949L5.47056 16.6145L5.62903 16.6291L20.6215 18.0119L22.3494 18.1712L23.3491 16.7529L31.4966 5.19387C31.7986 4.98071 32.0288 4.74113 32.1972 4.5249C32.2443 4.46433 32.2878 4.4042 32.3278 4.34507C32.9164 3.81654 33.5302 3.72192 33.8719 3.79045C34.0774 3.83169 34.1635 3.91238 34.1865 3.94011C34.1977 3.95372 34.2186 3.98221 34.2385 4.04666C34.2591 4.11332 34.2891 4.25352 34.2756 4.49366L34.1297 7.09151L34.939 7.25384L37.8314 20.3078L38.2068 22.002L39.8624 22.5213L54.2283 27.0277L54.3802 27.0753L54.5362 27.1066L54.5524 27.1099C55.1105 27.3831 55.4677 27.8785 55.6097 28.6478C55.7158 29.2227 55.4652 29.8075 55.0202 30.1038L55.0201 30.1039L54.7145 30.3074L47.7869 34.385L47.7152 34.4272L47.6458 34.4734C45.3016 36.0345 43.2732 37.3758 42.0787 37.9464L40.3411 38.7763L40.372 40.7016L40.6116 55.612C40.56 56.6206 40.0773 57.0305 39.7912 57.1325L39.6455 57.1845L39.5058 57.2512C39.3737 57.3143 38.9052 57.3748 38.2823 57.128C38.1197 56.9366 37.9504 56.7817 37.7933 56.6593C37.6863 56.576 37.5807 56.5043 37.4796 56.4426L26.3913 46.9263L25.1141 45.8301L23.5129 46.3489L9.2665 50.9646L8.77702 51.1232C8.69239 51.1079 8.59726 51.0889 8.46961 51.0633C8.32056 51.0334 8.19963 50.9822 8.11998 50.9338C8.08351 50.9116 8.06348 50.8949 8.05509 50.8873L7.86151 50.5965L7.58839 50.3479C7.36376 50.1434 7.07651 49.579 7.41786 48.6286L13.3894 34.9642Z" fill="#FFCF4C" stroke="#FFEEBE" strokeWidth="6"/>
//             </svg>
//         </div>

//         {currentPage == "forest" && <div ref={appRef} className={` text-amber-400 px-3 py-1 flex items-center  gap-2 rounded-md relative font-bold text-xl`} style={{backgroundColor: currentPage != "classic" ? "rgba(0,0,0,0.3)" : "#FFEFB6"}}>
//             <div>{money || 0}</div>
//             <img className='h-5 w-5' src={'/images/UX/coins.svg'}/>
//         </div>
//         }

//         </div>



//         </div>

        
        
//       }


      
    
//       <div className="App h-screen overflow-hidden">
//       <Routes>
//         {workspace && user ? <><Route path="/forest/:uwp?" element={forestView}  />
//         <Route path="/classic/" element={<Classic  setCurrentPage={setCurrentPage} auth={auth} setShowCards={setShowCards} learn={(card) => learnContext(card)} validCard={validCard} resetUserCards={() =>resetUserCards(cards, workspace, setCards, userWorkspace, setUserWorkspace)} role={role} />} exact />
//         <Route path="/" element={forestView}  exact />
//         <Route path="/deck/:deck_id" element={<DeckView setCurrentPage={setCurrentPage} setHideNavBar={setHideNavBar} setShowCards={setShowCards} fetchCards={(id) => fetchCardsDebounced(id)}  learn={(card) => learnContext(card)} validCard={validCard} resetUserCards={() =>resetUserCards(cards, workspace, setCards, userWorkspace, setUserWorkspace)}  />} />
//         <Route path="/admin" element={<AdminView setCurrentPage={setCurrentPage} />} />
//         <Route path="v2/deck/" element={<DeckScreen context={context} setCurrentPage={setCurrentPage} setHideNavBar={setHideNavBar} setShowCards={setShowCards} fetchCards={(id) => fetchCardsDebounced(id)}  learn={(card) => learnContext(card)} validCard={validCard} resetUserCards={() =>resetUserCards(cards, workspace, setCards, userWorkspace, setUserWorkspace)}  />} />

//         <Route path="/leaderboard" element={<Leaderboard setCurrentPage={setCurrentPage} />} />
//         <Route path="/store" element={<Store user={user} setCurrentPage={setCurrentPage}/>} />
//         <Route path="/test" element={<TestScreen user={user} setCurrentPage={setCurrentPage}/>} />
//         <Route path="/imageUpload" element={<ImageUploadScreen user={user} setCurrentPage={setCurrentPage}/>} />
//         <Route path="/user" element={<UserView user={user} userWorkspace={userWorkspace} setCurrentPage={setCurrentPage}/>} />
//         <Route path="/devoirs" element={<DevoirView user={user} devoir={devoir} setDevoir={setDevoir} userDevoir={userDevoir} setUserDevoir={setUserDevoir} setCurrentPage={setCurrentPage}/>} />
//         <Route path="/pareto" element={<ParetoAdmin workspace={workspace} setActiveTimer={setActiveTimer} setCurrentPage={setCurrentPage} />} />
//         </>
//       :  <Route path="/store" element={<Store user={user} setCurrentPage={setCurrentPage}/>} />
//       }
//         <Route path="/editor" element={<CodeView setActiveTimer={setActiveTimer} user={user} setCurrentPage={setCurrentPage}/>} />

//       </Routes>

//       {!user && <div className='my-20 flex flex-cols justify-center items-center'>
//         <div className='text-center '>
//             <img src={funLogin}  className="mb-4 mx-auto" style={{ maxWidth: "700px"}}/>
//             <h2 className="mb-4">Vous n'êtes pas connecté</h2>
//             <LoginWithGoogle mode="plain" className="bg-purple-500 rounded-xl px-4 py-2 text-white" auth={auth} user={user} setUser={user => setUser(user)} />
           
//         </div>
//         </div>}
      
//         {!workspace && currentPage != "store" && user && <div className='my-20 flex flex-cols justify-center items-center'>
//         <div className='text-center'>
//             <img src={searchImg}  className="mb-4 mx-auto" style={{ maxWidth: "100%", width: 700}}/>
//             <h2 className="mb-4">Aucun espace de travail trouvé </h2>
        
//             <Link to="/store" className='bg-purple-500 rounded-xl px-4 py-2 text-white inline-block'>Rejoindre un cours</Link>
//         </div>
//     </div>}
       

//         {(currentPage != "focus" || role == "admin"  ) && <nav className={`${currentPage != "forest" ? "bg-purple-500" : "bg-purple-500 "} text-white fixed bottom-0 left-0 right-0 flex justify-around py-4 px-4     p-8`}>
         
        
//           <Link to="/classic">
//           <svg  className={`${currentPage == "classic" ? 'text-white scale-125' : "text-purple-300"} w-6 h-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
//             <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
//           </svg>

//           </Link>

         

//           <Link to="/devoirs">
//           <svg className={`${currentPage == "devoirs" ? 'text-white scale-125' : "text-purple-300"} transition w-6 h-6`} width="24" height="24" viewBox="0 0 24 24" fill="none" strokeWidth={1.5}  stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
//             <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
//           </svg>
//           </Link>

//           <Link to={"/forest/"}>
//           <svg className={`${currentPage == "forest" ? 'text-white scale-125' : "text-purple-300"} transition`} width="20" height="18" viewBox="0 0 20 18" fill="currentColor" strokeWidth={0}  stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
//             <path d="M4 0C5.66501 0.000223331 7.27545 0.59376 8.54237 1.67411C9.80929 2.75447 10.6498 4.25093 10.913 5.895C11.5155 5.29318 12.2308 4.81604 13.0179 4.49088C13.8049 4.16571 14.6484 3.99891 15.5 4H20V6.5C20 7.35359 19.8319 8.19883 19.5052 8.98744C19.1786 9.77606 18.6998 10.4926 18.0962 11.0962C17.4926 11.6998 16.7761 12.1786 15.9874 12.5052C15.1988 12.8319 14.3536 13 13.5 13H11V18H9V10H7C5.14348 10 3.36301 9.2625 2.05025 7.94975C0.737498 6.63699 0 4.85652 0 3V0H4ZM18 6H15.5C14.3065 6 13.1619 6.47411 12.318 7.31802C11.4741 8.16193 11 9.30653 11 10.5V11H13.5C14.0909 11 14.6761 10.8836 15.2221 10.6575C15.768 10.4313 16.2641 10.0998 16.682 9.68198C17.0998 9.26412 17.4313 8.76804 17.6575 8.22208C17.8836 7.67611 18 7.09095 18 6.5V6ZM4 2H2V3C2 4.32608 2.52678 5.59785 3.46447 6.53553C4.40215 7.47322 5.67392 8 7 8H9V7C9 5.67392 8.47322 4.40215 7.53553 3.46447C6.59785 2.52678 5.32608 2 4 2Z" />
//           </svg>
//           </Link>


//           {/* {(role == "admin" ) && userWorkspace?.user_id == user?.id && 
//             <Link to="/admin">
//               <svg className={`${currentPage == "admin" ? 'text-white ' : "text-purple-300"} w-6 h-6 transition`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
//               <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
//             </svg>

//             </Link> 
//           } */}

//          <Link to="/leaderboard">
//           <svg  className={`${currentPage == "leaderboard" ? 'text-white scale-125' : "text-purple-300 "} w-6 h-6 transition`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
//   <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0" />
// </svg></Link>

//         <Link to="/user">
//         <svg  className={`${currentPage == "user" ? 'text-white scale-125' : "text-purple-300 "} w-6 h-6 transition`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
//            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />


       
//         </svg>
//         </Link>

//         </nav> }
//       </div>
//       {showCards?.length ? <LearnView 
//         learn={(card) => learnContext(card, currentPage)}
//         showCards={showCards} 
//         quit={() => setShowCards(null)} 
//         validCard={(card, valid) => validCard(card, valid, cards, setCards, userWorkspace, setUserWorkspace, user, currentPage, timer, resetTime)}/> : <div></div>}
//     </Router>
//   );
// } 

// export default App;


import { BrowserRouter as Router, Routes, Route, Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { Whitelist } from './V2/screens/whitelist';


function App(props) {
  console.log('window.location.href', window.location.href)
  console.log('props', props)

return (
      <Router >
        <Routes>
         <Route path="/whitelist" element={<Whitelist url={props.url} />} />
         <Route path="/whitelistFull" element={<Whitelist full={true} url={props.url} />} />
         </Routes>
      </Router>       
)}


 export default App;










