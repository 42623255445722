import { useState, useRef, useEffect } from "react";
import { getMessaging, getToken } from "firebase/messaging";
import { getFunctions, httpsCallable } from "firebase/functions";
import { collection, addDoc, updateDoc,deleteDoc, setDoc, getDoc, where, writeBatch, query, orderBy, doc, limit, getDocs } from "firebase/firestore";

import { db, app } from "../../config.js";
import {fire} from '../🔥db.js'


import { ImageUpload } from "../components/imageUpload.js";
import {displayDate, getCardsFromIDS, reduceCard, reduceUC} from '../../utilities/helper/helper'
import { coachs } from "../data/notification.js";
const functions = getFunctions(app);
const setNotification = httpsCallable(functions, 'setNotification');
const setNotificationFunction = httpsCallable(functions, 'setNotification');
const sendNotificationForUser = httpsCallable(functions, 'sendNotificationForUser');
const fakeToken = "fEh4U_dF4T267-jsbK7nme:APA91bEZo9QHhLoaBasoEajfZy12LyVbFRuVxVlNAQ11N9AEwpQzeMQk7phj8g8rytktk2wieHOC0r8nAkd1jPFFGYC1DnbApNAQ6jGjRBwZrXz2RxYvZQb1REdo1kgVIWy7X3_nysdu"


const AudioRecorder = () => {
  const [audioURL, setAudioURL] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  // Demander l'accès au microphone
  const handleStartRecording = () => {
      if (isBlocked) {
          console.log('Permission Denied');
          return;
      }

      navigator.mediaDevices.getUserMedia({ audio: true })
          .then(stream => {
              mediaRecorderRef.current = new MediaRecorder(stream);
              mediaRecorderRef.current.start();

              mediaRecorderRef.current.ondataavailable = (event) => {
                  audioChunksRef.current.push(event.data);
              };

              mediaRecorderRef.current.onstart = () => {
                  setIsRecording(true);
              };

              mediaRecorderRef.current.onstop = () => {
                  const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp4' });
                  const audioUrl = URL.createObjectURL(audioBlob);
                  setAudioURL(audioUrl);
                  audioChunksRef.current = [];
              };

              setIsRecording(true);
          })
          .catch(() => {
              setIsBlocked(true);
          });
  };

  const handleStopRecording = () => {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
  };

  return (
      <div>
          <button onClick={handleStartRecording} disabled={isRecording}>
              Start Recording
          </button>
          <button onClick={handleStopRecording} disabled={!isRecording}>
              Stop Recording
          </button>
          {audioURL && <audio src={audioURL} controls />}
      </div>
  );
};

const TestScreen = (props) => {
  const ct = props.context

//   const [notif, setNotif] = useState()
//   useEffect(() => {
//     console.log("testScreen loaded")
   
//     console.clear()
// }, [])

// const messaging = getMessaging(app);      
// const vapidKey = "BCfw1h2IwNt4cj-YKrQtKrnZ8WatJwEvNCPoJXrmf-UmRWJfVvbVL7oiIrlOKow2tZx-TetGaoE54NM4bZzudOA"



// const getNotif = async() => {

//   const today = new Date()
//   // const queryRef = query(collection(db, "notifications"), where("user_id", "==", ct.user?.id));

//   const queryRef = query(collection(db, "notifications"), where("date", "<", today), where("sended", "==", false), limit(5000));
//   const snapshot = await getDocs(queryRef);
//   const _notif = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
//   console.log(_notif)
//   _notif.forEach((n,i) => {
//     console.log('n' + i, n.user_id)
//   })
//   console.log('user', ct.user?.id)
//   setNotif(_notif)
// }


// function checkMicrophoneAccess() {
//   const info = document.getElementById('info')
//   // Configuration pour demander l'accès au microphone uniquement
//   const constraints = { audio: true };

//   // Demander l'accès au microphone
//   navigator.mediaDevices.getUserMedia(constraints)
//   .then(function(stream) {
//       console.log("Accès au microphone accordé.");
//       info.innerHTML += "Accès au microphone accordé."
//       // Accès accordé
//       // Vous pouvez ici arrêter le stream si vous n'en avez plus besoin immédiatement
//       stream.getTracks().forEach(track => track.stop());
//   })
//   .catch(function(error) {
//     info.innerHTML += "Accès au microphone refusé."
//       console.error("Accès au microphone refusé:", error);
      
//       // Accès refusé ou une erreur s'est produite
//   });
// }


//  function requestPermission() {
//   const data = document.getElementById('data')
//   const info = document.getElementById('info')
//   const error = document.getElementById('error')
//   console.log('Requesting permission...');
//   info.innerHTML = ""
//   info.innerHTML += "init</br>"
//   console.log('')

//   askForIOS2()

//   if (window.isInWebView) {
//     // Appel une fonction native pour demander la permission
//     window.webkit.messageHandlers.requestNotificationPermission.postMessage({});
//     info.innerHTML += "init web view</br>"
//   }  else { 
//     info.innerHTML += "no web view</br>"

//   }
//   try {

//     if (window.myFcmToken) {
//       info.innerHTML += "myFcmToken !</br>"
//       console.log("FCM Token from native app:", window.myFcmToken);
//       info.innerHTML += "</br>-----</br>"
//       info.innerHTML += window.myFcmToken
//       info.innerHTML += "</br>-----</br>"
//       fire.updateUser({...ct.user, fcmToken: window.myFcmToken}, ct)
     
//       setNotificationFunction({ fcmToken: window.myFcmToken })
//       .then((result) => {
//         console.log("Notification set:", result);
//         info.innerHTML += "</br> NOTIFICATION ENVOYEE" 
//       })
//       .catch((error) => {
//         console.error("Error calling setNotification:", error);
//         info.innerHTML += "</br> BUG ENVOIE ENVOYEE" + JSON.stringify(error)
//       });
//       return
//   } else {
//     info.innerHTML += "myFcmToken... n'existe pas</br>"
//   }
//   if (!Notification) {
//     info.innerHTML += "pas de notif !</br>"
//     return
//   }
//   Notification.requestPermission().then(async(permission) => {
//     info.innerHTML += "init 2</br>"
//     if (permission === 'granted') {
//       info.innerHTML += "granted</br>"
//       console.log('Notification permission granted.');

//       const newSw = await navigator.serviceWorker.register( 'service-worker.js' );

// getToken(messaging, { vapidKey: vapidKey,  serviceWorkerRegistration:newSw }).then((currentToken) => {
//   console.log('Current token', currentToken);
//   const data = document.getElementById('data')
//   data.innerHTML = currentToken
//   fire.updateUser({...ct.user, fcmToken: currentToken}, ct)
//   if (currentToken) {
//     // Send the token to your server and update the UI if necessary
//     console.log('CURRNET TOKEN', currentToken)
//     setNotificationFunction({ fcmToken: currentToken })
//     .then((result) => { console.log("Notification set:", result);
//     })
//     .catch((error) => {
//       console.error("Error calling setNotification:", error);
//     });
    
//     // ...
//   } else {
//     // Show permission request UI
//     console.log('No registration token available. Request permission to generate one.');
//     error.innerHTML += "No registration token available. "
//     // ...
//   }
// })
// .catch((err) => {
//   console.log('An error occurred while retrieving token. ', err);
//   error.innerHTML += "An error occurred while retrieving token"
//   // ...
// });
//     } else {
//       console.log('Notification permission NOT granted.');
//       info.innerHTML += "not granted"
//       error.innerHTML += "not granted notificaton"
//     }
//   })} catch (err) {
//     console.log('error', err)
//     error.innerHTML += err
//   }
// }
  
// function copyToClipboard(text) {
//   console.log(text)
//   navigator.clipboard.writeText(text).then(function() {
//       console.log('Text successfully copied to clipboard');
//   }).catch(function(err) {
//       console.error('Unable to copy text to clipboard', err);
//   });
// }

// const askForIOS = () => {
//   const info = document.getElementById('info')
//   const error = document.getElementById('error')
//   error.innerHTML = JSON.stringify(window?.webkit?.messageHandlers)
//   if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.requestNotificationPermission) {
//     window.webkit.messageHandlers.requestNotificationPermission.postMessage(null);
    
//     info.innerHTML = 'WEBIT MODE'
//   } else {
   
//     console.log("Interface native non disponible");
//     info.innerHTML = 'pas de webkit'
//   }
// }

// const askForIOS2 = () => {
//   const info = document.getElementById('info')
//   if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers["push-permission-request"]) {
//     window.webkit.messageHandlers["push-permission-request"].postMessage(null);
  
//     info.innerHTML = 'WEBIT MODE 2'
//   } else {
   
//     console.log("Interface native non disponible");
//     info.innerHTML = 'pas de webkit'
//   }
// }

// const askForIOS3 = () => {
//   const info = document.getElementById('info')
//   if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers["push-permission-request"]) {
//     window.webkit.messageHandlers["push-permission-request"].postMessage(null);
  
//     info.innerHTML = 'WEBIT MODE 2'
//   } else {
   
//     console.log("Interface native non disponible");
//     info.innerHTML = 'pas de webkit'
//   }
// }


// const handleNotification = async () => {
//   const info = document.getElementById('info');
//   let _token = "";

//   // Demander la permission pour les iPhones
//   if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers["push-permission-request"]) {
//     window.webkit.messageHandlers["push-permission-request"].postMessage(null);
//   }

//   if (window.myFcmToken) {
//     _token = window.myFcmToken; // Récupération du token iPhone
//   } else { // Pour Android
//     info.innerHTML += 'ANDROID // <br/> ';
//     const permission = await Notification.requestPermission();

//     if (permission === 'granted') {
//       info.innerHTML += 'GRANTED // <br/> ';
//       const newSw = await navigator.serviceWorker.register('service-worker.js');
//       try {
//         _token = await getToken(messaging, { vapidKey: vapidKey, serviceWorkerRegistration: newSw });
//         info.innerHTML += 'GET TOKEN // <br/> ' + _token;
//       } catch (error) {
//         console.error('Unable to get token', error);
//       }
//     } else {
//       console.log('Permission not granted for Notifications');
//     }
//   }
//   console.log('token', _token);
//   info.innerHTML += 'TOKEN == ' + _token;
//   fire.updateUser({ ...ct.user, fcmToken: _token }, ct);
//   fire.addNotifications(ct);
// };




// const handleButtonClick = async () => {
//   setNotificationFunction({ fcmToken: fakeToken })
//   .then((result) => {
//     console.log("Notification set:", result);
//   })
//   .catch((error) => {
//     console.error("Error calling setNotification:", error);
//   });
// }

// const SpeechRecognition =
//   window.SpeechRecognition || window.webkitSpeechRecognition;
// const SpeechGrammarList =
//   window.SpeechGrammarList || window.webkitSpeechGrammarList;
// const SpeechRecognitionEvent =
//   window.SpeechRecognitionEvent || window.webkitSpeechRecognitionEvent;



// const [speechResult, setSpeechResult] = useState()
// const [recording, setRecording] = useState()



// let globalRecognition; // Déclaration au niveau du module/component



// const go = () => {
//   const info = document.getElementById('info');
//   setRecording(true);

//   // Vérifiez la disponibilité de SpeechRecognition avec un support pour Safari via webkitSpeechRecognition
//   const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
  
//   // Si SpeechRecognition n'est pas supporté, affichez un message et arrêtez l'exécution de la fonction
//   if (!SpeechRecognition) {
//     console.error("La reconnaissance vocale n'est pas supportée par ce navigateur.");
//     setSpeechResult('La reconnaissance vocale nest pas supportée par ce navigateur.')
//     setRecording(false);
//     info.innerHTML = "La reconnaissance vocale n'est pas supportée par ce navigateur.";
//     return;
//   }

//   var recognition = new SpeechRecognition();
//   globalRecognition = recognition; // Stockez l'objet recognition dans la variable globale

//   const langDATA = document.querySelector('#lang-data').value;
//   recognition.lang = langDATA;
//   recognition.continuous = true;
//   recognition.interimResults = false;
//   recognition.maxAlternatives = 1;

//   console.log('langDATA', langDATA);
//   setSpeechResult();

//   recognition.onresult = function(event) {
//     var speechResult = event.results[0][0].transcript;
//     console.log('Résultat reconnu : ' + speechResult);
//     setSpeechResult(speechResult);
//   };

//   recognition.onspeechend = function() {
//     recognition.stop();
//     setRecording(false);
//   };

//   recognition.onerror = function(event) {
//     console.error('Erreur de reconnaissance vocale : ', event.error);
//     setRecording(false);
//     setSpeechResult('Erreur de reconnaissance vocale', JSON.stringify(event.error))
//     info.innerHTML = "Erreur de reconnaissance vocal. "+ JSON.stringify(event.error);
//   };

//   recognition.onend = function() {
//     console.log('La reconnaissance vocale est terminée');
//     setRecording(false);
//   };

//   // Démarrez la reconnaissance vocale
//   try {
//     recognition.start();
//   } catch (e) {
//     console.error('Erreur lors du démarrage de la reconnaissance vocale:', e);
//     setRecording(false);
//     info.innerHTML = "Erreur lors du démarrage de la reconnaissance vocale"+ JSON.stringify(e);
//     setSpeechResult('Erreur lors du démarrage de la reconnaissance vocale')
//   }
// };

// const stopRecording = () => {
//   if (globalRecognition) {
//     console.log('stop')
//     globalRecognition.stop(); // Arrêtez l'objet recognition
//     setRecording(false); // Mettez à jour l'état pour refléter que l'enregistrement est arrêté
//   }
//   setRecording(false)
// };

const learnedId = new Set(ct.userCards?.map(uc => uc.card_id));
const allId = ct.cardMap || [];
// const nextID = allId.filter(id => !learnedId.has(id));
const nextID = [];

for (const id of allId) {
  if (!learnedId.has(id)) {
    nextID.push(id);

    if (nextID.length >= 10) {
      break;
    }
  }
}


// const nextCards = nextID.map(id => ct.cards?.find(c => c.id == id))
// console.log(nextCards)
const [nextCards, setNextCards] = useState()
const [cardLearned, setCardLearned] = useState()


// useEffect(() => {
//   console.log('setNextCards', nextID)
//   nextID.length && getCardsFromIDS(nextID)
// }, [nextID])

const updateCardsInDB = async (cards) => {
  try {

    
    const batch = writeBatch(db);

    cards.forEach(card => {
      if (!card.id) {
        console.log('Cannot update card with no ID', card);
        
        return 
      } else {

      
      const cardRef = doc(db, 'user_cards', card.id);
      console.log('update card 🟣 : ' + card.user_id +" : ", card.id)
      batch.set(cardRef, card)
      }
    });

    await batch.commit();
    console.log('Cards successfully updated in the database');
  } catch (error) {
    console.error('Error updating cards in the database: ', error);
  }
};








const transformeUserCards = async (wp_id, cards_wihout_card) => {
  console.log('transformeUserCards 🟢', wp_id)
  const q = query(collection(db, 'cards'), where('workspace_id', "==", wp_id))


  const querySnapshot = await getDocs(q);

  const cards = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
  console.log('TOUTES LES CARDS + ', wp_id, cards.length)
  const cardlearnedGood = cards_wihout_card.map(lc => {
    const cardFound = cards.find(c => c.id === lc.card_id)
    if (cardFound) {
      return {...lc, content: reduceCard
        (cardFound)}
     
    } else {
      console.error('Card not found', lc.card_id)
      return {...lc}
      
    }
  }).filter(c => c != null).map(uc => reduceUC(uc))
  console.log('cardlearnedGood II', cardlearnedGood)

  updateCardsInDB(cardlearnedGood.slice(0, 2000))
  setCardLearned(cardlearnedGood)

}



const loadLearnedCards = async(wp_id, user_id) => {
  console.log('loadLearnedCards 🟢', wp_id, user_id)
  const q = query(collection(db, 'user_cards'), where('workspace_id', "==", wp_id))
  const querySnapshot = await getDocs(q);
  const cards = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
  console.log('loadLearnedCards ✅', cards)



  setCardLearned(cards) 
}




  const [wp, setWp] = useState()

  const cards_wihout_card = cardLearned?.filter(lc => !lc?.content)


  
  return (
    <div className="w-full mt-[10px] max-h-[100vh] bg-yellow-50 overflow-scroll rounded-md relative p-4">

      
      <h1  className="text-xl" >Test 1.400</h1>
      <div onClick={async () => {
  try {
    const q = query(collection(db, 'user_workspace_element'), where('name', "==", "temple_0"));
    const querySnapshot = await getDocs(q);
    
    const batch = writeBatch(db);

    querySnapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });

    await batch.commit();
    console.log('All temple_0 documents deleted successfully');
  } catch (error) {
    console.error('Error removing documents: ', error);
  }
}}>Remove all temple_0</div>
      <div className="flex gap-2 text-sm flex-wrap">{ct.workspaces?.filter(w => w.img && w.order < 5)?.map(wp => <div  onClick={() => {
        setWp(wp)
        //props.loadNextCards(wp?.id, ct.setCardMap, ct.setCardMap)
        loadLearnedCards(wp?.id, ct.user?.id)
        }}>
        {wp.name}</div>)}</div>
      <div onClick={() => {
        console.log('click', )
        props.loadNextCards(ct.workspace?.id, ct.setCardMap)
    
      }
        }>LOAD MAP CARD - {ct.workspace?.id}</div>
      <div className="my-8 flex gap-2">
        <div>cardMap: {ct.cardMap?.length} |</div>
        <div onClick={() => {console.log("archived", cardLearned.filter(e => e.archived).map(uc => reduceUC(uc)))}}>learnedId: {cardLearned?.length} | </div>
        <div>nextID: {nextID?.length}</div> 
      </div>
      <div className="text-red-500 mb-4 pt-4 ">cards_wihout_card: {cards_wihout_card?.length}</div>
      {/* <div className="text-red-500 mb-4 pt-4 ">total_string: {JSON.stringify(cardLearned)?.length}</div> */}

      <div className="px-2 rounded-full bg-cyan-500 text-white" onClick={async () => {
        getCardsFromIDS(nextID, setNextCards)

         }}>GET NEXT CARDS</div>
      <div className="px-2 mt-2 mb-2 rounded-full bg-blue-500 text-white" onClick={() => { transformeUserCards(wp?.id, cards_wihout_card)}}>transformeUserCards ({cards_wihout_card?.length}) {wp?.id}</div>
      <div className="flex gap-2 mt-8 text-xs flex-wrap">{nextCards?.map(c => <div className="px-1 rounded bg-black/10">{c.term}</div>)}</div>
      
{/*   
      <div></div>
      <div className="p-2 text-blue-500 text-xl  border">{recording && "recording"}: {speechResult}</div>
      <div className="p-2 rounded-xl " onClick={() => go()}>GO</div>
      <div className="p-2 rounded-xl " onClick={() => stopRecording()}>Stop</div>
      <div onClick={() => {checkMicrophoneAccess()}}>checkMicrophoneAccess</div>
      <AudioRecorder />
      <div className="p-6" onClick={() => {getNotif()}}>Notification pour tout le monde {notif?.length} </div>

      <input defaultValue="en-US" id="lang-data"/>
      <audio src="https://firebasestorage.googleapis.com/v0/b/mindseed-425a8.appspot.com/o/toeic_audio_prep%2F1707826702826?alt=media&token=1a8a99c8-4313-4bc9-91be-4eafac4f8db8" controls></audio>
      <div onClick={async () => {
        console.log('data sended', {user_id: ct.user?.id, fcmToken: ct.user?.fcmToken})
        const result = await sendNotificationForUser({user_id: ct.user?.id, fcmToken: ct.user?.fcmToken})
        console.log('DATA', result.data)
        }} >sendNotificationForUser</div>
      <div onClick={askForIOS} className="px-2 py-1 rounded text-green-500 my-2  border ">Demander permission 1</div>
      <div onClick={askForIOS2} className="px-2 py-1 rounded text-blue-500 my-2 border " >Demander autorisation Iphone</div>
      <div onClick={askForIOS3} className="px-2 py-1 rounded text-blue-500 my-2 border " >Demander permission 3</div>
      <div id="info" className="text-blue-500">...</div>

      <div>mail: {ct.user?.email}</div>
      <div>User_id: {ct.user?.id}</div>
      <div>fmc : {ct.user?.fcmToken}</div>

      <div onClick={() => fire.addNotifications(ct, coachs[0], ct.user)} className="py-1 px-2 rounded border-2">Créer les 3 prochaines notifications</div>
      
      <div onClick={() => fire.getNotifications(ct)} className="">
        <div>Prochaines notifications prévues</div>
        {ct.notifications?.map(notif => <div className={`${notif.sended ? "text-green-500" : ""} p-2 rounded mt-2 border`}>{notif.content}</div>)}
      </div>


      <div className="px-2 py-1 rounded text-yellow-500 my-2 border " onClick={() => requestPermission()}>Demander les notification </div>
  
      <div id="error" className="text-red-500">...</div>
      <div  onClick={(e) => copyToClipboard(e.currentTarget.innerText)} id="data" className="text-sm hover:text-blue-500 text-green-500 text-wrap" style={{textWrap: "wrap"}}>--</div>
       */}
    </div>
  );
};

export {TestScreen};