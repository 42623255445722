import { collection, addDoc, updateDoc, getDoc, setDoc, where, writeBatch, query, orderBy, doc, limit, getDocs } from "firebase/firestore";
import { db, app } from "../config.js";
import { GoogleAuthProvider, getAuth, signInWithPopup, signOut, onAuthStateChanged } from "firebase/auth";
import { createEvent } from "./learn";



const checkAuth =  (auth, user, setUser, toogle, setIsLoading) => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const ios = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream

    
    auth.onAuthStateChanged((_user) => {
      console.log('onAuthStateChanged !!', _user)
     
      if (_user) {
        // Vérifier si l'utilisateur existe dans la base de données
        // console.log('_user', _user)
        const usersRef = collection(db, "users");
        const _query = query(usersRef, where("email", "==", _user.email));
        console.log('checkAuth 🟣')
        getDocs(_query).then((snapshot) => {
          

          // console.log("snapshot", snapshot.docs.map(doc => doc.data()))
  
          if (snapshot.empty && !user) {
           
            // Ajouter l'utilisateur à la base de données s'il n'existe pas
            console.log('_user', _user)
            const newUser = { 
              uid: _user.uid,
              name: _user.displayName,
              email: _user.email,
              last_connexion: new Date(),
              blocked: true,
  
            }

           
            addDoc(usersRef, newUser)
              .then((docRef) => {
                setUser({...newUser, id: docRef.id})
                console.log("Utilisateur ajouté à la base de données")

                createEvent({
                  name: "log", 
                  user_id: docRef.id.id, 
                  user_name: _user.displayName, 
                })
              })
              .catch((error) => console.error(error));
          

          } else {
            setUser({...snapshot.docs[0].data(), id:  snapshot.docs[0].id})
            setIsLoading(false)

            let isPwa = null
            if (window.matchMedia('(display-mode: standalone)').matches) {
              isPwa = true
              console.log('L\'application est installée sur l\'écran d\'accueil')
            }
       
            // createEvent({
            //   name: "log", 
            //   user_id: snapshot.docs[0]?.id, 
            //   user_name: snapshot.docs[0]?.data()?.name, 
            //   isPwa: isPwa
            // })
            updateDoc(snapshot.docs[0].ref, {last_connexion: new Date(), isPwa, ios});

    
          }
        });
      
        toogle()
       
      } else {
        setIsLoading(false)
      }
    

    })
  }

  export {checkAuth}